import { cloneDeep, get as lodashGet, merge, omit } from 'lodash'

import { generateServices, globalErrorHandler, globalErrorProcessor, nestjsServices, paramsMapByGeneration, permissionPresets, projectName } from '@/utils'

import render from './render'
import visitedFavorites from '../visitedFavorites'

export function serviceTemplate(options = {}) {
  const { serviceName, inputFilter, outputFilter } = options

  const serviceNameViaPoint = serviceName.split('/').join('.')
  options.serviceNameViaPoint = serviceNameViaPoint

  const getCheckPermissionParams = method => {
    const permission = [
      'advanced',
      lodashGet(options, `permission.${method}.serviceName`, serviceNameViaPoint),
      lodashGet(options, `permission.${method}.method`, method)
    ].join('.')

    return [ permission, lodashGet(options, `permission.${method}.accept`, permissionPresets.meUp) ]
  }

  let limitFromLocalStorage = 25
  try {
    limitFromLocalStorage = JSON.parse(window.localStorage.getItem(`${projectName}:pagination:limit:${serviceNameViaPoint}`))
  } catch (error) {
  }

  options.views = options.views || [ 'panels' ]
  options.view = options.view || options.views[0]
  options.limit = options.limit || limitFromLocalStorage || 24
  options.width = options.width || 400
  options.dialog = options.dialog || {
    create: {},
    remove: {}
  }
  options.show = options.show || {
    after: {
      create: false
    }
  }
  options.backendGeneration = options.backendGeneration || 'legacy'

  options.has = merge({
    clone: true,
    copy: {
      title: true,
      id: true
    },
    search: true,
    filter: true,
    view: true,
    source: false,
    support: false,
    tabs: true
  }, options.has || {})

  options.clone = merge({ replacements: {} }, options.replacements || {})

  options.errorProcessor = options.errorProcessor || undefined
  options.beforeCreated = options.beforeCreated || (() => {})
  options.mounted = options.mounted || (() => {})

  const mergeLegacyParams = params => {
    const result = {}
    if (!Object.values(nestjsServices).includes(serviceName)) {
      result.params = {
        query: {
          $scope: [ 'full' ]
        }
      }
    }

    return merge(result, params)
  }

  const find = () => {
    if (options.find === false) {
      return false
    }

    return merge({
      defaultFilter: { [paramsMapByGeneration[options.backendGeneration].search]: undefined },
      defaultPagination: { limit: options.limit }
    }, options.find)
  }
  const get = () => options.get === false ? false : mergeLegacyParams(options.get)
  const update = () => options.update === false ? false : mergeLegacyParams(options.update)
  const create = () => options.create === false ? false : options.create
  const remove = () => options.remove === false ? false : options.remove

  const mixins = [
    generateServices([
      {
        name: serviceName,
        path: options.path,
        backendGeneration: options.backendGeneration,

        inputFilter,
        outputFilter,

        errorProcessor: options.errorProcessor,

        find: find(),
        get: get(),
        update: update(),
        create: create(),
        remove: remove()
      }
    ])
  ]

  if (options.has.tabs) {
    mixins.push(visitedFavorites({ service: options.serviceName }))
  }

  return {
    name: 'Template',

    mixins,

    data() {
      return {
        view: options.view,

        showCreateDialog: false,
        showRemoveDialog: false,
        showSourceDialog: false,

        updatedData: undefined,
        createdData: undefined,

        show: options.show
      }
    },

    computed: {
      canCreate() {
        return this.checkPermissions(...getCheckPermissionParams('create'))
      },
      canRemove() {
        return this.checkPermissions(...getCheckPermissionParams('remove'))
      },
      canUpdate() {
        return this.checkPermissions(...getCheckPermissionParams('update'))
      },
      canGet() {
        return this.checkPermissions(...getCheckPermissionParams('get'))
      },

      serviceNameViaPoint() {
        return serviceNameViaPoint
      },

      title() {
        const data = this.restData[options.serviceName].get.data
        if (data) {
          const result = data.title || lodashGet(data, options.titlePath)
          if (result) {
            return result
          }
        }

        return this.getTranslate(`${serviceNameViaPoint}.title`)
      },
      $title() {
        const data = this.restData[options.serviceName].get.data
        if (data) {
          return data.title || lodashGet(data, options.titlePath)
        }
      },
      titleURLSlack() {
        if (this.title) {
          return `[${this.title}](${window.location.href})`
        }
      },

      headerFieldsGridTemplateColumns() {
        const result = []
        if (this.viewport.breakpoint.mdUp) {
          if (options.has.search) {
            result.push('250px')
          }
          if (options.has.filter) {
            result.push('1fr')
          }
        } else if (this.viewport.breakpoint.smUp) {
          if (options.has.search) {
            result.push('1fr')
          }
          if (options.has.filter) {
            result.push('1fr')
          }
        } else {
          result.push('1fr')
        }

        return result.join(' ')
      }
    },

    watch: {
      $route: {
        handler() {
          this.getOrFind()
          this.watchRouteQuery()
        },
        deep: true
      }
    },

    beforeCreated() {
      options.beforeCreated.call(this)
    },

    async mounted() {
      await options.mounted.call(this)

      await this.getOrFind()
      await this.watchRouteQuery()

      if (options.has.tabs) {
        this.getVisited()
        this.getFavorites()
      }
    },

    methods: {
      async create() {
        if (this.canCreate) {
          if (this.restData[serviceName].create.isValid) {
            try {
              const result = await this.rest[serviceName].create(this.restData[serviceName].create.data)
              if (result) {
                this.createdData = cloneDeep(result)
                this.showCreateDialog = false
                this.show.after.create = true
              }
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            }
          }
        }
      },

      async remove() {
        if (this.canRemove) {
          try {
            const result = await this.rest[serviceName].remove(this.$route.params.id)
            if (result) {
              this.showRemoveDialog = false
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      },

      async update() {
        if (this.canUpdate) {
          if (this.restData[serviceName].update.isValid) {
            try {
              const result = await this.rest[serviceName].update(this.restData[serviceName].get.data)
              if (result) {
                this.updatedData = cloneDeep(result)
              }
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            }
          }
        }
      },

      async getOrFind() {
        this.updatedData = undefined

        if (this.canGet) {
          if (this.$route.params.id) {
            this.rest[serviceName].get(this.$route.params.id)
          } else {
            await this.rest[serviceName].find()
          }
        }
      },

      async watchRouteQuery() {
        const create = lodashGet(this.$route, 'query.create')
        if (create) {
          this.showCreateDialog = true
        }
      },

      async clone() {
        const omittedFields = [ 'id', 'createdAt', 'updatedAt', '$formattedCreatedAt', '$formattedUpdatedAt', 'errors' ]
        const cloneData = omit(cloneDeep(this.restData[serviceName].get.data), omittedFields)

        const regex = /\((\d+)\)$/
        const titlePath = options.titlePath || 'title'
        const match = cloneData[titlePath].match(regex)

        if (match) {
          const index = parseInt(match[1])
          cloneData[titlePath] = cloneData[titlePath].replace(regex, `(${index + 1})`)
        } else {
          cloneData[titlePath] = `${cloneData[titlePath]} (1)`
        }

        return await this.rest[serviceName].create(merge(cloneData, options.replacements))
      },

      copy(key, value) {
        if (options.has.copy[key]) {
          navigator.clipboard.writeText(value)
          this.addSnackbar({
            text: this.getTranslate('misc.copied'),
            type: 'success'
          })
        }
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
