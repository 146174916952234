import { dialogTypes } from '@/components/file/utils'

import button from '@/components/button'
import fileDialog from '@/components/file/dialog'

function renderFileDialog(h) {
  return h(
    fileDialog,
    {
      props: {
        value: this.currentEditFile,
        googleFileURL: this.googleFileURL,
        show: this.showDialog,
        type: dialogTypes.edit
      },
      on: {
        show: event => {
          this.showDialog = event
        },
        input: event => {
          this.currentEditFile = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    this.component === 'btn' ? button : this.component,
    {
      props: this.$props,
      on: { click: () => this.edit() },
      scopedSlots: {
        dialog: () => {
          return renderFileDialog.call(this, h)
        }
      }
    }
  )
}
