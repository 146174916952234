import Vue from 'vue'

import { buttonModes, colors, components, getPrice, icons, sizes } from '@/utils'

import squircle from '@/components/icon/squircle'
import button from '@/components/button'
import tag from '@/components/tag'
import info from '@/components/info'
import textOverflow from '../../text/overflow'

function renderRefreshBalanceButton(h, trigger) {
  if (trigger) {
    return h(
      button,
      {
        props: {
          iconSVG: 'refresh',
          mode: buttonModes.flat,
          loading: this.loading,
          disabled: this.loading
        },
        on: {
          click: () => {
            this.getBalance()
          }
        }
      }
    )
  }
}

function renderRow(h, options) {
  const { icon, iconSVG, color, value, label, update } = options

  return h(
    'div',
    {
      class: 'grid grid-gap--12 faic',
      style: { 'grid-template-columns': 'auto 1fr auto' }
    },
    [
      h(
        squircle,
        {
          props: {
            icon,
            iconSVG,
            iconSize: 30,
            size: sizes.medium,
            color: color || colors.grey
          }
        }
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--3'
        },
        [
          h(
            textOverflow,
            {
              props: {
                value
              }
            }
          ),
          h(
            tag,
            {
              props: {
                label,
                size: sizes.tiny,
                color: colors.grey
              }
            }
          )
        ]
      ),

      renderRefreshBalanceButton.call(this, h, update)
    ]
  )
}
function renderRows(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderRow.call(this, h, {
        iconSVG: this.$verified ? 'user-verified' : 'user',
        iconSize: 32,
        color: this.$verified ? colors.info : colors.grey,
        value: this.account.username,
        label: this.$name
      }),
      renderRow.call(this, h, {
        iconSVG: 'wallet',
        value: getPrice(this.balance),
        label: this.getTranslate('account.current.balance'),
        update: true
      }),
      renderRow.call(this, h, {
        iconSVG: 'wallet-add',
        value: getPrice(this.overdraft),
        label: this.getTranslate('account.current.overdraft')
      })
    ]
  )
}
function renderInfo(h) {
  let value
  let color
  if (this.overdraft > 0) {
    value = this.getTranslate('account.tooltips.warning')
    color = colors.warning
  }
  if (this.overdraft === 0) {
    value = this.getTranslate('account.tooltips.error')
    color = colors.error
  }

  if (this.balance <= 0) {
    return h(
      info,
      {
        props: {
          value,
          color
        }
      }
    )
  }
}
function renderBody(h) {
  return h(
    'div',
    {
      class: 'grid'
    },
    [
      renderRows.call(this, h),
      renderInfo.call(this, h)
    ]
  )
}

function renderProfileButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('users.buttons.account'),
        mode: buttonModes.flat,
        color: colors.primary
      },
      on: {
        click: () => {
          this.$emit('show', false)
          Vue.router.push({ name: 'account' })
        }
      }
    }
  )
}
function renderLogoutButton(h) {
  if (this.isLoggedAs) {
    return h(
      button,
      {
        props: {
          icon: icons.exit_to_app,
          label: this.getTranslate('login.buttons.logout'),
          mode: buttonModes.flat
        },
        on: {
          click: () => {
            this.$emit('show', false)
            this.loginAs(false)
          }
        }
      }
    )
  } else {
    return h(
      button,
      {
        props: {
          label: this.getTranslate('login.buttons.logout'),
          mode: buttonModes.flat
        },
        on: {
          click: () => {
            this.logout()
          }
        }
      }
    )
  }
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 fjcsb',
      style: { 'grid-template-columns': 'repeat(2, auto)' }
    },
    [
      renderProfileButton.call(this, h),
      renderLogoutButton.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    components.card,
    {
      class: 'grid grid-gap--8 pa-2',
      props: {
        flat: this.flat,
        rounded: this.rounded,
        maxWidth: 400
      }
    },
    [
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
