import { FILE_TYPES, colors, components, inputModes, sizes } from '@/utils'

import tag from '@/components/tag'
import squircle from '@/components/icon/squircle'
import selectorOwner from '@/components/selector/owner'

function renderSearchField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.$search,
        label: this.getTranslate('commons.labels.search'),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.$search = event
        }
      }
    }
  )
}
function renderIdField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.id,
        label: this.getTranslate('commons.labels.id'),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.id = event
        }
      }
    }
  )
}
function renderSelectorOwner(h) {
  return h(
    selectorOwner,
    {
      props: {
        value: this.proxy.OwnerId,
        mode: inputModes.outline,
        dense: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}
function renderFileIconInFilter(h, color, size) {
  return h(
    squircle,
    {
      props: {
        iconSVG: 'folder-file',
        color: colors[color],
        size
      }
    }
  )
}
function renderTypeSelect(h) {
  return h(
    components.select,
    {
      props: {
        value: this.searchTypes,
        label: this.getTranslate('commons.labels.type'),
        items: this.typesItems,
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        multiple: true,
        disabled: this.types.length > 0
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            tag,
            {
              props: {
                label: item.title,
                size: sizes.small,
                color: FILE_TYPES[item.value].color
              }
            }
          )
        },
        item: ({ item }) => {
          return h(
            'div',
            {
              class: 'faic grid-gap--8'
            },
            [
              renderFileIconInFilter.call(this, h, FILE_TYPES[item.value].color, sizes.small),

              h(
                'div',
                { class: 'grid grid-gap--0 fjcc' },
                [
                  item.title,
                  h('div', { class: 'text--grey tiny lh-11' }, FILE_TYPES[item.value].extensions.join(', '))
                ]
              )
            ]
          )
        }
      },
      on: {
        input: event => {
          this.searchTypes = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'files__filter'
    },
    [
      renderSearchField.call(this, h),
      renderIdField.call(this, h),
      renderSelectorOwner.call(this, h),
      renderTypeSelect.call(this, h)
    ]
  )
}
