import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Actions',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    source: String
  },

  render
}
