import { components } from '@/utils'

export default function(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy,
        label: this.getTranslate('filter.preset'),
        items: this.items,
        mode: this.mode,
        details: false,
        dense: true,
        rounded: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
