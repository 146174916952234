import { allowedSendingTypes, components } from '@/utils'

import selectorSendingType from '@/components/selector/sendingType'

function renderIsActiveField(h) {
  return h(
    components.switch,
    {
      props: {
        value: this.proxy.isActive,
        label: this.getTranslate(`filter.isActive.${this.proxy.isActive}`),
        outline: true
      },
      on: {
        input: event => {
          this.proxy.isActive = event
        }
      }
    }
  )
}
function renderSelectorSendingType(h) {
  return h(
    selectorSendingType,
    {
      props: {
        value: this.proxy.type,
        label: this.getTranslate('commons.labels.type'),
        items: allowedSendingTypes
      },
      on: {
        input: event => {
          this.proxy.type = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'routes-filter'
    },
    [
      renderSelectorSendingType.call(this, h),
      renderIsActiveField.call(this, h)
    ]
  )
}
