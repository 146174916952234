import Vue from 'vue'

import { cloneDeep } from 'lodash'

import { components, services } from '@/utils'

import settingsUser from '@/components/services/settingsUser/template'
import referrals from '@/components/services/referrals/template'
import recurrentPaymentsList from '@/components/services/recurrentPayments/list'
import tariffsGenerator from '@/components/services/tariffs/generator'
import usersTokens from '@/components/services/users/tokens/template'
import usersRequisites from '@/components/services/users/requisites/template'
import usersBasicForm from '@/components/services/users/form/basic'

function renderTabByKey(h, key) {
  switch (key) {
    case 'general': {
      return h(
        usersBasicForm,
        {
          props: {
            value: this.proxy,
            errors: this.errors
          },
          on: {
            input: event => {
              this.proxy = event
            }
          },
          key
        }
      )
    }

    case services.settingsUser: {
      return h(
        settingsUser(
          {
            payload: cloneDeep(this.proxy),
            UserId: this.account.id,
            ResellerId: this.account.ResellerId || this.account.Reseller && this.account.Reseller.id
          }
        ),
        {
          class: 'pa-2',
          key
        }
      )
    }

    case services.tariffs: {
      return h(
        tariffsGenerator,
        {
          props: {
            value: this.proxy.Tariffs,
            readonly: true
          },
          key
        }
      )
    }

    case services.tokens: {
      return h(
        usersTokens,
        {
          props: {
            OwnerId: this.account.id
          },
          key
        }
      )
    }

    case services.requisites: {
      return h(
        usersRequisites,
        {
          props: {
            OwnerId: this.account.id,
            User: cloneDeep(this.proxy)
          },
          key
        }
      )
    }

    case services.recurrentPayments: {
      return h(
        recurrentPaymentsList(),
        {
          key
        }
      )
    }
    case 'referral': {
      return h(
        referrals,
        {
          props: {
            OwnerId: this.proxy.id
          },
          key
        }
      )
    }
  }
}
function renderTab(h, key) {
  return h(
    'div',
    {
      key
    },
    [ renderTabByKey.call(this, h, key) ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      h(
        components['tabs-header'],
        {
          class: 'pt-2 px-2',
          props: {
            value: this.currentTab,
            tabs: this.tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`${services.users}.tabs.${key}`)
              }
            })
          },
          on: {
            input: event => {
              const query = event === services.settingsUser ? this.$route.query : {}
              Vue.router.push({
                name: 'account',
                query: {
                  ...query,
                  currentTab: event
                }
              })
            }
          }
        }
      ),

      h(
        components.tabs,
        {
          props: { value: this.currentTab },
          scopedSlots: {
            body: () => this.tabs.map(tab => {
              return renderTab.call(this, h, tab)
            })
          }
        }
      )
    ]
  )
}
