import { buttonModes, icons, services } from '@/utils'

import button from '@/components/button'

export default function(h) {
  if (this.checkPermissions(`advanced.${services.requisites}.get`)) {
    return h(
      button,
      {
        props: {
          mode: buttonModes.flat,
          icon: icons.print,
          disabled: !this.address
        },
        on: {
          click: () => {
            this.print()
          }
        }
      }
    )
  }
}
