import render from './render'

export default {
  name: 'ButtonBucketRelease',

  props: {
    length: {
      type: Number,
      default: 0
    },

    disabled: {
      type: Boolean,
      default: false
    },

    callback: {
      type: Function,
      default: undefined
    }
  },

  render
}
