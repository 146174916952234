import { NOTIFICATIONS_AVAILABLE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, inputModes, services, sizes } from '@/utils'

import button from '@/components/button'
import info from '@/components/info'

function renderTitleField(h) {
  if (~[ 'web', 'email' ].indexOf(NOTIFICATIONS_AVAILABLE_TYPES[this.channel])) {
    return h(
      components['text-field'],
      {
        props: {
          value: this.value.subject,
          label: this.getTranslate(`${services.notifications}.labels.subject`),
          mode: inputModes['line-label'],
          details: false,
          clearable: true,
          rounded: true,
          disabled: this.loading
        },
        on: {
          input: event => {
            this.proxy.subject = event
          }
        }
      }
    )
  }
}
function renderBodyField(h) {
  return h(
    components.textarea,
    {
      class: { 'mt-1': this.viewport.breakpoint.mdUp },
      props: {
        value: this.value.body,
        label: this.getTranslate(`${services.notifications}.labels.body`),
        rows: 8,
        grow: true,
        mode: inputModes['line-label'],
        rounded: true,
        clearable: false,
        details: false,
        disabled: this.loading
      },
      on: {
        input: event => {
          this.proxy.body = event
        }
      }
    }
  )
}
function renderInfoDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${services.notifications}.titles.variables`),
        value: this.showInfoDialog,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: 800
      },
      on: {
        input: event => {
          this.showInfoDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'documentation-content pa-3',
          domProps: { innerHTML: this.html }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe facfe pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat

              },
              on: {
                click: () => {
                  this.showInfoDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}
function renderInfo(h) {
  return h(
    info,
    {},
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          this.getTranslate(`${services.notifications}.hints.variables`),

          h(
            'div',
            {
              class: 'fjcfe'
            },
            [
              h(
                button,
                {
                  props: {
                    label: this.getTranslate('misc.buttons.documentation'),
                    mode: buttonModes.flat,
                    size: sizes.small,
                    color: colors.primary
                  },
                  scopedSlots: {
                    dialog: () => renderInfoDialog.call(this, h)
                  },
                  on: {
                    click: () => {
                      this.showInfoDialog = true
                    }
                  }
                }
              )
            ]
          )
        ]
      )
    ]
  )
}
function renderForm(h) {
  return h(
    components.form,
    {
      class: {
        grid: true,
        'grid-gap--8': this.viewport.breakpoint.mdUp,
        'grid-gap--12': !this.viewport.breakpoint.mdUp
      }
    },
    [
      renderTitleField.call(this, h),
      renderBodyField.call(this, h),
      renderInfo.call(this, h)
    ]
  )
}
function renderPreview(h) {
  return h(
    components.card,
    {
      class: 'pxpx-12 py-2',
      props: {
        title: this.getTranslate('misc.preview'),
        outline: true,
        rounded: true
      }
    },
    [
      h(
        'div',
        {
          domProps: {
            innerHTML: this.value.body
          }
        }
      )
    ]
  )
}
function renderBody(h) {
  return h(
    'div',
    {
      class: {
        'grid pt-2 px-2': true,
        'grid-gap--8': this.viewport.breakpoint.mdUp,
        'grid-gap--12': !this.viewport.breakpoint.mdUp
      },
      style: { gridTemplateColumns: this.viewport.breakpoint.mdUp ? '1fr 1fr' : '1fr' }
    },
    [
      renderForm.call(this, h),
      renderPreview.call(this, h)
    ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'pa-2 fjcfe grid-gap--8',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      ),

      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.save'),
            icon: icons.save,
            color: colors.primary,
            mode: buttonModes.filled,
            loading: this.loading,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.clickHandler()
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(this.title),
        rounded: this.viewport.breakpoint.mdUp,
        value: this.showDialog,
        maxWidth: 800
      },
      on: {
        input: event => {
          if (event === false) {
            this.$emit('close', true)
          }
          this.showDialog = event
        }
      }
    },
    [
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
