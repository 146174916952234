import { types } from './sendings'

export const notificationTypes = {
  tariff: {
    update: 'Тариф обновлён'
  },

  balance: {
    limit: 'Достигнут лимит баланса',
    accrual: 'Начисление на баланс',
    daily: 'Ежедневное уведомление о балансе'
  },

  payments: {
    failed: 'Платёж не прошёл'
  },

  auth: {
    registration: 'Регистрация',
    recover: 'Восстановление пароля'
  },

  pattern: {
    approved: 'Шаблон одобрен',
    rejected: 'Шаблон отклонён'
  },

  sendername: {
    approved: 'Имя отправителя одобрено',
    rejected: 'Имя отправителя отклонено'
  },

  subscription: {
    enabled: 'Подписка активирована',
    renew: {
      failed: 'Подписка не продлена'
    }
  },

  sendings: {
    bulk: {
      created: 'Создана массовая рассылка',
      paused: 'Массовая рассылка приостановлена',
      resumed: 'Массовая рассылка возобновлена'
    }
  },

  system: {
    platform: {
      issues: 'Системная ошибка платформы'
    },

    route: {
      issues: 'Системная ошибка маршрутизации'
    }
  }
}

const templates = { ...notificationTypes }

export default {
  title: 'Уведомления',

  titles: {
    variables: 'Переменные'
  },

  subtitles: {
    settingsSite: {
      smtp: 'SMTP подключение',
      sms: 'SMS уведомления',
      templates: 'Шаблоны уведомлений'
    }
  },

  labels: {
    notification: 'Уведомление',
    subject: 'Тема',
    body: 'Тело шаблона',

    channel: 'Канал',
    owner: 'Адресат',
    targetUser: 'Адресат',
    type: 'Тип',
    text: 'Текст',
    sentAt: 'Отправлено в',
    isRead: 'Статус',

    widget: {
      header: 'Последние уведомления',
      hint: 'Последние 10 уведомлений, полученные по каналу Web',
      empty: 'Здесь будут отображаться ваши уведомления'
    },

    settingsSite: {
      smtp: {
        host: 'Хост',
        port: 'Порт',
        from: 'От',
        login: 'Имя пользователя',
        password: 'Пароль'
      },

      sms: {
        senderUserId: 'Отправитель',
        sendername: 'Имя отправителя'
      },

      templates: {
        users: 'Шаблоны для пользователей',
        managers: 'Шаблоны для менеджеров'
      }
    },

    settingsUser: {
      templates: {
        my: 'Собственные события',
        users: 'События дочерних пользователей'
      },

      balance: {
        title: 'Уведомление о балансе',

        include: {
          overdraft: 'Учитывая овердрафт',
          invoice: 'отправлять счёт'
        },

        notInclude: {
          overdraft: 'Без учёта овердрафта',
          invoice: ''
        },

        limit: 'Порог',

        onLimit: 'При балансе',
        noLimit: '<не указан порог>',
        notifyBy: 'уведомлять по',
        noNotifications: '<не уведомлять>',
        activator: 'Уведомление сработает при балансе {limit}',
        overdraft: ' + овердрафт пользователя',
        extraRecipients: 'Уведомления о состоянии баланса всех пользователей системы',
        invoice: 'Приложить счёт на сумму {amount}',

        buttons: {
          add: 'Добавить лимит',
          invoice: {
            attach: 'Приложить счёт',
            detach: 'Открепить счёт',
            attached: 'Будет выставлен счёт на сумму {amount}'
          }
        }
      }
    }
  },

  hints: {
    variables: 'Пожалуйста, обратитесь к документации, чтобы узнать больше о правильном заполнении шаблонов и использовании переменных.',

    widget: {
      header: 'Здесь отображаются последние уведомления по темам, для которых включены уведомления по каналу веб.'
    },

    notifications: {
      events: {
        balance: {
          limit: {
            empty: 'Не указано ни одного лимита баланса.<br>Вы легко можете добавить новый лимит, нажав на кнопку "Добавить" ниже.'
          }
        }
      }
    }
  },

  channels: types,
  types: notificationTypes,
  events: notificationTypes,
  childrenUsersEvents: notificationTypes,

  email: {
    smtp: {
      host: 'Хост',
      port: 'Порт',
      from: 'От',
      login: 'Имя пользователя',
      password: 'Пароль'
    },

    templates
  },

  sms: {
    sendername: 'Имя отправителя',
    senderUserId: 'Отправитель',
    templates
  },

  web: { templates },
  push: { templates },
  tgBot: { templates },

  snackbars: {
    updated: 'Настройка успешно обновлена.'
  },

  isRead: {
    true: 'Прочитано',
    false: 'Не прочитано',
    sent: 'Отправлено: {date}',
    delivered: 'Доставлено: {date}'
  },

  tooltips: {
    channel: {
      on: 'Включён',
      off: 'Выключен'
    }
  }
}
