import { REST_ACTIONS } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, permissionPresets, services, states } from '@/utils'

import button from '@/components/button'
import preloader from '@/components/preloader'
import pagination from '@/components/pagination'
import buttonRefresh from '@/components/button/refresh'
import actions from '@/components/templates/service/item/actions'
import requisitesItem from '@/components/services/requisites/item'
import requisitesForm from '@/components/services/requisites/form'

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [ h(preloader) ]
  )
}

function renderCreateButton(h) {
  if (this.checkPermissions(`advanced.${services.requisites}.create`, permissionPresets.meUp)) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate(`${services.requisites}.buttons.add`),
          icon: icons.add,
          color: colors.secondary
        },
        on: {
          click: () => {
            this.dialogType = REST_ACTIONS.create
            this.showDialog = true
          }
        }
      }
    )
  }
}
function renderRefreshButton(h) {
  return h(
    buttonRefresh,
    {
      props: {
        state: this.restData[services.requisites].find.state,
        shortClickMethod: () => this.rest[services.requisites].find(),
        longClickMethod: () => this.rest[services.requisites].find({}, { noCache: true })
      }
    }
  )
}
function renderPagination(h) {
  return h(
    pagination,
    {
      props: {
        value: this.restData[services.requisites].find.pagination,
        show: this.restData[services.requisites].find.pagination.total >= this.restData[services.requisites].find.pagination.limit,
        service: services.requisites
      },
      on: {
        input: event => {
          this.restData[services.requisites].find.pagination = event
        }
      }
    }
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'flex grid-gap--8 pt-2 px-2'
    },
    [
      renderCreateButton.call(this, h),
      h('div', { class: 'ff' }),
      renderPagination.call(this, h),
      renderRefreshButton.call(this, h)
    ]
  )
}

function renderDivider(h, trigger = false) {
  if (trigger) {
    return h(components.divider)
  }
}
function renderItem(h, item) {
  return h(
    requisitesItem,
    {
      props: {
        value: item
      },
      key: item.id,
      scopedSlots: {
        actions: () => {
          return h(
            actions,
            {
              props: {
                value: item,
                service: services.requisites,
                accept: permissionPresets.meUp,
                editCallback: () => this.editClickHandler(item.id)
              }
            }
          )
        }
      }
    }
  )
}
function renderItems(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderDivider.call(this, h, true),

      this.restData[services.requisites].find.data.map(item => {
        return renderItem.call(this, h, item)
      }),

      renderDivider.call(this, h, this.$route.name === `${services.users}.single`)
    ]
  )
}
function renderBody(h) {
  switch (this.restData[services.requisites].find.state) {
    case states.loading: return renderPreloader.call(this, h)
    case states.ready:
    default: {
      if (this.restData[services.requisites].find.pagination.total > 0) {
        return renderItems.call(this, h)
      } else {
        return h(components.empty)
      }
    }
  }
}

function renderDialogBody(h) {
  switch (this.dialogType) {
    case REST_ACTIONS.create: {
      return h(
        requisitesForm,
        {
          props: {
            value: this.restData[services.requisites].create.data,
            OwnerId: this.OwnerId,
            isNew: true
          },
          on: {
            input: event => {
              this.restData[services.requisites].create.data = event
            },
            validation: event => {
              this.restData[services.requisites].create.isValid = event
            }
          }
        }
      )
    }
    case REST_ACTIONS.update: {
      switch (this.restData[services.requisites].get.state) {
        case states.loading: return renderPreloader.call(this, h)
        case states.ready: {
          return h(
            requisitesForm,
            {
              props: {
                value: this.restData[services.requisites].get.data
              },
              on: {
                input: event => {
                  this.restData[services.requisites].get.data = event
                },
                validation: event => {
                  this.restData[services.requisites].update.isValid = event
                }
              }
            }
          )
        }
      }
    }
  }
}
function renderDialogFooter(h) {
  if (this.showDialog) {
    const loading = this.restData[services.requisites][this.dialogType].state === states.loading

    return h(
      'div',
      {
        class: 'fjcfe grid-gap--8 pa-2',
        slot: 'footer'
      },
      [
        h(
          button,
          {
            props: {
              label: this.getTranslate('misc.buttons.cancel'),
              mode: buttonModes.flat,
              disabled: loading
            },
            on: {
              click: () => {
                this.showDialog = false
              }
            }
          }
        ),
        h(
          button,
          {
            props: {
              label: this.getTranslate(`misc.buttons.${this.dialogType}`),
              color: colors.primary,
              loading: loading,
              disabled: loading || !this.restData[services.requisites][this.dialogType].isValid
            },
            on: {
              click: () => {
                this[this.dialogType]()
              }
            }
          }
        )
      ]
    )
  }
}
function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.showDialog ? this.getTranslate(`${services.requisites}.dialogs.titles.${this.dialogType}`) : undefined,
        value: this.showDialog,
        rounded: this.viewport.breakpoint.mdUp ? true : undefined,
        maxWidth: 800
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderDialogBody.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}
