import button from '@/components/button'
import buttonReport from '@/components/button/report'
import billingsForm from '@/components/services/billings/form'
import billingsFilter from '@/components/services/billings/filter'
import billingsTasksTemplate from '@/components/services/billings/tasks/template'

import { buttonModes, colors, components, inputModes, permissionPresets, routerQueryReplace, services, states } from '@/utils'

const presets = [ `${services.billings}.details`, `${services.billings}.group001` ]

function renderPresetField(h) {
  if (this.checkPermissions(`advanced.${services.reports}.get`, permissionPresets.meUp)) {
    return h(
      components.select,
      {
        props: {
          value: this.preset,
          items: presets.map(preset => ({
            title: this.getTranslate(`${services.billings}.presets.${preset}`),
            value: preset
          })),
          mode: inputModes.outline,
          dense: true,
          rounded: true,
          required: true,
          details: false
        },
        on: {
          input: event => {
            this.preset = event
          }
        }
      }
    )
  }
}
function renderButtonReport(h) {
  return h(
    buttonReport,
    {
      props: {
        preset: this.preset,
        filter: this.filter
      }
    }
  )
}
function renderTitleSlot(h) {
  return h(
    'div',
    {
      class: `${services.billings}-header__title`,
      slot: 'title'
    },
    [
      renderPresetField.call(this, h),
      renderButtonReport.call(this, h)
    ]
  )
}

function renderFilter(h) {
  return h(
    billingsFilter,
    {
      props: {
        value: this.filter,
        preset: this.preset
      },
      on: {
        input: event => {
          this.filter = event
        }
      },
      slot: 'filter'
    }
  )
}

function renderDialogBody(h) {
  return h(
    billingsForm,
    {
      props: {
        value: this.restData[services.billings].create.data,
        errors: this.restData[services.billings].create.errors
      },
      on: {
        input: event => {
          this.restData[services.billings].create.data = event
        },
        validation: event => {
          this.restData[services.billings].create.isValid = event
        }
      }
    }
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat,
            disabled: this.restData[services.billings].create.state === states.loading
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.create'),
            color: colors.primary,
            loading: this.restData[services.billings].create.state === states.loading,
            disabled: !this.createDataIsValid
          },
          on: {
            click: () => {
              this.billingsCreate()
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${services.billings}.dialogs.create`),
        value: this.showDialog,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 500 : undefined
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderDialogBody.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}

function renderContentByPreset(h) {
  return h(
    this.presetNameKebab,
    {
      props: { filter: this.filter },
      on: {
        input: event => {
          this.filter = event
        },
        create: () => {
          this.showDialog = true
        }
      }
    },
    [
      renderTitleSlot.call(this, h),
      renderFilter.call(this, h)
    ]
  )
}
function renderBillingsTabItem(h) {
  return h(
    components.card,
    {
      props: {
        rounded: true,
        outline: true,
        overflow: true
      },
      key: services.billings
    },
    [
      renderContentByPreset.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}

function renderTasksTabItem(h) {
  if (this.checkPermissions(`advanced.${services.tasks}.find`, permissionPresets.resellerUp)) {
    return h(billingsTasksTemplate, { key: services.tasks })
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: services.billings
    },
    [
      h(
        components['tabs-header'],
        {
          props: {
            value: this.currentTab,
            tabs: this.tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`${services.billings}.tabs.${key}`)
              }
            }),
            isLink: true
          },
          on: {
            input: event => {
              routerQueryReplace({ currentTab: event })
            }
          }
        }
      ),

      h(
        components.tabs,
        {
          props: { value: this.currentTab }
        },
        [
          renderBillingsTabItem.call(this, h),
          renderTasksTabItem.call(this, h)
        ]
      )
    ]
  )
}
