export default {
  title: 'Группы маршрутизаций',

  titles: {
    create: 'Создать группу маршрутизаций'
  },

  routingGroup: 'Группа маршрутизаций',
  routingGroups: 'Группы маршрутизаций',
  one: 'Группа маршрутизаций',
  many: 'Группы маршрутизаций',

  labels: {
    title: 'Заголовок',
    type: 'Тип',
    routingRule: 'Правило маршрутизации',
    route: 'Маршрут',
    search: 'Поиск',
    description: 'Описание',
    isActive: 'Активен'
  },

  tabs: {
    eventsHistory: 'История изменений'
  },

  dialogs: {
    new: 'Новая группа маршрутизаций'
  },

  buttons: {
    add: {
      route: 'Добавить маршрут',
      routingRule: 'Добавить правило маршрутизации'
    }
  },

  hints: {
    notFound: 'Группы маршрутизаций не найдены'
  },

  contents: {
    confirm: {
      remove: 'Подтвердите удаление {title}?'
    }
  },

  tooltips: {
    isActive: 'Активировать или деактивировать группу маршрутов.',
    favorites: 'Сделать группу маршрутизации избранной',
    routingRules: 'Список правил маршрутизации',
    remove: 'Удалить группу маршрутизации из списка выбранных групп?',

    filter: {
      isActive: 'Показать только активные или неактивные группы маршрутизации'
    }
  },

  subtitles: {
    routingRules: 'Правила маршрутизаций'
  },

  snackbars: {
    created: 'Группа маршрутизаций успешно создана.',
    updated: 'Группа маршрутизаций успешно обновлена.',
    removed: 'Группа маршрутизаций успешно удалена.'
  }
}
