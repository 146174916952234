import { components, inputModes, permissionPresets, routerQueryReplace, services } from '@/utils'

import buttonReport from '@/components/button/report'
import outgoingFilter from '@/components/services/sendings/outgoing/filter'
import incomingTemplate from '@/components/services/sendings/incoming/template'
import tasksTemplate from '@/components/services/sendings/tasks/template'

function renderSelectPresetField(h) {
  if (this.checkPermissions(`advanced.${services.reports}.get`, permissionPresets.meUp)) {
    return h(
      components.select,
      {
        style: {
          'min-width': this.viewport.breakpoint.smDown ? '100%' : '308px',
          'max-width': this.viewport.breakpoint.smDown ? '100%' : '308px'
        },
        props: {
          value: this.preset,
          items: this.presets
            .filter(item => !!item.show)
            .map(preset => ({
              title: this.getTranslate(preset.title),
              value: preset.value
            })),
          mode: inputModes.outline,
          dense: true,
          rounded: true,
          required: true,
          details: false
        },
        on: {
          input: event => {
            this.preset = event
          }
        },
        slot: 'title'
      }
    )
  }
}

function renderFilter(h) {
  return h(
    outgoingFilter,
    {
      props: {
        value: this.filter,
        preset: this.preset
      },
      on: {
        input: event => {
          this.filter = event
        }
      },
      slot: 'filter'
    }
  )
}

function renderTabs(h) {
  return this.tabs.map(key => {
    switch (key) {
      case 'outgoing': {
        return h(
          components.card,
          {
            props: {
              rounded: true,
              outline: true
            },
            key
          },
          [
            h(
              this.presetNameKebab,
              {
                props: { filter: this.filter },
                on: {
                  filter: event => {
                    this.filter = event
                  }
                }
              },
              [
                renderSelectPresetField.call(this, h),

                h(
                  buttonReport,
                  {
                    props: {
                      preset: this.preset,
                      filter: this.filter
                    },
                    slot: 'title'
                  }
                ),

                renderFilter.call(this, h)
              ]
            )
          ]
        )
      }
      case 'incoming': return h(incomingTemplate, { key })
      case services.tasks: {
        return h(
          tasksTemplate,
          {
            on: {
              currentTab: event => {
                routerQueryReplace(event)
              }
            },
            key
          }
        )
      }
    }
  })
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        components['tabs-header'],
        {
          props: {
            value: this.currentTab,
            tabs: this.tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`${services.sendings}.tabs.${key}`)
              }
            }),
            isLink: true
          },
          on: {
            input: event => {
              routerQueryReplace({ currentTab: event })
            }
          }
        }
      ),
      h(
        components.tabs,
        {
          props: { value: this.currentTab }
        },
        [ renderTabs.call(this, h) ]
      )
    ]
  )
}
