import Vue from 'vue'

import { Macro } from '@sigma-legacy-libs/cache'

import { globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

const cachedGet = Cache.wrapWithCache(async (key, service) => {
  return await Vue.$GRequest.find(service + '/sendings/count')
})

export default {
  name: 'Counter',

  data() {
    return {
      count: 0,
      interval: undefined
    }
  },

  mounted() {
    this.getCount()
    this.interval = setInterval(this.getCount, 5 * 1000)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  methods: {
    async getCount() {
      if (process.env.NODE_ENV === 'production') {
        if (this.checkPermissions(`advanced.${services.sendingsModeration}.get`)) {
          try {
            const { data } = await cachedGet(`${services.sendingsModeration}:count`, services.sendingsModeration)
            if (data && data.result) {
              const count = parseInt(data.result)
              if (!isNaN(count)) {
                this.count = count
              }
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    }
  },

  render
}
