import { get } from 'lodash'

import { colors, components, inputModes } from '@/utils'

import info from '@/components/info'
import buttonConfirm from '@/components/button/confirm'

function renderField(h, { path, serviceName, payload }) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy,
        hint: this.getTranslate(`${serviceName}.labels.${path}`),
        suffix: this.getTranslate(`currency.${get(payload.settings, 'billings.currency')}.symbol.unicode`),
        mode: inputModes.outline,
        type: 'number',
        step: 'any',
        rounded: true,
        dense: true,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
function renderInfo(h, { path, serviceName }) {
  const balance = this.balance + this.proxy
  if (balance <= 0) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${serviceName}.hints.${path}.negative`),
          color: colors.error
        },
        slot: 'info'
      }
    )
  }
}

export default function(h, options) {
  const { path, serviceName } = options

  return h(
    buttonConfirm,
    {
      props: {
        value: this.proxy,
        title: this.getTranslate(`${serviceName}.labels.${path}`),
        dialog: true
      },
      on: {
        input: event => {
          this.$emit('input', event)
        }
      }
    },
    [
      renderField.call(this, h, options),
      renderInfo.call(this, h, options)
    ]
  )
}
