import { components } from '@/utils'

import textOverflow from '@/components/text/overflow'

function renderDivider(h) {
  return h(components.divider)
}
function renderTitle(h) {
  return h(
    textOverflow,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  if (this.value) {
    return h(
      'div',
      {
        class: 'title-divider'
      },
      [
        renderDivider.call(this, h),
        renderTitle.call(this, h),
        renderDivider.call(this, h)
      ]
    )
  }
}
