import { RECURRENT_PAYMENTS_METHODS, RECURRENT_PAYMENTS_STATUSES, RECURRENT_PAYMENTS_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { components, inputModes, services } from '@/utils'

import selectorOwner from '@/components/selector/owner'
import paymentsMethod from '@/components/services/payments/method'

function renderSelectorOwner(h) {
  if (this.isPage) {
    return h(
      selectorOwner,
      {
        props: {
          value: this.proxy.OwnerId,
          mode: inputModes.outline,
          dense: true
        },
        on: {
          input: event => {
            this.proxy.OwnerId = event
          }
        }
      }
    )
  }
}

function renderTypeSelect(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.type,
        label: this.getTranslate(`${services.recurrentPayments}.labels.type`),
        items: RECURRENT_PAYMENTS_TYPES.map(type => {
          return {
            title: this.getTranslate(`${services.recurrentPayments}.types.${type}`),
            value: type
          }
        }),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.type = event
        }
      }
    }
  )
}

function renderStatusSelect(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.status,
        label: this.getTranslate(`${services.recurrentPayments}.labels.status`),
        items: RECURRENT_PAYMENTS_STATUSES.map(status => {
          return {
            title: this.getTranslate(`${services.recurrentPayments}.statuses.${status}`),
            value: status
          }
        }),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.status = event
        }
      }
    }
  )
}

function renderPaymentsMethod(h) {
  return h(
    paymentsMethod,
    {
      props: {
        OwnerId: this.OwnerId,
        value: this.proxy.method,
        items: RECURRENT_PAYMENTS_METHODS,
        dense: true,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.method = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { gridTemplateColumns: this.gridTemplateColumns }
    },
    [
      renderSelectorOwner.call(this, h),
      renderTypeSelect.call(this, h),
      renderStatusSelect.call(this, h),
      renderPaymentsMethod.call(this, h),
      this.$scopedSlots.buttons ? this.$scopedSlots.buttons() : this.$slots.buttons
    ]
  )
}
