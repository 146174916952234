import { DICTIONARIES_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, inputModes, services } from '@/utils'

import info from '@/components/info'
import button from '@/components/button'
import sticker from '@/components/misc/sticker'

const headers = [ 'number', 'prefix', 'operator', 'mcc', 'mnc', 'region' ]

function renderSearchField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.value,
        placeholder: this.getTranslate('misc.search'),
        mode: inputModes.outline,
        loading: this.loading,
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          if (!this.value) {
            this.result = undefined
          }

          this.value = event
        }
      }
    }
  )
}
function renderSubmitButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.search'),
        mode: buttonModes.flat,
        color: colors.primary,
        loading: this.loading,
        disabled: this.loading || !this.validation,
        type: 'submit'
      }
    }
  )
}
function renderForm(h) {
  return h(
    components.form,
    {
      class: `${services.dictionaries}-search__form`,
      on: {
        submit: () => {
          this.search()
        }
      }
    },
    [
      renderSearchField.call(this, h),
      renderSubmitButton.call(this, h)
    ]
  )
}

function renderResult(h) {
  if (this.result) {
    return h(
      'div',
      {
        class: `${services.dictionaries}-search__result`
      },
      [
        headers.map(header => {
          if (this.result[header]) {
            return h(
              sticker,
              {
                props: {
                  value: this.result[header],
                  label: this.getTranslate(`${services.dictionaries}.labels.${header}`),
                  copy: true
                }
              }
            )
          }
        })
      ]
    )
  } else if (this.empty) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${services.dictionaries}.hints.search.empty`)
        }
      }
    )
  }
}

export default function(h) {
  if ([ DICTIONARIES_STATUSES.compiled, DICTIONARIES_STATUSES.activated ].includes(this.dictionary.status)) {
    return h(
      'div',
      {
        class: `${services.dictionaries}-search`
      },
      [
        renderForm.call(this, h),
        renderResult.call(this, h)
      ]
    )
  }
}
