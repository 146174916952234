import { components, services } from '@/utils'

import pagination from '@/components/pagination'
import buttonRefresh from '@/components/button/refresh'
import item from '@/components/services/sendingsModeration/sendings/item'

function renderPagination(h) {
  return h(
    pagination,
    {
      props: {
        value: this.pagination,
        selectRows: false,
        service: services.sendingsModeration
      },
      on: {
        input: event => {
          this.pagination = event
        }
      }
    }
  )
}
function renderButtonRefresh(h) {
  return h(
    buttonRefresh,
    {
      props: {
        state: this.loading,
        disabled: this.loading || !this.filter.OwnerId,
        tooltip: this.getTranslate(`${services.sendingsModeration}.tooltips.buttons.refresh`),
        shortClickMethod: () => this.$emit('refresh')
      }
    }
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.sendingsModeration}-header`
    },
    [
      renderPagination.call(this, h),
      renderButtonRefresh.call(this, h)
    ]
  )
}

function renderItem(h, value) {
  return h(
    item,
    {
      props: {
        value,
        filter: this.filter
      },
      key: value.uniqueKey
    }
  )
}

function renderItems(h) {
  if (this.items.length && this.filter.OwnerId) {
    return this.items.map(item => renderItem.call(this, h, item))
  }

  return h(components.empty)
}

export default function(h) {
  return h(
    components.card,
    {
      class: `${services.sendingsModeration}-sendings`,
      props: {
        rounded: true,
        outline: true
      }
    },
    [
      renderHeader.call(this, h),
      renderItems.call(this, h)
    ]
  )
}
