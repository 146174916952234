import { buttonModes, components } from '@/utils'

import button from '@/components/button'
import format from '@/components/services/billings/details/dialog/format'
import sourceBox from '@/components/source/box'

/*
NOTE: так как сервис eventsHistory ведёт учет только
тех биллингов которые были созданы конкретным пользователем
то отображать здесь вкладку eventsHistory бессмысленно
ибо она всегда будет пустой
все события по биллингам фиксируются во пользовательской вкладке eventsHistory
 */

function renderFormat(h) {
  if (this.data) {
    return h(
      format,
      {
        props: { value: this.data },
        key: 'format'
      }
    )
  }
}
function renderSource(h) {
  if (this.data) {
    return h(
      sourceBox,
      {
        class: 'px-2',
        props: { value: this.data },
        key: 'source'
      }
    )
  }
}

function renderHeader(h) {
  return h(
    components['tabs-header'],
    {
      class: 'pa-2',
      props: {
        value: this.currentTab,
        tabs: this.tabs.map(tab => {
          return {
            key: tab,
            title: this.getTranslate(`commons.tabs.${tab}`)
          }
        })
      },
      on: {
        input: event => {
          this.currentTab = event
        }
      },
      slot: 'header'
    }
  )
}
function renderBody(h) {
  return h(
    components.tabs,
    {
      props: { value: this.currentTab },
      scopedSlots: {
        body: () => [
          renderFormat.call(this, h),
          renderSource.call(this, h)
        ]
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.proxy = false
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.dialog,
    {
      props: {
        value: this.proxy,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 1000 : '100%'
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
