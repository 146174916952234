import { components, services, states } from '@/utils'

import RefreshButton from '@/components/button/refresh'
import TemplatesItem from '@/components/services/templates/item'
import TemplatesFilter from '@/components/services/templates/filter'
import pagination from '@/components/pagination'
import preloader from '@/components/preloader'

function renderFilter(h) {
  return h(
    TemplatesFilter,
    {
      props: {
        value: this.filter
      },
      on: {
        input: event => {
          this.filter = event
        }
      }
    }
  )
}

function renderSeparator(h) {
  if (this.restData[services.templates].find.pagination.total > 0) {
    return h(components.divider)
  }
}

function renderPagination(h) {
  if (this.restData[services.templates].find.pagination.total > 0) {
    return h(
      'div',
      {
        class: 'fjcfe grid-gap--8 pa-2'
      },
      [
        h(
          pagination,
          {
            props: {
              value: this.restData[services.templates].find.pagination,
              service: services.templates
            },
            on: {
              input: event => {
                this.restData[services.templates].find.pagination = event
              }
            }
          }
        ),

        h(
          RefreshButton,
          {
            props: {
              state: this.restData[services.templates].find.state,
              shortClickMethod: () => this.rest[services.templates].find(),
              longClickMethod: () => this.rest[services.templates].find({}, { noCache: true })
            }
          }
        )
      ]
    )
  }
}

function renderItem(h, item) {
  return h(
    TemplatesItem,
    {
      props: { value: item },
      on: {
        template: event => {
          this.$emit('template', event)
        }
      },
      key: item.id
    }
  )
}
function renderItems(h) {
  return this.restData[services.templates].find.data.map(item => {
    return renderItem.call(this, h, item)
  })
}
function renderPreloader(h) {
  if (this.restData[services.templates].find.state === states.loading) {
    return h(
      'div',
      {
        class: 'preloader__holder preloader__holder--absolute'
      },
      [ h(preloader) ]
    )
  }
}
function renderContent(h) {
  if (this.restData[services.templates].find.pagination.total > 0) {
    return h(
      components['expansion-panels'],
      {
        props: { flat: true },
        style: { position: 'relative' }
      },
      [
        renderItems.call(this, h),
        renderPreloader.call(this, h)
      ]
    )
  } else {
    return h(components.empty)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderFilter.call(this, h),
      h(components.divider),
      renderPagination.call(this, h),
      renderSeparator.call(this, h),
      renderContent.call(this, h),
      renderSeparator.call(this, h),
      renderPagination.call(this, h)
    ]
  )
}
