import { buttonModes, colors, components } from '@/utils'

import button from '@/components/button'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

function renderSelectView(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy,
        label: this.label,
        hint: this.hint,
        items: this.$items,
        error: this.error,
        mode: this.mode,
        dense: this.dense,
        rounded: true,
        clearable: this.$clearable,
        details: !!this.hint,
        multiple: this.multiple,
        required: this.required,
        loading: this.loading,
        disabled: this.disabled || this.loading,
        readonly: this.readonly
      },
      scopedSlots: {
        selection: ({ item, removeByValue }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                title: item.title,
                value: this.format(item.value),
                dense: this.dense,
                as: 'tag',
                cancelable: this.cancelable,
                cancel: () => removeByValue(item)
              }
            }
          )
        }
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderButtonsView(h) {
  return h(
    'div',
    {
      class: 'fw grid-gap--8'
    },
    this.$items.map(({ title, value, disabled }) => {
      const formattedValue = this.format(value)
      disabled = disabled || this.disabled

      return h(
        button,
        {
          props: {
            label: title,
            size: this.size,
            mode: disabled ? buttonModes.clear : this.proxyHasValue(value) ? buttonModes.filled : buttonModes.flat,
            color: this.proxyHasValue(value) ? formattedValue : colors.description,
            iconSVG: formattedValue,
            disabled
          },
          on: {
            click: () => {
              this.eventHandler(value)
            }
          }
        }
      )
    })
  )
}

export default function(h) {
  switch (this.view) {
    case 'buttons': return renderButtonsView.call(this, h)
    case components.select: return renderSelectView.call(this, h)
  }
}
