import { services } from '@/utils'

import eventsHistoryCol from '@/components/templates/service/cols/eventsHistory'

export default function(h) {
  return h(
    eventsHistoryCol,
    {
      props: {
        service: services.tariffs,
        id: this.$route.params.id
      }
    }
  )
}
