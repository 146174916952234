import render from './render'

export default {
  name: 'Fallbacks',

  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      dialogs: {},
      sending: undefined
    }
  },

  render
}
