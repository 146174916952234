import { components, services, states } from '@/utils'

import pagination from '@/components/pagination'
import buttonRefresh from '@/components/button/refresh'
import item from '@/components/services/sendingsModeration/tasks/item'
import dialog from '@/components/services/sendingsModeration/tasks/dialog'

function renderPagination(h) {
  return h(
    pagination,
    {
      props: {
        value: this.restData[services.sendingsModeration].find.pagination,
        selectRows: false,
        service: services.sendingsModeration
      },
      on: {
        input: event => {
          this.restData[services.sendingsModeration].find.pagination = event
        }
      }
    }
  )
}
function renderButtonRefresh(h) {
  return h(
    buttonRefresh,
    {
      props: {
        state: this.restData[services.sendingsModeration].find.state,
        disabled: this.restData[services.sendingsModeration].find.state === states.loading,
        tooltip: this.getTranslate(`${services.sendingsModeration}.tooltips.buttons.refresh`),
        shortClickMethod: () => this.rest[services.sendingsModeration].find(),
        longClickMethod: () => this.rest[services.sendingsModeration].find({}, { noCache: true })
      }
    }
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.sendingsModeration}-header`
    },
    [
      renderPagination.call(this, h),
      renderButtonRefresh.call(this, h)
    ]
  )
}

function renderItem(h, value) {
  return h(
    item,
    {
      props: { value },
      on: {
        click: () => {
          this.task = value
          this.showDialog = true
        }
      }
    }
  )
}
function renderItems(h) {
  if (this.restData[services.sendingsModeration].find.pagination.total > 0) {
    return this.restData[services.sendingsModeration].find.data.map(task => {
      return renderItem.call(this, h, task)
    })
  }

  return h(components.empty)
}

function renderDialog(h) {
  return h(
    dialog,
    {
      props: {
        value: this.showDialog,
        data: this.task
      },
      on: {
        input: () => {
          this.showDialog = false
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.sendingsModeration}-tasks`
    },
    [
      renderHeader.call(this, h),
      renderItems.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}
