import Vue from 'vue'

import { get } from 'lodash'

import { getUrlToStorage, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'Buttons',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    async download() {
      try {
        const { id, OwnerId } = get(this, 'value.File', {})
        if (id) {
          const { data } = await Vue.$GRequest.find(`${services.storage}/export/${id}`, { query: { OwnerId } })
          if (data) {
            window.location.href = getUrlToStorage([ data.OwnerId || data.Owner && data.Owner.id, data.id ])
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    }
  },

  render
}
