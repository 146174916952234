import { serviceTemplate, services } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services['otp-settings/templates'],

      backendGeneration: 'nest',

      find: {
        defaultFilter: {
          order: [ [ 'createdAt', 'desc' ] ],
          search: undefined,
          channelType: undefined
        }
      },
      create: { redirect: true },

      has: {
        search: false,
        clone: false,
        tabs: false
      },

      width: 600,
      titlePath: 'name',
      formClass: ''
    })
  ]
}
