import buttonConfirmRemove from '@/components/button/confirm/remove'

export default function(h) {
  if (this.checkPermissions(this.$permissions.path, this.$permissions.accept)) {
    return h(
      buttonConfirmRemove,
      {
        props: {
          labeled: this.labeled,
          icon: this.icon,
          size: this.size,
          color: this.color,
          loading: this.loading,
          disabled: this.loading,
          component: this.component,
          callback: this.remove
        }
      }
    )
  }
}
