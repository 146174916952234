import { get } from 'lodash'

import { colors, components, getPrice, icons, permissionPresets, services } from '@/utils'
import { billingsStatuses } from '@/components/services/billings/utils'

import sticker from '@/components/misc/sticker'
import owner from '@/components/templates/service/item/owner'
import squircle from '@/components/icon/squircle'

function renderTariff(h) {
  const [ source, type ] = this.value.source && this.value.source.split('.') || []
  if (this.checkPermissions(`advanced.${services.tariffs}.get`, permissionPresets.resellerUp)) {
    const value = get(this.value, 'Tariff', get(this.value, 'TariffId'))
    if (value) {
      return h(
        sticker,
        {
          props: {
            value,
            label: this.getTranslate(`${services.tariffs}.labels.tariff`),
            iconSVG: type || source,
            color: type || source,
            service: services.tariffs,
            maxWidth: 150,
            width: '100%'
          }
        }
      )
    }
  } else {
    return h(
      sticker,
      {
        props: {
          value: this.getTranslate(`${services.sendings}.types.${type || source}`),
          label: this.getTranslate(`${services.tariffs}.labels.source`),
          iconSVG: type || source,
          color: type || source,
          maxWidth: 150,
          width: '100%'
        }
      }
    )
  }
}
function renderOwner(h) {
  return h(
    owner,
    {
      props: {
        value: this.value,
        maxWidth: 150,
        width: '100%'
      }
    }
  )
}
function renderInfo(h) {
  if (this.total) {
    return h(
      sticker,
      {
        props: {
          value: this.getTranslate(`${services.billings}.labels.total`),
          maxWidth: 150,
          width: '100%'
        }
      }
    )
  } else {
    return h(
      'div',
      {
        class: `${services.billings}-item__info`
      },
      [
        renderTariff.call(this, h),
        renderOwner.call(this, h)
      ]
    )
  }
}

function renderCount(h, status) {
  const value = get(this.value, `sum_${status}`)
  const count = get(this.value, `count_multiplier_${status}`)

  return h(
    sticker,
    {
      props: {
        value: getPrice(value),
        icon: billingsStatuses[status].icon,
        color: billingsStatuses[status].color,
        label: this.getTranslate(`${services.billings}.labels.count.${status}`, { count }),
        maxWidth: 150,
        width: '100%'
      }
    }
  )
}
function renderCounts(h) {
  return h(
    'div',
    {
      class: `${services.billings}-item__counts`
    },
    [
      renderCount.call(this, h, 'pending'),
      renderCount.call(this, h, 'refunded'),
      renderCount.call(this, h, 'complete'),
      renderCount.call(this, h, 'invalid'),
      renderCount.call(this, h, 'total')
    ]
  )
}

function renderArrow(h, expanded) {
  return h(
    squircle,
    {
      props: {
        icon: expanded ? icons.keyboard_arrow_up : icons.keyboard_arrow_down,
        color: colors.grey
      }
    }
  )
}
function renderPanel(h, expanded) {
  return h(
    'div',
    {
      class: `${services.billings}-item__panel`
    },
    [
      renderInfo.call(this, h),
      renderArrow.call(this, h, expanded)
    ]
  )
}

export default function(h) {
  if (this.viewport.size.width > 1600) {
    return h(
      'div',
      {
        class: `service-template-item ${services.billings}-item ${services.billings}-item--group001 hover-parent hover-parent--opacity`
      },
      [
        renderInfo.call(this, h),
        renderCounts.call(this, h)
      ]
    )
  } else {
    return h(
      components['expansion-panel'],
      {
        scopedSlots: {
          header: ({ expanded }) => renderPanel.call(this, h, expanded),
          default: () => renderCounts.call(this, h)
        }
      }
    )
  }
}
