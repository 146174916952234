import { colors, icons } from '@/utils'

import squircle from '@/components/icon/squircle'

export default function(h) {
  return h(
    squircle,
    {
      props: {
        icon: this.expanded ? icons.keyboard_arrow_up : icons.keyboard_arrow_down,
        color: colors.grey
      }
    }
  )
}
