import { components, services } from '@/utils'

import eventsHistoryTab from '@/components/services/eventsHistory/tab'

function renderTabContent(h) {
  switch (this.tab) {
    case services.eventsHistory: {
      return h(
        eventsHistoryTab,
        {
          props: {
            entityId: this.id
          }
        }
      )
    }
  }
}
function renderTab(h, key) {
  return h(
    components.card,
    {
      props: {
        rounded: true,
        outline: true,
        width: '100%'
      },
      key
    },
    [ renderTabContent.call(this, h) ]
  )
}

export default function(h) {
  if (this.validService) {
    if (this.id) {
      return h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          h(
            components['tabs-header'],
            {
              props: {
                value: this.tab,
                tabs: this.tabs
              },
              on: {
                input: event => {
                  this.tab = event
                }
              }
            }
          ),

          h(
            components.tabs,
            {
              props: { value: this.tab },
              scopedSlots: {
                body: () => this.tabs.map(tab => {
                  return renderTab.call(this, h, tab.key)
                })
              }
            }
          )
        ]
      )
    }
  }
}
