import { serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/servers/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      outputFilter(result) {
        result.port = parseInt(result.port)

        return result
      },

      has: {
        clone: false,
        filter: false
      }
    })
  ]
}
