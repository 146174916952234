export const colors = {
  white: 'white',
  black: 'black',
  grey: 'grey',
  gray: 'gray',
  indigo: 'indigo',
  purple: 'purple',
  pink: 'pink',
  epic: 'epic',
  legendary: 'legendary',
  primary: 'primary',
  secondary: 'secondary',
  accent: 'accent',
  info: 'info',
  warning: 'warning',
  error: 'error',
  success: 'success',
  text: 'text',
  description: 'description',
  background: 'background',
  border: 'border',
  sms: 'sms',
  push: 'push',
  viber: 'viber',
  whatsapp: 'whatsapp',
  telegram: 'telegram',
  facebook: 'facebook',
  instagram: 'instagram',
  flashcall: 'flashcall',
  voice: 'voice',
  vk: 'vk',
  ok: 'ok',
  apisender: 'apisender',
  email: 'email',
  web: 'web',
  hlr: 'hlr',
  mts: 'mts',
  mobileid: 'mobileid',
  mobileid_mts_push: 'mobileid_mts_push',
  mobileid_mts_push_sms: 'mobileid_mts_push_sms',
  tgBot: 'tgBot',
  incoming_tg_bot: 'incoming_tg_bot',
  whatsappchat: 'whatsappchat',
  viberchat: 'viberchat',
  vkchat: 'vkchat',
  telegramchat: 'telegramchat',
  facebookchat: 'facebookchat',
  tinkoff: 'tinkoff',
  mandarin: 'mandarin',
  stripe: 'stripe',
  accrual: 'accrual',
  subtract: 'subtract',
  utm: 'utm',
  file: 'file',
  'file-document': 'file-document',
  'file-pdf': 'file-pdf',
  'file-sheet': 'file-sheet',
  'file-image': 'file-image',
  'file-audio': 'file-audio',
  'file-video': 'file-video'
}

export const colorsHEX = {
  white: '#ffffff',
  black: '#000000',
  grey: '#9e9e9e',
  gray: '#9e9e9e',
  indigo: '#3f51b5',
  purple: '#9c27b0',
  pink: '#e91e63',
  epic: '#673ab7',
  legendary: '#ff9800',
  primary: '#1976d2',
  secondary: '#ffa726',
  accent: '#6240ff',
  info: '#2196f3',
  warning: '#ffc107',
  error: '#f44336',
  success: '#4caf50',
  text: '#333333',
  description: '#5a677d',
  background: '#fafafa',
  border: '#e0e0e0',
  sms: '#0d47a1',
  push: '#0099e1',
  viber: '#59267c',
  whatsapp: '#00e676',
  telegram: '#0088cc',
  facebook: '#3b5998',
  instagram: '#e4405f',
  flashcall: '#d4376c',
  voice: '#f48fb1',
  vk: '#4c75a3',
  ok: '#ed812b',
  apisender: '#ffc347',
  email: '#7c0b0b',
  web: '#81c784',
  hlr: '#2e7d32',
  mts: '#e40422',
  mobileid: '#e40422',
  mobileid_mts_push: '#e40422',
  mobileid_mts_push_sms: '#e40422',
  tgBot: '#0088cc',
  incoming_tg_bot: '#0088cc',
  whatsappchat: '#00e676',
  viberchat: '#59267c',
  vkchat: '#4c75a3',
  telegramchat: '#0088cc',
  facebookchat: '#3b5998',
  tinkoff: '#f8d81c',
  mandarin: '#d1282c',
  stripe: '#635bff',
  accrual: '#4caf50',
  subtract: '#f44336',
  utm: '#2596BE',
  file: '#737373',
  'file-document': '#00a4ef',
  'file-pdf': '#f40f02',
  'file-sheet': '#7fba00',
  'file-image': '#f25022',
  'file-audio': '#ff8c00',
  'file-video': '#b400c3'
}

// const generateScss = () => {
//   let scssContent = ''
//   let colorsArrayContent = '$colors: (\n'

//   for (const [ key, value ] of Object.entries(colors)) {
//     const colorValue = typeof value === 'function' ? value() : value
//     scssContent += `$${key}: ${colorValue} !default;\n`
//     colorsArrayContent += `  "${key}": $${key},\n`
//   }

//   colorsArrayContent += ');\n'
//   scssContent += colorsArrayContent

//   return scssContent
// }
// console.log(generateScss())
