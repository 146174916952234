import { datePresets } from '../../enums'

export const BILLINGS_FILTER = {
  id: undefined,
  source: undefined,
  status: undefined,
  OwnerId: undefined,
  TariffId: undefined,
  createdAt: {
    $gt: undefined,
    $lt: undefined
  },
  createdAtPreset: datePresets.minutes['5'],
  $scope: [ 'full' ]
}
