import { buttonModes, colors, components, icons, inputModes, sizes } from '@/utils'

import button from '@/components/button'
import info from '@/components/info'
import HeadTitle from '@/components/misc/HeadTitle'
import tag from '@/components/tag'

function renderTextarea(h, serviceName, details = true) {
  return h(
    components.textarea,
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.smpp.ipAddresses`),
        hint: this.getTranslate(`${serviceName}.hints.smpp.ipAddresses`),
        mode: inputModes['line-label'],
        rounded: true,
        dense: true,
        grow: true,
        rows: 4,
        details
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
function renderConfirmButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.done,
        color: colors.success,
        mode: buttonModes.flat,
        tooltip: this.getTranslate('misc.buttons.save'),
        loading: this.loading.update,
        disabled: this.disabled
      },
      on: {
        click: () => {
          this.checkIpList()
        }
      }
    }
  )
}
function renderClearButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        icon: icons.clear,
        color: colors.error,
        tooltip: this.getTranslate('misc.buttons.clear')
      },
      on: {
        click: () => {
          this.proxy = ''
          this.eventHandler()
        }
      }
    }
  )
}
function renderButtons(h) {
  return h(
    'div',
    {
      class: 'fc grid-gap--8'
    },
    [
      renderConfirmButton.call(this, h),
      renderClearButton.call(this, h)
    ]
  )
}

function renderDialogHeader(h, serviceName) {
  return h(
    HeadTitle,
    {
      props: { value: this.getTranslate(`${serviceName}.labels.smpp.confirm`) },
      slot: 'header'
    }
  )
}
function renderInfo(h, serviceName) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${serviceName}.warnings.smpp.ipAddresses`),
        color: colors.error
      }
    }
  )
}
function renderInvalidIps(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--4'
    },
    [
      this.invalidIps.map(label => {
        return h(
          tag,
          {
            props: {
              label,
              color: colors.error,
              size: sizes.tiny
            }
          }
        )
      })
    ]
  )
}
function renderDialogInfo(h, serviceName) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderInfo.call(this, h, serviceName),
      renderInvalidIps.call(this, h)
    ]
  )
}
function renderDialogBody(h, serviceName) {
  return h(
    'div',
    {
      class: 'grid grid-gap--24 px-2'
    },
    [
      renderDialogInfo.call(this, h, serviceName),
      renderTextarea.call(this, h, serviceName, false)
    ]
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'pa-2 fjcfe grid-gap--8',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.cancel'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      ),

      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.save'),
            color: colors.primary,
            loading: this.loading.update,
            disabled: this.disabled
          },
          on: {
            click: () => {
              this.checkIpList()
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h, serviceName) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog && this.invalidIps.length,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderDialogHeader.call(this, h, serviceName),
      renderDialogBody.call(this, h, serviceName),
      renderDialogFooter.call(this, h)
    ]
  )
}

function renderCardContent(h, serviceName) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { gridTemplateColumns: '1fr 38px' }
        },
        [
          renderTextarea.call(this, h, serviceName),
          renderButtons.call(this, h)
        ]
      ),
      renderDialog.call(this, h, serviceName)
    ]
  )
}

export default function(h, { path, serviceName }) {
  return h(
    components.card,
    {
      class: 'px-2 pb-2 pt-3',
      props: {
        title: this.getTranslate(`${serviceName}.labels.${path}`),
        outline: true,
        dashed: true,
        rounded: true
      }
    },
    [ renderCardContent.call(this, h, serviceName) ]
  )
}
