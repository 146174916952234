import { buttonModes, colors, icons } from '@/utils'

import button from '@/components/button'

export default function(h) {
  if (this.length > 0) {
    return h(
      button,
      {
        props: {
          label: this.length,
          icon: icons.add,
          mode: buttonModes.flat,
          color: colors.primary,
          disabled: this.disabled
        },
        on: {
          click: () => {
            if (typeof this.callback === 'function') {
              this.callback()
            }
          }
        }
      }
    )
  }
}
