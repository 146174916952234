import {
  buttonModes,
  catchSymbolsMenus,
  colors,
  components,
  getEmptyFallback,
  getHammerTime,
  getISODate,
  getYesterday,
  icons,
  inputModes,
  isArrayNotEmpty,
  routerQueryReplace,
  services,
  sizes,
  states
} from '@/utils'

import button from '@/components/button'
import HeadTitle from '@/components/misc/HeadTitle'
import squircle from '@/components/icon/squircle'
import MessagePreview from '@/components/message/preview'
import TemplatesForm from '@/components/services/templates/form'
import TemplatesTemplate from '@/components/services/templates/template'
import SendingsCreateForm from '@/components/services/sendingsCreate/form'
import SendingsCreateFallbacks from '@/components/services/sendingsCreate/fallbacks'
import SendingsCreateRecipients from '@/components/services/sendingsCreate/recipients'

function renderWarning(h) {
  if (!isArrayNotEmpty(this.sendingTypes)) {
    return h(
      components.card,
      {
        class: {
          fasc: true,
          'mt-5': this.viewport.breakpoint.mdUp
        },
        props: {
          outline: true,
          rounded: true,
          maxWidth: 620
        }
      },
      [
        h(
          'div',
          {
            class: 'faic grid-gap--16 pa-3'
          },
          [
            h(
              squircle,
              {
                props: {
                  icon: icons.error_outline,
                  color: colors.error,
                  size: sizes.medium
                }
              }
            ),

            h(
              'div',
              {
                class: 'fz-14',
                domProps: { innerHTML: this.getTranslate(`${services.sendings}.contents.noAllowedSendingTypes`) }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderHeader(h) {
  if (isArrayNotEmpty(this.sendingTypes)) {
    if (this.viewport.size.width < 1832) {
      return h(
        components['tabs-header'],
        {
          class: 'pb-2',
          props: {
            value: this.currentTab,
            tabs: [
              {
                key: 'create',
                title: this.getTranslate(`${services.sendings}.tabs.create`)
              },
              {
                key: services.templates,
                title: this.getTranslate(`${services.sendings}.tabs.templates`),
                callback: () => {
                  this.template = undefined
                }
              }
            ]
          },
          on: {
            input: event => {
              routerQueryReplace({ currentTab: event })
            }
          }
        }
      )
    }
  }
}

function renderSendingRecipientsFields(h) {
  return h(
    SendingsCreateRecipients,
    {
      props: {
        value: this.restData[services.sendings].create.data.recipient,
        error: this.recipientsError
      },
      on: {
        input: event => {
          this.restData[services.sendings].create.data.recipient = event
        },
        'update:error': event => {
          this.recipientsError = event
        }
      }
    }
  )
}

function renderSendingTitleField(h) {
  if (this.computedTypeRecipient === 'bulk') {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: {
          gridTemplateColumns: '1fr 38px'
        }
      },
      [
        h(
          'g-text-field',
          {
            props: {
              value: this.restData[services.sendings].create.data.$options.title,
              label: this.getTranslate(`${services.sendings}.labels.title`),
              mode: inputModes.outline,
              rounded: true,
              clearable: true,
              details: false
            },
            on: {
              input: event => {
                this.restData[services.sendings].create.data.$options.title = event
              }
            }
          }
        )
      ]
    )
  }
}

function renderSendingForm(h) {
  if (!this.fallbacks) {
    return h(
      SendingsCreateForm,
      {
        props: {
          value: this.restData[services.sendings].create.data,
          errors: this.restData[services.sendings].create.errors,
          template: this.template,
          types: this.sendingTypes,
          isNewMessage: true,
          tags: catchSymbolsMenus.$,
          customMenu: catchSymbolsMenus.custom.$
        },
        on: {
          input: event => {
            this.restData[services.sendings].create.data = event
          },
          recipient: event => {
            this.templateRecipient(event)
          }
        }
      }
    )
  }
}

function renderSendingContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      renderSendingRecipientsFields.call(this, h),
      renderSendingTitleField.call(this, h),
      renderSendingForm.call(this, h)
    ]
  )
}

function renderFallbacks(h) {
  if (this.fallbacks) {
    return h(
      'div',
      {
        class: 'fc px-2'
      },
      [
        h(
          SendingsCreateFallbacks,
          {
            props: {
              value: this.restData[services.sendings].create.data,
              defaultValue: getEmptyFallback(),
              flat: true
            },
            scopedSlots: {
              default: ({ value }) => {
                return h(
                  SendingsCreateForm,
                  {
                    props: {
                      value,
                      types: this.sendingTypes,
                      fallback: true,
                      tags: catchSymbolsMenus.$,
                      customMenu: catchSymbolsMenus.custom.$
                    },
                    on: {
                      input: event => {
                        value = event
                      }
                    }
                  }
                )
              }
            }
          }
        )
      ]
    )
  }
}

function renderScheduled(h) {
  if (this.showScheduled) {
    return h(
      'div',
      {
        class: {
          'grid grid-gap--8 pa-2': true,
          'grid-cols--2': this.viewport.breakpoint.mdUp,
          'grid-cols--1': this.viewport.breakpoint.smDown
        }
      },
      [
        h(
          'g-menu',
          {
            class: 'w--100',
            props: {
              closeOnContentClick: false,
              rounded: true
            }
          },
          [
            h(
              'g-text-field',
              {
                props: {
                  value: this.dateScheduleDelay,
                  label: this.getTranslate(`${services.sendings}.labels.schedule.delay`),
                  afterIcon: icons.event,
                  readonly: true,
                  mode: inputModes['outline-label'],
                  rounded: true,
                  details: false
                },
                slot: 'activator'
              }
            ),

            h(
              'div',
              {
                class: 'fc ff'
              },
              [
                h(
                  'g-date-picker',
                  {
                    props: {
                      value: this.changeableDates.delay.date,
                      localeTag: this.locale,
                      range: false,
                      min: getYesterday(),
                      filter: dates => dates.map(date => date && getISODate(date))
                    },
                    on: {
                      input: event => {
                        this.changeableDates.delay.date = event
                      }
                    }
                  }
                ),

                h(
                  'div',
                  {
                    class: 'fjcc facc faic px-2'
                  },
                  [
                    h(
                      'g-text-field',
                      {
                        props: {
                          value: this.changeableDates.delay.time,
                          type: 'time',
                          mode: inputModes.outline,
                          rounded: true,
                          details: false
                        },
                        on: {
                          input: event => {
                            this.changeableDates.delay.time = event
                          }
                        }
                      }
                    )
                  ]
                ),

                h(
                  'div',
                  {
                    class: 'fjcfe facfe pa-2'
                  },
                  [
                    h(
                      button,
                      {
                        props: {
                          label: this.getTranslate('misc.buttons.clear'),
                          mode: buttonModes.flat,
                          size: sizes.small
                        },
                        on: {
                          click: () => {
                            this.changeableDates.delay.date = undefined
                            this.changeableDates.delay.time = getHammerTime()
                          }
                        }
                      }
                    )
                  ]
                )
              ]
            )
          ]
        ),

        h(
          'g-select',
          {
            props: {
              value: this.changeableDates.until,
              label: this.getTranslate(`${services.sendings}.labels.schedule.until`),
              items: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ].map(item => {
                return {
                  title: this.getTranslate(`${services.sendings}.schedule.until.${item}`),
                  value: item
                }
              }),
              disabled: !this.restData[services.sendings].create.data.schedule.delay,
              required: true,
              mode: inputModes['outline-label'],
              rounded: true,
              details: false
            },
            on: {
              input: event => {
                this.changeableDates.until = event
              }
            }
          }
        )
      ]
    )
  }
}

function renderSetFallbacks(h) {
  if (!this.fallbacks) {
    return h(
      'div',
      {
        class: 'fjcfe facfe px-2'
      },
      [
        h(
          button,
          {
            props: {
              label: this.getTranslate('misc.buttons.fallback'),
              icon: icons.add,
              mode: buttonModes.outline,
              color: colors.primary
            },
            on: {
              click: this.setFallbacks
            }
          }
        )
      ]
    )
  }
}

function renderMessagePreviewDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        value: this.showMessagePreview,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showMessagePreview = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: {
            value: this.getTranslate('sendingsCreate.titles.preview')
          },
          slot: 'header'
        }
      ),

      h(
        'div',
        {
          class: 'px-2'
        },
        [
          h(
            MessagePreview,
            {
              props: { value: this.restData[services.sendings].create.data }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: 'faic fjcfe pa-2'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.showMessagePreview = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}
function renderMessagePreviewButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.outline,
        icon: icons.preview,
        color: colors.primary,
        disabled: this.createDisabled,
        tooltip: this.getTranslate(`${services.sendings}.tooltips.preview`)
      },
      on: {
        click: () => {
          this.showMessagePreview = true
        }
      },
      scopedSlots: {
        dialog: () => renderMessagePreviewDialog.call(this, h)
      }
    }
  )
}
function renderSaveTemplateButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.save,
        mode: buttonModes.outline,
        color: colors.success,
        disabled: !this.restData[services.sendings].create.data.payload.sender && !this.restData[services.sendings].create.data.payload.text,
        tooltip: this.getTranslate(`${services.sendings}.tooltips.save`)
      },
      on: {
        click: () => {
          this.saveTemplate()
        }
      }
    }
  )
}
function renderScheduleButton(h) {
  return h(
    button,
    {
      props: {
        icon: this.showScheduled ? icons.keyboard_arrow_up : icons.date_range,
        mode: buttonModes.outline,
        color: colors.secondary,
        disabled: this.createDisabled,
        tooltip: this.getTranslate(`${services.sendings}.tooltips.planning`)
      },
      on: {
        click: () => {
          this.showScheduled = !this.showScheduled
        }
      }
    }
  )
}
function renderConfirmButton(h) {
  return h(
    button,
    {
      props: {
        label: this.restData[services.sendings].create.data.schedule.delay ? this.getTranslate('misc.buttons.scheduled') : this.getTranslate('misc.buttons.send'),
        suffix: this.restData[services.sendings].create.data.schedule.delay ? icons.schedule : icons.send,
        disabled: !(this.recipientValidation && this.payloadValidation && this.fallbacksValidation),
        color: this.restData[services.sendings].create.data.schedule.delay ? colors.secondary : colors.primary
      },
      on: {
        click: () => {
          this.showConfirmCreateSendings = true
        }
      }
    }
  )
}
function renderSendingsCreateFooter(h) {
  return h(
    'div',
    {
      class: 'faic fjcfe grid-gap--8 pa-2'
    },
    [
      renderMessagePreviewButton.call(this, h),
      renderSaveTemplateButton.call(this, h),
      renderScheduleButton.call(this, h),
      renderConfirmButton.call(this, h)
    ]
  )
}

function renderSendingsCreate(h) {
  return h(
    'div',
    {
      class: 'fc ff',
      key: 'create'
    },
    [
      renderSendingContent.call(this, h),
      renderFallbacks.call(this, h),
      renderScheduled.call(this, h),
      renderSetFallbacks.call(this, h),
      renderSendingsCreateFooter.call(this, h)
    ]
  )
}

function renderTemplates(h) {
  return h(
    TemplatesTemplate,
    {
      key: services.templates,
      on: {
        template: event => {
          this.template = event
        }
      }
    }
  )
}

function renderMessagePreviewContent(h, trigger) {
  if (trigger) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        renderTitle.call(this, h, 'sendingsCreate.titles.preview'),

        h(
          MessagePreview,
          {
            props: { value: this.restData[services.sendings].create.data }
          }
        )
      ]
    )
  }
}

function renderTitle(h, path) {
  return h(
    'div',
    {
      class: 'headline pa-3'
    },
    [ this.getTranslate(path) ]
  )
}
function renderCard(h, title, content) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderTitle.call(this, h, title),

      h(
        components.card,
        {
          props: {
            outline: true,
            rounded: true,
            maxWidth: 600
          }
        },
        [ content ]
      )
    ]
  )
}
function renderBody(h) {
  if (isArrayNotEmpty(this.sendingTypes)) {
    const trigger = this.viewport.size.width > 1832
    if (trigger) {
      return h(
        'div',
        {
          class: 'grid',
          style: { 'grid-template-columns': 'repeat(2, 600px) 300px' }
        },
        [
          renderCard.call(this, h, 'sendingsCreate.titles.message', renderSendingsCreate.call(this, h)),
          renderCard.call(this, h, 'templates.title', renderTemplates.call(this, h)),
          renderMessagePreviewContent.call(this, h, !this.createDisabled && trigger)
        ]
      )
    } else {
      return h(
        components.card,
        {
          props: {
            outline: true,
            rounded: true,
            maxWidth: 600
          }
        },
        [
          h(
            components.tabs,
            {
              props: { value: this.currentTab },
              scopedSlots: {
                body: () => [
                  renderSendingsCreate.call(this, h),
                  renderTemplates.call(this, h)
                ]
              }
            }
          )
        ]
      )
    }
  }
}

function renderConfirmDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.title,
        value: this.showConfirmCreateSendings,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 600 : undefined
      },
      on: {
        input: event => {
          this.showConfirmCreateSendings = event
        }
      }
    },
    [
      h('div', { class: 'pa-3' }, this.getTranslate(`${services.sendings}.confirm.send.text`)),

      h(
        'div',
        {
          class: 'fjcfe grid-gap--8 pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                mode: buttonModes.flat,
                disabled: this.restData[services.sendings].create.state === states.loading
              },
              on: {
                click: () => {
                  this.showConfirmCreateSendings = false
                }
              }
            }
          ),
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.continue'),
                loading: this.restData[services.sendings].create.state === states.loading,
                disabled: this.restData[services.sendings].create.state === states.loading,
                color: colors.primary
              },
              on: {
                click: () => {
                  this.createSending()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderCreateTemplateDialogBody(h) {
  return h(
    TemplatesForm,
    {
      props: {
        value: this.restData[services.templates].create.data,
        errors: this.restData[services.templates].create.errors
      },
      on: {
        input: event => {
          this.restData[services.templates].create.data = event
        }
      }
    }
  )
}
function renderCreateTemplateDialogFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.showCreateTemplates = false
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.create'),
            color: colors.primary,
            loading: this.restData[services.templates].create.state === states.loading,
            disabled: this.restData[services.templates].create.state === states.loading
          },
          on: {
            click: () => {
              this.createTemplate()
            }
          }
        }
      )
    ]
  )
}
function renderCreateTemplateDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate('templates.dialogs.create'),
        value: this.showCreateTemplates,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 600 : undefined
      },
      on: {
        input: event => {
          this.showCreateTemplates = event
        }
      }
    },
    [
      renderCreateTemplateDialogBody.call(this, h),
      renderCreateTemplateDialogFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderWarning.call(this, h),
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderConfirmDialog.call(this, h),
      renderCreateTemplateDialog.call(this, h)
    ]
  )
}
