import { services } from '@/utils'

import render from './render'

export default {
  name: 'TasksItem',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      menuIsShowed: false
    }
  },

  computed: {
    $class() {
      return {
        'service-template-item': true,
        'hover-parent hover-parent--opacity': !this.menuIsShowed,
        [`${services.sendings}-tasks-item`]: true
      }
    },
    $style() {
      const result = [
        '38px', // tasksType
        '150px' // tasksState
      ]
      if (this.viewport.size.width >= 600) {
        result.push('380px') // tasksCounts
      }
      if (this.viewport.breakpoint.xl) {
        result.push('150px') // tasksTitle
      }
      if (this.viewport.breakpoint.lgUp) {
        result.push(
          '150px', // sender
          '1fr' // text
        )
      }
      if (this.viewport.size.width > 1560) {
        result.push('150px') // owner
      }
      if (this.viewport.size.width > 1380) {
        result.push('auto') // actions
      }
      if (this.viewport.size.width > 1640) {
        result.push('auto') // createdAt
      }

      return { gridTemplateColumns: result.join(' ') }
    }
  },

  render
}
