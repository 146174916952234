import { cloneDeep } from 'lodash'

import { generateServices, globalErrorHandler, globalErrorProcessor, proxy, services, states } from '@/utils'

import render from './render'

const prefix = title => `[DIRECT] ${title}`

const defaultFilter = { title: undefined }

export default {
  name: 'CreateDirect',

  mixins: [
    proxy(),
    generateServices([
      {
        name: services.messageRuleGroups,

        disableWatchers: true,

        find: {
          defaultFilter: cloneDeep(defaultFilter)
        },

        get: false,
        update: false,
        remove: false
      },
      {
        name: services.messageRules,

        find: false,
        create: false,
        update: false,
        remove: false
      }
    ])
  ],

  data() {
    return {
      messageRuleId: undefined,
      messageRuleTitle: undefined
    }
  },

  computed: {
    loading() {
      return this.restData[services.messageRuleGroups].create.state === states.loading || this.restData.messageRules.get.state === states.loading
    },
    disabled() {
      return this.restData[services.messageRuleGroups].find.pagination.total > 0 || this.loading || !this.messageRuleId
    }
  },

  watch: {
    async messageRuleId() {
      if (this.messageRuleId) {
        const { title } = await this.rest.messageRules.get(this.messageRuleId)
        this.messageRuleTitle = title
        this.restData[services.messageRuleGroups].find.filter.title = prefix(title)
        this.rest[services.messageRuleGroups].find()
      }
    }
  },

  mounted() {
    this.clear()
  },

  beforeDestroy() {
    this.clear()
  },

  methods: {
    clear() {
      this.messageRuleId = undefined
      this.messageRuleTitle = undefined
      this.restData[services.messageRuleGroups].find.data = undefined
      this.restData[services.messageRuleGroups].find.state = states.empty
      this.restData[services.messageRuleGroups].find.filter = cloneDeep(defaultFilter)
    },

    async create() {
      try {
        const { id } = await this.rest[services.messageRuleGroups].create({
          title: prefix(this.messageRuleTitle),
          MessageRules: [
            {
              id: this.messageRuleId,
              priority: 0,
              $attach: true
            }
          ]
        })
        this.$emit('id', id)
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.clear()
        this.proxy = false
      }
    }
  },

  render
}
