import render from './render'

export default {
  name: 'Item',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    total: Boolean
  },

  render
}
