import Vue from 'vue'

import { get } from 'lodash'

import { backendServerIP, isUUID, socketsRenew, states } from '@/utils'

export default {
  namespaced: true,

  state: {
    status: states.empty,
    errors: {},
    isLoggedAs: !!window.localStorage.getItem('prevToken') || false
  },

  mutations: {
    setStatus(state, status) {
      state.status = status
    },
    setErrors(state, errors) {
      state.errors = errors
    },
    setIsLoggedAs(state, status) {
      state.isLoggedAs = status
    }
  },

  actions: {
    async login({ dispatch, rootState }, credentials) {
      try {
        await dispatch('setStatus', states.loading)

        const response = await fetch(`${backendServerIP}/api/login`, {
          method: 'post',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            username: get(credentials, 'username'),
            password: get(credentials, 'password')
          })
        })

        if (!response.ok) {
          throw response
        }

        const { token } = await response.json()
        if (token) {
          await dispatch('authentication/setToken', token, { root: true })
          await dispatch('authentication/setAccountFromToken', null, { root: true })

          if (Vue.router && rootState.route.meta.guest) {
            Vue.router.push({ name: 'home' })
          }
        }

        dispatch('setStatus', states.ready)
      } catch (error) {
        dispatch('setStatus', states.error)

        if (typeof error.json === 'function') {
          console.error('error', error)
          const data = await error.json()
          if (data) {
            dispatch('setErrors', {
              ...data,
              headers: error.headers
            })
          }
        }
      }
    },

    async loginAs({ dispatch, rootGetters }, id) {
      let accountToSave
      let tokenToSave
      let tokenToUse

      if (isUUID(id)) {
        try {
          await dispatch('setStatus', states.loading)

          const result = await Vue.$GRequest.get('login/as', id)
          if (result) {
            accountToSave = JSON.stringify(rootGetters['account/account'])
            tokenToSave = rootGetters['authentication/token']
            tokenToUse = result.data.token

            window.localStorage.setItem('prevAccount', accountToSave)
            window.localStorage.setItem('prevToken', tokenToSave)
          }
        } catch (error) {
          dispatch('setStatus', states.error)
          tokenToUse = rootGetters['authentication/token']
          if (error.message) {
            dispatch(
              'notifications/addSnackbar',
              {
                text: error.message,
                type: 'error'
              },
              { root: true }
            )
          }
        }
      } else {
        tokenToUse = window.localStorage.getItem('prevToken')

        window.localStorage.removeItem('prevAccount')
        window.localStorage.removeItem('prevToken')
      }

      await dispatch('setIsLoggedAs', !!tokenToSave)

      if (tokenToUse) {
        const oldId = rootGetters['account/account'].id
        await dispatch('authentication/setToken', tokenToUse, { root: true })
        await dispatch('authentication/setAccountFromToken', null, { root: true })

        socketsRenew()

        if (tokenToSave) {
          Vue.router.push({ name: 'account' })
        } else {
          Vue.router.push({
            name: 'users.single',
            params: { id: oldId }
          })
        }
      }

      dispatch('setStatus', states.ready)
    },

    async logout({ dispatch, rootState }) {
      dispatch('authentication/setToken', false, { root: true })
      await dispatch('authentication/setAccountFromToken', null, { root: true })
      if (Vue.router && rootState.route.meta.auth) {
        Vue.router.push({ name: 'login' })
      }
    },

    setStatus({ commit }, status) {
      commit('setStatus', status)
    },
    setErrors({ commit }, error) {
      commit('setErrors', error)
    },
    setIsLoggedAs({ commit }, status) {
      commit('setIsLoggedAs', status)
    }
  },

  getters: {
    status: state => state.status,
    errors: state => state.errors,
    isLoggedAs: state => state.isLoggedAs
  }
}
