import Vue from 'vue'

import { Macro } from '@sigma-legacy-libs/cache'
import { compact, flatten, get, isFunction, isPlainObject, pick } from 'lodash'

import { isArrayNotEmpty, isUUID } from '@/utils'

const Cache = new Macro({ ttl: 15 * 60 * 1000 })

const cachedGet = Cache.wrapWithCache(async (key, service, id, params) => {
  return await Vue.$GRequest.get(service, id, params)
})

export const fillDependence = async function(inputObject, options = {}) {
  if (typeof options.service !== 'string' && !options.service) {
    throw new Error('option service is empty or not string')
  }
  let permissions = options.permissions
  if (Array.isArray(permissions) && !permissions.length) {
    permissions = [ true ]
  }
  let handler = response => {
    if (isArrayNotEmpty(options.picks)) {
      return pick(response, options.picks)
    }

    return response
  }
  if (isFunction(options.handler)) {
    handler = options.handler
  }

  let outputPath = `_${options.service}`
  if (typeof options.outputPath === 'string' && options.outputPath) {
    outputPath = options.outputPath
  }

  if (this.checkPermissions(`advanced.${options.service}.get`, permissions)) {
    const id = get(inputObject, options.pathToId)
    const object = get(inputObject, options.pathToObject)

    let wasArray
    let res
    if (isArrayNotEmpty(id) || isUUID(id)) {
      wasArray = Array.isArray(id)
      res = compact(
        await Promise.all(
          flatten([ id ]).map(async id => {
            try {
              const { data } = await cachedGet(`${options.service}:${id}:${JSON.stringify(options.requestParams)}`, options.service, id, options.requestParams)
              if (data) {
                return handler(data)
              }
            } catch (error) {
              console.error('[UTILS][MISC][fillDependence]', error)
            }

            return undefined
          })
        )
      )
    } else if (isArrayNotEmpty(object) || isPlainObject(object)) {
      wasArray = Array.isArray(object)
      res = flatten([ object ]).map(handler)
    }
    if (res !== undefined) {
      Vue.set(inputObject, outputPath, !wasArray ? res[0] : res)
    }
  }

  return inputObject
}

export default { fillDependence }
