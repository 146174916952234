import Vue from 'vue'

import { globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'SecondCol',

  data() {
    return {
      tab: undefined,
      text: undefined,
      words: undefined,

      loading: false
    }
  },

  computed: {
    tabs() {
      const result = []
      if (this.checkPermissions(`advanced.${services.keywords}.get`)) {
        result.push('matches')
      }
      if (this.checkPermissions(`advanced.${services.eventsHistory}.get`)) {
        result.push(services.eventsHistory)
      }

      return result.map(key => {
        return {
          key,
          title: this.getTranslate(`commons.tabs.${key}`)
        }
      })
    }
  },

  mounted() {
    this.tab = this.tabs[0]?.key
  },

  methods: {
    async matchText() {
      if (this.checkPermissions(`advanced.${services.keywords}.get`)) {
        if (this.text) {
          this.loading = true
          try {
            const { data } = await Vue.$GRequest.create(services.keywords + '/match', {
              ListId: this.$route.params.id,
              text: this.text
            })
            if (data) {
              this.words = data
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          } finally {
            this.loading = false
          }
        } else {
          this.words = []
        }
      }
    },

    setKeyword(word) {
      if (this.$route.query.keyword) {
        this.$route.query.keyword = word
      } else {
        Vue.set(this.$route.query, 'keyword', word)
      }
    }
  },

  render
}
