import { services } from '@/utils'

import render from './render'

export default {
  name: 'SecondCol',

  data() {
    return {
      tab: undefined
    }
  },

  computed: {
    tabs() {
      const result = [ 'grafana', services.sendings ]

      return result.map(key => {
        return {
          key,
          title: this.getTranslate(`commons.tabs.${key}`)
        }
      })
    }
  },

  mounted() {
    this.tab = this.tabs[0]?.key
  },

  render
}
