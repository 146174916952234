import Vue from 'vue'

import { get } from 'lodash'

import { buttonModes, colors, services } from '@/utils'

import info from '@/components/info'
import button from '@/components/button'
import form from '@/components/services/registration/form'

function renderTitle(h) {
  return h(
    'div',
    {},
    [ this.getTranslate(`${services.registration}.title`) ]
  )
}
function renderPlatformTitle(h) {
  const title = get(this.globalSettings, 'frontend.title')
  if (title) {
    return h(
      'div',
      {
        class: 'text--grey'
      },
      [ title ]
    )
  }
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8 px-3': true,
        title: this.viewport.size.width <= 768,
        headline: this.viewport.size.width > 768
      },
      style: { gridTemplateColumns: '1fr auto' }
    },
    [
      renderTitle.call(this, h),
      renderPlatformTitle.call(this, h)
    ]
  )
}

function renderBody(h) {
  return h(
    'g-card',
    {
      class: 'grid pa-3',
      props: {
        outline: true,
        rounded: true,
        overflow: true,
        minWidth: this.viewport.size.width < 768 ? 360 : 400,
        maxWidth: this.viewport.size.width < 768 ? 360 : 400
      }
    },
    [
      h(
        form,
        {
          props: {
            value: this.payload,
            errors: this.errors
          },
          on: {
            input: event => {
              this.payload = event
            },
            submit: () => {
              this.registration(this.payload)
            },
            validation: event => {
              this.validation = event
            }
          }
        }
      ),

      h(
        info,
        {
          props:
          {
            value: this.getTranslate(`${services.registration}.hints.required`),
            color: colors.info
          }
        }
      )
    ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate(`${services.registration}.buttons.signin`),
            mode: buttonModes.flat,
            disabled: this.loading
          },
          on: {
            click: () => {
              Vue.router.push({ name: 'login' })
            }
          }
        }
      ),

      h(
        button,
        {
          props: {
            label: this.getTranslate(`${services.registration}.buttons.signup`),
            mode: buttonModes.filled,
            color: colors.primary,
            loading: this.loading,
            disabled: !this.validation
          },
          on: {
            click: () => {
              this.registration(this.payload)
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
