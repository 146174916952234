import { SENDING_STATUS, WEBHOOK_EVENTS } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, inputModes } from '@/utils'

import button from '@/components/button'
import arrow from '@/components/misc/arrow'
import settingRow from '@/components/services/settings/row'

function renderExtras(h, type, { path, serviceName }) {
  if (type === WEBHOOK_EVENTS.onStatusChange) {
    return h(
      components['expansion-panels'],
      {
        props: {
          rounded: true,
          outline: true
        }
      },
      [
        h(
          components['expansion-panel'],
          {
            scopedSlots: {
              header: ({ expanded }) => {
                return h(
                  'div',
                  {
                    class: 'default-expansion-panel-header'
                  },
                  [
                    this.getTranslate(`${serviceName}.contents.${path}.statuses`),
                    h(arrow, { props: { expanded } })
                  ]
                )
              }
            }
          },
          [
            h(
              'div',
              {
                class: 'grid grid-cols--2 pa-2'
              },
              SENDING_STATUS.map(status => {
                return h(
                  components['text-field'],
                  {
                    props: {
                      value: this.proxy[type].extras.statuses[status],
                      label: this.getTranslate(`sendings.statuses.${status}`),
                      mode: inputModes['line-label'],
                      dense: true,
                      rounded: true,
                      clearable: true,
                      details: false
                    },
                    on: {
                      input: event => {
                        this.proxy[type].extras.statuses[status] = event
                      }
                    }
                  }
                )
              })
            )
          ]
        )
      ]
    )
  }

  return h('div')
}

function renderForm(h, type, { path, serviceName }) {
  return h(
    'div',
    {
      class: 'grid pa-2'
    },
    [
      h(
        components.card,
        {
          class: 'grid px-2 pb-2 pt-3',
          props: {
            title: this.getTranslate(`${serviceName}.subtitles.${path}.general`),
            outline: true,
            dashed: true,
            rounded: true
          }
        },
        [
          h(
            'div',
            {
              class: 'grid faic',
              style: { 'grid-template-columns': '60% calc(40% - 16px)' }
            },
            [
              h(
                components['text-field'],
                {
                  props: {
                    value: this.proxy[type].url,
                    defaultValue: '',
                    label: this.getTranslate(`${serviceName}.labels.${path}.url`),
                    mode: inputModes['line-label'],
                    rounded: true,
                    clearable: true,
                    details: false
                  },
                  on: {
                    input: event => {
                      this.proxy[type].url = event
                    }
                  }
                }
              ),

              h(
                components.select,
                {
                  props: {
                    value: this.proxy[type].method,
                    defaultValue: '',
                    label: this.getTranslate(`${serviceName}.labels.${path}.method`),
                    items: [ 'POST', 'GET' ],
                    mode: inputModes['line-label'],
                    rounded: true,
                    clearable: true,
                    details: false
                  },
                  on: {
                    input: event => {
                      this.proxy[type].method = event
                    }
                  }
                }
              )
            ]
          ),

          h(
            components.textarea,
            {
              props: {
                value: this.proxy[type].headers,
                defaultValue: '',
                label: this.getTranslate(`${serviceName}.labels.${path}.headers`),
                mode: inputModes['line-label'],
                rounded: true,
                grow: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy[type].headers = event
                }
              }
            }
          ),

          h(
            components.textarea,
            {
              props: {
                value: this.proxy[type].body,
                defaultValue: '',
                label: this.getTranslate(`${serviceName}.labels.${path}.body`),
                mode: inputModes['line-label'],
                rounded: true,
                grow: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy[type].body = event
                }
              }
            }
          ),

          h(
            components['text-field'],
            {
              props: {
                value: this.proxy[type].maxConnections,
                defaultValue: 10,
                label: this.getTranslate(`${serviceName}.labels.${path}.maxConnections`),
                mode: inputModes['line-label'],
                type: 'number',
                step: 'any',
                rounded: true,
                clearable: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy[type].maxConnections = event
                }
              }
            }
          )
        ]
      ),

      h(
        components.card,
        {
          class: 'grid px-2 pb-2 pt-3',
          props: {
            title: this.getTranslate(`${serviceName}.subtitles.${path}.successOn`),
            outline: true,
            dashed: true,
            rounded: true
          }
        },
        [
          h(
            components['text-field'],
            {
              props: {
                value: this.proxy[type].successOn.status,
                defaultValue: '200',
                label: this.getTranslate(`${serviceName}.labels.${path}.successOn.status`),
                mode: inputModes['line-label'],
                rounded: true,
                clearable: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy[type].successOn.status = event
                }
              }
            }
          ),

          h(
            components['text-field'],
            {
              props: {
                value: this.proxy[type].successOn.body,
                defaultValue: '',
                label: this.getTranslate(`${serviceName}.labels.${path}.successOn.body`),
                mode: inputModes['line-label'],
                rounded: true,
                clearable: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy[type].successOn.body = event
                }
              }
            }
          )
        ]
      ),

      renderExtras.call(this, h, type, {
        path,
        serviceName
      })
    ]
  )
}

export default function(h, { path, serviceName }) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    WEBHOOK_EVENTS.map(type => {
      return h(
        settingRow,
        {
          props: {
            title: this.getTranslate(`${serviceName}.contents.${path}.${type}`),
            label: this.getTranslate(`${serviceName}.hints.${path}.${type}`),
            icon: icons.webhook
          },
          on: {
            click: () => {
              this.show[type] = true
            }
          },
          scopedSlots: {
            dialog: () => {
              return h(
                components.dialog,
                {
                  props: {
                    title: this.getTranslate(`${serviceName}.dialogs.titles.${path}.${type}`),
                    rounded: true,
                    value: this.show[type],
                    maxWidth: 600
                  },
                  on: {
                    input: event => {
                      this.show[type] = event
                    }
                  }
                },
                [
                  renderForm.call(this, h, type, {
                    path,
                    serviceName
                  }),

                  h(
                    'div',
                    {
                      class: 'fjcfe faic grid-gap--8 pa-2',
                      slot: 'footer'
                    },
                    [
                      h(
                        button,
                        {
                          props: {
                            label: this.getTranslate('misc.buttons.cancel'),
                            mode: buttonModes.flat
                          },
                          on: {
                            click: () => {
                              this.show[type] = false
                            }
                          }
                        }
                      ),

                      h(
                        button,
                        {
                          props: {
                            label: this.getTranslate('misc.buttons.save'),
                            color: colors.primary
                          },
                          on: {
                            click: () => {
                              this.$emit('input', this.proxy)
                              this.show[type] = false
                            }
                          }
                        }
                      )
                    ]
                  )
                ]
              )
            }
          }
        }
      )
    })
  )
}
