import { buttonModes, components, icons, sizes } from '@/utils'

import button from '@/components/button'

const today = new Date()
today.setHours(0, 0, 0, 0)

function renderTitle(h) {
  return h(
    'div',
    {
      class: `${components['date-picker']}__title`
    },
    [
      this.$dates.map((date, index) => {
        return h(
          button,
          {
            props: {
              label: date,
              size: sizes.small,
              mode: buttonModes.flat,
              disabled: date === '---'
            },
            on: {
              click: () => {
                this.state = 'days'
                const dateFromDate = new Date(this.proxy[index])
                this.date.year = dateFromDate.getFullYear()
                this.date.month = dateFromDate.getMonth()
                this.date.day = dateFromDate.getDate()
              }
            }
          }
        )
      })
    ]
  )
}
function renderArrow(h, direction = -1) {
  return h(
    button,
    {
      props: {
        icon: direction < 0 ? icons.keyboard_arrow_left : icons.keyboard_arrow_right,
        mode: buttonModes.flat
      },
      on: {
        click: () => {
          this.arrowHandler(direction)
        }
      }
    }
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${components['date-picker']}__header`
    },
    [
      renderArrow.call(this, h),
      renderTitle.call(this, h),
      renderArrow.call(this, h, 1)
    ]
  )
}

function renderInfo(h) {
  const month = new Date(this.date.year, this.date.month).toLocaleString(this.locale, { month: 'long' })

  return h(
    'div',
    {
      class: `${components['date-picker']}__info`
    },
    [ 'months', 'years' ].map(state => {
      return h(
        button,
        {
          props: {
            label: state === 'months' ? month : this.date.year,
            size: sizes.small,
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.state = state
            }
          }
        }
      )
    })
  )
}

function renderDay(h, { day, props }) {
  if (day) {
    return h(
      button,
      {
        class: `${components['date-picker']}__day`,
        props,
        on: {
          click: event => this.clickHandler(event, day),
          mouseenter: () => {
            if (this.range) {
              this.hoveringDay = day
            }
          }
        },
        key: this.getUnixTimeByDay(day)
      }
    )
  }

  return h('div')
}
function renderDaysOfWeek(h) {
  return h(
    'div',
    {
      class: `${components['date-picker']}__row`
    },
    [
      this.daysOfWeek.map(day => {
        return h(
          'div',
          {
            class: `${components['date-picker']}__day-of-week`
          },
          [ day ]
        )
      })
    ]
  )
}
function renderDaysMatrix(h) {
  return this.$date.days.map(week => {
    return h(
      'div',
      {
        class: `${components['date-picker']}__row`
      },
      [
        week.map(day => {
          return renderDay.call(this, h, day)
        })
      ]
    )
  })
}
function renderDays(h) {
  return h(
    'div',
    {
      class: `${components['date-picker']}__days`
    },
    [
      renderDaysOfWeek.call(this, h),
      renderDaysMatrix.call(this, h)
    ]
  )
}

function renderMonth(h, { month, props }) {
  return h(
    button,
    {
      class: `${components['date-picker']}__month`,
      props,
      on: { click: event => this.clickHandler(event, month, 'month') },
      key: `${components['date-picker']}-month-${month.number}`
    }
  )
}
function renderMonthsMatrix(h) {
  return this.$date.months.map(quarter => {
    return h(
      'div',
      {
        class: `${components['date-picker']}__row ${components['date-picker']}__row--three`
      },
      [
        quarter.map(month => {
          return renderMonth.call(this, h, month)
        })
      ]
    )
  })
}
function renderMonths(h) {
  return h(
    'div',
    {
      class: `${components['date-picker']}__months`
    },
    [ renderMonthsMatrix.call(this, h) ]
  )
}

function renderYear(h, { year, props }) {
  const current = today.getFullYear() === year

  return h(
    button,
    {
      class: {
        [`${components['date-picker']}__year`]: true,
        [`${components['date-picker']}__year--active`]: this.isActiveYear(year) || current
      },
      props,
      on: { click: event => this.clickHandler(event, year, 'year') },
      key: `${components['date-picker']}-year-${year}`
    }
  )
}
function renderYearsMatrix(h) {
  return this.$date.years.map(quarter => {
    return h(
      'div',
      {
        class: `${components['date-picker']}__row ${components['date-picker']}__row--three`
      },
      [
        quarter.map(year => {
          return renderYear.call(this, h, year)
        })
      ]
    )
  })
}
function renderYears(h) {
  return h(
    'div',
    {
      class: `${components['date-picker']}__years`,
      ref: 'years'
    },
    [ renderYearsMatrix.call(this, h) ]
  )
}

function renderContent(h) {
  switch (this.state) {
    case 'months': return renderMonths.call(this, h)
    case 'years': return renderYears.call(this, h)
    case 'days':
    default: return renderDays.call(this, h)
  }
}

function renderHolder(h) {
  return h(
    'div',
    {
      class: `${components['date-picker']}__holder`
    },
    [ renderContent.call(this, h) ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${components['date-picker']}`]: true,
        [`${components['date-picker']}--range`]: this.range,
        [`${components['date-picker']}--outline`]: this.outline
      },
      ref: 'date-picker'
    },
    [
      renderHeader.call(this, h),
      renderInfo.call(this, h),
      renderHolder.call(this, h)
    ]
  )
}
