import { buttonModes, colors, components, delimiters, icons, inputModes, permissionPresets, services, sizes } from '@/utils'

import info from '@/components/info'
import button from '@/components/button'
import arrow from '@/components/misc/arrow'
import HeadTitle from '@/components/misc/HeadTitle'

function renderInfo(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [ 'beforeRequest', 'repeatRequest', 'afterRequest' ].map(item => {
      let color = colors.info
      switch (item) {
        case 'repeatRequest': {
          color = 'error'
          break
        }
        case 'afterRequest': {
          color = 'warning'
          break
        }
      }

      return h(
        info,
        {
          props: {
            value: this.getTranslate(`${services.reports}.warnings.${item}`),
            color
          }
        }
      )
    })
  )
}

function renderFlagByPreset(h) {
  switch (this.preset) {
    case 'sendings.details': {
      return h(
        components.switch,
        {
          props: {
            value: this.flags.includeRefunded,
            label: this.getTranslate(`${services.reports}.labels.includeRefunded`),
            disabled: this.loading
          },
          on: {
            input: event => {
              this.flags.includeRefunded = event
            }
          }
        }
      )
    }
  }
}
function renderFlags(h) {
  if (this.checkPermissions(`advanced.${services.reports}.generate`, permissionPresets.true)) {
    return h(
      'div',
      {
        class: 'grid grid-cols--2 grid-gap--8 pa-2'
      },
      [ renderFlagByPreset.call(this, h) ]
    )
  }
}

function renderForm(h) {
  return h(
    components.card,
    {
      class: 'grid grid-gap--8 px-2 pb-2 pt-3',
      props: {
        title: this.getTranslate(`${services.reports}.titles.encoding`),
        rounded: true,
        outline: true,
        dashed: true
      }
    },
    [
      h(
        'div',
        {
          class: {
            'faic grid-gap--8': true
          }
        },
        Object.keys(this.platformButtons).map(platform => {
          return h(
            button,
            {
              props: {
                label: this.getTranslate(`misc.platforms.${platform}`),
                color: colors.primary,
                mode: this.platformButtons[platform].mode,
                size: sizes.small
              },
              on: {
                click: () => {
                  this.encoding = this.platformButtons[platform].encoding
                  this.delimiter = delimiters.semicolon
                }
              }
            }
          )
        })
      ),
      h(
        components['expansion-panels'],
        {
          props: {
            rounded: true,
            outline: true
          }
        },
        [
          h(
            components['expansion-panel'],
            {
              scopedSlots: {
                header: ({ expanded }) => {
                  return h(
                    'div',
                    {
                      class: 'default-expansion-panel-header'
                    },
                    [
                      this.getTranslate(`${services.reports}.titles.settings`),
                      h(arrow, { props: { expanded } })
                    ]
                  )
                },
                default: () => {
                  return h(
                    'div',
                    {
                      class: {
                        'faic grid-gap--8 px-2 pb-2 pt-3': true,
                        grid: this.viewport.breakpoint.xs
                      }
                    },
                    Object.keys(this.encodingSettings).map(setting => {
                      return h(
                        components.select,
                        {
                          props: {
                            value: this[setting],
                            label: this.getTranslate(`${services.reports}.labels.${setting}`),
                            items: this.encodingSettings[setting].items,
                            disabled: this.loading,
                            details: false,
                            dense: true,
                            mode: inputModes['line-label'],
                            rounded: true
                          },
                          on: {
                            input: event => {
                              this[setting] = event
                            }
                          }
                        }
                      )
                    })
                  )
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderRequestAndFilesButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.goto.requestAndFiles'),
        mode: buttonModes.outline,
        color: colors.primary
      },
      on: {
        click: async () => {
          await this.getReport()
          this.$router.push({ name: 'files' })
        }
      }
    }
  )
}

function renderDialogHeader(h) {
  h(
    HeadTitle,
    {
      props: { value: this.getTranslate(`${services.reports}.title`) },
      slot: 'header'
    }
  )
}
function renderDialogBody(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      renderInfo.call(this, h),
      renderForm.call(this, h),
      renderFlags.call(this, h)
    ]
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: {
        'faic grid-gap--8 pa-2': true,
        fjcsb: !this.viewport.breakpoint.xs,
        'fw fjcfe': this.viewport.breakpoint.xs
      },
      slot: 'footer'
    },
    [
      renderRequestAndFilesButton.call(this, h),

      h(
        'div',
        {
          class: 'faic grid-gap--8'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                mode: buttonModes.flat,
                disabled: this.loading
              },
              on: {
                click: () => {
                  this.proxy = false
                }
              }
            }
          ),
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.reports.request'),
                loading: this.loading,
                disabled: this.loading,
                color: colors.primary
              },
              on: {
                click: () => {
                  this.getReport()
                }
              }
            }
          )
        ]
      )
    ]
  )
}
function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        value: this.proxy,
        rounded: true,
        maxWidth: 600
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    },
    [
      renderDialogHeader.call(this, h),
      renderDialogBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}

export default function(h) {
  switch (this.type) {
    case 'list-item': {
      return h(
        components['list-item'],
        {
          props: {
            dense: true,
            label: this.getTranslate('misc.buttons.reports.get'),
            disabled: !this.checkPermissions(`advanced.${services.reports}.generate`, permissionPresets.meUp)
          },
          on: {
            click: () => {
              this.proxy = true
            }
          },
          scopedSlots: {
            dialog: () => renderDialog.call(this, h)
          }
        }
      )
    }

    case 'button':
    default: {
      return h(
        button,
        {
          props: {
            icon: icons.cloud_download,
            mode: buttonModes.flat,
            label: !this.icon && this.viewport.breakpoint.mdUp ? this.getTranslate('misc.buttons.reports.get') : undefined,
            disabled: !this.checkPermissions(`advanced.${services.reports}.generate`, permissionPresets.meUp)
          },
          on: {
            click: () => {
              this.proxy = true
            }
          },
          scopedSlots: {
            dialog: () => renderDialog.call(this, h)
          }
        }
      )
    }
  }
}
