export default {
  title: 'Debug',

  titles: {
    browser: 'Browser',
    ws: 'WebSocket',
    account: 'Account',
    sendings: 'Last sending',

    site: {
      address: 'Site address'
    }
  },

  labels: {
    version: 'Platform version',
    date: 'Date and time of page opening',
    viewport: 'Window size',
    host: 'Host',
    protocol: 'Protocol',

    ws: {
      connection: 'Connection',
      error: 'Error'
    },

    frontend: {
      title: 'Platform name'
    },

    browser: {
      name: 'Name',
      version: 'Version'
    },

    account: {
      username: 'Username',
      balance: 'Balance',
      id: 'ID',
      sendingTypes: 'Allowed sending types'
    },

    sending: {
      id: 'ID',
      recipient: 'Recipient',
      type: 'Sending type'
    }
  },

  ws: {
    connected: 'Connected',
    disconnected: 'Disconnected'
  },

  buttons: {
    reset: 'Reset',

    download: {
      storage: 'Download storage'
    },

    clean: {
      storage: 'Clean storage'
    }
  }
}
