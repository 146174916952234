import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'AfterCreateDialog',

  mixins: [ proxy() ],

  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      count: 15,
      interval: null,
      sensitivity: true,
      disabled: true
    }
  },

  computed: {
    token() {
      const token = this.data.token
      if (token) {
        if (this.sensitivity) {
          return '•'.repeat(token.length)
        }

        return token
      }
    }
  },

  watch: {
    value(value) {
      if (value) {
        this.interval = setInterval(() => {
          this.count--
          if (this.count === 0) {
            this.reset(false)
          }
        }, 1000)
      } else {
        this.reset(true)
      }
    }
  },

  mounted() {
    this.reset(true)
  },

  beforeDestroy() {
    this.reset(true)
  },

  methods: {
    reset(disabled) {
      this.count = 15
      clearInterval(this.interval)
      this.disabled = disabled
    }
  },

  render
}
