import { SENDING_HASHABLE_FIELDS } from '@sigma-legacy-libs/essentials/lib/constants'

import { components, inputModes } from '@/utils'

export default function(h, { path, serviceName }) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.${path}`),
        items: SENDING_HASHABLE_FIELDS,
        mode: inputModes['outline-label'],
        dense: true,
        rounded: true,
        details: false,
        multiple: true,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
