import { get } from 'lodash'

import { opfListShort } from '@/components/services/requisites/utils'

import render from './render'

export default {
  name: 'AfterTitle',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    address() {
      return get(this.value, 'data.postalAddress.address')
    },
    html() {
      const opf = get(this.value, 'data.legal.opf')
      const name = get(this.value, 'data.legal.name')
      const city = get(this.value, 'data.postalAddress.city')
      const index = get(this.value, 'data.postalAddress.index')

      return `
      <html style="height: 100%;">
        <head>
          <title>Print</title>
        </head>
        <body style="margin: 0px; height: 100%;">
          <div style="float: right; display: flex; align-items: flex-end; align-content: flex-end; justify-content: flex-end; height: 100%;">
            <table style="border-collapse: collapse; min-width: 350px; max-width: 350px;">
              <tr>
                <td style="padding-top: 4px; padding-bottom: 4px; padding-left: 8px; padding-right: 8px; vertical-align: top;">Кому:</td>
                <td style="padding-top: 4px; padding-bottom: 4px; padding-left: 8px; padding-right: 8px; vertical-align: top; width: 100%;">${opfListShort[opf]} "${name}"</td>
                <td style="padding-right: 48px;"></td>
              </tr>
              <tr>
                <td style="padding-top: 4px; padding-bottom: 4px; padding-left: 8px; padding-right: 8px; vertical-align: top;">Куда:</td>
                <td style="padding-top: 4px; padding-bottom: 8px; padding-left: 8px; padding-right: 8px; vertical-align: top; width: 100%;">${this.address}<br>${city}</td>
                <td style="padding-right: 48px;"></td>
              </tr>
              <tr>
                <td style="padding-top: 4px; padding-bottom: 4px; padding-left: 8px; padding-right: 8px; vertical-align: top;">Индекс:</td>
                <td style="padding-top: 4px; padding-bottom: 80px; padding-left: 8px; padding-right: 8px; vertical-align: top; width: 100%;">${index}</td>
                <td style="padding-right: 48px;"></td>
              </tr>
            </table>
          </div>
        </body>
      </html>
      `
    }
  },

  methods: {
    print() {
      const printWindow = window.open('', '_blank')
      printWindow.document.write(this.html)
      printWindow.document.close()
      printWindow.focus()
      printWindow.print()
      printWindow.close()
    }
  },

  render
}
