import Conditions from '@sigma-legacy-libs/essentials/lib/utils/Conditions'

import render from './render'

export default {
  name: 'Row',

  props: {
    value: null,

    tag: String,

    readonly: Boolean,
    clearable: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    hasValue() {
      return Conditions.validateValue(this.value, this.tag)
    }
  },

  render
}
