import Vue from 'vue'

import { debounce, uniqueId } from 'lodash'

import { currentTab, datePresets, getCreatedAtByPreset, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: services.sendingsModeration,

  mixins: [ currentTab() ],

  data() {
    return {
      filter: {
        OwnerId: undefined,
        pattern: undefined,
        sender: undefined,
        range: {
          $gt: undefined,
          $lt: undefined
        }
      },

      preset: datePresets.today,

      users: [],
      sendings: [],

      interval: undefined,

      usersLoading: false,
      sendingsLoading: false
    }
  },

  computed: {
    tabs() {
      const result = []

      if (this.checkPermissions(`advanced.${services.sendings}.find`)) {
        result.push(services.sendings)
      }
      if (this.checkPermissions(`advanced.${services.tasks}.get`)) {
        result.push(services.tasks)
      }

      return result
    }
  },

  watch: {
    filter: {
      handler() {
        this.getSendings()
      },
      deep: true
    },

    'filter.range'() {
      this.getUsers()
    },

    preset() {
      this.setRangeByPreset()
    }
  },

  mounted() {
    this.setRangeByPreset()
    this.interval = setInterval(() => {
      this.getUsers()
    }, 15 * 1000)

    this.currentTab = this.tabs[0]
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  methods: {
    async getUsers() {
      if (this.checkPermissions(`advanced.${services.sendingsModeration}.get`)) {
        try {
          this.usersLoading = true
          const path = [ services.sendingsModeration, services.sendings ].join('/')
          const { data } = await Vue.$GRequest.find(path, {
            query: {
              range: this.filter.range,
              t: Date.now()
            }
          })
          if (data) {
            this.users = data
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.usersLoading = false
        }
      }
    },

    getSendings: debounce(
      async function() {
        if (this.checkPermissions(`advanced.${services.sendingsModeration}.get`)) {
          if (this.filter.OwnerId) {
            try {
              this.sendingsLoading = true
              const path = [ services.sendingsModeration, services.sendings ].join('/')
              const { data } = await Vue.$GRequest.find(path, {
                query: Object.assign({}, this.filter, { t: Date.now() })
              })
              if (data) {
                this.sendings = data.map((item, index) => {
                  item.uniqueKey = uniqueId(`${services.sendingsModeration}-${index}-`)

                  return item
                })
              }
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            } finally {
              this.sendingsLoading = false
            }
          }
        }
      },
      500,
      {
        leading: false,
        trailing: true
      }
    ),

    setRangeByPreset() {
      if (this.preset) {
        this.filter.range = getCreatedAtByPreset(this.preset)
      }
    }
  },

  render
}
