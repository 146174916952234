import { get } from 'lodash'

import { buttonModes, colors, getPrice, icons, permissionPresets, services } from '@/utils'
import { billingsStatuses } from '@/components/services/billings/utils'

import button from '@/components/button'
import sticker from '@/components/misc/sticker'
import date from '@/components/templates/service/item/date'
import owner from '@/components/templates/service/item/owner'
import updateStatusButton from '@/components/services/billings/updateStatusButton'

function renderAmount(h) {
  const status = get(this.value, 'status')
  const statusIcon = get(billingsStatuses, `${status}.icon`)
  const statusColor = get(billingsStatuses, `${status}.color`)

  return h(
    sticker,
    {
      props: {
        value: getPrice(this.value.amount),
        label: this.getTranslate(`${services.billings}.labels.amount`),
        icon: statusIcon,
        color: statusColor,
        tooltip: `${getPrice(this.value.data.price)} x ${this.value.data.multiplier}`,
        iconTooltip: this.getTranslate(`${services.billings}.statuses.${status}`),
        maxWidth: 120,
        width: '100%'
      },
      key: [ this.value.id, this.value.amount ].join(':')
    }
  )
}
function renderTariff(h) {
  if (this.viewport.size.width > 480) {
    if (this.checkPermissions(`advanced.${services.tariffs}.get`, permissionPresets.resellerUp)) {
      const value = get(this.value, 'Tariff', get(this.value, 'TariffId'))
      if (value) {
        const [ source, type ] = this.value.source && this.value.source.split('.') || []

        return h(
          sticker,
          {
            props: {
              value,
              label: this.getTranslate(`${services.tariffs}.labels.tariff`),
              iconSVG: type || source,
              color: type || source,
              service: services.tariffs,
              maxWidth: 150,
              width: '100%'
            },
            key: [ this.value.id, value ].join(':')
          }
        )
      }
    }
  }
}
function renderOwner(h) {
  if (this.viewport.size.width > 1080) {
    return h(
      owner,
      {
        props: {
          value: this.value,
          maxWidth: 150,
          width: '100%'
        },
        key: [ this.value.id, get(this.value, 'Owner.id') ].join(':')
      }
    )
  }
}
function renderComment(h) {
  if (this.viewport.size.width > 1280) {
    const value = get(this.value, 'data.hidden.comment')
    if (value) {
      return h(
        sticker,
        {
          props: {
            value,
            label: this.getTranslate(`${services.billings}.labels.comment`),
            icon: icons.comment,
            color: colors.grey,
            maxWidth: 'fit-content',
            copy: true
          },
          key: [ this.value.id, value ].join(':')
        }
      )
    }
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: `${services.billings}-item__info`
    },
    [
      renderAmount.call(this, h),
      renderTariff.call(this, h),
      renderOwner.call(this, h),
      renderComment.call(this, h)
    ]
  )
}

function renderMoreButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.code,
        mode: buttonModes.flat,
        color: colors.primary
      },
      on: {
        click: () => {
          this.$emit('more', true)
        }
      }
    }
  )
}
function renderUpdateStatusButton(h) {
  return h(
    updateStatusButton,
    {
      props: {
        id: this.value.id,
        status: this.value.status
      }
    }
  )
}
function renderActions(h) {
  return h(
    'div',
    {
      class: `${services.billings}-item__actions`
    },
    [
      renderMoreButton.call(this, h),
      renderUpdateStatusButton.call(this, h)
    ]
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderUpdateAt(h) {
  if (this.viewport.size.width > 600) {
    return h(
      date,
      {
        props: {
          value: this.value,
          pathToDate: 'updatedAt'
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `service-template-item ${services.billings}-item hover-parent hover-parent--opacity`
    },
    [
      renderInfo.call(this, h),
      renderActions.call(this, h),
      renderCreatedAt.call(this, h),
      renderUpdateAt.call(this, h)
    ]
  )
}
