export default {
  titles: {
    import: {
      result: 'Результаты импорта'
    }
  },

  labels: {
    version: 'Версия'
  },

  contents: {
    import: {
      result: 'Успешно импортировано записей: {result}',
      error: 'Не удалось импортировать записи из этого файла.<br>Пожалуйста, проверьте файл и попробуйте еще раз.<strong class="mt-2">Обязательные столбцы: mcc, mnc, price.</strong>'
    }
  }
}
