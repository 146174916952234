import { FILE_TYPES, proxy } from '@/utils'

import render from './render'

export default {
  name: 'FileFilter',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    types: {
      type: Array,
      default: () => []
    },

    disabled: Boolean
  },

  data() {
    return {
      searchTypes: []
    }
  },

  computed: {
    $types() {
      return this.types.reduce((result, current) => {
        if (typeof current === 'string' && current) {
          for (const key in FILE_TYPES) {
            const { extensions } = FILE_TYPES[key]
            if (key !== 'file' && extensions.includes(current) && !result.includes(key)) {
              result.push(key)
            }
          }
        }

        return result
      }, [])
    },

    filteredTypes() {
      return this.searchTypes.concat(this.$types).map(type => FILE_TYPES[type].extensions).flat()
    },

    typesItems() {
      const result = []
      for (const key in FILE_TYPES) {
        if (key !== 'file') {
          result.push({
            title: this.getTranslate(FILE_TYPES[key].title),
            value: key
          })
        }
      }

      return result
    }
  },

  watch: {
    types: {
      handler() {
        this.setSearchTypes()
      },
      deep: true
    },
    filteredTypes: {
      handler(value) {
        this.proxy.type = value
      },
      deep: true
    }
  },

  mounted() {
    this.setSearchTypes()
  },

  methods: {
    setSearchTypes() {
      if (this.$types.length) {
        this.searchTypes = this.$types
      }
    }
  },

  render
}
