export default {
  apisender: 'WAPI',
  billings: 'Финансовые операции',
  companies: 'Компании',
  contacts: 'Контакты',
  contactLists: 'Списки контактов',
  contracts: 'Контракты',
  credentials: 'Учётные данные',
  dadata: 'DaData',
  dictionaries: 'Словари',
  documentTemplates: 'Шаблоны документов',
  eventsHistory: 'История событий',
  invoices: 'Счета',
  keywords: 'Ключевые слова',
  keywordLists: 'Списки ключевых слов',
  login: 'Авторизация',
  messageRules: 'Правила обработки сообщений',
  messageRuleGroups: 'Группы правил обработки сообщений',
  notifications: 'Уведомления',
  payments: 'Платежи',
  patterns: 'Шаблоны для модерации',
  providers: 'Провайдеры',
  providerGroups: 'Группы провайдеров',
  providersPrices: 'Цены провайдеров',
  recurrentPayments: 'Автоплатежи',
  registration: 'Регистрация',
  reports: 'Отчёты',
  requisites: 'Реквизиты',
  sendernames: 'Имена отправителей',
  routingGroups: 'Группы правил маршрутизации',
  routingRules: 'Правила маршрутизации',
  sendings: 'Исходящие сообщения',
  sendingsIncoming: 'Входящие сообщения',
  servers: 'Серверы',
  settingsUser: 'Настройки пользователя',
  storage: 'Файлы',
  tariffs: 'Тарифы',
  tasks: 'Задачи',
  telemetry: 'Телеметрия',
  templates: 'Шаблоны',
  tokens: 'Токены',
  users: 'Пользователи'
}
