import { buttonModes, colors, components, icons, services } from '@/utils'

import button from '@/components/button'
import buttonRefresh from '@/components/button/refresh'
import pagination from '@/components/pagination'

function renderPagination(h) {
  return h(
    pagination,
    {
      props: {
        value: this.pagination,
        selectRows: false,
        service: services.sendingsModeration
      },
      on: {
        input: event => {
          this.pagination = event
        }
      }
    }
  )
}
function renderButtonRefresh(h) {
  return h(
    buttonRefresh,
    {
      props: {
        state: this.loading,
        disabled: this.loading,
        tooltip: this.getTranslate(`${services.sendingsModeration}.tooltips.buttons.refresh`),
        shortClickMethod: () => this.$emit('refresh')
      }
    }
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.sendingsModeration}-header`
    },
    [
      renderPagination.call(this, h),
      renderButtonRefresh.call(this, h)
    ]
  )
}

function renderListItem(h, item) {
  return h(
    components['list-item'],
    {
      props: {
        dense: true,
        rounded: true,
        active: this.OwnerId === item.OwnerId
      },
      on: {
        click: () => {
          this.OwnerId = item.OwnerId
          this.$emit('userId', item.OwnerId)
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'fjcsb faic'
        },
        [
          h('div', { class: 'text-overflow caption' }, item.username),
          h('div', { class: 'text--grey caption' }, item.total)
        ]
      )
    ]
  )
}
function renderButton(h, item) {
  return h(
    button,
    {
      props: {
        icon: icons.open_in_new,
        mode: buttonModes.flat,
        color: colors.primary,
        tooltip: this.getTranslate(`${services.sendingsModeration}.tooltips.buttons.openInNew`)
      },
      on: {
        click: () => {
          this.openUser(item.OwnerId)
        }
      }
    }
  )
}
function renderItem(h, item) {
  return h(
    'div',
    {
      class: `${services.sendingsModeration}-users__item`
    },
    [
      renderListItem.call(this, h, item),
      renderButton.call(this, h, item)
    ]
  )
}
function renderItems(h) {
  return h(
    'div',
    {
      class: `${services.sendingsModeration}-users__items`
    },
    [
      this.items.map(item => {
        return renderItem.call(this, h, item)
      })
    ]
  )
}
function renderBody(h) {
  if (Array.isArray(this.users) && this.users.length) {
    return h(
      'div',
      {
        class: `${services.sendingsModeration}-users__body`
      },
      [ renderItems.call(this, h) ]
    )
  }

  return h(components.empty)
}

export default function(h) {
  return h(
    components.card,
    {
      class: `${services.sendingsModeration}-users`,
      props: {
        rounded: true,
        outline: true,
        overflow: true
      }
    },
    [
      renderHeader.call(this, h, 'top'),
      renderBody.call(this, h)
    ]
  )
}
