import { methods } from './payments'

export default {
  title: 'Автоплатежи',

  titles: {
    create: 'Создать автоплатёж',
    payments: 'История платежей',
    empty: {
      title: 'Не создано ни одного автоплатежа',
      sub: 'Создайте новый автоплатёж с помощью кнопки ниже'
    },
    remove: 'Подтверждение удаления',
    unlock: 'Подтверждение разблокировки'
  },

  methods,

  types: {
    threshold: 'По порогу',
    frequency: 'Периодически',
    description: {
      threshold: 'Платёж при достижении порога',
      frequency: 'Платёж раз в период'
    }
  },

  statuses: {
    init: 'Инициализирован',
    ready: 'Работает',
    error: 'Ошибка',
    pause: 'Приостановлен',
    block: 'Заблокирован'
  },

  labels: {
    method: 'Метод оплаты',
    amount: 'Сумма',
    type: 'Тип платежа',
    status: 'Статус платежа',
    threshold: 'Порог пополнения',
    frequency: 'Частота повторения',
    owner: 'Владелец'
  },

  contents: {
    threshold: 'При достижении порога {threshold}',
    frequency: '{frequency}',
    remove: 'Подтвердите удаление автоплатежа',
    unlock: 'Подтвердите разблокировку автоплатежа',

    confirm: {
      pause: 'Вы собираетесь приостановить автоплатёж.<br>Это отключит автоплатёж и предотвратит автоматическое списание средств по установленным порогам или расписанию.<br><strong class="mt-2">Вы уверены, что хотите продолжить?<strong>',
      resume: 'Вы собираетесь возобновить автоплатёж.<br>Это позволит системе автоматически списывать средства по установленным порогам или расписанию.<br><strong class="mt-2">Вы уверены, что хотите продолжить?<strong>'
    }
  },

  hints: {
    method: 'Выберите метод оплаты который будет использоваться для будущих платежей',
    amount: 'Введите сумму в рублях, больше нуля',
    type: 'Выберите способ повторения платежа',
    threshold: 'При достижения которого произойдёт попытка списания средств',
    frequency: 'Как часто списывать средства',
    redirect: 'После нажатия на кнопку "Создать", вы будете перенаправлены на страницу оплаты.',
    statuses: {
      init: 'Автоплатёж необходимо подтвердить',
      ready: 'Автоплатёж активен',
      pause: 'Автоплатёж поставлен на паузу',
      error: 'Автоплатёж завершён с ошибкой',
      block: 'Автоплатёж заблокирован'
    },
    notFound: 'Не найдено ни одного автоплатежа',
    owner: 'Владелец платежа',
    types: {
      threshold: 'Платёж по порогу',
      frequency: 'Платёж по времени'
    },
    description: {
      amount: 'Сумма платежа по умолчанию равняется порогу.'
    }
  },

  tooltips: {
    debit: 'Будет списано {amount}.',
    payments: 'История платежей',
    pause: 'Приостановить',
    continue: 'Возобновить',
    approve: 'Подтвердить платёж',
    repeat: 'Повторить подтверждение платежа'
  },

  buttons: {
    add: 'Добавить автоплатёж',
    repeat: 'Повторить платёж',
    resume: 'Возобновить',
    pause: 'Приостановить',
    payments: 'История платежей',
    confirm: 'Подтвердить'
  },

  links: {
    confirm: 'Подтвердить'
  },

  snackbars: {
    created: 'Автоплатёж создан',
    updated: 'Автоплатёж обновлён',
    removed: 'Автоплатёж удалён'
  },

  errors: {
    amount: {
      required: 'Сумма обязательна к заполнению',
      min: 'Сумма не может равняться или быть меньше чем ноль',
      threshold: 'Сумма не может быть меньше чем порог'
    },
    threshold: {
      required: 'Порог обязателен к заполнению',
      min: 'Порог не может равняться или быть меньше чем ноль'
    }
  }
}
