import { buttonModes, colors, components, icons, sizes } from '@/utils'

import render from './render'

export default {
  name: 'ButtonConfirmRemove',

  props: {
    callback: {
      type: Function,
      default: undefined
    },

    component: {
      type: String,
      default: 'btn',
      validator: value => {
        return [ components.button, components['list-item'], 'btn' ].includes(value)
      }
    },

    icon: {
      type: String,
      default: icons.delete,
      validator: value => {
        return Object.values(icons).includes(value)
      }
    },
    size: {
      type: String,
      default: sizes.medium,
      validator: value => {
        return Object.values(sizes).includes(value)
      }
    },
    color: {
      type: String,
      default: colors.error,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    tooltip: String,

    labeled: {
      type: Boolean,
      default: true
    },

    loading: Boolean,
    disabled: Boolean
  },

  data() {
    return {
      showDialog: false
    }
  },

  computed: {
    $props() {
      const result = {
        label: this.labeled ? this.getTranslate('misc.buttons.remove') : undefined,
        icon: this.icon,
        color: this.color,
        tooltip: this.tooltip,
        loading: this.loading,
        disabled: this.loading || this.disabled
      }

      switch (this.component) {
        case components.button: {
          result.flat = true
          result.rounded = true
          break
        }
        case components['list-item']: {
          result.icon = this.icon
          result.iconColor = this.color
          result.dense = true
          result.color = undefined
          break
        }
        case 'btn': {
          result.mode = buttonModes.flat,
          result.size = this.size
          break
        }
      }

      return result
    }
  },

  methods: {
    onClick(event) {
      if (this.component !== components['list-item']) {
        event.stopPropagation()
      }
      this.showDialog = true
    },
    async onRemove() {
      await this.callback()
      this.showDialog = false
      this.addSnackbar({
        text: this.getTranslate('commons.snackbars.removed'),
        type: colors.success
      })
    }
  },

  render
}
