import { get } from 'lodash'

import { buttonModes, components, services } from '@/utils'

import sourceBox from '@/components/source/box'
import providersLogs from '@/components/services/providers/logs'
import formatTab from '@/components/services/sendings/outgoing/details/tabs/format'
import billingsTab from '@/components/services/sendings/outgoing/details/tabs/billings'
import routingTab from '@/components/services/sendings/outgoing/details/tabs/routing'
import fallbacksTab from '@/components/services/sendings/outgoing/details/tabs/fallbacks'
import replacementTab from '@/components/services/sendings/outgoing/details/tabs/replacement'
import button from '@/components/button'

function renderDataFormatTab(h) {
  if (this.value) {
    return h(
      formatTab,
      {
        props: { value: this.value },
        key: 'format'
      }
    )
  }
}
function renderDataSourceTab(h) {
  if (this.value) {
    return h(
      sourceBox,
      {
        props: { value: this.value },
        key: 'source'
      }
    )
  }
}
function renderBillingsTab(h) {
  const value = get(this.value, 'meta.billings')
  const id = get(this.value, 'meta.billing.id')
  if (value || id) {
    return h(
      billingsTab,
      {
        props: {
          value,
          id
        },
        key: 'billings'
      }
    )
  }
}
function renderFallbacksTab(h) {
  if (Array.isArray(this.value.fallbacks) && this.value.fallbacks.length) {
    return h(
      fallbacksTab,
      {
        props: { items: this.value.fallbacks },
        key: 'fallbacks'
      }
    )
  }
}
function renderLogsTab(h) {
  const provider = get(this.value, 'meta._routing.provider')
  if (provider) {
    return h(
      providersLogs,
      {
        props: {
          ProviderId: provider,
          SendingId: this.value.id,
          start: new Date(this.value.createdAt).getTime(),
          end: new Date(this.value.updatedAt).getTime(),
          readonly: true,
          dialog: true
        },
        key: 'logs'
      }
    )
  }
}
function renderRoutingTab(h) {
  if (this.value) {
    return h(
      routingTab,
      {
        props: { value: this.value },
        key: 'routing'
      }
    )
  }
}
function renderReplacementTab(h) {
  return h(
    replacementTab,
    {
      props: { value: this.value },
      key: 'replacement'
    }
  )
}

function renderHeader(h) {
  return h(
    components['tabs-header'],
    {
      class: 'pa-2',
      props: {
        value: this.currentTab,
        tabs: this.tabs.map(tab => {
          return {
            key: tab,
            title: this.getTranslate(`${services.sendings}.tabs.${tab}`)
          }
        })
      },
      on: {
        input: event => {
          this.currentTab = event
        }
      },
      slot: 'header'
    }
  )
}
function renderBody(h) {
  return h(
    components.tabs,
    {
      props: { value: this.currentTab },
      scopedSlots: {
        body: () => [
          renderDataFormatTab.call(this, h),
          renderDataSourceTab.call(this, h),
          renderBillingsTab.call(this, h),
          renderFallbacksTab.call(this, h),
          renderRoutingTab.call(this, h),
          renderReplacementTab.call(this, h),
          renderLogsTab.call(this, h)
        ]
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.dialog,
    {
      props: {
        value: this.showDialog,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 1000 : undefined
      },
      on: {
        input: event => {
          if (event === false) {
            this.$emit('close', true)
          }
          this.showDialog = event
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
