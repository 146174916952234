import { components, services } from '@/utils'

import preloader from '@/components/preloader'
import buttonRefresh from '@/components/button/refresh'
import billingsGroup001Item from '@/components/services/billings/group001/item'

function renderButtonRefresh(h) {
  return h(
    buttonRefresh,
    {
      props: {
        disabled: this.downloadable,
        label: this.getTranslate('sendings.buttons.refresh'),
        tooltip: this.getTranslate('sendings.tooltips.refresh'),
        state: this.loading,
        shortClickMethod: () => this.getReport(),
        longClickMethod: () => this.getReport()
      }
    }
  )
}
function renderHeaderActions(h) {
  return h(
    'div',
    {
      class: `${services.billings}-header__actions`
    },
    [ renderButtonRefresh.call(this, h) ]
  )
}
function renderHeaderContent(h) {
  return h(
    'div',
    {
      class: `${services.billings}-header__content`
    },
    [
      this.$slots.title,
      renderHeaderActions.call(this, h)
    ]
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.billings}-header`
    },
    [
      renderHeaderContent.call(this, h),
      this.$slots.filter
    ]
  )
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [ h(preloader) ]
  )
}
function renderAbsolutePreloader(h) {
  if (this.loading) {
    return h(
      'div',
      {
        class: 'preloader__holder preloader__holder--absolute'
      },
      [ h(preloader) ]
    )
  }
}
function renderItem(h, item, total = false) {
  return h(
    billingsGroup001Item,
    {
      props: {
        value: item,
        total
      }
    }
  )
}
function renderItems(h) {
  return h(
    'div',
    {
      style: { position: 'relative' }
    },
    [
      this.report.map(item => {
        return renderItem.call(this, h, item)
      }),

      renderItem.call(this, h, this.total, true),

      renderAbsolutePreloader.call(this, h)
    ]
  )
}
function renderBody(h) {
  if (Array.isArray(this.report) && this.report.length > 0) {
    return renderItems.call(this, h)
  } else {
    switch (this.loading) {
      case true: return renderPreloader.call(this, h)
      case false:
      default: return h(components.empty)
    }
  }
}

function renderDivider(h) {
  if (Array.isArray(this.report) && this.report.length > 0) {
    return h(components.divider)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.billings}-holder`
    },
    [
      renderHeader.call(this, h),
      renderDivider.call(this, h),
      renderBody.call(this, h)
    ]
  )
}
