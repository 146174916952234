import { allowedSendingTypes, components, inputModes, permissionPresets, services } from '@/utils'

import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'
import selectorSendingType from '@/components/selector/sendingType'
import tag from '@/components/tag'

function renderAllowDuplicatesField(h, { path, serviceName }) {
  return h(
    components.switch,
    {
      props: {
        value: this.proxy.allowDuplicates,
        label: this.getTranslate(`${serviceName}.labels.${path}.allowDuplicates`),
        outline: true
      },
      on: {
        input: event => {
          this.proxy.allowDuplicates = event
        }
      }
    }
  )
}

function renderSenderNamesCheckField(h, { path, serviceName }) {
  return h(
    selectorSendingType,
    {
      props: {
        value: this.proxy.sendernamesCheck,
        label: this.getTranslate(`${serviceName}.labels.${path}.sendernamesCheck`),
        items: allowedSendingTypes,
        multiple: true,
        mode: inputModes['outline-label'],
        cancelable: true
      },
      on: {
        input: event => {
          this.proxy.sendernamesCheck = event
        }
      }
    }
  )
}
function renderPatternsCheckField(h, { path, serviceName }) {
  if (this.checkPermissions(`advanced.${services.patterns}.get`, permissionPresets.resellerUp)) {
    return h(
      selectorSendingType,
      {
        props: {
          value: this.proxy.patternsCheck,
          label: this.getTranslate(`${serviceName}.labels.${path}.patternsCheck`),
          items: allowedSendingTypes,
          multiple: true,
          mode: inputModes['outline-label'],
          cancelable: true
        },
        on: {
          input: event => {
            this.proxy.patternsCheck = event
          }
        }
      }
    )
  }
}

function renderBalanceField(h, { path, serviceName }) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.balance,
        label: this.getTranslate(`${serviceName}.labels.${path}.balance`),
        type: 'number',
        step: 'any',
        mode: inputModes['outline-label'],
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.balance = event
        }
      }
    }
  )
}

function renderSelectTariffsField(h, { path, serviceName }) {
  if (this.checkPermissions(`advanced.${services.tariffs}.get`, permissionPresets.resellerUp)) {
    return h(
      components.select,
      {
        props: {
          value: this.proxy.tariffs,
          label: this.getTranslate(`${serviceName}.labels.${path}.${services.tariffs}`),
          service: services.tariffs,
          mode: inputModes['outline-label'],
          picks: [ 'id', 'title', 'source' ],
          dense: true,
          rounded: true,
          multiple: true,
          autocomplete: true,
          clearable: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.tariffs = event
          }
        },
        scopedSlots: {
          selection: ({ item, removeByValue }) => {
            const [ source, type ] = item.source && item.source.split('.') || []

            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: type || source,
                  title: item.title,
                  cancelable: true,
                  cancel: () => removeByValue(item)
                }
              }
            )
          },
          item: ({ item }) => {
            const [ source, type ] = item.source && item.source.split('.') || []

            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: type || source,
                  title: item.title,
                  as: 'text'
                }
              }
            )
          }
        }
      }
    )
  }
}

function renderSelectRoutingGroupsField(h, { path, serviceName }) {
  if (this.checkPermissions(`advanced.${services.routingGroups}.get`, permissionPresets.resellerUp)) {
    return h(
      components.select,
      {
        props: {
          value: this.proxy.routingGroups,
          label: this.getTranslate(`${serviceName}.labels.${path}.${services.routingGroups}`),
          service: services.routingGroups,
          picks: [ 'id', 'title', 'type' ],
          multiple: true,
          autocomplete: true,
          mode: inputModes['outline-label'],
          dense: true,
          rounded: true,
          clearable: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.routingGroups = event
          }
        },
        scopedSlots: {
          selection: ({ item, removeByValue }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.type,
                  title: item.title,
                  cancelable: true,
                  cancel: () => removeByValue(item)
                }
              }
            )
          },
          item: ({ item }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.type,
                  title: item.title,
                  as: 'text'
                }
              }
            )
          }
        }
      }
    )
  }
}

function renderSelectMessageRuleGroupsField(h, { path, serviceName }) {
  if (this.checkPermissions(`advanced.${services.messageRuleGroups}.get`, permissionPresets.resellerUp)) {
    return h(
      components.select,
      {
        props: {
          value: this.proxy.messageRuleGroups,
          label: this.getTranslate(`${serviceName}.labels.${path}.${services.messageRuleGroups}`),
          service: services.messageRuleGroups,
          multiple: true,
          autocomplete: true,
          query: { isActive: true },
          mode: inputModes['outline-label'],
          dense: true,
          rounded: true,
          clearable: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.messageRuleGroups = event
          }
        },
        scopedSlots: {
          selection: ({ item, removeByValue }) => {
            return h(
              tag,
              {
                props: {
                  label: item.title,
                  as: 'select-dense',
                  cancelable: true,
                  cancel: () => removeByValue(item)
                }
              }
            )
          }
        }
      }
    )
  }
}

function renderSelectSenderNamesField(h, { path, serviceName }) {
  if (this.checkPermissions(`advanced.${services.sendernames}.get`, permissionPresets.resellerUp)) {
    return h(
      components.select,
      {
        props: {
          value: this.proxy.sendernames,
          label: this.getTranslate(`${serviceName}.labels.${path}.${services.sendernames}`),
          service: services.sendernames,
          picks: [ 'id', 'name', 'type' ],
          itemTitle: 'name',
          mode: inputModes['outline-label'],
          dense: true,
          multiple: true,
          autocomplete: true,
          rounded: true,
          clearable: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.sendernames = event
          }
        },
        scopedSlots: {
          selection: ({ item, removeByValue }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.type,
                  title: item.name,
                  cancelable: true,
                  cancel: () => removeByValue(item)
                }
              }
            )
          },
          item: ({ item }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.type,
                  title: item.name,
                  as: 'text'
                }
              }
            )
          }
        }
      }
    )
  }
}

function renderSelectPatternsField(h, { path, serviceName }) {
  if (this.checkPermissions(`advanced.${services.patterns}.get`, permissionPresets.resellerUp)) {
    return h(
      components.select,
      {
        props: {
          value: this.proxy.patterns,
          label: this.getTranslate(`${serviceName}.labels.${path}.${services.patterns}`),
          service: services.patterns,
          picks: [ 'id', 'title', 'type' ],
          mode: inputModes['outline-label'],
          dense: true,
          multiple: true,
          autocomplete: true,
          rounded: true,
          clearable: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.patterns = event
          }
        },
        scopedSlots: {
          selection: ({ item, removeByValue }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.type,
                  title: item.title,
                  cancelable: true,
                  cancel: () => removeByValue(item)
                }
              }
            )
          },
          item: ({ item }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.type,
                  title: item.title,
                  as: 'text'
                }
              }
            )
          }
        }
      }
    )
  }
}

export default function(h, options) {
  return h(
    components.card,
    {
      class: 'grid grid-gap--8 pt-3 pb-2 px-2',
      props: {
        title: this.getTranslate(`${options.serviceName}.subtitles.${options.path}`),
        outline: true,
        rounded: true
      }
    },
    [
      renderAllowDuplicatesField.call(this, h, options),
      renderSenderNamesCheckField.call(this, h, options),
      renderPatternsCheckField.call(this, h, options),
      renderBalanceField.call(this, h, options),
      renderSelectTariffsField.call(this, h, options),
      renderSelectRoutingGroupsField.call(this, h, options),
      renderSelectMessageRuleGroupsField.call(this, h, options),
      renderSelectSenderNamesField.call(this, h, options),
      renderSelectPatternsField.call(this, h, options)
    ]
  )
}
