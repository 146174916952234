import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { components, inputModes, moderationColors, moderationIcons, permissionPresets, services } from '@/utils'

import tag from '@/components/tag'
import selectorOwner from '@/components/selector/owner'
import selectorSendingType from '@/components/selector/sendingType'

export default function(h) {
  let cols = 2
  if (this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)) {
    cols = 3
  }

  return h(
    'div',
    {
      class: `${services.sendernames}-filter`,
      style: { 'grid-template-columns': this.viewport.breakpoint.mdUp ? `repeat(${cols}, minmax(170px, 200px))` : '1fr' }
    },
    [
      h(
        selectorSendingType,
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate(`${services.sendernames}.labels.type`),
            permission: `advanced.${services.sendernames}.get`
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          }
        }
      ),
      h(
        components.select,
        {
          props: {
            value: this.proxy.moderation,
            label: this.getTranslate(`${services.sendernames}.labels.moderation`),
            items: MODERATION_VALUES.map(value => {
              return {
                title: this.getTranslate(`misc.moderation.${value}`),
                icon: moderationIcons[value],
                iconColor: moderationColors[value],
                value
              }
            }),
            mode: inputModes.outline,
            dense: true,
            rounded: true,
            clearable: true,
            details: false
          },
          scopedSlots: {
            selection: ({ item }) => {
              return h(
                tag,
                {
                  props: {
                    label: this.getTranslate(`misc.moderation.${item.value}`),
                    color: moderationColors[item.value],
                    as: 'select-dense'
                  }
                }
              )
            }
          },
          on: {
            input: event => {
              this.proxy.moderation = event
            }
          }
        }
      ),

      h(
        selectorOwner,
        {
          props: {
            value: this.proxy.OwnerId,
            mode: inputModes.outline,
            dense: true
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      )
    ]
  )
}
