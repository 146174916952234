import proxy from '@sigma-legacy-libs/g-proxy'

import { get } from 'lodash'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    validation() {
      const sender = get(this.proxy, 'replacement.payload.sender')
      const text = get(this.proxy, 'replacement.payload.text')

      return !(sender || text)
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  render
}
