import { components, getLocaleDateString, inputModes, popperPlacements, services } from '@/utils'

import selectorOwner from '@/components/selector/owner'
import sticker from '@/components/misc/sticker'

function renderDescriptionField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.description,
        label: this.getTranslate(`${services.tokens}.labels.description`),
        hint: this.getTranslate(`${services.tokens}.hints.description`),
        mode: inputModes['line-label'],
        required: true,
        maxlength: 250,
        count: this.proxy.description ? this.proxy.description.length : 0,
        rounded: true,
        dense: true,
        grow: true,
        clearable: true,
        details: true,
        autofocus: true,
        mask: /^.{0,250}$/
      },
      on: {
        input: event => {
          this.proxy.description = event
        }
      }
    }
  )
}

function renderOwnerField(h) {
  if (!this.OwnerId) {
    return h(
      selectorOwner,
      {
        props: {
          value: this.proxy.OwnerId,
          dense: true
        },
        on: {
          input: event => {
            this.proxy.OwnerId = event
          }
        }
      }
    )
  }
}

function renderExpiredAtField(h) {
  const min = new Date()

  return h(
    components.menu,
    {
      props: {
        rounded: true,
        offsetDistance: 8,
        placement: popperPlacements['bottom-start'],
        closeOnContentClick: false
      }
    },
    [
      h(
        sticker,
        {
          props: {
            value: this.proxy.expiredAt ? getLocaleDateString(this.proxy.expiredAt) : this.getTranslate(`${services.tokens}.titles.expiredAt`),
            label: this.getTranslate(`${services.tokens}.labels.expiredAt`),
            onClick: () => {},
            width: '100%'
          },
          slot: 'activator'
        }
      ),
      h(
        components['date-picker'],
        {
          props: {
            value: this.proxy.expiredAt,
            localeTag: this.locale,
            range: false,
            min,
            max: new Date(min.getFullYear() + 1, min.getMonth(), min.getDate())
          },
          on: {
            input: event => {
              this.proxy.expiredAt = event
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.form,
    {
      class: `${services.tokens}-form`
    },
    [
      renderDescriptionField.call(this, h),
      renderOwnerField.call(this, h),
      renderExpiredAtField.call(this, h)
    ]
  )
}
