import { cloneDeep } from 'lodash'

import { SENDINGS_OUTGOING_FILTER, generateServices, isUUID, services } from '@/utils'

import render from './render'

export default {
  name: services.sendings,

  mixins: [
    generateServices({
      name: services.sendings,
      find: {
        defaultFilter: cloneDeep(SENDINGS_OUTGOING_FILTER),
        defaultPagination: { limit: 10 }
      },
      get: false,
      update: false,
      create: false,
      remove: false
    })
  ],

  data() {
    return {
      source: undefined,
      showDialog: false
    }
  },

  props: {
    providerId: {
      type: String,
      default: undefined,
      validator: isUUID,
      required: true
    }
  },

  mounted() {
    this.restData[services.sendings].find.filter.meta._routing.provider = this.providerId
  },

  render
}
