import { cloneDeep, merge } from 'lodash'
import { TASK_STATES, TASK_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { datePresets, getChosenMonth } from '@/utils'
import { tasksFilter } from '@/components/services/tasks/utils'

import tasksTemplate from '@/components/services/tasks/template'

import render from './render'

const defaultFilter = merge(
  cloneDeep(tasksFilter),
  { type: [ TASK_TYPES['payments:recurrent'], TASK_TYPES['billings:operations'] ] }
)

export default {
  name: 'Tasks',

  components: {
    'tasks-template': tasksTemplate({
      defaultFilter: cloneDeep(defaultFilter),
      presets: [
        {
          title: 'today',
          filter: merge(
            cloneDeep(defaultFilter),
            {
              state: undefined,
              createdAt: {
                $gt: undefined,
                $lt: undefined
              },
              createdAtPreset: datePresets.today
            }
          )
        },
        {
          title: 'twoWeeks',
          filter: Object.assign(
            cloneDeep(defaultFilter),
            {
              state: TASK_STATES.scheduled,
              createdAt: getChosenMonth(-1),
              createdAtPreset: undefined
            }
          )
        }
      ]
    })
  },

  render
}
