import { buttonModes, components, permissionPresets, services, states } from '@/utils'

import button from '@/components/button'
import buttonRefresh from '@/components/button/refresh'
import pagination from '@/components/pagination'
import item from '@/components/services/payments/item'
import filter from '@/components/services/payments/filter'
import paymentsLog from '@/components/services/paymentLogs/template'
import billingsDetailsItem from '@/components/services/billings/details/item'
import preloader from '@/components/preloader'

function renderRefreshButton(h) {
  return h(
    buttonRefresh,
    {
      props: {
        state: this.restData[services.payments].find.state,
        shortClickMethod: () => this.rest[services.payments].find(),
        longClickMethod: () => this.rest[services.payments].find({}, { noCache: true })
      }
    }
  )
}
function renderPagination(h) {
  return h(
    'div',
    {
      class: 'faic fjcfe grid-gap--8'
    },
    [
      h(
        pagination,
        {
          props: {
            value: this.restData[services.payments].find.pagination,
            service: services.payments
          },
          on: {
            input: event => {
              this.restData[services.payments].find.pagination = event
            }
          }
        }
      ),

      renderRefreshButton.call(this, h)
    ]
  )
}
function renderFilter(h) {
  return h(
    filter,
    {
      props: {
        value: this.restData[services.payments].find.filter
      },
      on: {
        input: event => {
          this.restData[services.payments].find.filter = event
        }
      }
    }
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      renderFilter.call(this, h),
      renderPagination.call(this, h, true)
    ]
  )
}

function renderDivider(h) {
  if (this.restData[services.payments].find.pagination.total > 0) {
    return h(components.divider)
  }
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [ h(preloader) ]
  )
}
function renderAbsolutePreloader(h) {
  if (this.restData[services.payments].find.state === states.loading) {
    return h(
      'div',
      {
        class: 'preloader__holder preloader__holder--absolute'
      },
      [ h(preloader) ]
    )
  }
}
function renderItem(h, value) {
  return h(
    item,
    {
      props: { value },
      key: value.id,
      on: {
        billings: event => {
          this.paymentsBillings = event
          this.showBillingsDialog = true
        },
        paymentLogs: event => {
          this.paymentId = event
          this.showPaymentLogsDialog = true
        }
      }
    }
  )
}
function renderItems(h) {
  return h(
    'div',
    {
      style: { position: 'relative' }
    },
    [
      this.restData[services.payments].find.data.map(item => {
        return renderItem.call(this, h, item)
      }),

      renderAbsolutePreloader.call(this, h)
    ]
  )
}
function renderBody(h) {
  if (this.restData[services.payments].find.pagination.total > 0) {
    return renderItems.call(this, h)
  } else {
    switch (this.restData[services.payments].find.state) {
      case states.loading: return renderPreloader.call(this, h)
      case states.ready:
      default: return h(components.empty)
    }
  }
}

function renderPaymentLogsDialog(h) {
  if (this.checkPermissions(`advanced.${services.paymentLogs}.get`)) {
    return h(
      components.dialog,
      {
        props: {
          title: this.getTranslate(`${services.payments}.titles.${services.paymentLogs}`),
          value: this.showPaymentLogsDialog,
          rounded: this.viewport.breakpoint.mdUp,
          maxWidth: this.viewport.breakpoint.mdUp ? 800 : '100%'
        },
        on: {
          input: event => {
            if (event === false) {
              this.paymentId = undefined
            }
            this.showPaymentLogsDialog = event
          }
        }
      },
      [
        h(paymentsLog({ PaymentId: this.paymentId })),

        h(
          'div',
          {
            class: 'fjcfe pa-2',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.close'),
                  mode: buttonModes.flat
                },
                on: {
                  click: () => {
                    this.showPaymentLogsDialog = false
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderBillingsList(h) {
  if (Array.isArray(this.paymentsBillings) && this.paymentsBillings.length) {
    return this.paymentsBillings.map(value => {
      return h(
        billingsDetailsItem,
        {
          props: {
            value
          }
        }
      )
    })
  }
}
function renderBillingsDialog(h) {
  if (this.checkPermissions(`advanced.${services.payments}.get`, permissionPresets.resellerUp)) {
    return h(
      components.dialog,
      {
        props: {
          value: this.showBillingsDialog,
          title: this.getTranslate(`${services.payments}.titles.${services.billings}`),
          rounded: this.viewport.breakpoint.mdUp,
          maxWidth: this.viewport.breakpoint.mdUp ? 1000 : undefined
        },
        on: {
          input: event => {
            if (event === false) {
              this.paymentsBillings = []
            }
            this.showBillingsDialog = event
          }
        }
      },
      [
        renderBillingsList.call(this, h),

        h(
          'div',
          {
            class: 'fjcfe pa-2',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.close'),
                  mode: buttonModes.flat
                },
                on: {
                  click: () => {
                    this.showBillingsDialog = false
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

export default function(h) {
  if (this.checkPermissions(`advanced.${services.payments}.get`, permissionPresets.meUp)) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        renderHeader.call(this, h),
        renderDivider.call(this, h),
        renderBody.call(this, h),
        renderPaymentLogsDialog.call(this, h),
        renderBillingsDialog.call(this, h)
      ]
    )
  }
}
