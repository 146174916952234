import { buttonModes, colors, components, icons, permissionPresets, popperPlacements, services } from '@/utils'

import button from '@/components/button'
import buttonServiceRemove from '@/components/button/service/remove'
import fileEdit from '@/components/services/contracts/item/buttons/file/edit'

function renderFileEdit(h) {
  if (this.checkPermissions(`advanced.${services.contracts}.update`, permissionPresets.resellerUp)) {
    return h(
      fileEdit,
      {
        props: {
          value: this.value,
          component: components['list-item']
        }
      }
    )
  }
}

function renderDownloadListItem(h) {
  return h(
    components['list-item'],
    {
      props: {
        icon: icons.file_download,
        iconColor: colors.primary,
        label: this.getTranslate(`${services.contracts}.actions.download`),
        dense: true
      },
      on: {
        click: () => {
          this.download()
        }
      }
    }
  )
}

function renderRemoveListItem(h) {
  if (this.checkPermissions('advanced.contracts.remove', permissionPresets.resellerUp)) {
    return h(
      buttonServiceRemove,
      {
        props: {
          id: this.value.id,
          service: services.contracts,
          component: components['list-item']
        }
      }
    )
  }
}

export default function(h) {
  return h(
    components.menu,
    {
      props: {
        rounded: true,
        closeOnContentClick: true,
        placement: popperPlacements['bottom-end']
      }
    },
    [
      h(
        button,
        {
          props: {
            icon: icons.more_vert,
            mode: buttonModes.flat
          },
          slot: 'activator'
        }
      ),

      h(
        components.list,
        {
          props: {
            dense: true,
            rounded: true
          }
        },
        [
          renderFileEdit.call(this, h),
          renderDownloadListItem.call(this, h),
          renderRemoveListItem.call(this, h)
        ]
      )
    ]
  )
}
