import { cloneDeep, isEqual, isPlainObject, merge } from 'lodash'

import { colors, components, icons, routerQueryReplace } from '@/utils'

import ExpandArrow from '@/components/misc/ExpandArrow'
import titleDivider from '@/components/title/divider'
import componentNotFound from '@/components/misc/componentNotFound'
import preloader from '@/components/preloader'
import squircle from '@/components/icon/squircle'

function renderPreloader(h) {
  if (this.loading.find) {
    return h(
      'div',
      {
        class: 'preloader__holder pa-5'
      },
      [ h(preloader) ]
    )
  }
}

function renderMenuItem(h, options, item) {
  const { key, icon } = item

  return h(
    components['list-item'],
    {
      props: {
        icon: icon || icons.settings,
        label: this.getTranslate(`${options.serviceName}.titles.${key}`),
        active: this.currentKey === key,
        dense: true
      },
      on: {
        click: () => {
          const { query } = this.$route
          if (query && (!query.settingKey || query.settingKey !== key)) {
            routerQueryReplace({ settingKey: key })
          }
        }
      }
    }
  )
}
function renderMenuItems(h, options) {
  return options.renderSchema.map(item => {
    return renderMenuItem.call(this, h, options, item)
  })
}
function renderMenu(h, options) {
  if (options.renderSchema && this.viewport.breakpoint.mdUp) {
    return h(
      components.list,
      {
        props: {
          dense: true,
          rounded: true,
          outline: true
        }
      },
      [ renderMenuItems.call(this, h, options) ]
    )
  }
}

function renderIcon(h, icon) {
  return h(
    squircle,
    {
      props: {
        icon: icon || icons.settings,
        color: colors.grey
      }
    }
  )
}

function renderSubtitle(h, options, subtitle) {
  if (subtitle) {
    return h(
      titleDivider,
      {
        props: { value: this.getTranslate(`${options.serviceName}.subtitles.${subtitle}`) }
      }
    )
  }
}
function renderChildByPath(h, options, path) {
  if (path) {
    try {
      const component = require(`@/components/services/${options.serviceName}/${path.split('.').join('/')}`).default({
        path,
        ...options
      })

      return h(
        component,
        {
          // Здесь нужен cloneDeep чтобы настройке не изменялись внутри компонентов
          // Так как настройки передаются в реактивном виде, они могут это делать
          // Изменение настроек происходит только при успешном ответе от сервера в методе updateSetting
          props: {
            value: cloneDeep(this.settings[path]),
            loading: this.loading,
            options
          },
          on: {
            input: event => {
              if (!isEqual(this.settings[path], event)) {
                this.updateSetting(path, event)
              }
            }
          }
        }
      )
    } catch (error) {
      return h(componentNotFound)
    }
  }
}
function renderChild(h, options, child) {
  switch (true) {
    case isPlainObject(child): {
      if (child.subtitle) {
        return renderSubtitle.call(this, h, options, child.subtitle)
      } else if (child.path) {
        return renderChildByPath.call(this, h, options, child.path)
      } else if (child.service) {
        try {
          return h(
            require(`@/components/services/${child.service.split('.').join('/')}/template`).default,
            {
              props: {
                options
              }
            }
          )
        } catch (error) {
          return h(componentNotFound)
        }
      }
      break
    }

    case Array.isArray(child): {
      return h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [ child.map(path => renderChildByPath.call(this, h, options, path)) ]
      )
    }

    case typeof child === 'string':
    default: return renderChildByPath.call(this, h, options, child)
  }
}
function renderItemByViewport(h, options, item) {
  const { key, icon, children } = item
  if (this.viewport.breakpoint.mdUp) {
    if (this.currentKey === key) {
      return h(
        'div',
        {
          class: 'grid grid-gap--12 pa-2'
        },
        [
          h(
            'div',
            {
              class: 'headline px-2'
            },
            [ this.getTranslate(`${options.serviceName}.titles.${key}`) ]
          ),
          h(
            'div',
            {
              class: 'grid'
            },
            [ children.map(child => renderChild.call(this, h, options, child)) ]
          )
        ]
      )
    }
  } else {
    return h(
      components['expansion-panel'],
      {
        scopedSlots: {
          header: ({ expanded }) => {
            return h(
              'div',
              {
                class: 'faic grid-gap--8 pl-2'
              },
              [
                renderIcon.call(this, h, icon),
                this.getTranslate(`${options.serviceName}.titles.${key}`),
                h('div', { class: 'ff' }),
                h(ExpandArrow, { props: { expanded } })
              ]
            )
          }
        }
      },
      [
        h(
          'div',
          {
            class: 'grid pa-2'
          },
          [ children.map(child => renderChild.call(this, h, options, child)) ]
        )
      ]
    )
  }
}
function renderItems(h, options) {
  return options.renderSchema.map(item => {
    return renderItemByViewport.call(this, h, options, item)
  })
}
function renderBody(h, options) {
  if (this.settings !== undefined) {
    const props = {
      flat: true,
      overflow: true,
      outline: true,
      rounded: true
    }

    if (this.viewport.breakpoint.mdUp) {
      if (options.card && options.card.props) {
        merge(props, options.card.props)
      }

      return h(
        components.card,
        { props },
        [ renderItems.call(this, h, options) ]
      )
    } else {
      if (options.panels && options.panels.props) {
        merge(props, options.panels.props)
      }

      return h(
        components['expansion-panels'],
        { props },
        [ renderItems.call(this, h, options) ]
      )
    }
  }
}

export default function(h, options) {
  if (this.loading.find) {
    return renderPreloader.call(this, h)
  } else {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faifs',
        style: { 'grid-template-columns': this.viewport.breakpoint.mdUp ? '260px 1fr' : '1fr' }
      },
      [
        renderMenu.call(this, h, options),
        renderBody.call(this, h, options)
      ]
    )
  }
}
