export default {
  title: 'HLR',

  labels: {
    search: 'Введите номер телефона',
    status: 'Статус поиска'
  },

  hints: {
    empty: 'Здесь появятся найденные данные по номеру'
  }
}
