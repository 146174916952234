import button from '@/components/button'
import sticker from '@/components/misc/sticker'

import { buttonModes, colors, components, icons, inputModes, services } from '@/utils'

const headers = [ 'mcc', 'mnc', 'type', 'operator', 'group' ]

function renderTitle(h) {
  return h(
    sticker,
    {
      props: {
        value: this.getTranslate(`${services.hlr}.title`),
        label: this.getTranslate('misc.search'),
        iconSVG: services.hlr,
        color: services.hlr
      }
    }
  )
}

function renderSearchField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.value,
        label: this.getTranslate(`${services.hlr}.labels.search`),
        mode: inputModes.outline,
        loading: this.loading,
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          if (!this.value) {
            this.result = undefined
          }

          this.value = event
        }
      }
    }
  )
}
function renderSubmitButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.search,
        mode: buttonModes.flat,
        color: colors.primary,
        loading: this.loading,
        disabled: this.loading || !this.validation,
        type: 'submit'
      }
    }
  )
}
function renderForm(h) {
  return h(
    components.form,
    {
      class: `${services.hlr}-form`,
      on: {
        submit: () => {
          this.get()
        }
      }
    },
    [
      renderSearchField.call(this, h),
      renderSubmitButton.call(this, h)
    ]
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.hlr}-header`
    },
    [
      renderTitle.call(this, h),
      renderForm.call(this, h)
    ]
  )
}

function renderResult(h) {
  if (this.result) {
    return h(
      'div',
      {
        class: `${services.dictionaries}-search__result`
      },
      [
        headers.map(header => {
          if (this.result[header]) {
            return h(
              sticker,
              {
                props: {
                  value: this.result[header],
                  label: this.getTranslate(`${services.dictionaries}.labels.${header}`),
                  copy: true
                }
              }
            )
          }
        })
      ]
    )
  }

  return h(
    sticker,
    {
      props: {
        value: this.getTranslate(`${services.hlr}.hints.empty`),
        label: this.getTranslate(`${services.hlr}.labels.status`),
        iconSVG: 'search-close',
        color: colors.grey,
        maxWidth: 'fit-content'
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: services.hlr
    },
    [
      renderHeader.call(this, h),
      renderResult.call(this, h)
    ]
  )
}
