import { EVENTSHISTORY_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors } from '@/utils'

import render from './render'

export default {
  name: 'Item',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    typeColor() {
      switch (this.value.type) {
        case EVENTSHISTORY_TYPES.created: return colors.success
        case EVENTSHISTORY_TYPES.updated: colors.primary
        case EVENTSHISTORY_TYPES.removed: colors.error
        default: return colors.grey
      }
    }
  },

  methods: {
    clickHandler() {
      this.$emit('click')
    }
  },

  render
}
