import { BILLING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { components, inputModes, permissionPresets, prioritizeAccountInList, services } from '@/utils'
import { billingsStatuses } from '@/components/services/billings/utils'

import tag from '@/components/tag'
import selectorOwner from '@/components/selector/owner'
import selectorSendingType from '@/components/selector/sendingType'
import filterDate from '@/components/filter/date'
import filterDatePreset from '@/components/filter/date/preset'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

function renderTariffIdField(h) {
  if (this.checkPermissions(`advanced.${services.tariffs}.find`, permissionPresets.resellerUp)) {
    return h(
      components.select,
      {
        props: {
          value: this.proxy.TariffId,
          label: this.getTranslate(`${services.billings}.labels.tariff`),
          picks: [ 'id', 'title', 'source' ],
          service: services.tariffs,
          mode: inputModes.outline,
          dense: true,
          rounded: true,
          clearable: true,
          autocomplete: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.TariffId = event
          }
        },
        scopedSlots: {
          selection: ({ item }) => {
            const [ source, type ] = item.source && item.source.split('.') || []

            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: type || source,
                  title: item.title
                }
              }
            )
          },
          item: ({ item }) => {
            const [ source, type ] = item.source && item.source.split('.') || []

            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: type || source,
                  title: item.title,
                  as: 'text'
                }
              }
            )
          }
        }
      }
    )
  }
}
function renderSelectorOwner(h) {
  return h(
    selectorOwner,
    {
      props: {
        value: this.proxy.OwnerId,
        clearItems: prioritizeAccountInList,
        mode: inputModes.outline,
        dense: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}
function renderFirstColumn(h) {
  if (this.checkPermissions([ `advanced.${services.users}.find`, `advanced.${services.tariffs}.find` ], permissionPresets.resellerUp)) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        renderSelectorOwner.call(this, h),
        renderTariffIdField.call(this, h)
      ]
    )
  }
}

function renderStatusField(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.status,
        label: this.getTranslate(`${services.billings}.labels.status`),
        items: BILLING_STATUS.map(value => {
          return {
            title: this.getTranslate(`${services.billings}.statuses.${value}`),
            value,
            icon: billingsStatuses[value].icon,
            iconColor: billingsStatuses[value].color
          }
        }),
        mode: inputModes.outline,
        clearable: true,
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.status = event
        }
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            tag,
            {
              props: {
                label: item.title,
                color: billingsStatuses[item.value].color,
                as: 'select-dense'
              }
            }
          )
        }
      }
    }
  )
}
function renderSelectorSendingType(h) {
  return h(
    selectorSendingType,
    {
      props: {
        value: this.proxy.source,
        label: this.getTranslate(`${services.billings}.labels.source`),
        items: this.sourceItems,
        format: value => value.replace(/sendings\.|subscription\./g, '')
      },
      on: {
        input: event => {
          this.proxy.source = event
        }
      }
    }
  )
}
function renderSecondColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderStatusField.call(this, h),
      renderSelectorSendingType.call(this, h)
    ]
  )
}

function renderThirdColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        components['text-field'],
        {
          props: {
            value: this.proxy.id,
            label: this.getTranslate(`${services.billings}.labels.id`),
            mode: inputModes.outline,
            dense: true,
            rounded: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.id = event
            }
          }
        }
      )
    ]
  )
}

function renderFourthColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        filterDatePreset,
        {
          props: {
            value: this.proxy.createdAtPreset,
            mode: inputModes.outline
          },
          on: {
            input: event => {
              if (event) {
                this.proxy.createdAt = {
                  $gt: undefined,
                  $lt: undefined
                }
              }

              this.proxy.createdAtPreset = event
            }
          }
        }
      ),

      h(
        filterDate,
        {
          props: {
            value: this.proxy.createdAt,
            min: this.minMax.min,
            max: this.minMax.max
          },
          on: {
            input: event => {
              if (event) {
                this.proxy.createdAtPreset = undefined
              }

              this.proxy.createdAt = event
            },
            pick: event => {
              if (event) {
                this.firstDate = event
              }
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8': true,
        'grid-cols--4': this.viewport.breakpoint.lgUp,
        'grid-cols--3': this.viewport.breakpoint.md,
        'grid-cols--2': this.viewport.breakpoint.sm
      }
    },
    [
      renderFirstColumn.call(this, h),
      renderSecondColumn.call(this, h),
      renderThirdColumn.call(this, h),
      renderFourthColumn.call(this, h)
    ]
  )
}
