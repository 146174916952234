import { get } from 'lodash'

import { colors, icons, services } from '@/utils'

import render from './render'

export default {
  name: 'Item',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      tooltip: undefined
    }
  },

  computed: {
    status() {
      const result = {
        icon: icons.error_outline,
        color: colors.error,
        tooltip: this.getTranslate(`${services.yclients}.tooltips.notActive`)
      }
      if (this.value.isActive) {
        result.icon = icons.check_circle_outline,
        result.color = colors.success,
        result.tooltip = this.getTranslate(`${services.yclients}.tooltips.active`)
      }

      return result
    },

    error() {
      let result
      try {
        const error = JSON.parse(this.value.error)
        if (error) {
          const message = get(error, 'meta.message')
          if (message) {
            this.tooltip = message
            result = message
          }
        }
      } catch (error) {
      } finally {
        if (this.value.error && !result) {
          this.tooltip = this.value.error
          result = this.value.error
        }
      }

      return result
    }
  },

  render
}
