import { cloneDeep, merge } from 'lodash'
import { TASK_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

import { datePresets, getChosenDay } from '@/utils'
import { tasksFilter } from '@/components/services/tasks/utils'

import TasksTemplate from '@/components/services/tasks/template'

import render from './render'

const tasksDefaultFilter = merge(
  cloneDeep(tasksFilter),
  {
    type: 'sendings:bulk',
    data: {
      sending: {
        type: undefined
      }
    }
  }
)

export default {
  name: 'SendingsTasks',

  components: {
    'tasks-template': TasksTemplate({
      defaultFilter: tasksDefaultFilter,
      presets: [
        {
          title: 'today',
          filter: merge(
            cloneDeep(tasksDefaultFilter),
            {
              state: undefined,
              createdAt: {
                $gt: undefined,
                $lt: undefined
              },
              createdAtPreset: datePresets.today
            }
          )
        },
        {
          title: 'twoWeeks',
          filter: merge(
            cloneDeep(tasksDefaultFilter),
            {
              state: TASK_STATES.scheduled,
              createdAt: getChosenDay(-14),
              createdAtPreset: undefined
            }
          )
        }
      ]
    })
  },

  data() {
    return {
      showTasksInfo: false,
      dialogData: undefined
    }
  },

  render
}
