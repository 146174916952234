import render from './render'

export default {
  name: 'Task',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  render
}
