import Vue from 'vue'

import { colors, components, notificationsColors, notificationsIcons, services } from '@/utils'

import arrow from '@/components/misc/arrow'
import squircle from '@/components/icon/squircle'
import EmailSMTP from '@/components/services/notifications/settingsSite/smtp'
import SMSSenderUserId from '@/components/services/notifications/settingsSite/sms/senderUserId'
import SMSSendername from '@/components/services/notifications/settingsSite/sms/sendername'
import NotificationTemplateDialog from '@/components/services/notifications/dialog/template'
import textOverflow from '@/components/text/overflow'

function renderDialog(h) {
  if (this.dialog.show) {
    return h(
      NotificationTemplateDialog,
      {
        props: {
          value: this.dialog.value,
          path: this.dialog.path,
          show: this.dialog.show,
          loading: this.loadings[this.dialog.path]
        },
        on: {
          input: event => {
            Vue.set(this.loadings, this.dialog.path, true)
            this.setNewSetting(this.dialog.path, event)
          },
          close: () => {
            this.dialog.show = false
          }
        }
      }
    )
  }
}

function renderEmailSMTP(h) {
  const path = `${services.notifications}.email.smtp`

  return h(
    EmailSMTP,
    {
      props: {
        value: this.settings[path],
        loading: this.loadings[path]
      },
      on: {
        input: event => {
          Vue.set(this.loadings, path, true)
          this.setNewSetting(path, event)
        }
      }
    }
  )
}

function renderSMSSenderUserId(h) {
  const path = `${services.notifications}.sms.senderUserId`

  return h(
    SMSSenderUserId,
    {
      props: {
        value: this.settings[path],
        loading: this.loadings[path]
      },
      on: {
        input: event => {
          Vue.set(this.loadings, path, true)
          this.setNewSetting(path, event)
        }
      }
    }
  )
}
function renderSMSSendername(h) {
  const path = `${services.notifications}.sms.sendername`

  return h(
    SMSSendername,
    {
      props: {
        value: this.settings[path],
        loading: this.loadings[path]
      },
      on: {
        input: event => {
          Vue.set(this.loadings, path, true)
          this.setNewSetting(path, event)
        }
      }
    }
  )
}
function renderSMS(h) {
  return h(
    components.card,
    {
      props: {
        title: this.getTranslate(`${services.notifications}.subtitles.${services.settingsSite}.sms`),
        outline: true,
        rounded: true
      }
    },
    [
      h(
        'div',
        {
          class: {
            'grid grid-gap--8 ptpx-12 px-2 pb-2': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [
          renderSMSSenderUserId.call(this, h),
          renderSMSSendername.call(this, h)
        ]
      )
    ]
  )
}

function renderTemplate(h, path) {
  return h(
    components['list-item'],
    {
      style: { 'border-top': '1px dashed grey' },
      key: path,
      on: {
        click: () => {
          this.dialog.show = true
          this.dialog.path = path
          this.dialog.value = this.settings[path]
        }
      }
    },
    [
      h(
        textOverflow,
        {
          props: {
            value: this.getTranslate(path.replace('.manager', ''))
          }
        }
      )
    ]
  )
}
function renderTemplates(h, templates) {
  if (Array.isArray(templates) && templates.length) {
    return h(
      components.list,
      {
        props: { dense: true }
      },
      [
        templates.map(path => {
          return renderTemplate.call(this, h, path)
        })
      ]
    )
  }
}

function renderChannel(h, owner, channel) {
  return h(
    components['expansion-panel'],
    {
      scopedSlots: {
        header: ({ expanded }) => {
          return h(
            'div',
            {
              class: 'default-expansion-panel-header pl-2'
            },
            [
              h(
                'div',
                {
                  class: 'row faic'
                },
                [
                  h(
                    squircle,
                    {
                      props: {
                        iconSVG: channel,
                        color: notificationsColors[channel],
                        tooltip: this.getTranslate(`${services.sendings}.types.${channel}`)
                      }
                    }
                  ),

                  this.getTranslate(`${services.sendings}.types.${channel}`)
                ]
              ),
              h(arrow, { props: { expanded } })
            ]
          )
        }
      },
      key: [ owner, channel ].join(':')
    },
    [ renderTemplates.call(this, h, this.templates[owner][channel]) ]
  )
}
function renderChannels(h, owner) {
  return Object.keys(this.templates[owner]).map(channel => {
    return renderChannel.call(this, h, owner, channel)
  })
}
function renderChannelsByOwner(h, owner) {
  if (Object.keys(this.templates[owner]).length) {
    return h(
      components['expansion-panels'],
      {
        props: { flat: true }
      },
      [ renderChannels.call(this, h, owner) ]
    )
  }
}

function renderTemplateByOwner(h, owner) {
  return h(
    components['expansion-panel'],
    {
      scopedSlots: {
        header: ({ expanded }) => {
          return h(
            'div',
            {
              class: 'default-expansion-panel-header pl-2'
            },
            [
              h(
                'div',
                {
                  class: 'row faic'
                },
                [
                  h(
                    squircle,
                    {
                      props: {
                        iconSVG: notificationsIcons[owner],
                        color: colors.grey
                      }
                    }
                  ),

                  this.getTranslate(`${services.notifications}.labels.${services.settingsSite}.templates.${owner}`)
                ]
              ),
              h(arrow, { props: { expanded } })
            ]
          )
        }
      },
      key: owner
    },
    [ renderChannelsByOwner.call(this, h, owner) ]
  )
}
function renderTemplatesByOwner(h) {
  return Object.keys(this.templates).map(owner => {
    return renderTemplateByOwner.call(this, h, owner)
  })
}
function renderItems(h) {
  return h(
    components['expansion-panels'],
    {
      props: {
        outline: true,
        rounded: true
      }
    },
    [ renderTemplatesByOwner.call(this, h) ]
  )
}

export default function(h) {
  if (this.settings) {
    return h(
      'div',
      {
        class: 'grid'
      },
      [
        renderEmailSMTP.call(this, h),
        renderSMS.call(this, h),
        renderItems.call(this, h),
        renderDialog.call(this, h)
      ]
    )
  }
}

