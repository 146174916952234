import { isEqual } from 'lodash'

import { buttonModes, colors, components, icons } from '@/utils'

import button from '@/components/button'
import info from '@/components/info'

function renderDialogBody(h) {
  return h(
    'div',
    { class: 'grid grid-gap--8 px-2' },
    [
      this.$slots.info,
      h(
        info,
        {
          props: {
            value: this.getTranslate('commons.contents.confirm.confirm'),
            color: colors.error
          }
        }
      )
    ]
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            icon: icons.close,
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.confirm'),
            icon: icons.done,
            color: colors.error
          },
          on: {
            click: () => {
              this.emitInput()
              this.showDialog = false
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h) {
  if (this.dialog) {
    return h(
      components.dialog,
      {
        props: {
          value: this.showDialog,
          title: this.title || this.getTranslate('commons.titles.confirm.title'),
          rounded: this.viewport.breakpoint.mdUp,
          maxWidth: this.viewport.breakpoint.mdUp ? 400 : undefined
        },
        on: {
          input: event => {
            this.showDialog = event
          }
        }
      },
      [
        renderDialogBody.call(this, h),
        renderDialogFooter.call(this, h)
      ]
    )
  }
}
function renderButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.done,
        mode: buttonModes.flat,
        color: colors.success,
        disabled: isEqual(this.proxy, this.value)
      },
      on: {
        click: () => {
          if (this.dialog) {
            this.showDialog = true
          } else {
            this.emitInput()
          }
        }
      },
      scopedSlots: { dialog: () => renderDialog.call(this, h) }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': '1fr auto' }
    },
    [
      this.$slots.default,
      renderButton.call(this, h)
    ]
  )
}
