import { cloneDeep, defaultsDeep, get } from 'lodash'
import { EMPTY_REQUISITES } from '@sigma-legacy-libs/essentials/lib/constants'

import { serviceTemplate, services } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.requisites,

      async inputFilter(result) {
        defaultsDeep(result, cloneDeep(EMPTY_REQUISITES)) // потому что с сервера приходит урезанный payload

        result.OwnerId = get(result, 'Owner.id')

        return result
      },

      find: {
        defaultFilter: {
          OwnerId: undefined,
          isActive: true,
          $scope: [ 'Owner' ]
        }
      },

      width: 800,
      formClass: ''
    })
  ]
}
