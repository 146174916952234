import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    isNew: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    validation() {
      if (!this.proxy.salonId || !this.proxy.OwnerId) {
        return false
      }

      return true
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  render
}
