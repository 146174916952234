import { alphabets } from '@/utils'

import render from './render'

export default {
  name: 'TextHighlight',

  props: {
    value: String
  },

  computed: {
    highlight() {
      if (this.value) {
        const className = 'text-highlight'

        return this.value
          .split('')
          .map(char => {
            switch (true) {
              case /[a-zA-Z]/.test(char): return `<span class="${className}--${alphabets.latin}">${char}</span>`
              case /[а-яА-ЯёЁ]/.test(char):
              default: return `<span class="${className}--${alphabets.cyrillic}">${char}</span>`
            }
          })
          .join('')
      }
    }
  },

  render
}
