import { components } from '@/utils'

import textOverflow from '@/components/text/overflow'

function renderTitle(h) {
  if (this.title) {
    return h(
      textOverflow,
      {
        class: `${components.card}__title`,
        props: { value: this.title }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: this.$class,
      style: this.$style,
      on: this.$eventHandlers
    },
    [
      renderTitle.call(this, h),
      this.$slots.default
    ]
  )
}
