import proxy from '@sigma-legacy-libs/g-proxy'

import { components } from '@/utils'

import render from './render'

export default {
  name: components['tabs-header'],

  mixins: [ proxy() ],

  props: {
    tabs: {
      type: Array,
      default: () => []
    },

    isLink: {
      type: Boolean,
      default: false
    },

    hotkeys: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hasArrows: false,

      scrollPosition: 0,

      prevDisabled: true,
      nextDisabled: false
    }
  },

  computed: {
    isOverflowing() {
      const { holder, items } = this.$refs
      if (items && holder) {
        return items.offsetWidth > holder.offsetWidth
      }

      return false
    }
  },

  mounted() {
    if (Array.isArray(this.tabs) && this.tabs.length) {
      this.setCurrentTab(this.tabs[0].key)
    }
    this.setShowArrows()
    window.addEventListener('resize', () => setTimeout(this.setShowArrows, 200))
    this.scrollToCurrent()

    if (this.hotkeys) {
      window.addEventListener('keydown', this.onKeyDown)
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', () => setTimeout(this.setShowArrows, 200))
    if (this.hotkeys) {
      window.removeEventListener('keydown', this.onKeyDown)
    }
  },

  methods: {
    setCurrentTab(key) {
      if (this.proxy !== key) {
        this.proxy = key
      }
    },

    slide(direction) {
      const { holder } = this.$refs
      const holderWidth = holder.offsetWidth
      const scrollAmount = holderWidth / 2

      let newScrollPosition = this.scrollPosition
      switch (direction) {
        case 'prev':
          newScrollPosition = Math.max(newScrollPosition - scrollAmount, 0)
          break
        case 'next':
          newScrollPosition = Math.min(newScrollPosition + scrollAmount, holder.scrollWidth - holderWidth)
          break
      }

      this.scrollPosition = newScrollPosition
      holder.scrollTo({ left: newScrollPosition })

      this.prevDisabled = newScrollPosition === 0
      this.nextDisabled = newScrollPosition === holder.scrollWidth - holderWidth
    },

    onWheel(event) {
      if (this.isOverflowing) {
        event.preventDefault()
        const { holder } = this.$refs
        holder.scrollLeft += event.deltaY
        this.scrollPosition = holder.scrollLeft
        this.prevDisabled = this.scrollPosition === 0
        this.nextDisabled = this.scrollPosition === holder.scrollWidth - holder.offsetWidth
      }
    },

    onItemClick(tab) {
      const { key, callback } = tab
      this.proxy = key
      if (typeof callback === 'function') {
        callback()
      }
      this.scrollToCurrentIfNotVisible()
    },

    scrollToCurrent() {
      this.$nextTick(() => {
        if (this.isOverflowing) {
          const { holder, items } = this.$refs

          const children = Array.from(items.children)
          const activeElement = children.find(child => child.classList.contains(`${components['tabs-header']}__item--active`))

          if (activeElement) {
            this.scrollPosition = activeElement.offsetLeft
            setTimeout(() => {
              holder.scrollTo({ left: activeElement.offsetLeft })

              const holderWidth = holder.offsetWidth
              const itemsWidth = items.scrollWidth
              const activeElementRight = activeElement.offsetLeft + activeElement.offsetWidth

              this.prevDisabled = activeElement.offsetLeft <= 0
              this.nextDisabled = activeElementRight >= itemsWidth - holderWidth
            }, 100)
          }
        }
      })
    },

    scrollToCurrentIfNotVisible() {
      this.$nextTick(() => {
        const { holder, items } = this.$refs

        const children = Array.from(items.children)
        const activeElement = children.find(child => child.classList.contains(`${components['tabs-header']}__item--active`))

        if (activeElement) {
          const holderLeft = holder.scrollLeft
          const holderRight = holderLeft + holder.offsetWidth
          const elementLeft = activeElement.offsetLeft
          const elementRight = elementLeft + activeElement.offsetWidth

          if (elementLeft < holderLeft) {
            this.scrollPosition = elementLeft
            holder.scrollTo({ left: elementLeft })
          } else if (elementRight > holderRight) {
            this.scrollPosition = elementRight - holder.offsetWidth
            holder.scrollTo({ left: this.scrollPosition })
          }

          this.prevDisabled = this.scrollPosition === 0
          this.nextDisabled = this.scrollPosition === holder.scrollWidth - holder.offsetWidth
        }
      })
    },

    setShowArrows() {
      const { holder, items } = this.$refs
      if (items) {
        const parentWidth = parseInt(holder.offsetWidth)
        const itemsWidth = parseInt(items.offsetWidth)

        if (itemsWidth > parentWidth) {
          this.hasArrows = true
        } else {
          this.hasArrows = false
        }
      }
    },

    onKeyDown(event) {
      if (event.altKey) {
        const key = parseInt(event.key, 10)
        if (key >= 1 && key <= 9 && key <= this.tabs.length) {
          const tab = this.tabs[key - 1]
          this.onItemClick(tab)
        } else if (key === 0 && this.tabs.length >= 10) {
          const tab = this.tabs[9]
          this.onItemClick(tab)
        }
      }
    }
  },

  render
}
