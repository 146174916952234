import services from './services'

export default {
  titles: {
    export: 'Export',
    filter: 'Filter',
    rename: 'Rename',
    data: 'Data',
    warning: 'Warning',
    copy: 'Copy',
    title: 'Title',
    loading: 'Loading...',
    country: 'Country',
    conditions: 'Conditions',
    lastVisited: 'Last visited',
    all: 'All',
    notifications: 'Notifications',

    confirm: {
      title: 'Confirm',
      remove: 'Confirm remove',
      confirm: 'Confirm'
    },

    file: {
      edit: 'Edit file'
    },

    online: {
      messages: 'Messages'
    },

    text: {
      highlight: 'Text highlight'
    }
  },

  subheader: {
    general: 'General',
    admin: 'Admin'
  },

  labels: {
    id: 'ID',
    title: 'Title',
    filter: 'Filter',
    search: 'Search',
    owner: 'Owner',
    type: 'Type',
    turnOff: 'Turn off',
    turnOn: 'Turn on',
    countries: 'Countries',
    operators: 'Operators',
    contactList: 'Contact list',
    format: 'Формат',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    expiredAt: 'Expired at',
    limit: 'Limit',
    symbols: 'Symbols'
  },

  contents: {
    confirm: {
      remove: 'Are you sure you want to remove?',
      confirm: 'You are about to perform an irreversible action.<br>It will be impossible to restore the original data after this.<br><strong class="mt-2">Are you sure you want to continue?<strong>'
    },

    text: {
      highlight: 'The result of the linguistic comparison will be displayed here. Cyrillic and Latin characters will be highlighted in colors according to the specified scheme.'
    }
  },

  filters: {
    isActive: {
      title: 'Filter not active',
      show: 'Show not active',
      hide: 'Hide not active'
    },
    lastVisited: {
      show: 'Show last visited',
      hide: 'Hide last visited'
    },
    filter: {
      show: 'Show filter',
      hide: 'Hide filter'
    }
  },

  chips: {
    more: 'And {title} more'
  },

  hints: {
    platinum: 'Platinum account',
    unsavedData: 'You have unsaved data on this tab. Click the update button to save them.',
    notFound: 'Not found',
    noDescription: 'No description',

    grafana: {
      empty: 'Data for the graph is not available.'
    }
  },

  tooltips: {
    copy: 'Copy',
    copyLinkForSlack: 'Copy link to this card formatted for Slack',
    favorites: 'Add to favorites',
    sensitivity: 'Show or hide sensitive data',

    getReport: {
      me: 'Reports for a period of more than 31 days are only available for download in CSV format.',
      reseller: 'Reports for a period of more than 93 days are only available for download in CSV format.'
    }
  },

  views: {
    table: 'View as table',
    cards: 'View as cards',
    panels: 'View as panels'
  },

  sizes: {
    small: 'Small',
    medium: 'Medium',
    large: 'Large'
  },

  snackbars: {
    removed: 'Successfully removed',
    tokenCopied: 'Token copied',
    copied: 'Copied'
  },

  errors: {
    hex: 'Color value must be in HEX format.'
  },

  expand: {
    all: 'Expand all'
  },

  collapse: {
    all: 'Collapse all'
  },

  tabs: {
    all: 'All',
    format: 'Format',
    source: 'Source',
    matches: 'Matches',
    checked: 'Checked',
    visited: 'Visited',
    favorites: 'Favorites',
    grafana: 'Grafana',

    ...services
  },

  has: {
    contract: 'Has contract'
  },

  alphabets: {
    latin: 'Latin',
    cyrillic: 'Cyrillic',
    other: 'Other'
  },

  removed: '< REMOVED >'
}
