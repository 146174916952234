import { buttonModes, colors, components, services, states } from '@/utils'

import button from '@/components/button'
import preloader from '@/components/preloader'
import ContactsForm from '@/components/services/contacts/form'
import ContactListsForm from '@/components/services/contactLists/form'

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [ h(preloader) ]
  )
}

function renderBody(h) {
  switch (this.type) {
    case `${services.contacts}.create`: {
      return h(
        ContactsForm,
        {
          class: 'pt-2 px-2',
          props: {
            value: this.restData.contacts.create.data,
            errors: this.restData.contacts.create.errors,
            ListId: this.ListId
          },
          on: {
            input: event => {
              this.restData.contacts.create.data = event
            },
            submit: () => {
              this.create(`${services.contacts}`)
            },
            validation: event => {
              this.restData.contacts.create.isValid = event
            }
          }
        }
      )
    }
    case `${services.contacts}.update`: {
      if (this.restData.contacts.get.state === states.ready) {
        return h(
          ContactsForm,
          {
            class: 'pt-2 px-2',
            props: {
              value: this.restData.contacts.get.data,
              errors: this.restData.contacts.update.errors,
              ListId: this.ListId
            },
            on: {
              input: event => {
                this.restData.contacts.get.data = event
              },
              submit: () => {
                this.update(`${services.contacts}`)
              },
              validation: event => {
                this.restData.contacts.update.isValid = event
              }
            }
          }
        )
      } else if (this.restData.contacts.get.state === states.loading) {
        return renderPreloader.call(this, h)
      }
      break
    }
    case `${services.contacts}.remove`: {
      return h(
        'div',
        {
          class: 'pa-3'
        },
        [ this.getTranslate(`${services.contacts}.contents.confirm.remove`) ]
      )
    }
    case `${services.contactLists}.create`: {
      return h(
        ContactListsForm,
        {
          class: 'pt-2 px-2',
          props: {
            value: this.restData.contactLists.create.data,
            errors: this.restData.contactLists.create.errors
          },
          on: {
            input: event => {
              this.restData.contactLists.create.data = event
            },
            submit: () => {
              this.create(`${services.contactLists}`)
            },
            validation: event => {
              this.restData.contactLists.create.isValid = event
            }
          }
        }
      )
    }
    case `${services.contactLists}.update`: {
      if (this.restData.contactLists.get.state === states.ready) {
        return h(
          ContactListsForm,
          {
            class: 'pt-2 px-2',
            props: {
              value: this.restData.contactLists.get.data,
              errors: this.restData.contactLists.update.errors
            },
            on: {
              input: event => {
                this.restData.contactLists.get.data = event
              },
              submit: () => {
                this.update(`${services.contactLists}`)
              },
              validation: event => {
                this.restData.contactLists.update.isValid = event
              }
            }
          }
        )
      } else if (this.restData.contacts.get.state === states.loading) {
        return renderPreloader.call(this, h)
      }
      break
    }
    case `${services.contactLists}.remove`: {
      return h(
        'div',
        {
          class: 'pa-3'
        },
        [ this.getTranslate(`${services.contactLists}.contents.confirm.remove`) ]
      )
    }
  }
}
function renderFooter(h) {
  if (this.actionByType) {
    const { service, method } = this.actionByType

    return h(
      'div',
      {
        class: 'fjcfe facfe grid-gap--8 pa-2',
        slot: 'footer'
      },
      [
        h(
          button,
          {
            props: {
              label: this.getTranslate('misc.buttons.cancel'),
              mode: buttonModes.flat,
              disabled: this.restData[service][method].state === states.loading
            },
            on: {
              click: () => {
                this.$emit('show', false)
              }
            }
          }
        ),

        h(
          button,
          {
            props: {
              label: this.getTranslate(`misc.buttons.${method}`),
              loading: this.restData[service][method].state === states.loading,
              disabled: this.restData[service][method].state === states.loading || method !== 'remove' && !this.restData[service][method].isValid,
              color: method === 'remove' ? colors.error : colors.primary
            },
            on: {
              click: () => {
                this[method](service)
              }
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  if (this.type) {
    const [ service, type ] = this.type.split('.')

    return h(
      components.dialog,
      {
        props: {
          title: this.getTranslate(`${service}.dialogs.titles.${type}`),
          value: this.show,
          rounded: this.viewport.breakpoint.mdUp,
          maxWidth: this.viewport.breakpoint.mdUp ? 420 : undefined
        },
        on: {
          input: event => {
            this.$emit('show', event)
          }
        }
      },
      [
        renderBody.call(this, h),
        renderFooter.call(this, h)
      ]
    )
  }
}
