import { mapGetters } from 'vuex'

export const currentTab = currentTab => {
  return {
    data() {
      return {
        currentTab
      }
    },

    computed: {
      ...mapGetters({ tab: 'currentTab/tab' })
    },

    watch: {
      $route: {
        handler() {
          this.setTabFromRoute()
        },
        deep: true
      },
      tab: {
        handler({ name }) {
          if (name) {
            this.currentTab = name
          }
        },
        deep: true
      }
    },

    mounted() {
      this.setTabFromRoute()
    },

    methods: {
      setTabFromRoute() {
        const { hash, query } = this.$route

        let result
        if (hash) {
          result = hash.replace('#', '')
        } else if (query && query.currentTab) {
          result = query.currentTab
        }

        if (result && this.currentTab !== result) {
          this.currentTab = result
        }
      }
    }
  }
}
