import { serviceTemplate, services } from '@/utils'

import { outputFilter } from '@/components/services/messageRules/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.messageRules,
      outputFilter,

      width: 500,

      has: { filter: false },

      clone: {
        replacements: {
          isActive: true
        }
      }
    })
  ]
}
