import proxy from '@sigma-legacy-libs/g-proxy'

import { components, detachable, numberToPxOrString, size } from '@/utils'

import render from './render'

export default {
  name: 'GDialog',

  mixins: [ proxy(), detachable, size ],

  props: {
    title: {
      type: String,
      default: undefined
    },

    fullscreen: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: [ String, Number ],
      default: 7
    },
    overflow: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: Boolean,
      default: false
    },

    closeOnClick: {
      type: Boolean,
      default: true
    },
    closeOnEsc: {
      type: Boolean,
      default: true
    },

    rounded: {
      type: Boolean,
      default: false
    },

    align: {
      type: String,
      default: 'center',
      validator: value => {
        return !!~[ 'top', 'bottom', 'left', 'right', 'center' ].indexOf(value)
      }
    }
  },

  data() {
    return {
      resizable: false,

      startHeight: 0,
      startY: 0,
      startTime: 0
    }
  },

  computed: {
    isMobileView() {
      return !this.fullscreen && this.viewport.breakpoint.xs
    },

    $class() {
      return {
        [`${components.dialog}`]: true,
        [`${components.dialog}--rounded`]: this.rounded && !this.isMobileView,
        [`${components.dialog}--overflow`]: this.overflow,
        [`${components.dialog}--scroll`]: this.scroll,
        [`${components.dialog}--mobile`]: this.isMobileView,
        [`${components.dialog}--resizable`]: this.resizable,
        [`${components.dialog}__align--${this.isMobileView ? 'bottom' : this.align}`]: true
      }
    },

    holderStyle() {
      if (!this.isMobileView) {
        return {
          minHeight: this.fullscreen ? '100%' : numberToPxOrString(this.minHeight),
          maxHeight: this.fullscreen ? '100%' : numberToPxOrString(this.maxHeight),
          height: this.fullscreen ? '100%' : numberToPxOrString(this.height),
          minWidth: this.fullscreen ? '100%' : numberToPxOrString(this.minWidth),
          maxWidth: this.fullscreen ? '100%' : numberToPxOrString(this.maxWidth),
          width: this.fullscreen ? '100%' : numberToPxOrString(this.width)
        }
      }
    },

    bodyStyle() {
      return {
        'min-height': this.minHeight,
        'max-height': this.isMobileView ? 'auto' : this.fullscreen ? this.maxHeight : '600px',
        height: this.fullscreen ? '100%' : this.height
      }
    }
  },

  watch: {
    value() {
      this.$nextTick(() => this.$emit('isShown', this.proxy))
    }
  },

  mounted() {
    if (document.body && document.body.addEventListener) {
      document.body.addEventListener('keyup', this.closeOnEscHandler)
    }
  },

  beforeDestroy() {
    this.hide()
    if (document.body && document.body.removeEventListener) {
      document.body.removeEventListener('keyup', this.closeOnEscHandler)
    }
  },

  methods: {
    show() {
      this.proxy = true
    },
    hide() {
      this.proxy = false
    },

    toggle() {
      this.proxy ? this.hide() : this.show()
    },

    closeOnClickHandler() {
      if (this.closeOnClick) {
        this.hide()
      }
    },
    closeOnEscHandler(event) {
      if (this.closeOnEsc && event.keyCode === 27) {
        this.hide()
      }
    },

    onWheel(event) {
      event.preventDefault()
    },

    touchStart(event) {
      if (this.$refs.holder) {
        this.startTime = event.timeStamp
        this.startY = event.touches[0].clientY
        this.startHeight = this.$refs.holder.offsetHeight
        this.resizable = true
        this.$el.addEventListener('touchmove', this.touchMove, false)
      }
    },
    touchMove(event) {
      if (this.resizable) {
        if (this.$refs.holder) {
          event.preventDefault()

          const deltaY = this.startY - event.touches[0].clientY
          const newHeight = this.startHeight + deltaY

          const timeDiff = event.timeStamp - this.startTime
          const speed = Math.abs(deltaY / timeDiff)

          if (newHeight > this.viewport.size.height * 0.9) {
            return
          }

          const thresholdSpeed = 1.5
          const minHeightForHide = 100
          if (speed > thresholdSpeed && deltaY < -minHeightForHide || newHeight < minHeightForHide) {
            this.hide()
          } else {
            this.$refs.holder.style.height = `${newHeight}px`
          }
        }
      }
    },
    touchEnd() {
      this.resizable = false
      this.startHeight = 0
      this.startY = 0
      this.startTime = 0

      this.$el.removeEventListener('touchmove', this.touchMove)
    }
  },

  render
}
