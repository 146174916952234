import { get } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

import { allowedSendingTypes, checkPermissions, getTranslate, isStringNotEmpty } from '@/utils'

import messages from '@/messages'

export default {
  computed: {
    ...mapGetters({
      platform: 'global/platform',
      browser: 'global/browser',
      locale: 'locale/locale',
      viewport: 'viewport/viewport',
      account: 'account/account',
      sendingTypes: 'account/sendingTypes',
      isPlatinum: 'account/isPlatinum',
      globalSettings: 'settings/global'
    })
  },

  methods: {
    ...mapActions({ addSnackbar: 'notifications/addSnackbar' }),

    checkPermissions(params, logic = null, accept = null, permissions = null) {
      return checkPermissions(params, logic, accept, permissions || this.account.permissions)
    },

    getTranslate(path, options = {}) {
      options.locale = this.locale

      return getTranslate.call(this, path, options)
    },

    getTranslateTemplate(path, options = {}) {
      if (isStringNotEmpty(path)) {
        let template = get(messages, [ this.locale || options.locale || 'en', path ].join('.'))
        if (template) {
          template = template.split('|')
          switch (options.count) {
            case undefined:
              console.warn('[getTranslateTemplate] Options count must be a number for translate by template')
              break
            case 0:
              return template[0]
            case 1:
              return template[1]
            default:
              return template[2].replace('{count}', options.count)
          }
        } else {
          console.warn(`[getTranslateTemplate] No translation template found for this path - ${path}`)

          return path
        }
      }
    },

    getSendingTypesByPermission(permission) {
      if (permission) {
        if (this.checkPermissions(permission)) {
          return allowedSendingTypes
        }
      }

      return this.sendingTypes || []
    }
  }
}
