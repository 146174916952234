import { components, services } from '@/utils'

import grafana from '@/components/services/providers/grafana'
import sendings from '@/components/services/providers/sendings'

function renderTabContent(h) {
  switch (this.tab) {
    case 'grafana': {
      return h(
        grafana,
        {
          props: {
            data: { id: this.$route.params.id },
            presets: [ 'queue', 'speed', 'count', 'delivery_rate' ].map(name => {
              return {
                name,
                label: this.getTranslate(`providers.chart.labels.${name === 'delivery_rate' ? 'deliveryRate' : name}`),
                preset: `providers_${name}`
              }
            }),
            hasFilter: true,
            noData: {
              icon: {
                show: true,
                library: undefined,
                value: 'bar_chart',
                size: 64
              }
            }
          }
        }
      )
    }
    case services.sendings: {
      return h(
        sendings,
        {
          props: {
            providerId: this.$route.params.id
          }
        }
      )
    }
  }
}
function renderTab(h, key) {
  return h(
    components.card,
    {
      props: {
        rounded: true,
        outline: true,
        overflow: true,
        width: '100%'
      },
      key
    },
    [ renderTabContent.call(this, h) ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        components['tabs-header'],
        {
          props: {
            value: this.tab,
            tabs: this.tabs
          },
          on: {
            input: event => {
              this.tab = event
            }
          }
        }
      ),

      h(
        components.tabs,
        {
          props: { value: this.tab },
          scopedSlots: {
            body: () => this.tabs.map(tab => {
              return renderTab.call(this, h, tab.key)
            })
          }
        }
      )
    ]
  )
}
