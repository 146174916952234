import { components } from '@/utils'

import stepper from '@/components/services/sendings/replacement/stepper'

export default function(h) {
  return h(
    components.card,
    {
      class: 'pt-3 pb-2 px-2',
      props: {
        title: this.getTranslate(`replacement.types.${this.type}`),
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [
      h(
        stepper,
        {
          props: {
            type: this.type,
            value: this.value
          }
        }
      )
    ]
  )
}
