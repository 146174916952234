import { components, routerQueryReplace, services } from '@/utils'

function renderCardHeader(h, tab) {
  if (this.items.length > 1) {
    return h(
      'div',
      {
        class: 'headline pa-3'
      },
      [ this.getTranslate(`${services.payments}.tabs.${tab}`) ]
    )
  }
}
function renderCards(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--2 faifs'
    },
    [
      this.items.map(tab => {
        return h(
          'div',
          {
            class: 'fc ff'
          },
          [
            renderCardHeader.call(this, h, tab),

            h(
              components.card,
              {
                props: {
                  rounded: true,
                  outline: true
                }
              },
              [ h(require(`@/components/services/${tab}/list`).default({ isPage: true })) ]
            )
          ]
        )
      })
    ]
  )
}

function renderTabs(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        components['tabs-header'],
        {
          props: {
            value: this.currentTab,
            tabs: this.items.map(key => {
              return {
                key,
                title: this.getTranslate(`${services.payments}.tabs.${key}`)
              }
            })
          },
          on: {
            input: event => {
              routerQueryReplace({ currentTab: event })
            }
          }
        }
      ),

      h(
        components.card,
        {
          props: {
            rounded: true,
            outline: true
          }
        },
        [
          h(
            components.tabs,
            {
              props: { value: this.currentTab }
            },
            this.items.map(tab => {
              return h(
                require(`@/components/services/${tab}/list`).default({ isPage: true }),
                {
                  key: tab,
                  scopedSlots: {
                    filter: ({ filter }) => {
                      return h(
                        require(`@/components/services/${tab}/filter`).default,
                        {
                          props: { value: filter },
                          on: {
                            input: event => {
                              filter = event
                            }
                          }
                        }
                      )
                    }
                  }
                }
              )
            })
          )
        ]
      )
    ]
  )
}

export default function(h) {
  if (this.items.length === 1 || this.viewport.size.width > 1920) {
    return renderCards.call(this, h)
  } else {
    return renderTabs.call(this, h)
  }
}
