import render from './render'

export default {
  name: 'Item',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    service: {
      type: String,
      required: true
    }
  },

  render
}
