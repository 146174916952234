import Vue from 'vue'

import { globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'UpdateStatus',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    async updateStatus() {
      try {
        this.loading = true
        await Vue.$GRequest.update(services.yclients, this.value.id, {
          ...this.value,
          isActive: true,
          error: null
        })
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
