export default {
  title: 'Dictionaries',

  states: {
    loading: 'Uploaded {count}.',
    processing: 'Processing...',
    import: 'Importing {count} rows.',
    done: 'Imported {count} rows.'
  },

  dialogs: {
    titles: {
      remove: 'Confirm remove dictionary'
    },
    create: 'Create new key'
  },

  new: {
    key: 'New key'
  },

  titles: {
    'phone:mnp': 'MNP',
    'phone:ranges': 'Prefixes',
    'phone:opgroups': 'Operator groups',
    service: {
      mnp: 'MNP',
      ranges: 'Ranges',
      operatorGroups: 'Operator groups'
    },
    upload: 'Upload',
    import: 'Import',
    mix: 'Add prefixes from INTIS server?',
    createGroup: 'Create operator group',
    group: 'Group',
    operator: 'Operator',
    number: 'Number',
    mcc: 'MCC',
    mnc: 'MNC'
  },

  labels: {
    key: 'Key',
    opcode: 'Operator code',
    group: 'Group',
    groupName: 'Group name',
    operatorName: 'Operator name',
    operator: 'Operator',
    number: 'Number',
    region: 'Region',
    prefix: 'Prefix',
    mcc: 'MCC',
    mnc: 'MNC',
    type: 'Dictionary type',
    status: 'Status',
    OwnerId: 'Owner',
    total: 'Total',

    ranges: {
      start: 'Range start',
      end: 'Range end'
    },

    choose: {
      dictionary: 'Choose dictionary',
      key: 'Enter key'
    },

    search: {
      keys: 'Search on keys'
    }
  },

  hints: {
    noItems: 'No dictionaries to show',
    loading: 'Do not close or reload window while processing import.',
    importOr: 'Or use file manager',

    search: {
      empty: 'No data found for this number'
    }
  },

  captions: {
    createdAt: 'Dictionary created at',
    total: 'Total entity'
  },

  buttons: {
    compile: 'Compile',
    set: 'Activate',
    unset: 'Deactivate',

    add: {
      range: 'Add range'
    }
  },

  contents: {
    confirm: {
      remove: {
        dictionary: 'Are you sure you want to remove dictionary {title}?',
        key: 'Are you sure you want to remove key {title}?'
      }
    }
  },

  snackbars: {
    dictionary: {
      created: 'Dictionary successfully created.',
      compile: 'Dictionary compiled successfully',
      updated: 'Dictionary successfully updated.',
      removed: 'Dictionary successfully removed.'
    },
    key: {
      created: 'Key on dictionary successfully created.',
      updated: 'Key on dictionary successfully updated.',
      removed: 'Key on dictionary successfully removed.'
    },
    updated: 'Dictionary successfully updated.'
  },

  statuses: {
    created: 'Created',
    ready: 'Ready',
    downloading: 'Downloading',
    importing: 'Importing',
    importError: 'Importing error',
    compiling: 'Compiling',
    compiled: 'Compiled',
    compileError: 'Compilation error',
    activated: 'Activated'
  },

  tooltips: {
    upload: {
      filemanager: 'Import from file',
      intis: 'Import from INTIS Server'
    },
    info: 'CSV structure information'
  },

  filters: {
    type: {
      'phone:mnp': 'MNP',
      'phone:ranges': 'Ranges',
      'phone:opgroups': 'Operator groups'
    },

    items: {
      default: 'System dictionaries'
    }
  },

  info: {
    columns: {
      Number: 'phone number',
      OwnerId: 'operator name',
      MCC: 'MCC',
      MNC: 'MNC',
      NumberFrom: 'phone number where the range starts',
      NumberTo: 'phone number that ends the range',
      RegionCode: 'region code',
      GROUP: 'group name',
      OrgCode: 'operator name',
      TYPE: 'phone number type (city ABC, mobile DEF)'
    },

    hint: 'Column names are case sensitive'
  }
}
