import { currentTab, services } from '@/utils'

import render from './render'

export default {
  name: 'ThirdCol',

  mixins: [ currentTab(services.providersPrices) ],

  computed: {
    tabs() {
      const result = [ services.providersPrices ]
      if (this.checkPermissions(`advanced.${services.eventsHistory}.get`)) {
        result.push(services.eventsHistory)
      }

      return result
    }
  },

  render
}
