import { mapActions, mapGetters } from 'vuex'

import { colors } from '@/utils'

import render from './render'

const getDiff = reset => {
  const now = Math.floor(Date.now() / 1000)

  return reset - now
}

export default {
  name: 'Template',

  data() {
    return {
      payload: {
        username: undefined,
        password: undefined
      },

      isValid: false,

      interval: undefined,
      count: 0,
      limit: 5,
      remaining: undefined
    }
  },

  computed: {
    ...mapGetters({
      errors: 'login/errors',
      status: 'login/status'
    }),

    infoStyle() {
      return {
        minWidth: this.viewport.size.width < 768 ? '360px' : '400px',
        maxWidth: this.viewport.size.width < 768 ? '360px' : '400px'
      }
    }
  },

  watch: {
    errors: {
      handler(value) {
        if (value.error) {
          if (value.error === 429) {
            const reset = Number(value.headers.get('x-ratelimit-reset'))
            if (reset) {
              const diff = getDiff(reset)
              if (diff > 0) {
                this.makeInterval(reset)
              }
            }
          }
          const limit = value.headers.get('x-ratelimit-limit')
          if (limit) {
            this.limit = Number(limit)
          }
          const remaining = value.headers.get('x-ratelimit-remaining')
          if (remaining) {
            this.remaining = Number(remaining)
          }
          if (value.message) {
            this.addSnackbar({
              type: colors.error,
              text: value.message
            })
          }
        } else {
          clearInterval(this.interval)
          this.interval = undefined
          this.count = 0
          this.limit = undefined
          this.remaining = undefined
        }
      },
      deep: true
    }
  },

  methods: {
    ...mapActions({ login: 'login/login' }),

    makeInterval(reset) {
      this.count = getDiff(reset)
      this.interval = setInterval(() => {
        if (this.count > 0) {
          this.count -= 1
        } else {
          clearInterval(this.interval)
          this.interval = undefined
          this.count = 0
          this.limit = undefined
          this.remaining = undefined
        }
      }, 1000)
    }
  },

  render
}
