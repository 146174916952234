import proxy from '@sigma-legacy-libs/g-proxy'

import { get } from 'lodash'
import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence } from '@/utils'

import render from './render'

export default {
  name: 'EntityInfo',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    paths() {
      const result = []

      if (this.proxy.Owner) {
        result.push('owner')
      }

      const ResellerId = this.proxy.ResellerId || this.proxy.Reseller && this.proxy.Reseller.id
      if (this.checkPermissions('advanced.users.get', [ 'reseller', true ]) && ResellerId && ResellerId !== this.account.id) {
        result.push('reseller')
      }

      const source = get(this.proxy, 'data.meta.source')
      if (source) {
        result.push('source')
      }

      if (this.proxy.createdAt) {
        result.push('createdAt')
      }
      if (this.proxy.updatedAt) {
        result.push('updatedAt')
      }

      return result
    }
  },

  methods: {
    async inputFilter(result) {
      if (!result.Owner) {
        await fillDependence.call(this, result, {
          service: 'users',
          permissions: [ 'reseller', true ],
          pathToId: 'OwnerId',
          pathToObject: 'Owner',
          outputPath: 'Owner',
          picks: PUBLIC_FIELDS_USER
        })
      }

      result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

      return result
    }
  },

  render
}
