import { get } from 'lodash'

import { MODERATION_VALUES, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, components, icons, inputModes, moderationColors, moderationIcons, permissionPresets, prioritizeAccountInList, services } from '@/utils'

import tag from '@/components/tag'
import info from '@/components/info'
import arrow from '@/components/misc/arrow'
import selectorOwner from '@/components/selector/owner'
import selectorSendingType from '@/components/selector/sendingType'
import requisites from '@/components/services/sendernames/form/requisites'

function renderInfo(h) {
  if (this.isNew) {
    if (this.proxy.type === SENDING_TYPES.sms || this.proxy.type === SENDING_TYPES.voice) {
      return h(
        info,
        {
          props: {
            value: this.getTranslate(`${services.sendernames}.warnings.name.${this.proxy.type}`),
            icon: icons.warning_amber,
            color: colors.warning
          }
        }
      )
    }
  }
}
function renderIsActiveField(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.update`)) {
    return h(
      components.switch,
      {
        props: {
          value: this.proxy.isActive,
          tooltip: this.getTranslate(`${services.sendernames}.labels.isActive`),
          outline: true
        },
        on: {
          input: event => {
            this.proxy.isActive = event
          }
        }
      }
    )
  }
}
function renderNameField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.name,
        label: this.getTranslate(`${services.sendernames}.labels.name`),
        error: this.proxy.name && !this.senderNameIsValid ? this.getTranslate(`${services.sendernames}.errors.${this.proxy.type}`) : undefined,
        mode: inputModes['line-label'],
        disabled: !this.isNew && !this.checkPermissions(`advanced.${services.sendernames}.update`),
        dense: true,
        rounded: true,
        required: true,
        autofocus: true,
        mask: this.senderNameMask
      },
      on: {
        input: event => {
          this.proxy.name = event
        }
      }
    }
  )
}
function renderNameAndIsActiveFields(h) {
  if (this.proxy.type) {
    return h(
      'div',
      {
        class: 'column',
        style: { 'grid-template-columns': '1fr auto' }
      },
      [
        renderNameField.call(this, h),
        renderIsActiveField.call(this, h)
      ]
    )
  }
}
function renderName(h) {
  return h(
    'div',
    {
      class: 'grid'
    },
    [
      renderInfo.call(this, h),
      renderNameAndIsActiveFields.call(this, h)
    ]
  )
}

function renderModerationField(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.update`, permissionPresets.resellerUp)) {
    return h(
      components.select,
      {
        props: {
          value: this.proxy.moderation,
          label: this.getTranslate(`${services.sendernames}.labels.moderation`),
          items: MODERATION_VALUES.map(moderation => {
            return {
              title: this.getTranslate(`misc.moderation.${moderation}`),
              value: moderation,
              icon: moderationIcons[moderation],
              iconColor: moderationColors[moderation]
            }
          }),
          error: this.errors.moderation,
          mode: inputModes['line-label'],
          dense: true,
          rounded: true,
          required: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.moderation = event
          }
        },
        scopedSlots: {
          selection: ({ item }) => {
            return h(
              tag,
              {
                props: {
                  label: this.getTranslate(`misc.moderation.${item.value}`),
                  color: moderationColors[item.value],
                  as: 'select-dense'
                }
              }
            )
          }
        }
      }
    )
  }
}

function renderTypeField(h) {
  return h(
    selectorSendingType,
    {
      props: {
        value: this.proxy.type,
        label: this.getTranslate(`${services.sendernames}.labels.type`),
        permission: `advanced.${services.sendernames}.get`,
        error: this.errors.type,
        mode: inputModes['line-label'],
        required: true
      },
      on: {
        input: event => {
          this.proxy.type = event
        }
      }
    }
  )
}

function renderCommentField(h) {
  return h(
    components.textarea,
    {
      props: {
        value: this.proxy.meta.comment,
        label: this.getTranslate(`${services.sendernames}.labels.meta.comment`),
        hint: this.getTranslate(`${services.sendernames}.hints.meta.comment`),
        readonly: false, // https://sigmasms.slack.com/archives/C03RRLZB8MP/p1736413622572719
        count: this.proxy.meta.comment === undefined ? 0 : this.proxy.meta.comment.length,
        mode: inputModes['line-label'],
        grow: true,
        dense: true,
        rounded: true,
        details: true,
        maxlength: 150,
        required: true
      },
      on: {
        input: event => {
          this.proxy.meta.comment = event
        }
      }
    }
  )
}

function renderWarning(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.create`, [ permissionPresets.me, permissionPresets.reseller ])) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${services.sendernames}.warnings.meta.comment`),
          icon: icons.warning_amber,
          color: colors.warning
        }
      }
    )
  }
}

function renderExampleComment(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.create`, [ permissionPresets.me, permissionPresets.reseller ])) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${services.sendernames}.examples.meta.comment`),
          color: colors.info
        }
      }
    )
  }
}

function renderOwnerField(h) {
  return h(
    selectorOwner,
    {
      props: {
        value: this.proxy.OwnerId,
        clearItems: prioritizeAccountInList
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderAdvancedComment(h) {
  switch (true) {
    case this.checkPermissions(`advanced.${services.sendernames}.create`, permissionPresets.reseller): {
      return h(
        'div',
        {
          class: 'grid'
        },
        [
          h(
            components.textarea,
            {
              props: {
                value: this.proxy.meta.resellerComment,
                label: this.getTranslate(`${services.sendernames}.labels.meta.resellerComment`),
                mode: inputModes['line-label'],
                rounded: true,
                grow: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.resellerComment = event
                }
              }
            }
          ),

          h(
            components.textarea,
            {
              props: {
                value: this.proxy.meta.resellerCommentPrivate,
                label: this.getTranslate(`${services.sendernames}.labels.meta.resellerCommentPrivate`),
                mode: inputModes['line-label'],
                grow: true,
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.resellerCommentPrivate = event
                }
              }
            }
          )
        ]
      )
    }
    case this.checkPermissions(`advanced.${services.sendernames}.create`): {
      return h(
        'div',
        {
          class: 'grid'
        },
        [
          h(
            components.textarea,
            {
              props: {
                value: this.proxy.meta.adminComment,
                label: this.getTranslate(`${services.sendernames}.labels.meta.adminComment`),
                mode: inputModes['line-label'],
                rounded: true,
                grow: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.adminComment = event
                }
              }
            }
          ),

          h(
            components.textarea,
            {
              props: {
                value: this.proxy.meta.adminCommentPrivate,
                label: this.getTranslate(`${services.sendernames}.labels.meta.adminCommentPrivate`),
                mode: inputModes['line-label'],
                rounded: true,
                grow: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.adminCommentPrivate = event
                }
              }
            }
          )
        ]
      )
    }
  }
}

function renderAdditional(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.create`, permissionPresets.resellerUp)) {
    return h(
      components['expansion-panels'],
      {
        props: {
          outline: true,
          rounded: true
        }
      },
      [
        h(
          components['expansion-panel'],
          {
            scopedSlots: {
              header: ({ expanded }) => {
                return h(
                  'div',
                  { class: 'default-expansion-panel-header' },
                  [
                    this.getTranslate('misc.additional'),
                    h(arrow, { props: { expanded } })
                  ]
                )
              },
              default: () => {
                return h(
                  'div',
                  { class: 'grid pa-3' },
                  [
                    renderOwnerField.call(this, h),
                    renderAdvancedComment.call(this, h)
                  ]
                )
              }
            }
          }
        )
      ]
    )
  }
}

function renderRequisites(h) {
  if (!this.isNew) {
    if (this.checkPermissions(`advanced.${services.requisites}.get`, permissionPresets.resellerUp)) {
      return h(
        requisites,
        {
          props: {
            OwnerId: this.proxy.Owner && this.proxy.Owner.id || this.proxy.OwnerId,
            foreign: this.proxy.$foreign
          }
        }
      )
    }
  }
}

function renderComment(h, comment, { value, color }) {
  return h(
    info,
    {
      props: {
        value: comment,
        icon: value,
        color
      }
    }
  )
}

function renderCommentFromAdmin(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.get`, [ 'me', false ])) {
    const comment = get(this.value, 'meta.adminComment') || get(this.value, 'meta.resellerComment')
    if (comment) {
      return renderComment.call(this, h, comment, {
        value: icons.error_outline,
        color: colors.error
      })
    }
  }
}

export default function(h) {
  return h(
    components.form,
    {
      class: {
        [`${services.sendernames}-form`]: true,
        [`${services.sendernames}-form--new`]: this.isNew,
        [`${services.sendernames}-form--${this.proxy.type}`]: true
      }
    },
    [
      renderName.call(this, h),
      renderModerationField.call(this, h),
      renderTypeField.call(this, h),
      renderCommentField.call(this, h),
      renderWarning.call(this, h),
      renderExampleComment.call(this, h),
      renderAdditional.call(this, h),
      renderRequisites.call(this, h),
      renderCommentFromAdmin.call(this, h)
    ]
  )
}
