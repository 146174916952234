import { components, inputModes, services } from '@/utils'

import selectorOwner from '@/components/selector/owner'

function renderSelectorOwner(h) {
  return h(
    selectorOwner,
    {
      props: {
        value: this.proxy.OwnerId,
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderRequisiteSelect(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.RequisiteId,
        label: this.getTranslate(`${services.contracts}.labels.requisite`),
        service: services.requisites,
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        autocomplete: true
      },
      on: {
        input: event => {
          this.proxy.RequisiteId = event
        }
      }
    }
  )
}

function renderCompanySelect(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.CompanyId,
        label: this.getTranslate(`${services.contracts}.labels.company`),
        service: services.companies,
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        autocomplete: true
      },
      on: {
        input: event => {
          this.proxy.CompanyId = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { gridTemplateColumns: this.gridTemplateColumns }
    },
    [
      renderSelectorOwner.call(this, h),
      renderRequisiteSelect.call(this, h),
      renderCompanySelect.call(this, h)
    ]
  )
}
