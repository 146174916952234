import proxy from '@sigma-legacy-libs/g-proxy'

import { chunk, cloneDeep } from 'lodash'

import { buttonModes, colors, components, isValidDate, numberToPxOrString } from '@/utils'

import render from './render'

const today = new Date()
today.setHours(0, 0, 0, 0)

export default {
  name: components['date-picker'],

  mixins: [ proxy() ],

  props: {
    localeTag: {
      type: String,
      default: 'en'
    },

    min: [ Number, String, Date ],
    max: [ Number, String, Date ],

    yearMin: {
      type: Number,
      default: 1950
    },
    yearMax: {
      type: Number,
      default: 2050
    },

    range: {
      type: Boolean,
      default: true
    },

    filter: {
      type: Function,
      default: v => v
    },

    width: {
      type: [ Number, String ],
      default: undefined
    },

    outline: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      date: {
        year: today.getFullYear(),
        month: today.getMonth(),
        day: today.getDate()
      },

      hoveringDay: undefined,

      state: 'days'
    }
  },

  computed: {
    daysOfWeek() {
      const date = new Date()
      const day = date.getDay()
      const firstDay = new Date(date.setDate(date.getDate() - day + (day === 0 ? -6 : 1)))
      const days = []

      for (let i = 0; i < 7; i++) {
        days.push(firstDay.toLocaleString(this.localeTag, { weekday: 'narrow' }))
        firstDay.setDate(firstDay.getDate() + 1)
      }

      return days
    },
    months() {
      const months = []

      for (let index = 0; index < 12; index++) {
        months.push({
          full: new Date(0, index).toLocaleString(this.localeTag, { month: 'long' }),
          short: new Date(0, index).toLocaleString(this.localeTag, { month: 'short' })
            .substring(0, 3),
          number: index
        })
      }

      return months
    },
    years() {
      const years = []
      for (let year = this.yearMax; year >= this.yearMin; year--) {
        years.push(year)
      }

      return years.reverse()
    },

    $min() {
      if (this.min) {
        return new Date(this.min).getTime()
      }
    },
    $max() {
      if (this.max) {
        return new Date(this.max).getTime()
      }
    },

    $date() {
      const date = new Date(this.date.year, this.date.month, this.date.day)
      const dayOfFirstDate = new Date(this.date.year, this.date.month, 1).getDay()
      const dayAmount = new Date(this.date.year, this.date.month + 1, 0).getDate()
      const days = []

      const diff = dayOfFirstDate - 1
      const prepend = diff < 0 ? 7 + diff : diff

      for (let index = 0; index < prepend; index++) {
        days.push(undefined)
      }

      for (let index = 1; index <= dayAmount; index++) {
        days.push(index)
      }

      return {
        date,
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate(),
        days: chunk(days, 7).map(week => {
          return week.map(day => {
            return {
              day,
              props: this.getDayProps(day)
            }
          })
        }),
        months: chunk(this.months, 3).map(months => {
          return months.map(month => {
            return {
              month,
              props: this.getMonthProps(month)
            }
          })
        }),
        years: chunk(this.years, 3).map(years => {
          return years.map(year => {
            return {
              year,
              props: this.getYearProps(year)
            }
          })
        })
      }
    },
    $dates() {
      const result = []

      for (let index = 0; index < (this.range ? 2 : 1); index++) {
        const value = this.proxy[index]
        if (value) {
          result.push(
            new Date(value).toLocaleString(this.localeTag, {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            })
          )
        } else {
          result.push('---')
        }
      }

      return result
    },

    chosenDate() {
      const date = cloneDeep(new Date(this.proxy[0]))
      if (isValidDate(date)) {
        return {
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDate()
        }
      }
    }
  },

  watch: {
    state() {
      this.scrollYearsList()
    },

    'date.year'() {
      this.scrollYearsList()
    },

    date: {
      handler() {
        if (this.date.month < 0) {
          this.date.year--
          this.date.month = 11
          this.date.day = 1
        } else if (this.date.month > 11) {
          this.date.year++
          this.date.month = 0
          this.date.day = 1
        }
      },
      deep: true
    }
  },

  mounted() {
    if (this.chosenDate) {
      this.date.year = this.chosenDate.year
      this.date.month = this.chosenDate.month
      this.date.day = this.chosenDate.day
    }

    if (this.width) {
      this.controlPickerWidth()
    }
  },

  beforeDestroy() {
    this.state = 'days'
  },

  methods: {
    _inputFilter(data) {
      if (!Array.isArray(data)) {
        data = [ data ]
      }

      let result = data.reduce((result, value) => {
        if (value) {
          const date = new Date(value)
          if (isValidDate(date)) {
            date.setHours(0, 0, 0, 0)
            result.push(date.getTime())
          }
        }

        return result
      }, [])

      if (result.length > 1 && result[0] === result[1]) {
        result = [ result[0] ]
      }

      return result
    },
    _outputFilter(data) {
      data = this.filter(data)
      if (this.range) {
        return data
      } else {
        return data[0]
      }
    },

    watchProxyHandler() {
      if (JSON.stringify(this.value) !== JSON.stringify(this.proxy)) {
        this.transmitValue()
      }
    },

    changeState(date) {
      if (!date) {
        if (this.proxy[0]) {
          date = new Date(this.proxy[0])
        } else {
          date = new Date()
        }
      }

      this.date.month = date.getMonth()
      this.date.year = date.getFullYear()
      this.state = 'days'
    },

    scrollYearsList() {
      if (this.state === 'years') {
        this.$nextTick(() => {
          const { years } = this.$refs
          const active = years.querySelector(`.${components['date-picker']}__years-year--active`)
          if (active) {
            years.scrollTo({
              top: active.offsetTop - years.offsetTop - years.clientHeight / 2 + active.clientHeight / 2
            })
          }
        })
      }
    },

    pickDateHandler(day) {
      if (day && !isNaN(day)) {
        const pickedDate = this.getUnixTimeByDay(day)

        this.$emit('pick', pickedDate)

        this.date.day = day

        if (this.range && this.proxy.length < 2 && pickedDate !== this.proxy[0]) {
          const dates = cloneDeep(this.proxy)
          dates.push(pickedDate)
          dates.sort()
          this.proxy = dates
        } else if (!this.range || this.proxy.length === 2) {
          this.proxy = [ pickedDate ]
        }
      }
    },

    convertDate(input) {
      const output = new Date(input)
      output.setHours(0, 0, 0, 0)

      return output.getTime()
    },

    getUnixTimeByDay(day) {
      return new Date(this.date.year, this.date.month, day).getTime()
    },

    isActiveDay(unixTime) {
      const isActiveDate = this.proxy.some(value => {
        if (value) {
          const timeToCompare = this.convertDate(value)
          if (unixTime === timeToCompare) {
            return true
          }
        }

        return false
      })

      let isInRange = false
      if (this.proxy.length > 1) {
        isInRange = unixTime < this.proxy[1] && unixTime > this.proxy[0]
      }

      let isLeftActiveEdge = false
      let isRightActiveEdge = false

      if (this.proxy.length === 2) {
        const leftEdge = this.convertDate(this.proxy[0])
        const rightEdge = this.convertDate(this.proxy[1])
        if (leftEdge === unixTime) {
          isLeftActiveEdge = true
        }
        if (rightEdge === unixTime) {
          isRightActiveEdge = true
        }
      }

      return {
        isActiveDate,
        isInRange,
        isLeftActiveEdge,
        isRightActiveEdge
      }
    },

    isActiveMonth(year, month) {
      if (!this.proxy.length) {
        return false
      }

      const monthForCheck = new Date(year, month)

      if (this.proxy.length < 2) {
        return (
          new Date(this.proxy[0]).getMonth() === monthForCheck.getMonth() &&
          new Date(this.proxy[0]).getFullYear() === monthForCheck.getFullYear()
        )
      }

      const leftBorder = new Date(this.proxy[0])
      leftBorder.setDate(1)
      leftBorder.setHours(0, 0, 0, 0)

      const rightBorder = new Date(this.proxy[1])
      rightBorder.setDate(1)
      rightBorder.setHours(0, 0, 0, 0)

      return leftBorder.getTime() <= monthForCheck.getTime() && rightBorder.getTime() >= monthForCheck.getTime()
    },

    isActiveYear(year) {
      if (!this.proxy.length) {
        return false
      }

      if (this.proxy.length < 2) {
        return new Date(this.proxy[0]).getFullYear() === year
      }

      const topBorder = new Date(this.proxy[0])
      const bottomBorder = new Date(this.proxy[1])

      return topBorder.getFullYear() <= year && bottomBorder.getFullYear() >= year
    },

    isDisabledDay(unixTime) {
      if (!isNaN(this.$min) || !isNaN(this.$max)) {
        return unixTime < this.$min || unixTime > this.$max
      }
    },

    controlPickerWidth() {
      const picker = this.$refs['date-picker']

      picker.style.width = numberToPxOrString(this.width)
      picker.style.maxWidth = numberToPxOrString(this.width)

      Array.from(picker.children).map(item => {
        item.style.width = numberToPxOrString(this.width)
        item.style.maxWidth = numberToPxOrString(this.width)
      })
    },

    arrowHandler(direction) {
      if (direction < 0) {
        switch (this.state) {
          case 'years': {
            this.date.year--
            break
          }
          case 'months':
          case 'days':
          default: {
            this.date.month--
            this.date.day = 1
            break
          }
        }
      } else if (direction > 0) {
        switch (this.state) {
          case 'years': {
            this.date.year++
            break
          }
          case 'months':
          case 'days':
          default: {
            this.date.month++
            this.date.day = 1
            break
          }
        }
      }
    },
    clickHandler(event, value, type = 'day') {
      event.preventDefault()
      event.stopPropagation()
      switch (type) {
        case 'day': {
          this.pickDateHandler(value)
          break
        }
        case 'month': {
          this.date.month = value.number
          this.state = 'days'
          break
        }
        case 'year': {
          this.date.year = value
          this.state = 'months'
          break
        }
      }
    },

    checkDayIsInHoveringRange(unixTime) {
      if (this.proxy.length === 1) {
        const hoveringDayUnixTime = this.getUnixTimeByDay(this.hoveringDay)
        if (hoveringDayUnixTime < this.proxy[0]) {
          return unixTime > hoveringDayUnixTime && unixTime < this.proxy[0]
        } else {
          return unixTime < hoveringDayUnixTime && unixTime > this.proxy[0]
        }
      }
    },
    getDayProps(day) {
      const currentDay = today.getTime()
      const uTime = this.getUnixTimeByDay(day)

      const { isActiveDate, isInRange, isLeftActiveEdge, isRightActiveEdge } = this.isActiveDay(uTime)
      const isActive = isActiveDate || isInRange || isLeftActiveEdge || isRightActiveEdge || false

      const result = {
        label: day,
        mode: buttonModes.clear,
        color: isActive ? colors.primary : undefined,
        disabled: this.isDisabledDay(uTime)
      }

      if (isActive) {
        result.mode = buttonModes.filled
      } else {
        if (uTime === currentDay) {
          result.mode = buttonModes.flat
          result.color = colors.primary
        }
      }

      if (this.checkDayIsInHoveringRange(uTime)) {
        result.mode = buttonModes.flat
      }

      return result
    },
    getMonthProps(month) {
      const currentYear = today.getFullYear()
      const currentMonth = today.getMonth()
      const isActive = this.isActiveMonth(this.date.year, month.number)
      const isCurrentMonth = currentYear === this.date.year && currentMonth === month.number

      const result = {
        label: month.short,
        mode: buttonModes.clear,
        color: isActive ? colors.primary : undefined
      }

      if (isActive) {
        result.mode = buttonModes.filled
      } else {
        if (isCurrentMonth) {
          result.mode = buttonModes.flat
          result.color = colors.primary
        }
      }

      return result
    },
    getYearProps(year) {
      const isActive = this.isActiveYear(year)
      const isCurrentYear = today.getFullYear() === year

      const result = {
        label: year,
        mode: buttonModes.clear,
        color: isActive ? colors.primary : undefined
      }

      if (isActive) {
        result.mode = buttonModes.filled
      } else {
        if (isCurrentYear) {
          result.mode = buttonModes.flat
          result.color = colors.primary
        }
      }

      return result
    }
  },

  render
}
