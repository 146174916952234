import { generateServices, services } from '@/utils'

import render from './render'

export default function({ PaymentId }) {
  return {
    name: services.paymentLogs,

    mixins: [
      generateServices({
        name: services.paymentLogs,

        find: {
          defaultFilter: {
            PaymentId,
            $scope: [ 'full' ]
          }
        },

        get: false,
        create: false,
        update: false,
        remove: false
      })
    ],

    data() {
      return {
        showDialog: false,

        request: undefined,
        response: undefined,

        currentTab: undefined
      }
    },

    mounted() {
      if (this.checkPermissions(`advanced.${services.paymentLogs}.get`)) {
        this.rest[services.paymentLogs].find()
      }
    },

    render
  }
}
