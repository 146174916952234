import services from './services'

export default {
  titles: {
    export: 'Экспорт',
    filter: 'Фильтр',
    rename: 'Переименовать',
    data: 'Исходные данные',
    warning: 'Предупреждение',
    copy: 'Скопировать',
    title: 'Заголовок',
    loading: 'Загрузка...',
    country: 'Страна',
    conditions: 'Условия',
    lastVisited: 'Последние посещённые',
    all: 'Все',
    notifications: 'Уведомления',

    confirm: {
      title: 'Подтверждение',
      remove: 'Подтверждение удаления',
      confirm: 'Подтвердить'
    },

    file: {
      edit: 'Редактировать файл'
    },

    online: {
      messages: 'Сообщения'
    },

    text: {
      highlight: 'Подсветка текста'
    }
  },

  subheader: {
    general: 'Основной раздел',
    admin: 'Административный раздел'
  },

  labels: {
    id: 'ID',
    title: 'Название',
    filter: 'Фильтр',
    search: 'Поиск',
    owner: 'Владелец',
    type: 'Тип',
    turnOff: 'Выключить',
    turnOn: 'Включить',
    countries: 'Страны',
    operators: 'Операторы',
    contactList: 'Адресная книга',
    format: 'Формат',
    createdAt: 'Создано в',
    updatedAt: 'Обновлено в',
    expiredAt: 'Истекает в',
    limit: 'Лимит',
    symbols: 'Символы'
  },

  contents: {
    confirm: {
      remove: 'Вы уверены, что хотите удалить?',
      confirm: 'Вы собираетесь выполнить необратимое действие.<br>Восстановить исходные данные после этого будет невозможно.<br><strong class="mt-2">Вы уверены, что хотите продолжить?<strong>'
    },

    text: {
      highlight: 'Здесь будет отображен результат лингвистического сравнения. Символы кириллицы и латиницы будут выделены цветами в соответствии с указанной схемой.'
    }
  },

  filters: {
    isActive: {
      title: 'Фильтровать неактивные',
      show: 'Показать неактивные записи',
      hide: 'Скрыть неактивные записи'
    },
    lastVisited: {
      show: 'Показать последние посещённые',
      hide: 'Скрыть последние посещённые'
    },
    filter: {
      show: 'Показать фильтр',
      hide: 'Скрыть фильтр'
    }
  },

  chips: {
    more: 'И ещё {title}'
  },

  hints: {
    platinum: 'Платиновый аккаунт',
    unsavedData: 'У вас есть несохраненные данные на этой вкладке. Нажмите кнопку обновить чтобы сохранить их.',
    notFound: 'Ничего не найдено',
    noDescription: 'Нет описания',

    grafana: {
      empty: 'Данные для графика не доступны.'
    }
  },

  tooltips: {
    copy: 'Скопировать',
    copyLinkForSlack: 'Скопировать ссылку на эту карточку отформатированную для Slack',
    favorites: 'Добавить в избранное',
    sensitivity: 'Показать или скрыть чувствительные данные',

    getReport: {
      me: 'Отчеты за период больше 31 дня доступны только для скачивания в CSV формате.',
      reseller: 'Отчеты за период больше 93 дней доступны только для скачивания в CSV формате.'
    }
  },

  views: {
    table: 'Вид как таблица',
    cards: 'Вид как карточки',
    panels: 'Вид как панели'
  },

  sizes: {
    small: 'Маленький',
    medium: 'Средний',
    large: 'Большой'
  },

  snackbars: {
    removed: 'Успешно удалено',
    tokenCopied: 'Токен скопирован',
    copied: 'Скопировано'
  },

  errors: {
    hex: 'Значение цвета должно быть в HEX формате.'
  },

  expand: {
    all: 'Раскрыть все'
  },

  collapse: {
    all: 'Свернуть все'
  },

  tabs: {
    all: 'Все',
    format: 'Формат',
    source: 'Исходные данные',
    matches: 'Совпадения',
    checked: 'Выбранные',
    visited: 'Посещённые',
    favorites: 'Избранные',
    grafana: 'Grafana',

    ...services
  },

  has: {
    contract: 'Есть договор'
  },

  alphabets: {
    latin: 'Латиница',
    cyrillic: 'Кириллица',
    other: 'Остальные'
  },

  removed: '< УДАЛЕНО >'
}
