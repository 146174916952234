import { TASK_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, getISODate, icons, inputModes, permissionPresets, popperPlacements, services } from '@/utils'

import button from '@/components/button'

export default function(h) {
  if (this.checkPermissions(`advanced.${services.tasks}.update`, permissionPresets.meUp)) {
    switch (this.value.state) {
      case TASK_STATES.running:
      case TASK_STATES.scheduled: {
        return h(
          button,
          {
            props: {
              mode: buttonModes.flat,
              icon: icons.pause,
              color: colors.secondary,
              loading: this.loading,
              disabled: this.loading
            },
            on: {
              click: () => {
                this.updateTask(this.value.id, TASK_STATES.paused)
              }
            }
          }
        )
      }

      default: {
        return h(
          components.menu,
          {
            props: {
              rounded: true,
              offsetDistance: 8,
              placement: popperPlacements['bottom-end'],
              closeOnContentClick: false,
              disabled: this.value.state !== TASK_STATES.paused
            }
          },
          [
            h(
              button,
              {
                props: {
                  mode: buttonModes.flat,
                  icon: icons.play_arrow,
                  color: colors.success,
                  loading: this.loading,
                  disabled: this.value.state !== TASK_STATES.paused
                },
                slot: 'activator'
              }
            ),

            h(
              'div',
              {
                class: 'fc',
                props: { flat: true }
              },
              [
                h(
                  components['date-picker'],
                  {
                    props: {
                      value: this.changeableDate.date,
                      localeTag: this.locale,
                      range: false,
                      min: new Date().setDate(new Date().getDate() - 1),
                      filter: dates => dates.map(date => date && getISODate(date))
                    },
                    on: {
                      input: event => {
                        this.changeableDate.date = event
                      }
                    }
                  }
                ),

                h(
                  'div',
                  {
                    class: 'px-2'
                  },
                  [
                    h(
                      components['text-field'],
                      {
                        props: {
                          value: this.changeableDate.time,
                          type: 'time',
                          mode: inputModes.outline,
                          dense: true,
                          rounded: true,
                          details: false
                        },
                        on: {
                          input: event => {
                            this.changeableDate.time = event
                          }
                        }
                      }
                    )
                  ]
                ),

                h(
                  'div',
                  {
                    class: 'fjcfe pa-2'
                  },
                  [
                    h(
                      button,
                      {
                        props: {
                          label: this.computedDate ? this.getTranslate(`${services.tasks}.actions.schedule`) : this.getTranslate(`${services.tasks}.actions.start`),
                          loading: this.loading,
                          disabled: this.loading,
                          color: colors.secondary
                        },
                        on: {
                          click: () => {
                            this.updateTask(this.value.id, TASK_STATES.scheduled, this.computedDate)
                          }
                        }
                      }
                    )
                  ]
                )
              ]
            )
          ]
        )
      }
    }
  }
}
