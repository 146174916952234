import { buttonModes, colors, components, icons, permissionPresets, services, states } from '@/utils'

import button from '@/components/button'
import buttonRefresh from '@/components/button/refresh'
import buttonBucketRelease from '@/components/button/bucket/release'
import preloader from '@/components/preloader'
import pagination from '@/components/pagination'
import billingsDetailsItem from '@/components/services/billings/details/item'
import billingsDetailsDialog from '@/components/services/billings/details/dialog'

function renderBucketReleaseButton(h) {
  return h(
    buttonBucketRelease,
    {
      props: {
        length: this.restData[services.billings].find.bucketLength,
        disabled: this.restData[services.billings].find.state === states.loading,
        callback: () => this.rest[services.billings].bucketRelease()
      }
    }
  )
}
function renderCreateButton(h) {
  if (this.checkPermissions(`advanced.${services.billings}.generate`, permissionPresets.resellerUp)) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate('misc.buttons.create'),
          icon: icons.add,
          mode: buttonModes.flat,
          color: colors.secondary
        },
        on: {
          click: () => {
            this.$emit('create')
          }
        }
      }
    )
  }
}
function renderButtonRefresh(h) {
  return h(
    buttonRefresh,
    {
      props: {
        disabled: this.downloadable,
        label: this.getTranslate(`${services.billings}.buttons.refresh`),
        tooltip: this.getTranslate(`${services.billings}.tooltips.refresh`),
        state: this.restData[services.billings].find.state,
        shortClickMethod: () => this.rest[services.billings].find(),
        longClickMethod: () => this.rest[services.billings].find({}, { noCache: true })
      }
    }
  )
}
function renderHeaderActions(h) {
  return h(
    'div',
    {
      class: `${services.billings}-header__actions`
    },
    [
      renderBucketReleaseButton.call(this, h),
      renderCreateButton.call(this, h),
      renderButtonRefresh.call(this, h)
    ]
  )
}
function renderHeaderContent(h) {
  return h(
    'div',
    {
      class: `${services.billings}-header__content`
    },
    [
      this.$slots.title,
      renderHeaderActions.call(this, h)
    ]
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.billings}-header`
    },
    [
      renderHeaderContent.call(this, h),
      this.$slots.filter
    ]
  )
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [ h(preloader) ]
  )
}
function renderAbsolutePreloader(h) {
  if (this.restData[services.billings].find.state === states.loading) {
    return h(
      'div',
      {
        class: 'preloader__holder preloader__holder--absolute'
      },
      [ h(preloader) ]
    )
  }
}
function renderItem(h, item) {
  return h(
    billingsDetailsItem,
    {
      props: {
        value: item
      },
      on: {
        more: () => {
          this.source = item
          this.showDialog = true
        }
      }
    }
  )
}
function renderItems(h) {
  return h(
    'div',
    {
      style: { position: 'relative' }
    },
    [
      this.restData[services.billings].find.data.map(item => {
        return renderItem.call(this, h, item)
      }),

      renderAbsolutePreloader.call(this, h)
    ]
  )
}
function renderBody(h) {
  if (this.restData[services.billings].find.data?.length > 0) {
    return renderItems.call(this, h)
  } else {
    switch (this.restData[services.billings].find.state) {
      case states.loading: return renderPreloader.call(this, h)
      case states.ready:
      default: return h(components.empty)
    }
  }
}

function renderDivider(h) {
  if (this.restData[services.billings].find.pagination.total > 0) {
    return h(components.divider)
  }
}

function renderPagination(h) {
  if (this.restData[services.billings].find.pagination.total > 0) {
    return h(
      'div',
      {
        class: `${services.billings}-pagination`
      },
      [
        h(
          pagination,
          {
            props: {
              value: this.restData[services.billings].find.pagination,
              service: services.billings
            },
            on: {
              input: event => {
                this.restData[services.billings].find.pagination = event
              }
            }
          }
        )
      ]
    )
  }

  return h('div')
}

function renderDialog(h) {
  return h(
    billingsDetailsDialog,
    {
      props: {
        data: this.source,
        value: this.showDialog
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.billings}-holder`
    },
    [
      renderHeader.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderDivider.call(this, h),
      renderBody.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}
