import { buttonModes, colors, services, states } from '@/utils'

import button from '@/components/button'
import generatorRow from '@/components/generator/row'
import routesFormLink from '@/components/services/routes/form/link'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'
import info from '@/components/info'
import preloader from '@/components/preloader'

const serviceNameViaPoint = services['otp-settings/channels'].split('/').join('.')

function renderWarning(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${serviceNameViaPoint}.warnings.templates`),
        color: colors.warning
      }
    }
  )
}

function renderLinkToTemplate(h, item) {
  if (this.checkPermissions(`advanced.${services['otp-settings/templates'].split('/').join('.')}.create`)) {
    return h(
      routesFormLink,
      {
        props: {
          value: { id: item.otpTemplateSettingsId },
          service: services['otp-settings/templates'].split('/').join('.')
        }
      }
    )
  }
}
function renderTemplateField(h, item) {
  return h(
    'g-select',
    {
      props: {
        value: item.otpTemplateSettingsId,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.templates`),
        service: services['otp-settings/templates'],
        picks: [ 'id', 'name', 'channelType' ],
        itemTitle: 'name',
        itemsDisabled: this.proxy.otpChannelTemplateSettings.map(({ otpTemplateSettingsId }) => otpTemplateSettingsId),
        query: { channelType: this.proxy.channelType },
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        disabled: this.disabled
      },
      on: {
        input: event => {
          item.otpTemplateSettingsId = event
        }
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.channelType,
                title: item.name
              }
            }
          )
        },
        item: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.channelType,
                title: item.name,
                as: 'text'
              }
            }
          )
        }
      }
    }
  )
}
function renderGeneratorRow(h) {
  return h(
    generatorRow,
    {
      props: {
        value: this.proxy.otpChannelTemplateSettings,
        disabled: this.disabled,
        defaultValue: {
          otpTemplateSettingsId: undefined,
          priority: 0
        },
        sortable: true,
        sortableProperty: 'priority'
      },
      scopedSlots: {
        row: ({ item }) => {
          return h(
            'div',
            {
              class: 'grid grid-gap--8',
              style: { 'grid-template-columns': this.checkPermissions(`advanced.${serviceNameViaPoint}.create`) ? '1fr 38px' : '1fr' }
            },
            [
              renderTemplateField.call(this, h, item),
              renderLinkToTemplate.call(this, h, item)
            ]
          )
        }
      },
      on: {
        input: event => {
          this.proxy.otpChannelTemplateSettings = event
        }
      }
    }
  )
}
function renderTemplatesFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        button,
        {
          props: {
            icon: 'save',
            label: this.getTranslate('misc.buttons.save'),
            color: colors.primary,
            mode: buttonModes.flat,
            loading: this.loading,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.attachTemplates()
            }
          }
        }
      )
    ]
  )
}
function renderContent(h) {
  if (this.restData[services['otp-settings/templates']].find.state === states.loading) {
    return h(preloader)
  }

  if (this.restData[services['otp-settings/templates']].find.state === states.ready) {
    if (this.restData[services['otp-settings/templates']].find.pagination.total === 0) {
      return renderWarning.call(this, h)
    }
  }

  return [
    renderGeneratorRow.call(this, h),
    renderTemplatesFooter.call(this, h)
  ]
}
function renderTemplatesField(h) {
  if (!this.isNew) {
    if (!this.excludeTypes.includes(this.proxy.channelType)) {
      return h(
        'g-card',
        {
          class: 'grid grid-gap--8 pt-3 px-2 pb-2',
          props: {
            title: this.getTranslate(`${serviceNameViaPoint}.titles.templates`),
            rounded: true,
            outline: true,
            dashed: true
          }
        },
        [ renderContent.call(this, h) ]
      )
    }
  }
}

function renderHeader(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 square--38'
      },
      []
    )
  }
}
function renderForm(h) {
  return h(
    'g-card',
    {
      class: 'pa-3',
      props: {
        outline: true,
        rounded: true
      }
    },
    [
      h(
        'g-form',
        {
          class: 'grid',
          style: { 'grid-template-columns': '100%' }
        },
        [ renderTemplatesField.call(this, h) ]
      )
    ]
  )
}

export default function(h) {
  if (!this.excludeTypes.includes(this.proxy.channelType)) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        renderHeader.call(this, h),
        renderForm.call(this, h)
      ]
    )
  }
}
