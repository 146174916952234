import routesFilter from '@/components/services/routes/filter'

export default function(h) {
  return h(
    routesFilter,
    {
      props: {
        value: this.proxy
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
