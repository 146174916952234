export const asciiChars = '[a-zA-Z0-9!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]'
export const usernameChars = '[A-z_0-9+-.@]'

export const integerRegex = /^\d*$/
export const negativeNumberRegex = /^-?\d*([.,]\d*)?$/
export const positiveNumberRegex = /^\d*([.,]\d*)?$/
export const positiveNumberTwoSymbolsAfterDotRegex = /^\d*([.,]\d{0,2})?$/
export const percentageRegex = /^\d{1,2}$|^100$/
export const portsRegex = /^(0|[1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/
export const asciiCharsRegex = new RegExp(`^${asciiChars}$`)
export const usernameRegex = new RegExp(`^${usernameChars}{5,150}$`)
export const usernameRegexSoft = new RegExp(`^${usernameChars}{1,150}$`)
export const safeNameRegex = /^[A-Za-zА-Яа-яЁ-ё -]+$/
export const passwordRegex = new RegExp(`^${asciiChars}{6,18}$`)
export const passwordRegexSoft = new RegExp(`^${asciiChars}{1,18}$`)
export const phoneRegexSoft = /^[\d\s()+-]+$/
