export default {
  title: 'Задачи',

  schedule: {
    now: 'Запустить сейчас',
    delay: 'Запланировать на ',
    delayed: 'Запланировано на '
  },

  labels: {
    id: 'ID',
    title: 'Заголовок',
    cycle: {
      batch: 'Количество сообщений',
      every: 'Каждые (секунды)'
    },
    owner: 'Владелец',
    creator: 'Создатель',
    type: 'Тип',
    state: 'Состояние',
    data: {
      source: 'Источник',
      amount: 'Сумма',
      frequency: 'Периодичность',
      endsAt: 'Когда закончить'
    },
    startsAt: 'Когда начать',
    analytics: 'Аналитика',
    contactLists: 'Списки контактов'
  },

  headers: {
    title: 'Заголовок',
    task: 'Задача',
    progress: 'Прогресс',
    type: 'Тип',
    state: 'Состояние',
    sent: 'Отправлено',
    statistic: 'Статистика',
    errored: 'С ошибкой',
    text: 'Текст',
    owner: 'Владелец',
    actions: 'Действия'
  },

  dialogs: {
    create: 'Создать новую задачу'
  },

  states: {
    scheduled: 'Запланировано',
    running: 'Запущено',
    paused: 'Приостановлено',
    completed: 'Готово',
    failed: 'Неудачно'
  },

  simpleStates: {
    scheduled: 'Активен',
    running: 'Активен',
    paused: 'Приостановлен',
    completed: 'Завершён',
    failed: 'Неудачно'
  },

  actions: {
    resume: 'Возобновить',
    start: 'Запустить',
    schedule: 'Запланировать',
    paused: 'Приостановить'
  },

  misc: {
    receivers: 'Всего получателей',
    sent: 'Отправлено',
    errored: 'С ошибкой',
    rest: 'Осталось отправить',
    include: {
      lists: 'Включённые списки',
      recipients: 'Включенные получатели'
    },
    exclude: {
      lists: 'Исключённые списки',
      recipients: 'Исключённые получатели'
    },
    total: 'Всего'
  },

  titles: {
    analytics: 'Аналитика задачи',
    filter: 'Фильтр задач'
  },

  types: {
    bulk: 'Рассылка',
    'sendings:bulk': 'Sendings bulk',
    'payments:recurrent': 'Рекуррентное пополнение',
    'billings:operations': 'Финансовая операция'
  },

  subheaders: {
    include: {
      lists: 'Включённые списки',
      recipients: 'Включенные получатели'
    },
    exclude: {
      lists: 'Исключённые списки',
      recipients: 'Исключённые получатели'
    },
    error: {
      lists: 'С ошибкой'
    },
    errors: 'Ошибки'
  },

  contents: {
    confirm: {
      remove: 'Вы уверен что хотите удалить задачу {title}?'
    }
  },

  buttons: {
    today: 'Сегодняшние',
    twoWeeks: 'Запланированные',
    analytics: 'Аналитика',
    refresh: 'Обновить'
  },

  confirm: {
    remove: {
      title: 'Подтвердите удаление задачи',
      text: 'Вы собираетесь удалить задачу {title}.'
    }
  },

  frequencies: {
    once: 'Единоразово',
    daily: 'Ежедневно',
    weekly: 'Еженедельно',
    monthly: 'Ежемесячно',

    title: {
      once: 'Единоразовое',
      daily: 'Ежедневное',
      weekly: 'Еженедельное',
      monthly: 'Ежемесячное'
    }
  },

  tooltips: {
    refresh: 'Обновление данных таблицы с применением выбранного фильтра.'
  },

  snackbars: {
    updated: 'Задача успешно обновлена',
    removed: 'Задача успешно удалена',
    created: 'Задача успешно создана'
  }
}
