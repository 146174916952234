import proxy from '@sigma-legacy-libs/g-proxy'

import { billingsSources, isUUID } from '@/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    currency: String
  },

  computed: {
    validation() {
      const amount = parseFloat(this.proxy.amount)
      if (isNaN(amount) || amount <= 0) {
        return false
      }
      if (!Object.values(billingsSources).includes(this.proxy.source)) {
        return false
      }
      if (!isUUID(this.proxy.OwnerId)) {
        return false
      }
      if (!this.proxy.data.multiplier) {
        return false
      }

      return true
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  render
}
