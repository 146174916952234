import Vue from 'vue'

import { upperFirst } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

import { projectName, services } from '../constants'

export default function(options = {}) {
  const cache = new Map()
  const localStorageKey = `${projectName}:generator:search:${options.service}:defaultType`

  return {
    data() {
      return {
        visited: [],
        favorites: [],

        loading: {
          visited: false,
          favorites: false
        },
        loadingItem: {},

        currentTab: this.getDefaultTab()
      }
    },

    computed: {
      ...mapGetters({ favoriteItems: 'favorites/items' })
    },

    watch: {
      $route: {
        handler(value) {
          const name = value.name
          if (name === `${options.service}.single` || name === `${services.contacts}.single`) {
            this.addVisited({
              service: options.service,
              id: value.params.id
            })
          }
          this.getVisited()
        },
        deep: true
      },

      currentTab(value) {
        switch (value) {
          case 'visited':
          case 'favorites': {
            this[`get${upperFirst(value)}`]()
            break
          }
        }
      },

      [`restData.${options.service}.find.filter`]: {
        handler() {
          this.currentTab = 'all'
        },
        deep: true
      }
    },

    mounted() {
      this.$nextTick(() => {
        this.currentTab = this.getDefaultTab()
      })
    },

    methods: {
      ...mapActions({
        getVisitedIds: 'visited/get',
        addVisited: 'visited/add',
        removeVisited: 'visited/remove',
        refreshFavorites: 'favorites/refresh',
        toggleFavorite: 'favorites/toggle'
      }),

      async processItems(items, type, service) {
        try {
          this.loading[type] = true
          const prevToken = window.localStorage.getItem('prevToken')
          if (!prevToken) {
            const result = []
            if (Array.isArray(items) && items.length) {
              for (const id of items) {
                let item
                const data = this.restData[service].find.data
                if (Array.isArray(data) && data.length) {
                  item = data.find(el => el.id === id)
                }
                if (!item) {
                  if (cache.has(id)) {
                    item = cache.get(id)
                  } else {
                    try {
                      Vue.set(this.loadingItem, id, true)
                      const response = await Vue.$GRequest.get(service, id, { query: { $scope: [ 'full' ] } })
                      if (response?.data) {
                        item = response.data
                      }
                      Vue.set(this.loadingItem, id, false)
                    } catch (error) {
                      switch (type) {
                        case 'visited': {
                          this.removeVisited({
                            id,
                            service
                          })
                          break
                        }
                        case 'favorites': {
                          this.toggleFavorite({
                            id,
                            service
                          })
                          break
                        }
                      }
                    }
                  }
                }
                if (item) {
                  result.push(item)
                  cache.set(id, item)
                }
              }
            }

            return result
          }
        } catch (error) {
          return []
        } finally {
          this.loading[type] = false
        }
      },

      async getVisited() {
        const items = await this.getVisitedIds(options.service)
        const result = await this.processItems(items, 'visited', options.service)
        if (Array.isArray(result)) {
          this.visited.splice(0, this.visited.length, ...result)
        }
      },

      async getFavorites() {
        const items = await this.refreshFavorites(options.service)
        const result = await this.processItems(items, 'favorites', options.service)
        if (Array.isArray(result)) {
          this.favorites.splice(0, this.favorites.length, ...result)
        }
      },

      setDefaultTab(value) {
        window.localStorage.setItem(localStorageKey, value)
      },
      getDefaultTab() {
        return window.localStorage.getItem(localStorageKey) || 'all'
      }
    }
  }
}
