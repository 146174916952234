import { colors, icons } from '@/utils'

export const serviceName = 'sendings'

export const sendingsStatuses = {
  pending: {
    icon: icons.update,
    color: colors.info
  },
  paused: {
    icon: icons.pause_circle_outline,
    color: colors.info
  },
  processing: {
    icon: icons.autorenew,
    color: colors.info
  },
  sent: {
    icon: icons.trending_flat,
    color: colors.info
  },
  delivered: {
    icon: icons.done,
    color: colors.success
  },
  seen: {
    icon: icons.done_all,
    color: colors.success
  },
  failed: {
    icon: icons.clear,
    color: colors.error
  },
  moderation: {
    icon: icons.motion_photos_paused,
    color: colors.warning
  }
}
