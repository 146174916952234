import { REQUISITE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, components, inputModes, services, sizes } from '@/utils'
import { bikRegExp, innRegExp, kppRegExp, ksRegExp, ogrnRegExp, okpoRegExp, opfList, rsRegExp } from '@/components/services/requisites/utils'

import tag from '@/components/tag'
import info from '@/components/info'
import selectorOwner from '@/components/selector/owner'
import requisitesFormAddress from '@/components/services/requisites/form/address'

function renderValidation(h, state) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        tag,
        {
          props: {
            label: this.getTranslate(`${services.requisites}.hints.validation.state.${state}`),
            color: state === true ? colors.success : colors.error,
            size: sizes.small
          }
        }
      )
    ]
  )
}

function renderAutoFilling(h) {
  return h(
    components.card,
    {
      class: `${services.requisites}-form__section`,
      props: {
        title: this.getTranslate(`${services.requisites}.titles.autoFilling`),
        outline: true,
        rounded: true,
        dashed: true
      }
    },
    [
      h(
        'div',
        {
          class: `${services.requisites}-form__row`
        },
        [
          h(
            components.select,
            {
              props: {
                value: this.suggestion.inn,
                label: this.getTranslate(`${services.requisites}.labels.data.legal.inn`),
                hint: this.getTranslate(`${services.requisites}.hints.suggestions.inn`),
                mode: inputModes['line-label'],
                rounded: true,
                dense: true,
                details: true,
                clearable: true,
                combobox: true,
                hideSelections: true,
                loading: this.loading.inn,
                items: this.suggestions.inn,
                mask: /^\d{1,10}$|^\d{1,12}$/
              },
              on: {
                search: event => {
                  this.getRequisitesByInn(event)
                },
                input: event => {
                  this.suggestion.inn = event
                }
              },
              scopedSlots: {
                selection: ({ item }) => {
                  return h(
                    tag,
                    {
                      props: {
                        label: item.title,
                        as: 'select-dense'
                      }
                    }
                  )
                }
              }
            }
          ),
          h(
            components.select,
            {
              props: {
                value: this.suggestion.bank,
                label: this.getTranslate(`${services.requisites}.labels.suggestions.bik`),
                hint: this.getTranslate(`${services.requisites}.hints.suggestions.bik`),
                mode: inputModes['line-label'],
                rounded: true,
                dense: true,
                details: true,
                clearable: true,
                combobox: true,
                hideSelections: true,
                loading: this.loading.bank,
                items: this.suggestions.bank
              },
              on: {
                search: event => {
                  this.getSuggestions(event, 'bank')
                },
                input: event => {
                  this.suggestion.bank = event
                }
              },
              scopedSlots: {
                selection: ({ item }) => {
                  return h(
                    tag,
                    {
                      props: {
                        label: item.title,
                        as: 'select-dense'
                      }
                    }
                  )
                }
              }
            }
          )
        ]
      ),
      h(
        components.select,
        {
          props: {
            value: this.suggestion.address,
            label: this.getTranslate(`${services.requisites}.labels.suggestions.address`),
            hint: this.getTranslate(`${services.requisites}.hints.suggestions.address`),
            mode: inputModes['line-label'],
            rounded: true,
            dense: true,
            details: true,
            clearable: true,
            combobox: true,
            hideSelections: true,
            loading: this.loading.address,
            items: this.suggestions.address
          },
          on: {
            search: event => {
              this.getSuggestions(event, 'address')
            },
            input: event => {
              this.suggestion.address = event
            }
          },
          scopedSlots: {
            selection: ({ item }) => {
              return h(
                tag,
                {
                  props: {
                    label: item.title,
                    as: 'select-dense'
                  }
                }
              )
            }
          }
        }
      ),
      h(
        info,
        {
          props: {
            value: this.getTranslate(`${services.requisites}.hints.suggestion`),
            color: colors.primary
          }
        }
      )
    ]
  )
}

function renderContact(h) {
  return h(
    components.card,
    {
      class: `${services.requisites}-form__section`,
      props: {
        title: this.getTranslate(`${services.requisites}.subheaders.contact`),
        outline: true,
        rounded: true,
        dashed: true,
        accentColorName: this.contactValidation ? colors.success : colors.error
      }
    },
    [
      renderValidation.call(this, h, this.contactValidation),

      h(
        components.switch,
        {
          props: {
            value: this.contactMatches,
            label: this.getTranslate(`${services.requisites}.labels.contact.matches`),
            outline: true
          },
          on: {
            input: event => {
              this.contactMatches = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: `${services.requisites}-form__row`
        },
        [
          h(
            'div',
            {
              class: `${services.requisites}-form__column`
            },
            [
              h(
                components['text-field'],
                {
                  attrs: { id: `${services.requisites}.form.contact.lastName` },
                  props: {
                    value: this.proxy.contact.lastName,
                    label: this.getTranslate(`${services.requisites}.labels.contact.lastName`),
                    mode: inputModes['line-label'],
                    disabled: this.contactMatches,
                    loading: this.loading.owner,
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true,
                    validateOnBlur: true
                  },
                  on: {
                    input: event => {
                      this.proxy.contact.lastName = event
                    }
                  }
                }
              ),

              h(
                components['text-field'],
                {
                  attrs: { id: `${services.requisites}.form.contact.firstName` },
                  props: {
                    value: this.proxy.contact.firstName,
                    label: this.getTranslate(`${services.requisites}.labels.contact.firstName`),
                    mode: inputModes['line-label'],
                    disabled: this.contactMatches,
                    loading: this.loading.owner,
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true,
                    validateOnBlur: true
                  },
                  on: {
                    input: event => {
                      this.proxy.contact.firstName = event
                    }
                  }
                }
              ),

              h(
                components['text-field'],
                {
                  props: {
                    value: this.proxy.contact.middleName,
                    label: this.getTranslate(`${services.requisites}.labels.contact.middleName`),
                    mode: inputModes['line-label'],
                    disabled: this.contactMatches,
                    loading: this.loading.owner,
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false
                  },
                  on: {
                    input: event => {
                      this.proxy.contact.middleName = event
                    }
                  }
                }
              )
            ]
          ),

          h(
            'div',
            {
              class: `${services.requisites}-form__column`
            },
            [
              h(
                components['text-field'],
                {
                  attrs: { id: `${services.requisites}.form.contact.email` },
                  props: {
                    value: this.proxy.contact.email,
                    label: this.getTranslate(`${services.requisites}.labels.contact.email`),
                    error: this.emailValidation,
                    mode: inputModes['line-label'],
                    disabled: this.contactMatches,
                    loading: this.loading.owner,
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true,
                    validateOnBlur: true
                  },
                  on: {
                    input: event => {
                      this.proxy.contact.email = event
                    }
                  }
                }
              ),

              h(
                components['text-field'],
                {
                  attrs: {
                    id: `${services.requisites}.form.contact.phone`,
                    type: 'text'
                  },
                  props: {
                    value: this.proxy.contact.phone,
                    label: this.getTranslate(`${services.requisites}.labels.contact.phone`),
                    error: this.phoneValidation,
                    mode: inputModes['line-label'],
                    disabled: this.contactMatches,
                    loading: this.loading.owner,
                    dense: true,
                    rounded: true,
                    details: false,
                    validateOnBlur: true,
                    required: true
                  },
                  on: {
                    input: event => {
                      this.proxy.contact.phone = event
                    }
                  }
                }
              )
            ]
          )
        ]
      )
    ]
  )
}

function renderSigner(h) {
  return h(
    components.card,
    {
      class: `${services.requisites}-form__section`,
      props: {
        title: this.getTranslate(`${services.requisites}.subheaders.signer`),
        outline: true,
        rounded: true,
        dashed: true,
        accentColorName: this.signerValidation ? colors.success : colors.error
      }
    },
    [
      renderValidation.call(this, h, this.signerValidation),

      h(
        components.select,
        {
          props: {
            value: this.proxy.signer.fullName.nominative,
            label: this.getTranslate(`${services.requisites}.labels.signer.fullName.nominative`),
            hint: this.getTranslate(`${services.requisites}.hints.suggestions.fio`),
            items: this.suggestions.fio,
            mode: inputModes['line-label'],
            dense: true,
            rounded: true,
            details: true,
            combobox: true,
            required: true,
            hideSelections: true,
            validateOnBlur: true,
            loading: this.loading.fio
          },
          on: {
            search: event => {
              this.getSuggestions(event, 'fio')
            },
            input: event => {
              this.suggestion.fio = event
            }
          },
          scopedSlots: {
            selection: ({ item }) => {
              return h(
                tag,
                {
                  props: {
                    label: item.title,
                    as: 'select-dense'
                  }
                }
              )
            }
          }
        }
      ),

      h(
        'div',
        {
          class: `${services.requisites}-form__row`
        },
        [
          h(
            components.card,
            {
              class: `${services.requisites}-form__section`,
              props: {
                title: this.getTranslate(`${services.requisites}.hints.nominative`),
                outline: true,
                rounded: true,
                dashed: true
              }
            },
            [
              h(
                components['text-field'],
                {
                  props: {
                    value: this.proxy.signer.fullName.nominative,
                    label: this.getTranslate(`${services.requisites}.labels.signer.fullName.nominative`),
                    mode: inputModes['line-label'],
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true,
                    validateOnBlur: true
                  },
                  on: {
                    input: event => {
                      this.proxy.signer.fullName.nominative = event
                    }
                  }
                }
              ),
              h(
                components['text-field'],
                {
                  props: {
                    value: this.proxy.signer.position.nominative,
                    label: this.getTranslate(`${services.requisites}.labels.signer.position.nominative`),
                    mode: inputModes['line-label'],
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true,
                    validateOnBlur: true
                  },
                  on: {
                    input: event => {
                      this.proxy.signer.position.nominative = event
                    }
                  }
                }
              ),
              h(
                components['text-field'],
                {
                  props: {
                    value: this.proxy.signer.basis.nominative,
                    label: this.getTranslate(`${services.requisites}.labels.signer.basis.nominative`),
                    mode: inputModes['line-label'],
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true,
                    validateOnBlur: true
                  },
                  on: {
                    input: event => {
                      this.proxy.signer.basis.nominative = event
                    }
                  }
                }
              )
            ]
          ),
          h(
            components.card,
            {
              class: `${services.requisites}-form__section`,
              props: {
                title: this.getTranslate(`${services.requisites}.hints.genitive`),
                outline: true,
                rounded: true,
                dashed: true
              }
            },
            [
              h(
                components['text-field'],
                {
                  props: {
                    value: this.proxy.signer.fullName.genitive,
                    label: this.getTranslate(`${services.requisites}.labels.signer.fullName.genitive`),
                    mode: inputModes['line-label'],
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true,
                    validateOnBlur: true
                  },
                  on: {
                    input: event => {
                      this.proxy.signer.fullName.genitive = event
                    }
                  }
                }
              ),
              h(
                components['text-field'],
                {
                  props: {
                    value: this.proxy.signer.position.genitive,
                    label: this.getTranslate(`${services.requisites}.labels.signer.position.genitive`),
                    mode: inputModes['line-label'],
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true,
                    validateOnBlur: true
                  },
                  on: {
                    input: event => {
                      this.proxy.signer.position.genitive = event
                    }
                  }
                }
              ),
              h(
                components['text-field'],
                {
                  props: {
                    value: this.proxy.signer.basis.genitive,
                    label: this.getTranslate(`${services.requisites}.labels.signer.basis.genitive`),
                    mode: inputModes['line-label'],
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true,
                    validateOnBlur: true
                  },
                  on: {
                    input: event => {
                      this.proxy.signer.basis.genitive = event
                    }
                  }
                }
              )
            ]
          )
        ]
      )
    ]
  )
}

function renderLegal(h) {
  if (this.proxy.type === REQUISITE_TYPES.business) {
    return h(
      components.card,
      {
        class: `${services.requisites}-form__section`,
        props: {
          title: this.getTranslate(`${services.requisites}.subheaders.legal`),
          outline: true,
          rounded: true,
          dashed: true,
          accentColorName: this.legalValidation ? colors.success : colors.error
        }
      },
      [
        renderValidation.call(this, h, this.legalValidation),

        h(
          'div',
          {
            class: `${services.requisites}-form__row`
          },
          [
            h(
              components.select,
              {
                props: {
                  value: this.proxy.data.legal.opf,
                  label: this.getTranslate(`${services.requisites}.labels.data.legal.opf`),
                  hint: this.getTranslate(`${services.requisites}.hints.data.legal.opf`),
                  items: opfList,
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  details: true,
                  combobox: true,
                  required: true,
                  setOnBlur: true,
                  hideSelections: true,
                  validateOnBlur: true
                },
                on: {
                  input: event => {
                    this.proxy.data.legal.opf = event
                  }
                },
                scopedSlots: {
                  selection: ({ item }) => {
                    return h(
                      tag,
                      {
                        props: {
                          label: item.title,
                          as: 'select-dense'
                        }
                      }
                    )
                  }
                }
              }
            ),
            h(
              components['text-field'],
              {
                props: {
                  value: this.proxy.data.legal.name,
                  label: this.getTranslate(`${services.requisites}.labels.data.legal.name`),
                  hint: this.getTranslate(`${services.requisites}.hints.data.legal.name`),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: true,
                  required: true,
                  validateOnBlur: true
                },
                on: {
                  input: event => {
                    this.proxy.data.legal.name = event
                  }
                }
              }
            ),
            h(
              components['text-field'],
              {
                props: {
                  value: this.proxy.data.legal.inn,
                  label: this.getTranslate(`${services.requisites}.labels.data.legal.inn`),
                  hint: this.getTranslate(`${services.requisites}.hints.data.legal.inn`),
                  error: this.checkField(
                    this.proxy.data.legal.inn,
                    innRegExp,
                    this.getTranslate(`${services.requisites}.errors.inn`)
                  ),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: true,
                  required: true,
                  validateOnBlur: true,
                  mask: /^\d{1,10}$|^\d{1,12}$/
                },
                on: {
                  input: event => {
                    this.proxy.data.legal.inn = event
                  }
                }
              }
            ),
            h(
              components['text-field'],
              {
                props: {
                  value: this.proxy.data.legal.ogrn,
                  label: this.getTranslate(`${services.requisites}.labels.data.legal.ogrn`),
                  hint: this.getTranslate(`${services.requisites}.hints.data.legal.ogrn`),
                  error: this.checkField(
                    this.proxy.data.legal.ogrn,
                    ogrnRegExp,
                    this.getTranslate(`${services.requisites}.errors.ogrn`)
                  ),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: true,
                  required: true,
                  validateOnBlur: true,
                  mask: /^\d{1,15}$/
                },
                on: {
                  input: event => {
                    this.proxy.data.legal.ogrn = event
                  }
                }
              }
            ),
            h(
              components['text-field'],
              {
                props: {
                  value: this.proxy.data.legal.kpp,
                  label: this.getTranslate(`${services.requisites}.labels.data.legal.kpp`),
                  hint: this.getTranslate(`${services.requisites}.hints.data.legal.kpp`),
                  error: this.checkField(
                    this.proxy.data.legal.kpp,
                    kppRegExp,
                    this.getTranslate(`${services.requisites}.errors.kpp`)
                  ),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: true,
                  mask: /^(\d{1,9})$/
                },
                on: {
                  input: event => {
                    this.proxy.data.legal.kpp = event
                  }
                }
              }
            ),
            h(
              components['text-field'],
              {
                props: {
                  value: this.proxy.data.legal.okpo,
                  label: this.getTranslate(`${services.requisites}.labels.data.legal.okpo`),
                  hint: this.getTranslate(`${services.requisites}.hints.data.legal.okpo`),
                  error: this.checkField(
                    this.proxy.data.legal.okpo,
                    okpoRegExp,
                    this.getTranslate(`${services.requisites}.errors.okpo`)
                  ),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: true,
                  mask: /^\d{1,8}$|^\d{1,10}$/
                },
                on: {
                  input: event => {
                    this.proxy.data.legal.okpo = event
                  }
                }
              }
            )
          ]
        ),
        h(
          components.switch,
          {
            props: {
              value: this.proxy.data.tax.vat,
              label: this.getTranslate(`${services.requisites}.labels.data.tax.vat`),
              outline: true
            },
            on: {
              input: event => {
                this.proxy.data.tax.vat = event
              }
            }
          }
        )
      ]
    )
  }
}

function renderBank(h) {
  if (this.proxy.type === REQUISITE_TYPES.business) {
    return h(
      components.card,
      {
        class: `${services.requisites}-form__section`,
        props: {
          title: this.getTranslate(`${services.requisites}.subheaders.bank`),
          outline: true,
          rounded: true,
          dashed: true,
          accentColorName: this.bankValidation ? colors.success : colors.error
        }
      },
      [
        renderValidation.call(this, h, this.bankValidation),

        h(
          'div',
          {
            class: `${services.requisites}-form__row`
          },
          [
            h(
              components['text-field'],
              {
                props: {
                  value: this.proxy.data.bank.name,
                  label: this.getTranslate(`${services.requisites}.labels.data.bank.name`),
                  hint: this.getTranslate(`${services.requisites}.hints.data.bank.name`),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: true,
                  required: true,
                  validateOnBlur: true
                },
                on: {
                  input: event => {
                    this.proxy.data.bank.name = event
                  }
                }
              }
            ),
            h(
              components['text-field'],
              {
                props: {
                  value: this.proxy.data.bank.rs,
                  label: this.getTranslate(`${services.requisites}.labels.data.bank.rs`),
                  hint: this.getTranslate(`${services.requisites}.hints.data.bank.rs`),
                  error: this.checkField(
                    this.proxy.data.bank.rs,
                    rsRegExp,
                    this.getTranslate(`${services.requisites}.errors.rs`)
                  ),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: true,
                  required: true,
                  validateOnBlur: true,
                  mask: /^\d{1,20}$/
                },
                on: {
                  input: event => {
                    this.proxy.data.bank.rs = event
                  }
                }
              }
            ),
            h(
              components['text-field'],
              {
                props: {
                  value: this.proxy.data.bank.ks,
                  label: this.getTranslate(`${services.requisites}.labels.data.bank.ks`),
                  hint: this.getTranslate(`${services.requisites}.hints.data.bank.ks`),
                  error: this.checkField(
                    this.proxy.data.bank.ks,
                    ksRegExp,
                    this.getTranslate(`${services.requisites}.errors.ks`)
                  ),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: true,
                  required: true,
                  validateOnBlur: true,
                  mask: /^\d{1,20}$/
                },
                on: {
                  input: event => {
                    this.proxy.data.bank.ks = event
                  }
                }
              }
            ),
            h(
              components['text-field'],
              {
                props: {
                  value: this.proxy.data.bank.bik,
                  label: this.getTranslate(`${services.requisites}.labels.data.bank.bik`),
                  hint: this.getTranslate(`${services.requisites}.hints.data.bank.bik`),
                  error: this.checkField(
                    this.proxy.data.bank.bik,
                    bikRegExp,
                    this.getTranslate(`${services.requisites}.errors.bik`)
                  ),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: true,
                  required: true,
                  validateOnBlur: true,
                  mask: /^\d{1,9}$/
                },
                on: {
                  input: event => {
                    this.proxy.data.bank.bik = event
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderLegalAddress(h) {
  return h(
    components.card,
    {
      class: `${services.requisites}-form__section`,
      props: {
        title: this.getTranslate(`${services.requisites}.subheaders.legalAddress`),
        outline: true,
        rounded: true,
        dashed: true,
        accentColorName: this.legalAddressValidation ? colors.success : colors.error
      }
    },
    [
      renderValidation.call(this, h, this.legalAddressValidation),

      h(
        requisitesFormAddress,
        {
          props: {
            value: this.proxy.data.legalAddress
          },
          on: {
            input: event => {
              this.proxy.data.legalAddress = event
            }
          }
        }
      )
    ]
  )
}

function renderPostalAddress(h) {
  return h(
    components.card,
    {
      class: `${services.requisites}-form__section`,
      props: {
        title: this.getTranslate(`${services.requisites}.subheaders.postalAddress`),
        outline: true,
        rounded: true,
        dashed: true,
        accentColorName: this.postalAddressValidation ? colors.success : colors.error
      }
    },
    [
      renderValidation.call(this, h, this.postalAddressValidation),

      h(
        requisitesFormAddress,
        {
          props: {
            value: this.proxy.data.postalAddress,
            address: this.proxy.data.legalAddress,
            matches: this.proxy.type === REQUISITE_TYPES.business
          },
          on: {
            input: event => {
              this.proxy.data.postalAddress = event
            }
          }
        }
      )
    ]
  )
}

function renderOwner(h) {
  return h(
    selectorOwner,
    {
      style: { 'max-width': '50%' },
      props: {
        value: this.proxy.OwnerId,
        hint: this.getTranslate(`${services.requisites}.hints.owner`),
        dense: true,
        combobox: true,
        disabled: !!this.OwnerId
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    components.form,
    {
      class: `${services.requisites}-form`
    },
    [
      renderAutoFilling.call(this, h),
      renderContact.call(this, h),
      renderSigner.call(this, h),
      renderLegal.call(this, h),
      renderBank.call(this, h),
      renderLegalAddress.call(this, h),
      renderPostalAddress.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}
