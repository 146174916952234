import Vue from 'vue'

import { Macro } from '@sigma-legacy-libs/cache'

import { globalErrorHandler, globalErrorProcessor, isValidPhone } from '@/utils'
import { dictionaryToService } from '@/components/services/dictionaries/utils'

import render from './render'
import { isEmpty } from 'lodash'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

export default {
  name: 'LocalSearch',

  props: {
    dictionary: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      value: undefined,
      result: undefined,

      loading: false,
      empty: false
    }
  },

  computed: {
    validation() {
      return isValidPhone(this.value)
    }
  },

  methods: {
    cachedRequest: Cache.wrapWithCache(async (key, params) => {
      return await Vue.$GRequest.create(`/${params.service}/search`, {
        id: params.id,
        OwnerId: params.OwnerId,
        value: params.value
      })
    }),

    async search() {
      if (this.loading) {
        return
      }

      try {
        if (this.validation) {
          this.loading = true
          this.empty = false
          this.result = undefined
          const { data } = await this.cachedRequest(`${this.dictionary.type}:${this.dictionary.OwnerId}:${this.dictionary.id}:${this.value}`, {
            service: dictionaryToService(this.dictionary.type),
            id: this.dictionary.id,
            OwnerId: this.dictionary.OwnerId,
            value: this.value
          })
          if (!isEmpty(data)) {
            this.result = data
          } else {
            this.empty = true
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
