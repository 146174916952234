import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { services } from '@/utils'

export const otpSettingsChannelTypes = {
  sms: SENDING_TYPES.sms,
  viber: SENDING_TYPES.viber,
  whatsapp: SENDING_TYPES.whatsapp,
  voice: SENDING_TYPES.voice,
  flashcall: SENDING_TYPES.flashcall,
  mobileid_mts_push: 'mobileid_mts_push',
  mobileid_mts_push_sms: 'mobileid_mts_push_sms',
  incoming_tg_bot: 'incoming_tg_bot'
}

export const getChannelTypes = service => {
  const result = [ otpSettingsChannelTypes.sms ]
  if (service !== services['otp-settings/templates']) {
    result.push(
      otpSettingsChannelTypes.mobileid_mts_push,
      otpSettingsChannelTypes.mobileid_mts_push_sms
    )
  }
  result.push(otpSettingsChannelTypes.incoming_tg_bot)

  return result.reduce((result, type) => {
    if (Object.values(otpSettingsChannelTypes).includes(type)) {
      result.push(type)
    }

    return result
  }, [])
}
