import { colors, components, getPrice, icons, permissionPresets, services } from '@/utils'
import { paymentStatusColors, paymentStatusIcons } from '@/components/services/payments/utils'

import sticker from '@/components/misc/sticker'
import date from '@/components/templates/service/item/date'
import owner from '@/components/templates/service/item/owner'
import actions from '@/components/templates/service/item/actions'

function renderStatus(h) {
  return h(
    sticker,
    {
      props: {
        value: this.viewport.size.width > 500 ? this.getTranslate(`${services.payments}.statuses.${this.value.status}`) : undefined,
        label: this.viewport.size.width > 500 ? this.getTranslate(`${services.payments}.labels.status`) : undefined,
        icon: paymentStatusIcons[this.value.status],
        iconTooltip: this.getTranslate(`${services.payments}.statuses.${this.value.status}`),
        color: paymentStatusColors[this.value.status]
      }
    }
  )
}
function renderSum(h) {
  return h(
    sticker,
    {
      props: {
        value: getPrice(this.value.amount, { currency: this.currency }),
        label: this.viewport.breakpoint.xs ? this.value.Owner.username : this.getTranslate(`${services.payments}.labels.amount`),
        iconSVG: this.currency,
        color: colors.grey
      }
    }
  )
}
function renderMethod(h) {
  if (this.viewport.size.width > 610) {
    return h(
      sticker,
      {
        props: {
          value: this.getTranslate(`${services.payments}.methods.${this.value.method}`),
          label: this.getTranslate(`${services.recurrentPayments}.labels.method`),
          iconSVG: this.value.method,
          iconTooltip: this.getTranslate(`${services.payments}.methods.${this.value.method}`),
          color: colors[this.value.method]
        }
      }
    )
  }
}
function renderOwner(h) {
  if (this.viewport.size.width > 820) {
    return h(
      owner,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: {
        [`${services.payments}-item__info`]: true,
        [`${services.payments}-item__info--admin`]: this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)
      }
    },
    [
      renderStatus.call(this, h),
      renderSum.call(this, h),
      renderMethod.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderPaymentLogsItem(h) {
  return h(
    components['list-item'],
    {
      props: {
        label: this.getTranslate(`${services.payments}.buttons.paymentLogs`),
        icon: icons.history,
        dense: true
      },
      on: {
        click: () => {
          this.$emit('paymentLogs', this.value.id)
        }
      }
    }
  )
}
function renderBillingsItem(h) {
  return h(
    components['list-item'],
    {
      props: {
        label: this.getTranslate(`${services.payments}.buttons.billings`),
        icon: icons.request_page,
        dense: true,
        disabled: !Array.isArray(this.value.Billings) || Array.isArray(this.value.Billings) && !this.value.Billings.length
      },
      on: {
        click: () => {
          this.$emit(services.billings, this.value.Billings)
        }
      }
    }
  )
}
function renderActions(h) {
  if (this.checkPermissions(`advanced.${services.payments}.get`)) {
    return h(
      actions,
      {
        props: {
          value: this.value,
          service: services.payments,
          actions: []
        }
      },
      [
        renderPaymentLogsItem.call(this, h),
        renderBillingsItem.call(this, h)
      ]
    )
  }
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        ['service-template-item']: true,
        ['hover-parent hover-parent--opacity']: this.viewport.breakpoint.mdUp,
        [`${services.payments}-item`]: true,
        [`${services.payments}-item--admin`]: this.checkPermissions(`advanced.${services.payments}.get`)
      }
    },
    [
      renderInfo.call(this, h),
      renderActions.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
