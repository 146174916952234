import { types } from './sendings'

export default {
  title: 'Tariffs',

  tariff: 'Tariff',
  tariffs: 'Tariffs',
  new: 'New tariff',
  create: 'Create new tariff',
  activeAt: 'Active at',
  cycle: 'Cycle',
  per: 'per',
  for: 'for',
  inherited: 'Inherited from tariff',
  withOperatorGroups: '(w/ operator groups)',
  sendingId: 'Sending ID',
  conditions: 'Conditions',
  customize: 'Customize',
  unconditional: 'Unconditional rule',
  usersOnTariff: 'Users on the tariff',
  addRule: 'Add rule',
  addSetConditions: 'Add set of conditions',
  addAnotherSetConditions: 'Add another set of conditions',
  always: 'Always',
  otherCases: 'In other cases',
  choosePath: '<Choose what to test>',
  chooseCondition: '<Set test conditions>',
  matchesWith: 'Matches',
  matchesWithRegex: 'regular expression',
  matchesWithMultiple: 'one these values',
  operator: 'no operators | one operator | {count} operators',
  sender: 'no sender names | one sender name | {count} sender names',
  rule: 'Rule #{rule}',

  titles: {
    rules: 'Rules',
    similar: 'Similar tariff'
  },

  sources: {
    ...types,

    apisender: 'Subscription API sender',
    hlr: 'HLR'
  },

  cycles: {
    month: 'Month',
    week: 'Week',
    day: 'Day',
    trigger: 'Trigger'
  },

  labels: {
    tariff: 'Tariff',
    title: 'Title',
    source: 'Source',
    cycle: 'Per',
    cycleStart: 'Cycle start',
    price: 'Price',
    comment: 'Comment',
    owner: 'Owner',
    actions: 'Actions',
    refundOnFailed: 'Refund on failed',
    noPayments: 'May be free',
    rules: 'Rules',
    index: 'Tariff #{index}',

    activePeriod: {
      start: 'Start date',
      end: 'End date'
    },

    conditions: {
      path: 'Path',
      comparator: 'Comparator',
      value: 'Value',
      negate: 'Negate',
      multiple: 'Choose from the list',
      regex: 'Enter regular expression'
    }
  },

  tabs: {
    eventsHistory: 'Events history'
  },

  dialogs: {
    new: 'New tariff'
  },

  subheaders: {
    rules: 'Rules',
    conditions: 'Conditions',
    activePeriod: {
      title: 'Active period',
      start: 'Active period start',
      end: 'Active period end'
    },
    cycleStart: 'Cycle start'
  },

  actions: {
    decrease: 'Decrease',
    increase: 'Increase',
    main: {
      decrease: 'Decrease',
      increase: 'Increase'
    }
  },

  buttons: {
    add: {
      tariff: 'Add tariff',
      rule: 'Add rule',
      condition: 'Add condition'
    },
    remove: {
      tariff: 'Remove tariff'
    },
    clone: {
      divided: '/ 100'
    }
  },

  tooltips: {
    custom: 'Customized',

    empty: {
      condition: 'Condition is not properly set up'
    },

    remove: {
      default: {
        rule: {
          always: 'Remove default rule',
          otherCases: 'Remove rule for other cases'
        }
      }
    }
  },

  snackbars: {
    created: 'Tariff successfully created.',
    updated: 'Tariff successfully updated.',
    removed: 'Tariff successfully removed.'
  },

  hints: {
    default: 'Per message',
    perCall: 'Per segment',
    perSecond: 'Per second',
    perPeriod: 'Per period'
  }
}
