import { components, isEmpty } from '@/utils'

import preloader from '@/components/preloader'

function renderLabel(h) {
  if (this.label) {
    return h(
      'label',
      {
        attrs: { for: this.$id },
        class: {
          [`${components['text-field']}__label`]: true,
          [this.focusedClass]: this.isFocused
        },
        on: { mousedown: this._click }
      },
      [ this.label, this.required && '*' ].filter(Boolean).join(' ')
    )
  }
}

function renderPrefix(h) {
  if (!isEmpty(this.prefix)) {
    return h(
      'div',
      {
        class: `${components['text-field']}__prefix`,
        style: { opacity: !isEmpty(this.prefix) && (this.isFocused || !isEmpty(this.proxy)) ? 1 : 0 },
        domProps: { innerHTML: this.prefix }
      }
    )
  }
}
function renderInput(h) {
  return h(
    'g-input',
    {
      class: `${components['text-field']}__input`,
      props: this.$inputProps,
      on: this.$inputOn,
      ref: 'input'
    }
  )
}
function renderSuffix(h) {
  if (!isEmpty(this.suffix)) {
    return h(
      'div',
      {
        class: `${components['text-field']}__suffix`,
        domProps: { innerHTML: this.suffix }
      }
    )
  }
}
function renderGroup(h) {
  return h(
    'div',
    {
      class: `${components['text-field']}__group`,
      on: { click: this._click }
    },
    [
      renderPrefix.call(this, h),
      renderInput.call(this, h),
      renderSuffix.call(this, h)
    ]
  )
}

function renderLoading(h) {
  if (this.loading) {
    return h(
      'div',
      {
        class: `${components['text-field']}__loading`
      },
      [
        h(
          preloader,
          {
            props: {
              size: 18
            }
          }
        )
      ]
    )
  }
}
function renderClearable(h) {
  if (this.isClearable) {
    return h(
      'div',
      {
        class: `${components['text-field']}__after-holder`
      },
      [
        h(
          'g-icon',
          {
            class: { [this.focusedClass]: this.isFocused },
            props: { value: 'clear' },
            on: { click: this._clear }
          }
        )
      ]
    )
  }
}

function renderBeforeSlot(h) {
  if (this.hasBeforeSlot) {
    return h(
      'div',
      {
        class: `${components['text-field']}__before-holder`
      },
      [ this.$scopedSlots.before ? this.$scopedSlots.before() : this.$slots.before ]
    )
  }
}
function renderBeforeIcon(h) {
  if (this.beforeIcon) {
    return h(
      'div',
      {
        class: `${components['text-field']}__before-holder`
      },
      [
        h(
          'g-icon',
          {
            class: { [this.focusedClass]: this.isFocused },
            props: { value: this.beforeIcon },
            on: {
              click: () => {
                if (!this.isDisabled && typeof this.beforeIconCallback === 'function') {
                  this.beforeIconCallback()
                }
              }
            }
          }
        )
      ]
    )
  }
}
function renderBeforeContent(h) {
  return renderBeforeIcon.call(this, h)
}
function renderBefore(h) {
  if (this.hasBefore) {
    return h(
      'div',
      {
        class: {
          [`${components['text-field']}__before`]: true,
          [`${components['text-field']}__before--pointer`]: this.beforeIcon && typeof this.beforeIconCallback === 'function'
        }
      },
      [ renderBeforeSlot.call(this, h) || renderBeforeContent.call(this, h) ]
    )
  }
}

function renderAfterSlot(h) {
  if (this.hasAfterSlot) {
    return h(
      'div',
      {
        class: `${components['text-field']}__after-holder`
      },
      [ this.$scopedSlots.after ? this.$scopedSlots.after() : this.$slots.after ]
    )
  }
}
function renderAfterIcon(h) {
  if (this.afterIcon) {
    return h(
      'div',
      {
        class: `${components['text-field']}__after-holder`
      },
      [
        h(
          'g-icon',
          {
            class: { [this.focusedClass]: this.isFocused },
            props: { value: this.afterIcon },
            on: {
              click: () => {
                if (!this.isDisabled && typeof this.afterIconCallback === 'function') {
                  this.afterIconCallback()
                }
              }
            }
          }
        )
      ]
    )
  }
}
function renderAfterContent(h) {
  return renderAfterIcon.call(this, h)
}
function renderAfter(h) {
  if (this.hasAfter) {
    return h(
      'div',
      {
        class: {
          [`${components['text-field']}__after`]: true,
          [`${components['text-field']}__after--pointer`]: this.afterIcon && typeof this.afterIconCallback === 'function'
        }
      },
      [
        renderClearable.call(this, h),
        renderAfterSlot.call(this, h) || renderAfterContent.call(this, h)
      ]
    )
  }
}

function renderHolder(h) {
  return h(
    'div',
    {
      class: `${components['text-field']}__holder`,
      style: { backgroundColor: this.backgroundColor }
    },
    [
      renderBefore.call(this, h),
      renderGroup.call(this, h),
      renderAfter.call(this, h),
      renderLoading.call(this, h)
    ]
  )
}

function renderBorder(h) {
  if (!this.loading) {
    return h(
      'div',
      {
        class: {
          [`${components['text-field']}__border`]: true,
          [this.focusedClass]: this.isFocused
        }
      }
    )
  }
}

function renderFooter(h) {
  if (this.mode === 'default') {
    return h(
      'div',
      {
        class: `${components['text-field']}__footer`
      },
      [ renderBorder.call(this, h) ]
    )
  }
}

function renderDetails(h) {
  if (this.details) {
    return h(
      'div',
      {
        class: `${components['text-field']}__details`
      },
      [
        h(
          'div',
          {
            class: `${components['text-field']}__details--left`
          },
          this.$hint
        ),
        h(
          'div',
          {
            class: `${components['text-field']}__details--right`
          },
          this.count
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: this.$class
    },
    [
      renderLabel.call(this, h),
      renderHolder.call(this, h),
      renderFooter.call(this, h),
      renderDetails.call(this, h)
    ]
  )
}
