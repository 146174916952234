import { colors, icons, moderationColors, moderationIcons, permissionPresets, services } from '@/utils'

import owner from '@/components/templates/service/item/owner'
import actions from '@/components/templates/service/item/actions'
import date from '@/components/templates/service/item/date'
import squircle from '@/components/icon/squircle'
import sticker from '@/components/misc/sticker'

function renderHolder(h, content) {
  return h(
    'div',
    {
      class: 'ff fjcfe w--100'
    },
    [ content ]
  )
}

function renderType(h) {
  return h(
    squircle,
    {
      props: {
        iconSVG: this.value.type,
        color: colors[this.value.type],
        tooltip: this.getTranslate(`${services.sendings}.types.${this.value.type}`)
      }
    }
  )
}
function renderTitle(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        label: this.getTranslate(`${services.patterns}.labels.title`),
        icon: this.value.isActive ? icons.check_circle_outline : icons.highlight_remove,
        color: this.value.isActive ? colors.success : colors.error,
        iconTooltip: this.getTranslate(`${services.patterns}.tooltips.isActive.${this.value.isActive}`),
        service: services.patterns
      }
    }
  )
}
function renderModeration(h) {
  if (this.viewport.size.width > 550) {
    return h(
      sticker,
      {
        props: {
          value: this.getTranslate(`misc.moderation.${this.value.moderation}`),
          label: this.getTranslate(`${services.patterns}.labels.moderation`),
          icon: moderationIcons[this.value.moderation],
          color: moderationColors[this.value.moderation],
          iconTooltip: this.getTranslate(`misc.moderation.${this.value.moderation}`)
        }
      }
    )
  }
}
function renderOwner(h) {
  if (this.viewport.size.width > 720) {
    return h(
      owner,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: {
        [`${services.patterns}-panel__info`]: true,
        [`${services.patterns}-panel__info--admin`]: this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)
      }
    },
    [
      renderType.call(this, h),
      renderTitle.call(this, h),
      renderModeration.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderTextContent(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value.payload.text,
        label: this.getTranslate(`${services.patterns}.labels.text`),
        icon: icons.text_snippet,
        color: colors.grey,
        width: 'fit-content',
        copy: true
      }
    }
  )
}
function renderText(h) {
  if (this.viewport.size.width > 1280) {
    return renderHolder.call(this, h, renderTextContent.call(this, h))
  }
}

function renderActionsContent(h) {
  return h(
    actions,
    {
      props: {
        value: this.value,
        service: services.patterns
      }
    }
  )
}
function renderActions(h) {
  if (this.viewport.size.width <= 1280) {
    return renderHolder.call(this, h, renderActionsContent.call(this, h))
  }

  return renderActionsContent.call(this, h)
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-item': true,
        [`${services.patterns}-panel`]: true,
        'hover-parent hover-parent--opacity': this.viewport.breakpoint.mdUp
      }
    },
    [
      renderInfo.call(this, h),
      renderText.call(this, h),
      renderActions.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
