export default {
  apisender: 'WAPI',
  billings: 'Financial operations',
  companies: 'Companies',
  contacts: 'Contacts',
  contactLists: 'Contact lists',
  contracts: 'Contracts',
  credentials: 'Credentials',
  dadata: 'DaData',
  dictionaries: 'Dictionaries',
  documentTemplates: 'Document templates',
  eventsHistory: 'Events history',
  invoices: 'Invoices',
  keywords: 'Keywords',
  keywordLists: 'Keyword lists',
  login: 'Authorization',
  messageRules: 'Message rules',
  messageRuleGroups: 'Message rule groups',
  notifications: 'Notifications',
  payments: 'Payments',
  patterns: 'Patterns for moderation',
  providers: 'Providers',
  providerGroups: 'Providers groups',
  providersPrices: 'Providers prices',
  recurrentPayments: 'Recurrent payments',
  registration: 'Registration',
  reports: 'Reports',
  requisites: 'Requisites',
  routingGroups: 'Routing groups',
  routingRules: 'Routing rules',
  sendernames: 'Sender names',
  sendings: 'Outgoing messages',
  sendingsIncoming: 'Incoming messages',
  servers: 'Servers',
  settingsUser: 'User settings',
  storage: 'Storage',
  tariffs: 'Tariffs',
  tasks: 'Tasks',
  telemetry: 'Telemetry',
  templates: 'Templates',
  tokens: 'Tokens',
  users: 'Users'
}
