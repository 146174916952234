import { services } from '@/utils'

import sticker from '@/components/misc/sticker'
import date from '@/components/templates/service/item/date'

function renderTitle(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        label: this.getTranslate(`${services.providerGroups}.labels.title`),
        iconSVG: this.value.type,
        color: this.value.type,
        service: services.providerGroups,
        width: 'fit-content'
      }
    }
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'service-template-item hover-parent hover-parent--opacity',
      style: { gridTemplateColumns: '1fr auto' }
    },
    [
      renderTitle.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
