import Vue from 'vue'

import { BILLING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'UpdateStatus',

  props: {
    id: {
      type: String,
      required: true
    },

    status: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      disabled: false
    }
  },

  computed: {
    $disabled() {
      if (this.loading) {
        return true
      }
      if (this.disabled) {
        return true
      }
      if (this.status !== BILLING_STATUS.pending) {
        return true
      }

      return false
    }
  },

  methods: {
    async update(status) {
      try {
        this.loading = true
        const { data } = await Vue.$GRequest.update(services.billings, this.id, { status }, { query: { $scope: [ 'Owner' ] } })
        if (data) {
          if (data.status && data.status === status) {
            this.disabled = true
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
