import { cloneDeep, forOwn, get, isPlainObject, set } from 'lodash'

import { attach } from '@/utils'
import { userTariffsInputFilter, userTariffsOutputFilter } from '@/components/services/tariffs/utils'

export const serviceName = 'users'

export const flatThis = (value, prefix = '') => {
  const result = {}

  for (const key in value) {
    if (isPlainObject(value[key])) {
      Object.assign(result, flatThis(value[key], prefix + key + '.'))
    } else {
      result[prefix + key] = value[key]
    }
  }

  return result
}

export function inputFilter(result) {
  result.password = ''
  result.ResellerId = result.ResellerId || result.Reseller && result.Reseller.id || null
  result.data.partnership.ManagerId = get(
    result,
    'data.partnership.Manager.id',
    get(result, 'data.partnership.ManagerId', null)
  )

  if (!Array.isArray(result.RoutingGroups)) {
    result.RoutingGroups = []
  }
  result._RoutingGroups = result.RoutingGroups.map(item => {
    return {
      id: item.id,
      $attach: true
    }
  })

  const availableSendingTypeForRoutingGroups = {}
  for (const type of this.getSendingTypesByPermission('advanced.users.setRoutingGroups')) {
    availableSendingTypeForRoutingGroups[type] = []
  }
  result.RoutingGroups = result.RoutingGroups.reduce((result, group) => {
    if (group.priority === undefined) {
      group.priority = 0
    }
    if (!result[group.type]) {
      result[group.type] = []
    }
    result[group.type].push(group)
    result[group.type].sort((a, b) => a.priority - b.priority)

    return result
  }, availableSendingTypeForRoutingGroups)

  if (!Array.isArray(result.Tariffs)) {
    result.Tariffs = []
  }
  result._Tariffs = cloneDeep(result.Tariffs)
  result.Tariffs = result.Tariffs
    .map(item => userTariffsInputFilter(item))
    .sort((a, b) => a.priority - b.priority)

  const availableSendingType = {}
  for (const type of this.getSendingTypesByPermission('advanced.users.update')) {
    availableSendingType[type] = []
  }

  if (!Array.isArray(result.Sendernames)) {
    result.Sendernames = []
  }
  result._Sendernames = cloneDeep(result.Sendernames)
  result.Sendernames = result.Sendernames.reduce((result, { id, type }) => {
    if (!result[type]) {
      result[type] = []
    }
    result[type].push(id)

    return result
  }, cloneDeep(availableSendingType))

  if (!Array.isArray(result.Patterns)) {
    result.Patterns = []
  }
  result._Patterns = cloneDeep(result.Patterns)
  result.Patterns = result.Patterns.reduce((result, { id, type }) => {
    if (!result[type]) {
      result[type] = []
    }
    result[type].push(id)

    return result
  }, cloneDeep(availableSendingType))

  if (Array.isArray(result.MessageRuleGroups)) {
    result._MessageRuleGroups = cloneDeep(result.MessageRuleGroups)
    result.MessageRuleGroups = result.MessageRuleGroups.map(item => {
      return {
        id: item.id,
        priority: item.priority,
        $attach: true
      }
    }).sort((a, b) => (a.priority || 0) - (b.priority || 0))
  } else {
    result.MessageRuleGroups = []
  }

  if (!result.data.meta) {
    result.data.meta = {}
  }
  if (!result.data.meta.usedReferralCode) {
    result.data.meta.usedReferralCode = undefined
  }

  if (isPlainObject(result.permissions)) {
    result.permissions = flatThis(result.permissions)
  } else {
    delete result.permissions
  }

  return result
}

export function outputFilter(result) {
  if (!result.ResellerId) {
    result.ResellerId = null
  }
  delete result.Reseller

  if (!result.data.partnership.ManagerId) {
    set(result, 'data.partnership.ManagerId', null)
  }
  delete result.data.partnership.Manager

  result.RoutingGroups = Object.keys(result.RoutingGroups).reduce((groups, type) => {
    const group = result.RoutingGroups[type]
    for (let index = 0; index < group.length; index++) {
      const { id } = group[index]
      groups.push({
        id,
        priority: index,
        $attach: true
      })
    }

    return groups
  }, [])
  attach(result, 'RoutingGroups')

  result.Tariffs = result.Tariffs
    .map(item => userTariffsOutputFilter(item))
    .sort((a, b) => a.priority - b.priority)
  attach(result, 'Tariffs')

  const Sendernames = []
  for (const sendingType in result.Sendernames) {
    for (const id of result.Sendernames[sendingType]) {
      if (!~Sendernames.findIndex(sendername => sendername.id === id)) {
        Sendernames.push({
          id,
          $attach: true
        })
      }
    }
  }
  result.Sendernames = Sendernames
  attach(result, 'Sendernames')

  const Patterns = []
  for (const sendingType in result.Patterns) {
    for (const id of result.Patterns[sendingType]) {
      if (!~Patterns.findIndex(pattern => pattern.id === id)) {
        Patterns.push({
          id,
          $attach: true
        })
      }
    }
  }
  result.Patterns = Patterns
  attach(result, 'Patterns')

  if (result.permissions) {
    const tempPermissions = {}

    forOwn(result.permissions, (value, key) => {
      set(tempPermissions, key, value)
    })

    result.permissions = tempPermissions
  }

  if (Array.isArray(result.MessageRuleGroups)) {
    result.MessageRuleGroups = result.MessageRuleGroups.map((item, index) => {
      return {
        id: item.id,
        priority: index,
        $attach: true
      }
    }).sort((a, b) => (a.priority || 0) - (b.priority || 0))
    attach(result, 'MessageRuleGroups')
  } else {
    result.MessageRuleGroups = undefined
  }

  delete result.Users

  return result
}

export default {
  serviceName,

  inputFilter,
  outputFilter
}
