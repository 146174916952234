import { buttonModes, colors, getLocaleDateString, getLocaleTimeString, services, sizes, states } from '@/utils'

import buttonRefresh from '@/components/button/refresh'
import pagination from '@/components/pagination'
import SingleLink from '@/components/misc/SingleLink'
import button from '@/components/button'
import preloader from '@/components/preloader'
import tag from '@/components/tag'

function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.userComments}-header`
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.payload.text,
            label: this.getTranslate(`${services.userComments}.labels.text`),
            mode: 'outline',
            details: false,
            grow: true,
            dense: true,
            rounded: true,
            clearable: true
          },
          on: {
            input: event => {
              this.payload.text = event
            },
            keypress: event => {
              if (event.keyCode === 13) {
                if (this.payload.text) {
                  this.create()
                }
              }
            }
          }
        }
      ),

      h(
        button,
        {
          props: {
            iconSVG: 'send',
            mode: buttonModes.flat,
            color: colors.primary,
            loading: this.restData[services.userComments].create.state === states.loading,
            disabled: this.restData[services.userComments].create.state === states.loading || !this.payload.text || !this.checkPermissions(`advanced.${services.userComments}.create`)
          },
          on: {
            click: () => {
              this.create()
            }
          }
        }
      )
    ]
  )
}

function renderItem(h, item) {
  return h(
    'div',
    {
      class: `${services.userComments}-item`
    },
    [
      h(
        'div',
        {
          class: `${services.userComments}-item__text`
        },
        [ item.text ]
      ),

      h(
        'div',
        {
          class: `${services.userComments}-item__footer`
        },
        [
          h(
            SingleLink,
            {
              props: {
                value: item.OwnerId
              }
            }
          ),

          h(
            tag,
            {
              props: {
                label: [ getLocaleDateString(item.createdAt), this.getTranslate('misc.in'), getLocaleTimeString(item.createdAt) ].join(' '),
                size: sizes.tiny,
                color: colors.grey
              }
            }
          )
        ]
      )
    ]
  )
}

function renderItems(h) {
  return h(
    'div',
    {
      class: `${services.userComments}-items`
    },
    [ this.restData[services.userComments].find.data.map(item => renderItem.call(this, h, item)) ]
  )
}

function renderList(h) {
  if (this.restData[services.userComments].find.state === states.ready) {
    if (this.restData[services.userComments].find.pagination.total > 0) {
      return renderItems.call(this, h)
    } else {
      return h(
        'div',
        {
          class: 'fjcc facc pa-3'
        },
        [ h('g-empty') ]
      )
    }
  } else if (this.restData[services.userComments].find.state === states.loading) {
    return h(
      'div',
      {
        class: 'fjcc facc pa-3'
      },
      [ h(preloader) ]
    )
  }

  return h('div')
}

function renderPagination(h) {
  return h(
    pagination,
    {
      props: {
        selectRows: false,
        value: this.restData[services.userComments].find.pagination,
        service: services.userComments
      },
      on: {
        input: event => {
          this.restData[services.userComments].find.pagination = event
        }
      }
    }
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: `${services.userComments}-footer`
    },
    [
      renderPagination.call(this, h),

      h(
        buttonRefresh,
        {
          props: {
            state: this.restData[services.userComments].find.state,
            shortClickMethod: () => this.rest[services.userComments].find({ query: { t: Date.now() } }),
            longClickMethod: () => this.rest[services.userComments].find({ query: { t: Date.now() } }, { noCache: true })
          }
        }
      )
    ]
  )
}

export default function(h) {
  if (this.checkPermissions(`advanced.${services.userComments}.get`)) {
    return h(
      'div',
      {
        class: services.userComments
      },
      [
        renderHeader.call(this, h),
        renderList.call(this, h),
        renderFooter.call(this, h)
      ]
    )
  }
}
