import Vue from 'vue'

import { eventHandlers, globalErrorHandler, globalErrorProcessor, nestjsServices, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'Actions',

  mixins: [ eventHandlers ],

  props: {
    actions: {
      type: Array,
      default: () => [ 'edit', 'remove' ],
      validator: value => {
        return value.every(action => [ 'edit', 'remove' ].includes(action))
      }
    },

    value: {
      type: Object,
      default: () => ({})
    },

    service: {
      type: String,
      required: true,
      validator: value => {
        return Object.values(services).includes(value)
      }
    },

    accept: {
      type: Array,
      default: () => permissionPresets.resellerUp
    },

    editCallback: Function
  },

  data() {
    return {
      loading: false,
      showMenu: false
    }
  },

  computed: {
    $serviceViaPoint() {
      return this.service.split('/').join('.')
    },
    $disabled() {
      if (
        this.checkPermissions(`advanced.${this.$serviceViaPoint}.update`, this.accept) ||
        this.checkPermissions(`advanced.${this.$serviceViaPoint}.remove`, this.accept) ||
        typeof this.$scopedSlots.default === 'function'
      ) {
        return false
      }

      return true
    }
  },

  methods: {
    async editClickHandler() {
      if (this.editCallback && typeof this.editCallback === 'function') {
        await this.editCallback()
      } else {
        Vue.router.push({
          name: `${this.$serviceViaPoint}.single`,
          params: { id: this.value.id }
        })
      }
    },

    async remove() {
      if (this.checkPermissions(`advanced.${this.$serviceViaPoint}.remove`, this.accept)) {
        try {
          this.loading = true
          let path = this.service
          if (Object.values(nestjsServices).includes(this.service)) {
            path = `/n/${this.service}`
          }
          const { data } = await Vue.$GRequest.remove(path, this.value.id)
          if (data && data.success) {
            this.addSnackbar({
              text: this.getTranslate(`${this.$serviceViaPoint}.snackbars.removed`),
              type: 'error'
            })
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    }
  },

  render
}
