import { buttonModes, colors, icons } from '@/utils'

import button from '@/components/button'

function renderFileInput(h) {
  return h(
    'input',
    {
      attrs: {
        id: 'csvFile',
        hidden: 'hidden',
        type: 'file'
      },
      ref: 'csvFile',
      on: {
        change: event => {
          this.import(event.target.files)
        }
      }
    }
  )
}

function renderButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        icon: icons.file_upload,
        color: colors.success,
        tooltip: this.tooltip
      },
      on: {
        click: () => {
          const csvFile = this.$refs.csvFile
          if (csvFile) {
            csvFile.click()
          }
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'faic'
    },
    [
      renderFileInput.call(this, h),
      renderButton.call(this, h)
    ]
  )
}
