import { colors } from '@/utils'

import render from './render'

export default {
  name: 'Preloader',

  props: {
    size: {
      type: Number,
      default: 24
    },

    count: {
      type: Number,
      default: 3
    },

    color: {
      type: String,
      default: colors.description,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    rainbow: {
      type: Boolean,
      default: false
    },

    duration: {
      type: Number,
      default: 500
    }
  },

  data() {
    return {
      index: 0,
      interval: undefined
    }
  },

  computed: {
    $class() {
      return {
        preloader: true,
        [`preloader--${this.color}`]: !!this.color && !this.rainbow,
        'preloader--rainbow': this.rainbow
      }
    },
    $style() {
      return {
        'grid-template-columns': `repeat(${this.count}, ${this.size}px)`,
        gap: `${this.size / 4}px`
      }
    },

    rainbowColors() {
      return [
        colors.success,
        colors.warning,
        colors.error,
        colors.primary,
        colors.epic
      ]
    }
  },

  mounted() {
    this.interval = setInterval(() => {
      this.index = this.index === this.count - 1 ? 0 : this.index + 1
    }, this.duration)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  render
}
