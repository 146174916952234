import Vue from 'vue'

import { globalErrorHandler, globalErrorProcessor, proxy, services } from '@/utils'

import render from './render'

export default {
  name: 'Dialog',

  mixins: [ proxy() ],

  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      sendings: [],

      loading: false,

      pagination: {
        limit: 10,
        offset: 0,
        total: 0
      }
    }
  },

  watch: {
    'pagination.offset'() {
      this.getSendings()
    }
  },

  mounted() {
    this.getSendings()

    Vue.$socket.on(`${services.sendingsModeration}.sendings.created`, data => {
      if (data.taskId === this.data.id) {
        const index = this.sendings.findIndex(sending => sending.sendingId === data.sendingId)
        if (!~index) {
          this.sendings.unshift(data)
        }
      }
    })
  },

  methods: {
    async getSendings() {
      if (this.checkPermissions(`advanced.${services.sendingsModeration}.get`)) {
        try {
          this.loading = true
          const path = [ services.sendingsModeration, this.data.id, services.sendings ].join('/')
          const { data, total } = await Vue.$GRequest.find(path, {
            query: {
              $offset: this.pagination.offset,
              $limit: this.pagination.limit
            }
          })
          if (data) {
            this.sendings = data
            this.pagination.total = total
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    },

    copyId(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.addSnackbar({
          text: this.getTranslate('misc.copied'),
          type: 'success'
        })
      })
    }
  },

  render
}
