import { components, inputModes } from '@/utils'

const regexps = {
  hours: /^([01]?[0-9]|2[0-3])$/,
  minutes: /^(0?[0-9]|[1-5][0-9])$/,
  seconds: /^(0?[0-9]|[1-5][0-9])$/
}

function renderSelect(h, key, type, index, increment = 0) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.time[key][type],
        disabled: this.disabled,
        label: this.getTranslate(`misc.measure.time.${type}`),
        suffix: this.getTranslate(`misc.measure.time.short.${type}`),
        mode: inputModes['line-label'],
        details: false,
        rounded: true,
        dense: true,
        mask: regexps[type],
        tabindex: index + increment
      },
      on: {
        input: value => {
          this.time[key][type] = value
        }
      },
      key: `${key}-${type}-${index}`
    }
  )
}

function renderCard(h, key) {
  return h(
    components.card,
    {
      class: 'filter-date-time__time-card',
      props: {
        title: this.getTranslate(`filter.time.${key}`),
        outline: true,
        rounded: true
      }
    },
    [ 'hours', 'minutes', 'seconds' ].map((type, index) => {
      return renderSelect.call(this, h, key, type, index + 1, key === 'lt' ? 3 : 0)
    })
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'filter-date-time__time'
    },
    [ 'gt', 'lt' ].map(key => {
      return renderCard.call(this, h, key)
    })
  )
}
