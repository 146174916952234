export default {
  title: 'Contacts',

  copyTo: 'Copy to',

  titles: {
    custom: {
      fields: 'Custom fields'
    }
  },

  labels: {
    list: 'List',
    phone: 'Phone',
    email: 'E-mail',
    firstName: 'First name',
    lastName: 'Last name',
    middleName: 'Middle name',
    date: 'Date of birth',
    gender: 'Gender',
    contactList: 'Contact list',
    actions: '',
    custom: 'Custom field',
    custom01: 'Custom 01',
    custom02: 'Custom 02',
    custom03: 'Custom 03',
    custom04: 'Custom 04',
    custom05: 'Custom 05',
    custom06: 'Custom 06',
    custom07: 'Custom 07',
    custom08: 'Custom 08',
    custom09: 'Custom 09',
    custom10: 'Custom 10',
    custom11: 'Custom 11',
    custom12: 'Custom 12',
    custom13: 'Custom 13',
    custom14: 'Custom 14',
    custom15: 'Custom 15',
    custom16: 'Custom 16',
    custom17: 'Custom 17',
    custom18: 'Custom 18',
    custom19: 'Custom 19',
    custom20: 'Custom 20',
    clear: 'Clear filter'
  },

  dialogs: {
    titles: {
      create: 'Create contact',
      update: 'Update contact',
      remove: 'Remove contact',
      import: 'Import contacts'
    }
  },

  subheaders: {
    custom: 'Custom fields',
    date: 'Select date of birth'
  },

  emptyState: {
    emptyAddressBook: 'No Address Book Found',
    title: 'No contacts to show',
    description: '{0} a csv/xls/xlsx file or {1} a single contact'
  },

  contents: {
    confirm: {
      remove: 'Are you sure you want to remove contact?'
    },
    import: {
      loading: 'Dialog will automatically close on complete import.',
      success: 'Successfully import: {count}.',
      error: 'Unfortunately, we could not import a single contact from given file.<br><br><ul><li>Check if your columns are named properly. Try renaming you phone number column to "phone"</li><li>If you expiriencing troubles uploading CSV file, try XLSX or XLS. Keep in mind, in that case your records must be on first sheet / book.</li><li>Contact our support! We may need your file to resolve the issue.</li></ul>'
    }
  },

  buttons: {
    add: 'Add',
    remove: 'Remove'
  },

  tooltips: {
    add: 'Add one contact to contact list',
    remove: 'Remove contact from contact list',
    loading: 'Loading...',
    total: '&#xB7&#xB7&#xB7\nTotal {total}',
    noData: 'No contacts',
    permanent: 'Insert the word "permanent" into this field'
  },

  errors: {
    phone: 'Invalid phone number',
    email: 'Invalid e-mail',
    loading: 'Loading...',
    total: '&#xB7&#xB7&#xB7\nTotal {total}',
    noData: 'No contacts'
  },

  snackbars: {
    created: 'Contact successfully created.',
    updated: 'Contact successfully updated.',
    removed: 'Contact successfully removed.'
  }
}
