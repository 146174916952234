import { get } from 'lodash'

import { getUnix } from '@/utils'

export function downloadable(options = {}) {
  return {
    props: {
      preset: String
    },

    computed: {
      downloadable() {
        const oneMonth = 31 * 24 * 60 * 60 * 1000
        const lt = getUnix(get(this.filter, 'createdAt.$lt'))
        const gt = getUnix(get(this.filter, 'createdAt.$gt'))
        const diff = lt - gt
        switch (true) {
          case this.checkPermissions(options.permissionAccept, 'me'): {
            if (diff >= oneMonth) {
              return true
            }
            break
          }
          case this.checkPermissions(options.permissionAccept, 'reseller'): {
            if (diff >= 3 * oneMonth) {
              return true
            }
            break
          }
          case this.checkPermissions(options.permissionAccept):
          default: {
            return false
          }
        }

        return false
      }
    }
  }
}
