import Vue from 'vue'

import { globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'TaskMenuButton',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      showMenu: false,
      showSourceDataDialog: false,
      showEventsHistoryDialog: false
    }
  },

  watch: {
    showMenu(value) {
      this.$emit('menu', value)
    }
  },

  methods: {
    async remove() {
      try {
        const { data } = await Vue.$GRequest.remove(services.tasks, this.value.id)
        if (data && data.id) {
          this.addSnackbar({
            text: this.getTranslate(`${services.tasks}.snackbars.removed`),
            type: 'error'
          })
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    }
  },

  render
}
