import { billingsSources, components, inputModes, negativeNumberRegex, services } from '@/utils'

import tag from '@/components/tag'

function renderAmountField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.amount,
        label: this.getTranslate(`${services.billings}.labels.amount`),
        suffix: this.$currency ? this.getTranslate(`currency.${this.$currency}.symbol.unicode`) : null,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        required: this.required,
        clearable: true,
        autofocus: true,
        details: false,
        mask: negativeNumberRegex,
        processValue: value => value?.toString().replace(',', '.')
      },
      on: {
        input: event => {
          this.proxy.amount = event
        }
      }
    }
  )
}
function renderSourceField(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.source,
        label: this.getTranslate(`${services.billings}.labels.source`),
        items: Object.values(billingsSources).map(value => {
          return {
            title: this.getTranslate(`${services.billings}.sources.${value}`),
            iconSVG: value,
            iconColor: value,
            value
          }
        }),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        required: this.required,
        details: false
      },
      on: {
        input: event => {
          this.proxy.source = event
        }
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            tag,
            {
              props: {
                label: item.title,
                color: item.value,
                as: 'select-dense'
              }
            }
          )
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'flex grid-gap--8'
    },
    [
      renderAmountField.call(this, h),
      renderSourceField.call(this, h)
    ]
  )
}
