import Vue from 'vue'

import { mapGetters } from 'vuex'

import { globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'ProvidersPricesImport',

  props: {
    ProviderId: String,

    disabled: Boolean
  },

  data() {
    return {
      showFileManager: false,
      showResultDialog: false,
      loading: false,

      result: undefined
    }
  },

  computed: {
    ...mapGetters({ file: 'file/file' })
  },

  watch: {
    file: {
      async handler({ data }) {
        this.import(data.id)

        this.showFileManager = false
      },
      deep: true
    }
  },

  methods: {
    async import(fileId) {
      try {
        this.showFileManager = false
        this.showResultDialog = true
        this.loading = true
        const { data } = await Vue.$GRequest.update(services.providersPrices, 'import', {
          ProviderId: this.ProviderId,
          fileId
        })
        if (data) {
          this.result = data?.result
          this.$emit('import', data?.result)
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
