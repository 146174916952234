import { components, icons } from '@/utils'

function renderActivatorSlot() {
  if (this.$scopedSlots.activator) {
    return this.$scopedSlots.activator()
  }

  return this.$slots.activator
}

function renderActivator(h) {
  if (this.$scopedSlots.activator || this.$slots.activator) {
    return renderActivatorSlot.call(this, h)
  }

  return h(
    components['list-item'],
    {
      props: {
        label: this.label,
        icon: this.icon,
        iconColor: this.iconColor,
        active: this.active,
        hovered: this.hovered,
        dense: this.dense,
        rounded: this.rounded,
        disabled: this.disabled,
        suffix: this.value ? icons.keyboard_arrow_up : icons.keyboard_arrow_down
      },
      on: {
        click: event => {
          event.stopPropagation()
          this.value = !this.value
        }
      }
    }
  )
}

function renderDefaultSlot() {
  if (this.$scopedSlots.default) {
    return this.$scopedSlots.default()
  }

  return this.$slots.default
}

function renderContent(h) {
  if (this.value) {
    return h(
      'div',
      {
        class: `${components['list-group']}__content`
      },
      [ renderDefaultSlot.call(this, h) ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      attrs: { role: 'group' },
      class: {
        [`${components['list-group']}`]: true,
        [`${components['list-group']}--dense`]: this.dense
      }
    },
    [
      renderActivator.call(this, h),
      renderContent.call(this, h)
    ]
  )
}
