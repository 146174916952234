import { isUUID, services } from '@/utils'

import render from './render'

export default {
  name: 'SecondCol',

  props: {
    value: null,

    service: {
      type: String,
      validator: value => {
        return Object.values(services).includes(value)
      }
    },

    id: {
      type: String,
      validator: isUUID
    }
  },

  render
}
