import proxy from '@sigma-legacy-libs/g-proxy'

import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    isNew: Boolean
  },

  computed: {
    sourceItems() {
      const result = []
      const permission = `advanced.${services.tariffs}.find`
      if (this.checkPermissions(permission, permissionPresets.resellerUp)) {
        result.push(...this.getSendingTypesByPermission(permission).map(type => `${services.sendings}.${type}`))
        result.push('hlr')
      }

      return result
    },

    validation() {
      if (!this.proxy.title) {
        return false
      }
      if (!this.proxy.source) {
        return false
      }

      return true
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  async mounted() {
    await fillDependence.call(this, this.proxy, {
      service: services.users,
      permissions: permissionPresets.resellerUp,
      pathToObject: 'Users',
      picks: PUBLIC_FIELDS_USER.concat([ 'usersTariffs' ]),
      outputPath: 'Users'
    })

    this.$emit('validation', this.validation)
  },

  render
}
