import { inputModes, services } from '@/utils'

import selectorOwner from '@/components/selector/owner'

function renderFields(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1'
    },
    this.proxy.map((item, index) => {
      return h(
        'div',
        {
          class: 'grid grid-gap--8 faic',
          style: { 'grid-template-columns': '1fr 1fr 36px' }
        },
        [
          h(
            selectorOwner,
            {
              props: {
                value: item.key,
                label: this.getTranslate(`${services.providers}.labels.d360.owner`),
                mode: inputModes.outline,
                dense: true
              },
              on: {
                input: event => {
                  item.key = event
                }
              }
            }
          ),

          h(
            'g-text-field',
            {
              props: {
                value: item.value,
                label: this.getTranslate(`${services.providers}.labels.d360.token`),
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false,
                clearable: true
              },
              on: {
                input: event => {
                  item.value = event
                }
              }
            }
          ),

          h(
            'g-button',
            {
              class: 'ma-0',
              props: {
                flat: true,
                icon: 'remove',
                color: 'error'
              },
              on: {
                click: () => {
                  this.removeField(index)
                }
              }
            }
          )
        ]
      )
    })
  )
}

function renderAddFieldButton(h) {
  return h(
    'g-card-actions',
    {
      class: 'fjcfe facfe px-0'
    },
    [
      h(
        'g-button',
        {
          class: 'mr-0',
          props: {
            flat: true,
            icon: 'add',
            color: 'primary'
          },
          on: {
            click: () => {
              this.addField()
            }
          }
        }
      )
    ]
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderFields.call(this, h),
      renderAddFieldButton.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
