import { DICTIONARIES_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, globalErrorHandler, globalErrorProcessor, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: services.operatorGroups,

  mixins: [
    generateServices({
      name: services.operatorGroups,

      find: {
        defaultFilter: {
          DictionaryId: undefined,
          $scope: [ 'Dictionary' ],
          $search: undefined
        },
        alwaysCreateFromWebSocket: true,
        alwaysRemoveFromWebSocket: true
      },

      create: {
        params: {
          query: {
            $scope: [ 'Dictionary' ]
          }
        }
      }
    })
  ],

  props: {
    dictionary: {
      type: Object,
      default: () => ({})
    },

    removeCallback: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      showDialog: false
    }
  },

  computed: {
    isCorrectStatus() {
      return [
        DICTIONARIES_STATUSES.ready,
        DICTIONARIES_STATUSES.compiled,
        DICTIONARIES_STATUSES.activated
      ].includes(this.dictionary.status)
    }
  },

  watch: {
    dictionary: {
      handler() {
        this.getDictionary()
      },
      deep: true
    }
  },

  mounted() {
    this.getDictionary()
  },

  methods: {
    getDictionary() {
      if (this.isCorrectStatus) {
        this.restData[services.operatorGroups].find.filter.DictionaryId = this.dictionary.id
      }
    },

    async createGroup() {
      if (this.checkPermissions(`advanced.${services.operatorGroups}.create`, permissionPresets.resellerUp)) {
        try {
          const result = await this.rest[services.operatorGroups].create(Object.assign({ DictionaryId: this.dictionary.id }, this.restData[services.operatorGroups].create.data))
          if (result) {
            this.showDialog = false
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    }
  },

  render
}
