import { defaultsDeep, toPlainObject } from 'lodash'
import { EMPTY_TARIFFS, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'
import { splitRegExpStringToArray } from '@sigma-legacy-libs/essentials/lib/utils/regexp'

import Conditions from '@sigma-legacy-libs/essentials/lib/utils/Conditions'

export const setTariffPrice = (price, source) => {
  if (!Array.isArray(price)) {
    price = [ price ]
  }

  price.push(0)

  switch (source) {
    case SENDING_TYPES.voice:
      price.length = 2
      break
    default:
      price.splice(1, price.length)
      break
  }

  return price
}

export const extractDefaultRule = (rules, source) => {
  let { price, action, conditions } = rules[rules.length - 1] || {}
  if (Array.isArray(conditions) && conditions.length === 0) {
    rules.pop()

    price = setTariffPrice(price, source)

    return {
      price,
      action
    }
  }
}

export const ruleProcessor = (rule, tagOrPath) => {
  let result

  const tagMeta = Conditions.tagsMeta[tagOrPath]

  if (tagMeta) {
    switch (tagMeta.tag) {
      case 'text':
      case 'recipient': {
        result = typeof rule === 'string' ? rule : ''
        break
      }
      case 'type':
      case 'group':
      case 'sender':
      case 'keywords':
      case 'contactList': {
        if (Array.isArray(rule) && rule.length) {
          result = rule
          break
        }
        let rules = []
        if (typeof rule === 'string') {
          rules = splitRegExpStringToArray(rule)
        }
        result = rules
        break
      }
      case 'mccmnc': {
        result = toPlainObject(rule)
        break
      }
    }
  }

  return result
}

export const tariffRulesToGroup = (rules, source) => {
  const result = []
  if (Array.isArray(rules) && rules.length) {
    rules.sort((a, b) => a.priority - b.priority)

    let lastKey = ''
    for (let { action, price, conditions } of rules) {
      price = setTariffPrice(price, source)

      const rule = { conditions: [] }
      if (conditions) {
        rule.conditions = conditions.reduce((accumulator, { tag, path, value }) => {
          const meta = Conditions.tagsMeta[tag || path]
          const item = ruleProcessor(value, meta.tag)
          if (item) {
            accumulator.push({
              tag: meta.tag,
              value: item
            })
          }

          return accumulator
        }, [])
      }

      const key = `${action}_${price.toString()}`
      if (key === lastKey) {
        result[result.length - 1].rules.push(rule)
      } else {
        result.push({
          action,
          price,
          rules: [ rule ]
        })
      }

      lastKey = key
    }
  }

  return result
}
export const tariffGroupToRules = groups => {
  const result = []
  if (Array.isArray(groups) && groups.length) {
    for (const { action, price, rules } of groups) {
      if (Array.isArray(rules) && rules.length) {
        for (const { conditions } of rules) {
          result.push({
            action,
            price,
            priority: result.length,
            conditions: conditions.reduce((result, { tag, path, value }) => {
              const meta = Conditions.tagsMeta[tag || path]
              const rule = ruleProcessor(value, meta.tag)
              if (rule) {
                result.push({
                  tag: meta.tag,
                  value: rule
                })
              }

              return result
            }, [])
          })
        }
      }
    }
  }

  return result
}

export const userTariffsInputFilter = tariff => {
  const { id, title, data, usersTariffs } = tariff || {}
  const result = {
    id,
    title,
    source: tariff.source,
    priority: usersTariffs?.priority || 0,
    $attach: true,
    _defaultRules: [],
    _defaultRule: {}
  }

  const [ , source ] = tariff.source?.split('.') || []
  if (data?.rules) {
    const _defaultRule = extractDefaultRule(data.rules, source)
    if (_defaultRule) {
      result._defaultRule = _defaultRule
    }

    result._defaultRules = tariffRulesToGroup(data.rules, source)
  }

  return result
}
export const userTariffsOutputFilter = tariff => {
  const { id, source, priority, $attach } = tariff || {}

  return {
    id,
    source,
    $attach: $attach === undefined || !!$attach,
    priority: priority || 0
  }
}

export const tariffInputFilter = tariff => {
  defaultsDeep(tariff, EMPTY_TARIFFS)

  const rules = tariff.data.rules
  const [ , source ] = tariff.source?.split('.') || []

  if (Array.isArray(rules) && rules.length) {
    const _defaultRule = extractDefaultRule(tariff.data.rules, source)
    if (_defaultRule) {
      tariff._defaultRule = _defaultRule
    }

    tariff.data.rules = tariffRulesToGroup(rules, source)
  }

  return tariff
}
export const tariffOutputFilter = tariff => {
  tariff.data.rules = tariffGroupToRules(tariff.data.rules)

  if (tariff._defaultRule) {
    tariff.data.rules.push({
      action: tariff._defaultRule.action,
      price: tariff._defaultRule.price,
      priority: tariff.data.rules.length,
      conditions: []
    })

    delete tariff._defaultRule
  }

  return tariff
}
