import { isUUID, services } from '@/utils'

import render from './render'

export default {
  name: 'Users',

  props: {
    users: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      OwnerId: undefined,

      pagination: {
        limit: 15,
        offset: 0,
        total: 0
      }
    }
  },

  computed: {
    items() {
      if (Array.isArray(this.users) && this.users.length) {
        return this.users.slice(this.pagination.offset, this.pagination.offset + this.pagination.limit)
      }

      return []
    }
  },

  watch: {
    users: {
      handler() {
        this.pagination.total = this.users.length
      },
      deep: true
    }
  },

  mounted() {
    this.pagination.total = this.users.length
  },

  methods: {
    openUser(id) {
      if (isUUID(id)) {
        const routeData = this.$router.resolve({
          name: `${services.users}.single`,
          params: { id }
        })
        window.open(window.location.origin + routeData.href, '_blank')
      }
    }
  },

  render
}
