import { datePresets, inputModes, proxy } from '@/utils'

import render from './render'

const getPresets = (value, prefix = '') => {
  let result = []
  for (const key in value) {
    if (typeof value[key] === 'object') {
      result = result.concat(getPresets(value[key], `${prefix}${key}.`))
    } else {
      result.push(`${prefix}${key}`)
    }
  }

  return result
}

export default {
  name: 'Preset',

  mixins: [ proxy() ],

  props: {
    mode: {
      type: String,
      default: inputModes['line-label'],
      validator: value => {
        return Object.values(inputModes).includes(value)
      }
    }
  },

  computed: {
    items() {
      const presets = getPresets(datePresets)

      return presets.map(value => ({
        title: this.getTranslate(`filter.date.presets.${value}`),
        value
      }))
    }
  },

  render
}
