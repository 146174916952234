import { getLocaleDateString, getLocaleTimeString, services } from '@/utils'

import render from './render'

const formatDateTime = date => `${getLocaleDateString(date)} ${getLocaleTimeString(date)}`

export default {
  name: 'Item',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      showDialog: false
    }
  },

  computed: {
    gridTemplateColumns() {
      switch (true) {
        case this.viewport.size.width < 600: return '1fr auto auto'
        case this.viewport.size.width < 768: return 'auto 1fr auto auto'
        default: return 'auto 1fr 200px auto auto'
      }
    },

    iconIsReadTooltip() {
      const path = [ services.notifications, 'isRead' ]

      let status
      let date

      switch (this.value.isRead) {
        case true: {
          if (this.value.deliveredAt) {
            status = 'delivered'
            date = formatDateTime(this.value.deliveredAt)
          } else if (this.value.sentAt) {
            status = 'sent'
            date = formatDateTime(this.value.sentAt)
          }
        }
        case false: {
          if (this.value.sentAt) {
            status = 'sent'
            date = formatDateTime(this.value.sentAt)
          }
        }
      }

      if (status) {
        path.push(status)
      } else {
        path.push(this.value.isRead)
      }

      return this.getTranslate(path.filter(Boolean).join('.'), { date })
    }
  },

  render
}
