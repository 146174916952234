import { colors, icons, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import fileCard from '@/components/file/item/card'
import date from '@/components/templates/service/item/date'

function renderFrom(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value.from,
        label: this.getTranslate(`${services.sendings}.headers.from`),
        iconSVG: this.value.type,
        iconColor: colors[this.value.type],
        iconTooltip: this.getTranslate(`${services.sendings}.types.${this.value.type}`)
      }
    }
  )
}

function renderText(h) {
  if (this.viewport.breakpoint.smUp) {
    if (this.value.payload.text) {
      return h(
        sticker,
        {
          props: {
            value: this.value.payload.text,
            label: this.getTranslate('sendings.headers.text'),
            icon: icons.text_snippet,
            color: colors.grey,
            iconTooltip: this.getTranslate('sendings.headers.text')
          }
        }
      )
    }
  }
}

function renderOwner(h) {
  if (this.checkPermissions('advanced.users.get')) {
    if (this.viewport.breakpoint.smUp) {
      return h(
        sticker,
        {
          props: {
            value: this.value.Owner || this.value.OwnerId,
            pathToTitle: 'username',
            label: this.getTranslate('sendings.headers.owner'),
            icon: 'person',
            color: 'grey',
            to: {
              name: 'users.single',
              params: { id: this.value.Owner.id || this.value.OwnerId }
            }
          }
        }
      )
    }
  }
}

function renderInfo(h) {
  return h(
    'div',
    {
      class: `${services.sendingsIncoming}__info`,
      style: { gridTemplateColumns: this.infoGridTemplateColumns }
    },
    [
      renderFrom.call(this, h),
      renderText.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderFileCard(h) {
  if (this.viewport.breakpoint.lgUp) {
    if (this.value.File) {
      return h(
        fileCard,
        {
          class: 'pa-0',
          props: {
            value: this.value.File,
            outline: false,
            displayMenu: false,
            flat: true,
            maxWidth: 300,
            small: true
          }
        }
      )
    }
  }
}

function renderSourceDataButton(h) {
  if (this.checkPermissions(`advanced.${services.sendingsIncoming}.get`)) {
    return h(
      'g-button',
      {
        class: 'ma-0',
        props: {
          icon: 'code',
          color: 'primary',
          flat: true
        },
        on: {
          click: () => {
            this.$emit('source', this.value)
          }
        }
      }
    )
  }
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      date,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `service-template-item ${services.sendingsIncoming}__panel`,
      style: { gridTemplateColumns: this.panelGridTemplateColumns }
    },
    [
      renderInfo.call(this, h),
      renderFileCard.call(this, h),
      renderSourceDataButton.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
