import proxy from '@sigma-legacy-libs/g-proxy'

import { components, inputModes, services, sizes } from '@/utils'

import render from './render'

export default {
  name: 'SelectorSendingType',

  mixins: [ proxy() ],

  props: {
    permission: String,
    label: String,
    hint: String,
    error: String,
    titlePrefix: String,
    valuePrefix: String,

    items: {
      type: Array,
      default: () => []
    },
    itemsPrepend: {
      type: Array,
      default: () => []
    },
    itemsAppend: {
      type: Array,
      default: () => []
    },
    itemsDisabled: {
      type: Array,
      default: () => []
    },

    format: {
      type: Function,
      default: value => value
    },

    loading: Boolean,
    disabled: Boolean,
    required: Boolean,
    readonly: Boolean,
    multiple: Boolean,
    cancelable: Boolean,

    dense: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },

    size: {
      type: String,
      default: sizes.medium,
      validator: value => {
        return Object.values(sizes).includes(value)
      }
    },
    mode: {
      type: String,
      default: inputModes.outline,
      validator: value => {
        return Object.values(inputModes).includes(value)
      }
    },
    view: {
      type: String,
      default: components.select,
      validator: value => {
        return [ components.select, 'buttons' ].includes(value)
      }
    }
  },

  computed: {
    $items() {
      const result = []

      if (this.itemsPrepend.length) {
        result.push(...this.itemsPrepend)
      }
      if (this.items.length) {
        result.push(...this.items)
      } else {
        result.push(...this.getSendingTypesByPermission(this.permission))
      }
      if (this.itemsAppend.length) {
        result.push(...this.itemsAppend)
      }

      return result.map(value => {
        const formattedValue = this.format(value)

        return {
          title: this.getTranslate(`${services.sendings}.types.${formattedValue}`),
          value,
          iconSVG: formattedValue,
          iconColor: formattedValue,
          disabled: this.itemsDisabled.includes(value)
        }
      })
    },

    $clearable() {
      if (this.required) {
        return false
      }

      return this.clearable
    }
  },

  methods: {
    proxyHasValue(value) {
      if (Array.isArray(this.proxy) && this.proxy.length) {
        return this.proxy.includes(value)
      } else {
        return this.proxy === value
      }
    },

    eventHandler(value) {
      if (!this.readonly && !this.disabled) {
        if (this.multiple) {
          if (Array.isArray(this.proxy)) {
            const index = this.proxy.findIndex(item => item === value)
            if (index > -1) {
              this.proxy.splice(index, 1)
            } else {
              this.proxy.push(value)
            }
          }
        } else {
          this.proxy = value
        }
      }
    }
  },

  render
}
