import { cloneDeep } from 'lodash'

import Conditions from '@sigma-legacy-libs/essentials/lib/utils/Conditions'

import { buttonModes, colors, components, inputModes, services } from '@/utils'

import button from '@/components/button'
import mccmnc from '@/components/trees/mccmnc'
import groups from '@/components/trees/group'
import senders from '@/components/trees/sender'
import groupsReadonly from '@/components/trees/mccmnc/readonly'
import listReadonly from '@/components/trees/list/readonly'
import sendingType from '@/components/misc/rules/commons/type'

function renderTagEdit(h) {
  if (this.tag) {
    if (this.tagEdit && !this.readonly) {
      return h(
        components.select,
        {
          props: {
            value: this.tag,
            label: this.getTranslate(`conditions.tags.${this.tag}`),
            items: Conditions.tagsOnly.map(tag => {
              return {
                title: this.getTranslate(`conditions.tags.${tag}`),
                value: tag
              }
            }),
            itemsDisabled: this.tagDisabled,
            mode: inputModes.outline,
            dense: true,
            rounded: true,
            details: false,
            required: true
          },
          on: {
            input: event => {
              this.$emit('tag', event)
            }
          }
        }
      )
    }
  }
}
function renderMainComponent(h) {
  if (this.tag) {
    let component = components.select
    let props

    const defaultProps = {
      value: this.proxy,
      label: this.getTranslate(`conditions.tags.${this.tag}`),
      mode: inputModes['line-label'],
      disabled: this.disabled,
      dense: true,
      details: false,
      rounded: true,
      autofocus: true,
      clearable: true,
      readonly: this.readonly
    }

    switch (this.tag) {
      case 'text': {
        component = components.textarea
        props = Object.assign({}, defaultProps, {
          details: true,
          error: this.error,
          grow: true
        })
        break
      }

      case 'mccmnc': {
        component = this.readonly ? groupsReadonly : mccmnc
        props = Object.assign({}, { value: this.proxy })
        break
      }

      case 'group': {
        component = this.readonly ? listReadonly : groups
        props = Object.assign({}, { value: this.proxy })
        break
      }

      case 'sender': {
        component = this.readonly ? listReadonly : senders
        props = Object.assign({}, {
          value: this.proxy,
          type: this.type
        })
        break
      }

      case 'contactList': {
        props = Object.assign({}, defaultProps, {
          service: services.contactLists,
          multiple: true,
          autocomplete: true
        })
        break
      }
      case 'keywords': {
        props = Object.assign({}, defaultProps, {
          service: services.keywordLists,
          multiple: true,
          autocomplete: true
        })
        break
      }

      case 'type': {
        component = sendingType({ type: 'array' })
        props = Object.assign({}, defaultProps, { value: this.proxy })
        break
      }

      default: {
        component = components['text-field']
        props = Object.assign({}, defaultProps)
        break
      }
    }

    return h(
      component,
      {
        props,
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}
function renderAdditionalComponent(h) {
  if (!this.readonly) {
    switch (this.tag) {
      case 'text': {
        return h(
          components.textarea,
          {
            props: {
              value: this.text,
              label: this.getTranslate('conditions.labels.textToCheck'),
              mode: inputModes['line-label'],
              state: this.text ? this.checkPattern() ? 'success' : 'error' : undefined,
              dense: true,
              rounded: true,
              clearable: true,
              rows: 4,
              grow: true
            },
            on: {
              input: event => {
                this.text = event
              }
            }
          }
        )
      }
      case 'group': {
        return h(
          'div',
          {
            class: 'grid grid-gap--8 faic',
            style: { gridTemplateColumns: '1fr auto' }
          },
          [
            h(
              components['text-field'],
              {
                props: {
                  value: this.pattern,
                  label: this.getTranslate('conditions.labels.group.pattern'),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: false
                },
                on: {
                  input: event => {
                    this.pattern = event
                  }
                }
              }
            ),

            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.add'),
                  mode: buttonModes.flat,
                  color: colors.primary,
                  disabled: !this.pattern
                },
                on: {
                  click: () => {
                    this.proxy.push(this.pattern)
                    this.pattern = undefined
                  }
                }
              }
            )
          ]
        )
      }
      case 'contactList': {
        return h(
          components.textarea,
          {
            props: {
              value: this.phone,
              label: this.getTranslate('conditions.labels.phone'),
              mode: inputModes['line-label'],
              state: this.phoneCheck,
              error: this.error,
              dense: true,
              rounded: true,
              loading: this.phoneCheckLoading,
              clearable: true
            },
            on: {
              input: event => {
                this.phone = event
              }
            }
          }
        )
      }
    }
  }
}
function renderContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 px-2'
    },
    [
      renderTagEdit.call(this, h),
      renderMainComponent.call(this, h),
      renderAdditionalComponent.call(this, h)
    ]
  )
}

function renderCloseButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        label: this.getTranslate('misc.buttons.close')
      },
      on: {
        click: () => {
          this.$emit('dialog', false)
        }
      }
    }
  )
}
function renderResetButton(h) {
  if (!this.readonly) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate('misc.buttons.clear'),
          mode: buttonModes.flat
        },
        on: {
          click: () => {
            this.proxy = cloneDeep(this.defaultValue)
          }
        }
      }
    )
  }
}
function renderSaveButton(h) {
  if (!this.readonly) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate('misc.buttons.save'),
          disabled: this.readonly,
          color: colors.primary
        },
        on: {
          click: () => {
            this.$emit('input', this.proxy)
            this.$emit('dialog', false)
          }
        }
      }
    )
  }
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'flex grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      renderCloseButton.call(this, h),
      h('div', { class: 'ff' }),
      renderResetButton.call(this, h),
      renderSaveButton.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.tag ? this.getTranslate(`conditions.tags.${this.tag}`) : undefined,
        value: this.show,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 500 : undefined
      },
      on: {
        input: event => {
          this.$emit('dialog', event)
        }
      }
    },
    [
      renderContent.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
