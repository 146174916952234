import { buttonModes, colors, components, getString, sizes } from '@/utils'

import { eventHandlers } from '../../utils/mixins' // TODO: выяснить почему именно в этом компоненте нельзя импортировать eventHandlers из '@/utils'

import render from './render'

export default {
  name: components.tag,

  mixins: [ eventHandlers ],

  props: {
    label: [ String, Number, Boolean ],
    tooltip: [ String, Number ],

    loading: Boolean,
    disabled: Boolean,
    monospace: Boolean,
    strikethrough: Boolean,
    cancelable: Boolean,

    color: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },
    size: {
      type: String,
      default: sizes.medium,
      validator: value => {
        return Object.values(sizes).includes(value)
      }
    },
    mode: {
      type: String,
      default: buttonModes.flat,
      validator: value => {
        return Object.values(buttonModes).includes(value)
      }
    },
    as: {
      type: String,
      default: undefined,
      validator: value => {
        return !!~[ 'button', 'select', 'select-dense', 'link' ].indexOf(value)
      }
    },

    cancel: {
      type: Function,
      default: () => undefined
    }
  },

  computed: {
    $label() {
      return getString(this.label)
    },
    $tooltip() {
      return getString(this.tooltip)
    },

    $class() {
      const result = {
        [`${components.tag}`]: true,

        [`${components.tag}--${this.mode}`]: true,
        [`${components.tag}-as--${this.as}`]: !!this.as,

        [`${components.tag}--loading`]: this.loading,
        [`${components.tag}--disabled`]: this.disabled,
        [`${components.tag}--monospace`]: this.monospace,
        [`${components.tag}--strikethrough`]: this.strikethrough,
        [`${components.tag}--cancelable`]: this.cancelable,

        [`${components.tag}--${this.color}`]: !!this.color
      }
      let size = this.size
      if ([ 'select', 'select-dense' ].includes(this.as)) {
        size = this.as
      }
      result[`${components.tag}--${size}`] = true

      return result
    },

    $directives() {
      const result = []
      if (this.$tooltip) {
        result.push({
          name: components.tooltip,
          options: { value: this.$tooltip }
        })
      }

      return result
    }
  },

  render
}
