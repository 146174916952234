import Vue from 'vue'

import { services } from '@/utils'

import buttonClone from '@/components/services/inactiveProviders/buttons/clone'
import buttonIsActive from '@/components/services/providers/item/buttons/isActive'

function renderIsActiveButton(h) {
  return h(
    buttonIsActive,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderCloneButton(h) {
  return h(
    buttonClone,
    {
      props: { provider: this.value },
      on: {
        clone: id => {
          Vue.router.push({
            name: `${services.providers}.single`,
            params: { id }
          })
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8'
    },
    [
      renderIsActiveButton.call(this, h),
      renderCloneButton.call(this, h)
    ]
  )
}
