import { DICTIONARIES_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, inputModes, permissionPresets, services, states } from '@/utils'

import button from '@/components/button'
import buttonConfirmRemove from '@/components/button/confirm/remove'
import pagination from '@/components/pagination'
import form from '@/components/services/operatorGroups/form'
import preloader from '@/components/preloader'
import sticker from '@/components/misc/sticker'

const columns = [ 'operator', 'group', 'mcc', 'mnc' ]

function renderSearchField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.restData[services.operatorGroups].find.filter.$search,
        label: this.getTranslate('commons.labels.search'),
        loading: this.restData[services.operatorGroups].find.state === states.loading,
        disabled: !this.restData[services.operatorGroups].find.filter.$search && this.restData[services.operatorGroups].find.pagination.total === 0,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.restData[services.operatorGroups].find.filter.$search = event
        }
      }
    }
  )
}
function renderCreateButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.add'),
        icon: icons.add,
        color: colors.secondary,
        disabled: !this.checkPermissions(`advanced.${services.operatorGroups}.create`, permissionPresets.resellerUp)
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      }
    }
  )
}
function renderRemoveDictionaryButton(h) {
  if (this.checkPermissions(`advanced.${services.operatorGroups}.remove`, permissionPresets.resellerUp)) {
    return h(
      buttonConfirmRemove,
      {
        props: {
          content: this.getTranslate('dictionaries.contents.confirm.remove.dictionary', { title: this.dictionary.id }),
          disabled: this.dictionary.status === DICTIONARIES_STATUSES.activated,
          callback: () => {
            this.removeCallback()
          }
        }
      }
    )
  }
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.operatorGroups}-header`
    },
    [
      renderSearchField.call(this, h),
      renderRemoveDictionaryButton.call(this, h),
      renderCreateButton.call(this, h)
    ]
  )
}

function renderDivider(h) {
  if (Array.isArray(this.restData[services.operatorGroups].find.data) && this.restData[services.operatorGroups].find.data.length) {
    return h('g-divider')
  }
}

function renderPagination(h) {
  return h(
    'div',
    {
      class: 'fjcfe pa-2'
    },
    [
      h(
        pagination,
        {
          props: {
            value: this.restData[services.operatorGroups].find.pagination,
            chunk: this.viewport.breakpoint.smDown ? 2 : 5,
            service: services.operatorGroups
          },
          on: {
            input: event => {
              this.restData[services.operatorGroups].find.pagination = event
            }
          }
        }
      )
    ]
  )
}

function renderRemoveButton(h, item) {
  return h(
    buttonConfirmRemove,
    {
      class: 'hover-child',
      props: {
        labeled: false,
        disabled:
          this.restData[services.operatorGroups].remove.state === states.loading ||
          !this.checkPermissions('advanced.contacts.remove', permissionPresets.resellerUp),
        callback: () => {
          this.rest[services.operatorGroups].remove(item.id)
        }
      }
    }
  )
}
function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [ h(preloader) ]
  )
}
function renderAbsolutePreloader(h) {
  if (this.restData[services.operatorGroups].find.state === states.loading) {
    return h(
      'div',
      {
        class: 'preloader__holder preloader__holder--absolute'
      },
      [ h(preloader) ]
    )
  }
}
function renderItemColumns(h, item) {
  return h(
    'div',
    {
      class: `${services.operatorGroups}-item__columns`
    },
    [
      columns.map(column => {
        return h(
          sticker,
          {
            props: {
              value: item[column],
              label: this.getTranslate(`${services.dictionaries}.titles.${column}`),
              copy: true
            }
          }
        )
      })
    ]
  )
}
function renderItem(h, item) {
  return h(
    'div',
    {
      class: `service-template-item ${services.operatorGroups}-item`
    },
    [
      renderItemColumns.call(this, h, item),
      renderRemoveButton.call(this, h, item)
    ]
  )
}
function renderItems(h) {
  return h(
    'div',
    {
      class: `${services.operatorGroups}-items`
    },
    [
      this.restData[services.operatorGroups].find.data.map(item => {
        return renderItem.call(this, h, item)
      }),

      renderAbsolutePreloader.call(this, h)
    ]
  )
}
function renderBody(h) {
  if (this.restData[services.operatorGroups].find.pagination.total > 0) {
    return renderItems.call(this, h)
  } else {
    switch (this.restData[services.operatorGroups].find.state) {
      case states.loading: return renderPreloader.call(this, h)
      case states.ready:
      default: return h(components.empty)
    }
  }
}

function renderCreateDialog(h) {
  if (this.checkPermissions(`advanced.${services.operatorGroups}.create`, permissionPresets.resellerUp)) {
    return h(
      components.dialog,
      {
        props: {
          title: this.getTranslate('dictionaries.titles.createGroup'),
          value: this.showDialog,
          rounded: this.viewport.breakpoint.mdUp ? true : undefined,
          maxWidth: this.viewport.breakpoint.mdUp ? 550 : undefined
        },
        on: {
          input: event => {
            this.showDialog = event
          }
        }
      },
      [
        h(
          form,
          {
            props: {
              value: this.restData[services.operatorGroups].create.data,
              dictionary: this.dictionary
            },
            on: {
              input: event => {
                this.restData[services.operatorGroups].create.data = event
              },
              validation: event => {
                this.restData[services.operatorGroups].create.isValid = event
              }
            }
          }
        ),
        h(
          'div',
          {
            class: 'dialog__footer',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.close'),
                  mode: buttonModes.flat
                },
                on: {
                  click: () => {
                    this.showDialog = false
                  }
                }
              }
            ),
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.create'),
                  loading: this.restData[services.operatorGroups].create.state === states.loading,
                  disabled: !this.checkPermissions(`advanced.${services.operatorGroups}.create`, permissionPresets.resellerUp) || !this.restData[services.operatorGroups].create.isValid,
                  color: colors.primary
                },
                on: {
                  click: () => {
                    this.createGroup()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

export default function(h) {
  if (this.dictionary.id === this.restData[services.operatorGroups].find.filter.DictionaryId) {
    if (this.isCorrectStatus) {
      return h(
        components.card,
        {
          props: {
            rounded: true,
            outline: true
          }
        },
        [
          renderHeader.call(this, h),
          renderDivider.call(this, h),
          renderPagination.call(this, h),
          renderDivider.call(this, h),
          renderBody.call(this, h),
          renderDivider.call(this, h),
          renderPagination.call(this, h),
          renderCreateDialog.call(this, h)
        ]
      )
    }
  }
}
