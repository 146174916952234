import { colors, components, eventHandlers } from '@/utils'

import render from './render'

export default {
  name: components['list-item'],

  mixins: [ eventHandlers ],

  props: {
    label: [ String, Number ],

    active: Boolean,
    hovered: Boolean,
    dense: Boolean,
    rounded: Boolean,
    loading: Boolean,
    disabled: Boolean,

    color: String,
    icon: String,
    iconSVG: String,
    iconColor: String,
    iconSize: Number,
    suffix: String,

    to: {
      type: [ Object, String ],
      default: undefined
    }
  },

  computed: {
    hasIcon() {
      return !!this.icon || !!this.iconSVG || typeof this.$scopedSlots.icon === 'function' || !!this.$slots.icon
    },

    hasSuffix() {
      return !!this.suffix || typeof this.$scopedSlots.suffix === 'function' || !!this.$slots.suffix
    },

    $iconColor() {
      let result = this.iconColor || this.color
      if (this.active) {
        if (!result) {
          result = colors.primary
        }
      } else if (this.disabled) {
        result = colors.grey
      }

      return result || colors.grey
    },

    $class() {
      return {
        [`${components['list-item']}`]: true,

        [`${components['list-item']}--active`]: this.active,
        [`${components['list-item']}--hovered`]: this.hovered,
        [`${components['list-item']}--dense`]: this.dense,
        [`${components['list-item']}--rounded`]: this.rounded,
        [`${components['list-item']}--loading`]: this.loading,
        [`${components['list-item']}--disabled`]: this.disabled,

        [`${components['list-item']}--icon`]: this.hasIcon,
        [`${components['list-item']}--suffix`]: this.hasSuffix,

        [`${components['list-item']}--${this.color}`]: !!this.color
      }
    }
  },

  render
}
