import { TASK_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

import { services } from '@/utils'
import { tasksStates } from '@/components/services/tasks/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  const state = this.value.state

  return h(
    sticker,
    {
      props: {
        value: this.$value,
        label: this.getTranslate(`${services.tasks}.labels.state`),
        icon: tasksStates[state].icon,
        color: tasksStates[state].color,
        tooltip: this.stateTooltip,
        iconTooltip: state === TASK_STATES.scheduled ? this.stateLabel : this.getTranslate(`${services.tasks}.states.${state}`)
      }
    }
  )
}
