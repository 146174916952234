import Vue from 'vue'

import { isEqual } from 'lodash'

// NOTE: https://github.com/vuejs/vue-router/issues/2872
const noop = () => {}

export const routerReplacer = (route = {}) => {
  const currentRoute = Vue.router.currentRoute
  const nextRoute = {
    ...currentRoute,
    ...route
  }
  if (!isEqual(currentRoute, nextRoute)) {
    Vue.router.replace(nextRoute).catch(noop)
  }
}

export const routerQueryReplace = (query = {}, removeHash = false) => {
  const currentRoute = Vue.router.currentRoute
  Vue.router.replace({
    ...currentRoute,
    query: {
      ...currentRoute.query,
      ...query
    },
    hash: removeHash ? undefined : currentRoute.hash
  }).catch(noop)
}

export const routerParamsReplace = (params = {}) => {
  const currentRoute = Vue.router.currentRoute
  Vue.router.replace({
    ...currentRoute,
    params: {
      ...currentRoute.params,
      ...params
    }
  }).catch(noop)
}

export default {
  routerReplacer,
  routerQueryReplace,
  routerParamsReplace
}
