export const datePresets = {
  minutes: {
    5: 'minutes.5',
    15: 'minutes.15',
    30: 'minutes.30'
  },
  hours: {
    1: 'hours.1',
    6: 'hours.6'
  },
  today: 'today',
  yesterday: 'yesterday',
  current: {
    week: 'current.week',
    month: 'current.month'
  },
  last: {
    week: 'last.week',
    month: 'last.month'
  }
}
