import { buttonModes, components, permissionPresets, services, states } from '@/utils'

import info from '@/components/info'
import button from '@/components/button'
import buttonRefresh from '@/components/button/refresh'
import preloader from '@/components/preloader'
import pagination from '@/components/pagination'
import UploadFileButton from '@/components/misc/UploadFileButton'
import fileItems from '@/components/file/items'
import fileDialog from '@/components/file/dialog'
import fileFilter from '@/components/file/filter'

function renderLoadingArea(h) {
  return h(
    'div',
    {
      class: {
        'files__upload-area': true,
        'files__upload-area--active': this.showLoadingArea
      },
      domProps: { innerHTML: this.getTranslate('files.drop') },
      on: {
        dragleave: this.drop,
        drop: this.drop
      }
    }
  )
}
function renderUploadFileButtonOrArea(h, trigger = true, type = 'button') {
  if (trigger) {
    if (this.checkPermissions(`advanced.${services.storage}.upload`, permissionPresets.meUp)) {
      return h(
        UploadFileButton,
        {
          props: {
            name: this.name,
            isPublic: this.isPublic,
            maxFileSize: this.maxFileSize,
            accepted: this.types,
            loading: this.loading,
            disabled: this.loading,
            type
          }
        },
        [ renderLoadingArea.call(this, h) ]
      )
    }
  }
}

function renderPreloader(h, absolute = false) {
  if (this.restData[services.storage].find.state === states.loading) {
    return h(
      'div',
      {
        class: {
          'preloader__holder pa-5': true,
          'preloader__holder--absolute': absolute
        }
      },
      [ h(preloader) ]
    )
  }
}

function renderPagination(h) {
  return h(
    'div',
    {
      class: 'files__pagination'
    },
    [
      h(
        pagination,
        {
          props: {
            value: this.restData[services.storage].find.pagination,
            service: services.storage,
            selectRows: false
          },
          on: {
            input: event => {
              this.restData[services.storage].find.pagination = event
            }
          }
        }
      ),

      h(
        buttonRefresh,
        {
          props: {
            state: this.restData[services.storage].find.state,
            shortClickMethod: () => this.rest[services.storage].find(),
            longClickMethod: () => this.rest[services.storage].find({}, { noCache: true }),
            disabled: this.loading
          }
        }
      )
    ]
  )
}

function renderFilter(h) {
  return h(
    fileFilter,
    {
      props: {
        value: this.restData[services.storage].find.filter,
        types: this.types
      },
      on: {
        input: event => {
          this.restData[services.storage].find.filter = event
        }
      }
    }
  )
}
function renderActions(h) {
  return h(
    'div',
    {
      class: 'files__actions'
    },
    [ renderUploadFileButtonOrArea.call(this, h) ]
  )
}
function renderHeaderContent(h) {
  return h(
    'div',
    {
      class: 'files__header-content'
    },
    [
      renderFilter.call(this, h),
      renderActions.call(this, h)
    ]
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: {
        files__header: true,
        'files__header--embedded': this.embedded
      },
      slot: this.embedded && !this.viewport.breakpoint.xs ? 'header' : undefined
    },
    [
      renderHeaderContent.call(this, h),
      renderPagination.call(this, h, true)
    ]
  )
}

function renderItems(h) {
  return h(
    fileItems,
    {
      props: {
        items: this.restData[services.storage].find.data,
        order: this.restData[services.storage].find.order,
        currentChosenFileId: this.currentChosenFileId,
        readonly: this.readonly,
        embedded: this.embedded
      },
      on: {
        input: event => {
          this.$emit('input', event)
        },
        order: event => {
          this.restData[services.storage].find.order = event
        },
        dialog: event => {
          if (event.close) {
            this.googleFileURL = undefined
            this.currentEditFile = undefined
            this.editDialogType = undefined
            this.showEditDialog = false

            return
          }
          this.googleFileURL = event.googleFileURL
          this.currentEditFile = event.currentEditFile
          this.editDialogType = event.editDialogType
          this.showEditDialog = true
        }
      },
      key: 'file-manager-content'
    }
  )
}
function renderContent(h) {
  if (this.restData[services.storage].find.pagination.total) {
    return renderItems.call(this, h)
  }

  return h(components.empty)
}

function renderBodyWithDrag(h) {
  return h(
    'div',
    {
      class: {
        files__body: true,
        'files__body--loading': this.state === states.loading
      },
      on: {
        dragenter: event => {
          event.preventDefault()
          event.stopPropagation()
          this.showLoadingArea = true
        }
      }
    },
    [
      renderContent.call(this, h),
      renderPreloader.call(this, h, true),
      renderUploadFileButtonOrArea.call(this, h, true, 'drop')
    ]
  )
}

function renderInfo(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${services.storage}.hints.expire`)
      }
    }
  )
}
function renderCloseButton(h) {
  if (this.embedded) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate('misc.buttons.close'),
          mode: buttonModes.flat
        },
        on: {
          click: () => {
            this.$emit('show', false)
          }
        }
      }
    )
  }
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: {
        files__footer: true,
        'files__footer--embedded': this.embedded
      },
      slot: this.embedded ? 'footer' : undefined
    },
    [
      renderInfo.call(this, h),
      renderCloseButton.call(this, h)
    ]
  )
}

function renderFileDialog(h) {
  return h(
    fileDialog,
    {
      props: {
        value: this.currentEditFile,
        googleFileURL: this.googleFileURL,
        show: this.showEditDialog,
        type: this.editDialogType
      },
      on: {
        show: event => {
          this.showEditDialog = event
        },
        input: event => {
          this.currentEditFile = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    this.component,
    {
      props: {
        value: this.show,
        rounded: this.embedded ? this.viewport.breakpoint.mdUp : true,
        outline: !this.embedded,
        maxWidth: this.embedded ? this.viewport.breakpoint.mdUp ? 1000 : '100%' : undefined,
        closeOnClick: this.embedded && !this.loading,
        closeOnEsc: this.embedded && !this.loading
      },
      on: {
        input: event => {
          this.$emit('show', event)
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderBodyWithDrag.call(this, h),
      renderFooter.call(this, h),
      renderFileDialog.call(this, h)
    ]
  )
}

