import { DICTIONARIES, DICTIONARIES_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, icons, services } from '@/utils'

export const dictionariesStatuses = {
  [DICTIONARIES_STATUSES.created]: {
    icon: icons.bookmark_border,
    color: colors.grey
  },
  [DICTIONARIES_STATUSES.ready]: {
    icon: icons.bookmark,
    color: colors.primary
  },
  [DICTIONARIES_STATUSES.importing]: {
    icon: icons.history,
    color: colors.grey
  },
  [DICTIONARIES_STATUSES.compiling]: {
    icon: icons.autorenew,
    color: colors.grey
  },
  [DICTIONARIES_STATUSES.compiled]: {
    icon: icons.check_circle_outline,
    color: colors.primary
  },
  [DICTIONARIES_STATUSES.importError]: {
    icon: icons.highlight_off,
    color: colors.error
  },
  [DICTIONARIES_STATUSES.compileError]: {
    icon: icons.highlight_off,
    color: colors.error
  },
  [DICTIONARIES_STATUSES.activated]: {
    icon: icons.check_circle,
    color: colors.success
  },
  [DICTIONARIES_STATUSES.downloading]: {
    icon: icons.download,
    color: colors.primary
  }
}

export const dictionaryToService = type => {
  switch (type) {
    case DICTIONARIES['phone:mnp']: return services.mnp
    case DICTIONARIES['phone:ranges']: return services.prefixes
    case DICTIONARIES['phone:opgroups']: return services.operatorGroups
  }
}
