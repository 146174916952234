import { buttonModes, colors, icons, services } from '@/utils'

import button from '@/components/button'

export default function(h) {
  return h(
    button,
    {
      props: {
        icon: icons.update,
        mode: buttonModes.flat,
        color: colors.primary,
        loading: this.loading,
        disabled: this.loading,
        tooltip: this.getTranslate(`${services.yclients}.tooltips.updateStatus`)
      },
      on: {
        click: () => {
          this.updateStatus()
        }
      }
    }
  )
}
