export default {
  title: 'Вход',

  labels: {
    username: 'Логин',
    password: 'Пароль'
  },

  buttons: {
    logout: 'Выход',
    signin: 'Войти',
    signup: 'Регистрация'
  },

  errors: {
    tooManyRequests: 'Превышен лимит попыток ({limit}) ввести правильную комбинацию логин / пароль.'
  },

  warnings: {
    remainingAttempts: 'Для ввода верной комбинации логин / пароль у вас осталось {remaining} попыток из {limit}.'
  }
}
