import { permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'Item',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    to() {
      if (this.checkPermissions(`advanced.${services.requisites}.get`, permissionPresets.resellerUp)) {
        return {
          name: `${services.requisites}.single`,
          params: { id: this.value.id }
        }
      }
    }
  },

  render
}
