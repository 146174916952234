import { buttonModes, colors, components, icons, inputModes, popperPlacements, services } from '@/utils'

import button from '@/components/button'

function renderProviderSelect(h) {
  return h(
    components.select,
    {
      props: {
        value: this.providerId,
        service: services.providers,
        query: { isActive: true },
        label: this.getTranslate(`${services.sendingsModeration}.labels.defaultRoute`),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        clearable: true,
        autocomplete: true,
        details: false,
        disabled: this.disabled
      },
      on: {
        input: event => {
          this.providerId = event
        }
      }
    }
  )
}

function renderInfoButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        color: colors.grey,
        icon: icons.question_mark,
        tooltip: this.getTranslate(`${services.sendingsModeration}.tooltips.info`)
      }
    }
  )
}
function renderApproveButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        icon: this.massive ? icons.done_all : icons.done,
        color: colors.success,
        loading: this.loading,
        disabled: this.disabled,
        tooltip: this.massive
          ? this.getTranslate(`${services.sendingsModeration}.actions.massive.approved`)
          : this.getTranslate(`${services.sendingsModeration}.actions.approved`)
      },
      on: {
        click: () => {
          this.createSendingsModerationTask('approved')
        }
      }
    }
  )
}
function renderRejectButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        icon: this.icon.rejected.icon,
        color: this.icon.rejected.color,
        loading: this.loading,
        disabled: this.disabled,
        tooltip: this.massive
          ? this.getTranslate(`${services.sendingsModeration}.actions.massive.rejected`)
          : this.getTranslate(`${services.sendingsModeration}.actions.rejected`)
      },
      slot: 'activator'
    }
  )
}

function renderRejectMenuListItem(h, action) {
  return h(
    components['list-item'],
    {
      props: {
        icon: this.icon[action].icon,
        iconColor: this.icon[action].color,
        label: this.massive
          ? this.getTranslate(`${services.sendingsModeration}.actions.massive.${action}`)
          : this.getTranslate(`${services.sendingsModeration}.actions.${action}`),
        dense: true
      },
      on: {
        click: () => {
          this.createSendingsModerationTask('rejected', action === 'rejectedRefound' ? true : undefined)
        }
      }
    }
  )
}
function renderRejectMenuList(h) {
  return h(
    components.list,
    {
      props: {
        dense: true,
        rounded: true
      }
    },
    [
      renderRejectMenuListItem.call(this, h, 'rejected'),
      renderRejectMenuListItem.call(this, h, 'rejectedRefound')
    ]
  )
}
function renderRejectMenu(h) {
  return h(
    components.menu,
    {
      props: {
        value: this.showRejectMenu,
        rounded: true,
        offsetDistance: 8,
        placement: popperPlacements['bottom-end'],
        disabled: this.disabled,
        width: this.massive ? 260 : 225
      },
      on: {
        input: event => {
          this.showRejectMenu = event
        }
      }
    },
    [
      renderRejectButton.call(this, h),
      renderRejectMenuList.call(this, h)
    ]
  )
}

function renderButtons(h) {
  return [
    renderProviderSelect.call(this, h),

    h(
      'div',
      {
        class: {
          [`${services.sendingsModeration}-actions__buttons`]: true,
          'hover-child': !this.showRejectMenu
        }
      },
      [
        renderInfoButton.call(this, h),
        renderApproveButton.call(this, h),
        renderRejectMenu.call(this, h)
      ]
    )
  ]
}

function renderApproveMenuItem(h) {
  return h(
    components['list-item'],
    {
      props: {
        icon: icons.done,
        iconColor: colors.success,
        label: this.getTranslate(`${services.sendingsModeration}.actions.approved`),
        disabled: this.disabled,
        dense: true
      },
      on: {
        click: () => {
          this.createSendingsModerationTask('approved')
        }
      }
    }
  )
}

function renderMenu(h) {
  return h(
    components.menu,
    {
      props: {
        rounded: true,
        offsetDistance: 8,
        placement: popperPlacements['bottom-end'],
        closeOnContentClick: false,
        width: 250
      }
    },
    [
      h(
        button,
        {
          props: {
            mode: buttonModes.flat,
            icon: icons.more_vert
          },
          slot: 'activator'
        }
      ),

      h(
        'div',
        {
          class: 'fc pt-2 pb-1'
        },
        [
          h(
            'div',
            {
              class: 'px-2 pb-1'
            },
            [ renderProviderSelect.call(this, h) ]
          ),
          renderApproveMenuItem.call(this, h),
          renderRejectMenuList.call(this, h)
        ]
      )
    ]
  )
}

function renderContentByViewport(h) {
  if (this.viewport.size.width >= 1920 || this.massive) {
    return renderButtons.call(this, h)
  } else {
    return renderMenu.call(this, h)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${services.sendingsModeration}-actions`]: true,
        [`${services.sendingsModeration}-actions--massive`]: this.massive
      }
    },
    [ renderContentByViewport.call(this, h) ]
  )
}

