import { colors, icons, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import date from '@/components/templates/service/item/date'

function renderTitle(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        icon: icons.subtitles,
        color: colors.grey,
        label: this.getTranslate(`${services.credentials}.labels.title`),
        service: services.credentials,
        maxWidth: 200
      }
    }
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'service-template-item hover-parent hover-parent--opacity',
      style: { gridTemplateColumns: '1fr auto' }
    },
    [
      renderTitle.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
