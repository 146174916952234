import Vue from 'vue'

import { getLocaleDateString, getLocaleTimeString, getUnixSeconds, globalErrorHandler, globalErrorProcessor, hexToRgb } from '@/utils'

import render from './render'

const colors = {
  queue: '#1976d2',
  speed: '#4caf50',
  count: '#ffa726',
  delivery_rate: '#000000'
}

export default {
  name: 'Grafana',

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    presets: {
      type: Array,
      default: () => []
    },

    period: {
      type: Number,
      default: 5
    },

    hasFilter: Boolean,

    noData: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      start: getUnixSeconds() - 5 * 60,
      end: getUnixSeconds(),

      step: 1,

      labels: [],
      datasets: [],

      range: 5,

      interval: undefined,

      loading: false,
      online: false
    }
  },

  watch: {
    online() {
      if (this.online) {
        this.setInterval()
      } else {
        this.clearInterval()
      }
    }
  },

  mounted() {
    this.get()
  },

  beforeDestroy() {
    this.clearInterval()
  },

  methods: {
    async get() {
      try {
        this.loading = true

        this.refreshTimeRange()

        await Promise.all(this.presets.map(async (item, index) => {
          const { label, name, preset } = item
          const { data } = await Vue.$GRequest.get('grafana', 'range', {
            query: {
              data: this.data,
              preset,
              source: 'prometheus',
              start: this.start,
              end: this.end,
              params: { step: this.step * this.range }
            }
          })
          if (Array.isArray(data) && data.length) {
            const labels = data.map(item => {
              const uTime = item[0] * 1000

              return getLocaleDateString(uTime) + ' ' + getLocaleTimeString(uTime)
            })
            this.labels.splice(0, this.labels.length || 0, ...labels)
            this.datasets.splice(index, 1, {
              label,
              yAxisID: name,
              data: data.map(item => item[1]),
              fill: true,
              backgroundColor: `rgba(${hexToRgb(colors[name], true)}, 0.1)`,
              borderWidth: 1,
              borderColor: colors[name],
              pointBorderWidth: 0,
              pointHoverBorderWidth: 0,
              pointRadius: 2,
              pointHoverRadius: 6
            })
          }
        }))
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    refreshTimeRange() {
      const now = getUnixSeconds()
      this.start = now - this.range * 60
      this.end = now
    },
    setInterval() {
      this.interval = setInterval(() => {
        if (this.online) {
          this.get()
        }
      }, this.period * 1000)
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
      }
    }
  },

  render
}
