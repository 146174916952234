import { inputModes, permissionPresets, services } from '@/utils'

import selectorOwner from '@/components/selector/owner'
import selectorSendingType from '@/components/selector/sendingType'

export default function(h) {
  let cols = 3
  if (this.viewport.breakpoint.smUp) {
    if (!this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)) {
      cols = 2
    }
  } else {
    cols = 1
  }

  return h(
    'div',
    {
      class: `grid grid-gap--8 pa-2 grid-cols--${cols}`
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.$search,
            label: this.getTranslate('commons.labels.search'),
            mode: inputModes.outline,
            dense: true,
            rounded: true,
            details: false,
            clearable: true
          },
          on: {
            input: event => {
              this.proxy.$search = event
            }
          }
        }
      ),

      h(
        selectorSendingType,
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate(`${services.templates}.labels.type`),
            permission: `advanced.${services.templates}.get`
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          }
        }
      ),

      h(
        selectorOwner,
        {
          props: {
            value: this.proxy.OwnerId,
            mode: inputModes.outline,
            dense: true
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      )
    ]
  )
}
