export default {
  title: 'Sign In',

  labels: {
    username: 'Username',
    password: 'Password'
  },

  buttons: {
    logout: 'Logout',
    signin: 'Sign In',
    signup: 'Sign Up'
  },

  errors: {
    tooManyRequests: 'You have exceeded the limit of attempts ({limit}) to enter the correct login password combination.'
  },

  warnings: {
    remainingAttempts: 'You have {remaining} attempts left out of {limit} to enter the correct login password combination.'
  }
}
