export default {
  title: 'Словари',

  states: {
    loading: 'Загружено {count}.',
    processing: 'Обработка...',
    import: 'Импортировано {count} строк.',
    done: 'Импортировано {count} строк.'
  },

  dialogs: {
    titles: {
      remove: 'Подтверждение удаления словаря'
    },
    create: 'Создать новый ключ'
  },

  new: {
    key: 'Новый ключ'
  },

  titles: {
    'phone:mnp': 'MNP',
    'phone:ranges': 'Префиксы',
    'phone:opgroups': 'Группы операторов',
    service: {
      mnp: 'MNP',
      ranges: 'Диапазоны',
      operatorGroups: 'Группы операторов'
    },
    upload: 'Загрузка',
    import: 'Импорт',
    mix: 'Подмешать префиксы с сервера ИНТИС?',
    createGroup: 'Создать группу оператора',
    group: 'Группа',
    operator: 'Оператор',
    number: 'Номер',
    mcc: 'MCC',
    mnc: 'MNC'
  },

  labels: {
    key: 'Ключ',
    opcode: 'Код оператора',
    group: 'Группа',
    groupName: 'Имя группы',
    operatorName: 'Имя оператора',
    operator: 'Оператор',
    number: 'Номер',
    region: 'Регион',
    prefix: 'Префикс',
    mcc: 'MCC',
    mnc: 'MNC',
    type: 'Тип словаря',
    status: 'Статус',
    OwnerId: 'Владелец',
    total: 'Всего',

    ranges: {
      start: 'Начало диапазона',
      end: 'Конец диапазона'
    },

    choose: {
      dictionary: 'Выбрать словарь',
      key: 'Ввести ключ'
    },

    search: {
      keys: 'Поиск по ключам'
    }
  },

  hints: {
    noItems: 'Нет словарей для отображения',
    loading: 'Не закрывайте или не перезагружайте окно во время процесса импорта.',
    importOr: 'Или выберите файл через файловый менеджер',

    search: {
      empty: 'Для данного номер данные не найдены'
    }
  },

  captions: {
    createdAt: 'Словарь создан',
    total: 'Всего записей'
  },

  buttons: {
    compile: 'Скомпилировать',
    set: 'Активировать',
    unset: 'Деактивировать',

    add: {
      range: 'Добавить диапазон'
    }
  },

  contents: {
    confirm: {
      remove: {
        dictionary: 'Вы уверены что хотите удалить словарь {title}?',
        key: 'Вы уверены что хотите удалить ключ {title}?'
      }
    }
  },

  snackbars: {
    dictionary: {
      created: 'Словарь успешно создан.',
      compile: 'Словарь успешно скомпилирован',
      updated: 'Словарь успешно обновлён.',
      removed: 'Словарь успешно удалён.'
    },
    key: {
      created: 'Ключ в словаре успешно создан.',
      updated: 'Ключ в словаре успешно обновлён.',
      removed: 'Ключ в словаре успешно удалён.'
    },
    updated: 'Словарь успешно обновлён.'
  },

  statuses: {
    created: 'Создан',
    ready: 'Готов',
    downloading: 'Скачивается',
    importing: 'Импортируется',
    importError: 'Ошибка в процессе импорта',
    compiling: 'Компилируется',
    compiled: 'Скомпилирован',
    compileError: 'Ошибка в процессе компиляции',
    activated: 'Активирован'
  },

  tooltips: {
    upload: {
      filemanager: 'Импортировать из файла',
      intis: 'Импортировать с сервера ИНТИС'
    },
    info: 'Подробности о формате CSV файла'
  },

  filters: {
    items: {
      default: 'Системные словари'
    }
  },

  info: {
    columns: {
      Number: 'номер телефона',
      OwnerId: 'название оператора',
      MCC: 'MCC',
      MNC: 'MNC',
      NumberFrom: 'номер телефона, с которого начинается диапазон',
      NumberTo: 'номер телефона, которым заканчивается диапазон',
      RegionCode: 'код региона',
      GROUP: 'название группы',
      OrgCode: 'название оператора',
      TYPE: 'тип номера телефона (городской ABC, мобильный DEF)'
    },

    hint: 'Названия столбцов чувствительны к регистру'
  }
}
