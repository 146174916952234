import { datePresets } from '../../../enums'

export const SENDINGS_OUTGOING_FILTER = {
  id: undefined,
  groupId: undefined,
  chainId: undefined,
  type: undefined,
  payload: {
    recipient: undefined,
    sender: undefined
  },
  state: {
    status: undefined
  },
  meta: {
    _routing: {
      provider: undefined
    },
    user: {
      id: undefined
    }
  },
  $scope: [ 'full' ],
  createdAt: {
    $gt: undefined,
    $lt: undefined
  },
  createdAtPreset: datePresets.minutes['5']
}
