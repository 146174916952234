import { buttonModes, colors, components, icons, popperPlacements } from '@/utils'

import button from '@/components/button'
import buttonConfirmRemove from '@/components/button/confirm/remove'

function renderActivator(h) {
  return h(
    button,
    {
      props: {
        icon: icons.more_vert,
        mode: buttonModes.flat,
        disabled: this.$disabled || this.loading
      },
      slot: 'activator'
    }
  )
}

function renderEditItem(h) {
  if (this.actions.includes('edit')) {
    return h(
      components['list-item'],
      {
        props: {
          label: this.getTranslate('misc.buttons.edit'),
          icon: icons.edit,
          iconColor: colors.black,
          loading: this.loading,
          disabled: !this.checkPermissions(`advanced.${this.$serviceViaPoint}.update`, this.accept) || this.loading,
          dense: true
        },
        on: {
          click: () => {
            this.editClickHandler()
          }
        }
      }
    )
  }
}
function renderDefaultSlot() {
  if (typeof this.$scopedSlots.default === 'function') {
    return this.$scopedSlots.default()
  }

  return this.$slots.default
}
function renderRemoveItem(h) {
  if (this.actions.includes('remove')) {
    return h(
      buttonConfirmRemove,
      {
        props: {
          labeled: true,
          component: components['list-item'],
          loading: this.loading,
          disabled: !this.checkPermissions(`advanced.${this.$serviceViaPoint}.remove`, this.accept) || this.loading,
          callback: () => this.remove()
        }
      }
    )
  }
}
function renderItems(h) {
  return [
    renderEditItem.call(this, h),
    renderDefaultSlot.call(this, h),
    renderRemoveItem.call(this, h)
  ].filter(Boolean)
}
function renderList(h) {
  return h(
    components.list,
    {
      props: {
        dense: true,
        rounded: true
      }
    },
    [ renderItems.call(this, h) ]
  )
}

export default function(h) {
  if (this.value.id) {
    return h(
      components.menu,
      {
        class: { 'hover-child': this.viewport.breakpoint.mdUp && !this.showMenu },
        props: {
          value: this.showMenu,
          disabled: this.$disabled,
          rounded: true,
          offsetDistance: 8,
          placement: popperPlacements['bottom-end']
        },
        on: {
          input: event => {
            this.showMenu = event
          }
        }
      },
      [
        renderActivator.call(this, h),
        renderList.call(this, h)
      ]
    )
  }
}
