import { sizes } from '@/utils'

import tag from '@/components/tag'

function renderCount(h) {
  if (this.isMany) {
    return h(
      tag,
      {
        props: {
          label: `+${this.values.length - this.count}`,
          size: sizes.tiny,
          tooltip: this.values.reduce((result, { mcc, mnc, country, operator }, index) => {
            if (index < 20) {
              if (country && operator) {
                result.push(`${country} | ${operator}`)
              } else {
                result.push(`${mcc} | ${mnc}`)
              }
            }

            return result
          }, []).join('\n')
        }
      }
    )
  }
}

function renderItem(h, { mcc, mnc, country, operator }) {
  let label = `${mcc} | ${mnc}`
  if (country && operator) {
    label = `${country} | ${operator}`
  }

  return h(
    tag,
    {
      props: {
        label,
        size: sizes.tiny
      },
      key: `${mcc}:${mnc}`
    }
  )
}

function renderItems(h) {
  return this.values.slice(0, this.count).map(item => renderItem.call(this, h, item))
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'routes-conditions__condition-value': true,
        link: this.isMany
      },
      on: {
        click: () => {
          if (this.isMany) {
            this.$emit('click', true)
          }
        }
      }
    },
    [
      renderItems.call(this, h),
      renderCount.call(this, h)
    ]
  )
}
