export default {
  title: 'HLR',

  labels: {
    search: 'Enter phone number',
    status: 'Search status'
  },

  hints: {
    empty: 'Here will be found data by number'
  }
}
