import { TASK_FREQUENCY } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, inputModes, popperPlacements, services, sizes } from '@/utils'

import button from '@/components/button'
import sticker from '@/components/misc/sticker'
import selectorOwner from '@/components/selector/owner'
import billingsFormBasic from '@/components/services/billings/form/basic'

function renderTitleField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.title,
        label: this.getTranslate(`${services.tasks}.labels.title`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        required: true,
        autofocus: true,
        clearable: true,
        details: false,
        mask: /^.{0,255}$/
      },
      on: {
        input: event => {
          this.proxy.title = event
        }
      }
    }
  )
}

function renderBillingsFormBasic(h) {
  return h(
    billingsFormBasic,
    {
      props: {
        value: this.proxy.data,
        currency: this.currency
      },
      on: {
        input: event => {
          this.proxy.data = event
        }
      }
    }
  )
}

function renderFrequency(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.data.frequency,
        label: this.getTranslate(`${services.tasks}.labels.data.frequency`),
        items: TASK_FREQUENCY.reduce((result, frequency) => {
          if (frequency !== TASK_FREQUENCY.weekly) {
            result.push({
              title: this.getTranslate(`${services.tasks}.frequencies.${frequency}`),
              value: frequency
            })
          }

          return result
        }, []),
        required: true,
        mode: inputModes['line-label'],
        rounded: true,
        dense: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.data.frequency = event
        }
      }
    }
  )
}

function renderDate(h) {
  return h(
    components.menu,
    {
      props: {
        rounded: true,
        offsetDistance: 8,
        placement: popperPlacements['bottom-end'],
        closeOnContentClick: false
      }
    },
    [
      h(
        sticker,
        {
          props: {
            value: this.$date.title,
            label: this.$date.label,
            width: '100%',
            icon: icons.event,
            color: colors.grey,
            to: { service: undefined }
          },
          slot: 'activator'
        }
      ),
      h(
        components['date-picker'],
        {
          props: {
            value: this.$date.value,
            localeTag: this.locale,
            range: this.proxy.data.frequency !== TASK_FREQUENCY.once,
            min: this.$date.min
          },
          on: {
            input: event => {
              if (this.proxy.data.frequency === TASK_FREQUENCY.once) {
                this.proxy.startsAt = event
              } else {
                this.proxy.startsAt = event[0]
                this.proxy.data.endsAt = event[1]
              }
            }
          }
        }
      ),
      h(
        'div',
        {
          class: 'fjcfe pa-2'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.clear'),
                size: sizes.small,
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.proxy.startsAt = undefined
                  this.proxy.data.endsAt = undefined
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderSelectorOwner(h) {
  return h(
    selectorOwner,
    {
      props: {
        value: this.proxy.OwnerId,
        itemsDisabled: [ this.account.id ],
        dense: true,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    components.form,
    {
      class: `${services.tasks}-form`
    },
    [
      renderTitleField.call(this, h),
      renderBillingsFormBasic.call(this, h),
      renderFrequency.call(this, h),
      renderDate.call(this, h),
      renderSelectorOwner.call(this, h)
    ]
  )
}
