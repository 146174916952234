import tag from '@/components/tag'

export default function(h) {
  return h(
    tag,
    {
      props: this.$props,
      on: {
        mouseenter: () => {
          this.getContacts()
        }
      }
    }
  )
}
