import { generateUniqueKey } from '@/utils'

import componentNotFound from '@/components/misc/componentNotFound'

export default function(h) {
  try {
    return h(
      require(`@/components/services/${this.service}/item`).default,
      {
        props: { value: this.value },
        key: generateUniqueKey()
      }
    )
  } catch (error) {
    return h(componentNotFound)
  }
}
