import { get } from 'lodash'
import { createPopper } from '@popperjs/core'

import { components } from '@/utils'

const defaultOptions = {
  placement: 'top',
  strategy: 'absolute',
  modifiers: [
    {
      name: 'offset',
      options: { offset: [ 0, 8 ] }
    },
    {
      name: 'preventOverflow',
      options: { padding: 8 }
    }
  ]
}

const showEvents = [ 'mouseenter', 'focus' ]
const hideEvents = [ 'mouseleave', 'blur' ]

let popperInstance
let timeoutInstance
let intervalInstance

const destroyTimeout = () => {
  if (timeoutInstance) {
    clearTimeout(timeoutInstance)
    timeoutInstance = undefined
  }
}
const destroyInterval = () => {
  if (intervalInstance) {
    clearInterval(intervalInstance)
    intervalInstance = undefined
  }
}
const destroyPopper = () => {
  if (popperInstance) {
    popperInstance.destroy()
    popperInstance = undefined
  }
}

const toggle = (value, tooltip, trigger) => {
  tooltip.innerHTML = value
  if (trigger) {
    tooltip.style.display = 'inline-block'
    tooltip.style.visibility = 'visible'
    tooltip.dataset.popperReferenceHidden = 'false'
  } else {
    tooltip.style.display = 'none'
    tooltip.style.visibility = 'hidden'
    tooltip.dataset.popperReferenceHidden = 'true'
  }
}

const hide = (element, tooltip) => {
  if (element && tooltip) {
    toggle('', tooltip, false)

    destroyTimeout()
    destroyInterval()
    destroyPopper()
  }
}
const destroy = (element, tooltip) => {
  if (element && tooltip) {
    if (timeoutInstance) {
      setupTimeout(element, tooltip)
    } else {
      hide(element, tooltip)
    }
  }
}

const setupTimeout = (element, tooltip, timeout) => {
  if (timeout) {
    destroyTimeout()
    destroyInterval()

    let remainingTime = timeout / 1000
    let countdownElement = tooltip.querySelector('.g-tooltip-countdown')
    if (!countdownElement) {
      countdownElement = document.createElement('div')
      countdownElement.className = 'g-tooltip-countdown'
      tooltip.appendChild(countdownElement)
    }
    countdownElement.style.width = '0%'

    const step = 100 / (timeout / 1000)

    const finish = () => {
      destroyInterval()
      countdownElement.style.width = '100%'
      setTimeout(() => hide(element, tooltip), 1000)
    }

    intervalInstance = setInterval(() => {
      remainingTime -= 1
      if (remainingTime <= 0) {
        finish()
      } else {
        countdownElement.style.width = `${100 - remainingTime * step}%`
      }
    }, 1000)

    timeoutInstance = setTimeout(() => finish(), timeout)
  }
}

const create = (element, tooltip) => {
  destroyTimeout()

  if (element && tooltip) {
    if (popperInstance) {
      destroy(element, tooltip)
    }

    const value = get(element, '$tooltipOptions.value')
    const timeout = get(element, '$tooltipOptions.timeout')
    if (value) {
      toggle(value, tooltip, true)

      if (/\S{30,}/.test(value)) {
        tooltip.style.wordBreak = 'break-all'
      } else {
        tooltip.style.wordBreak = undefined
      }

      if (timeout) {
        setupTimeout(element, tooltip, timeout)

        tooltip.addEventListener('mouseenter', () => {
          destroyTimeout()
          destroyInterval()
        })
        tooltip.addEventListener('mouseleave', () => {
          if (!timeoutInstance) {
            setupTimeout(element, tooltip, timeout)
          }
        })
      }
    }

    if (!popperInstance) {
      popperInstance = createPopper(element, tooltip, element.$tooltipOptions)
    } else {
      popperInstance.setOptions(element.$tooltipOptions)
      popperInstance.update()
    }
  }
}

const getOptionsFromDirectives = tooltip => {
  const options = { modifiers: defaultOptions.modifiers }

  if (tooltip) {
    if (tooltip.value && typeof tooltip.value === 'string') {
      options.value = tooltip.value
    }

    if (tooltip.options && typeof tooltip.options === 'object') {
      const { placement, strategy, value, timeout, offsetDistance, offsetSkidding } = tooltip.options

      options.placement = placement || defaultOptions.placement
      options.strategy = strategy || defaultOptions.strategy

      if (value && typeof value === 'string') {
        options.value = value
      }
      if (timeout && typeof timeout === 'number') {
        options.timeout = timeout
      }

      if (offsetDistance || offsetSkidding) {
        options.modifiers.push({
          name: 'offset',
          options: {
            offset: [ offsetSkidding || 0, offsetDistance || 0 ]
          }
        })
      }
    }
  }

  return options
}

export default {
  install: Vue => {
    Vue.directive(
      components.tooltip,
      {
        bind(element) {
          const tooltip = document.getElementById(components.tooltip)

          const showListener = () => create(element, tooltip)
          const hideListener = () => destroy(element, tooltip)

          showEvents.forEach(event => element.addEventListener(event, showListener))
          hideEvents.forEach(event => element.addEventListener(event, hideListener))
        },

        inserted(element, tooltip) {
          element.$tooltipOptions = getOptionsFromDirectives(tooltip)
        },

        update(element, tooltip) {
          element.$tooltipOptions = getOptionsFromDirectives(tooltip)

          if (popperInstance) {
            const value = get(element, '$tooltipOptions.value')
            if (value) {
              tooltip.innerHTML = value
              popperInstance.setOptions(element.$tooltipOptions)
              popperInstance.update()
            }
          }
        },

        unbind(element) {
          destroy(element, document.getElementById(components.tooltip))
          element.$tooltipOptions = undefined
        }
      }
    )
  }
}
