import { colors, components, icons, isSingleDate, popperPlacements } from '@/utils'

import time from '@/components/filter/date/time'
import sticker from '@/components/misc/sticker'
import filterDatePreset from '@/components/filter/date/preset'

function renderPresetPicker(h) {
  if (this.presets) {
    return h(
      filterDatePreset,
      {
        props: {
          value: this.preset
        },
        on: {
          input: this._presetInputHandler
        }
      }
    )
  }
}
function renderDatePicker(h) {
  return h(
    components.card,
    {
      props: {
        title: this.getTranslate('filter.date.title'),
        outline: true,
        rounded: true
      }
    },
    [
      h(
        components['date-picker'],
        {
          props: {
            value: this.date,
            localeTag: this.locale,
            disabled: this.disabled,
            min: this.min,
            max: this.max,
            yearMin: 2015,
            yearMax: new Date().getFullYear()
          },
          on: {
            input: this._inputHandler,
            pick: this._pickHandler
          }
        }
      )
    ]
  )
}
function renderTimePicker(h) {
  return h(
    time,
    {
      props: {
        value: this.proxy,
        disabled: this.disabled,
        isSingleDate: isSingleDate(this.proxy.date)
      },
      on: {
        input: value => {
          this.proxy = value
        }
      }
    }
  )
}
function renderPickers(h) {
  return h(
    'div',
    {
      class: 'filter-date-time__pickers'
    },
    [
      renderPresetPicker.call(this, h),
      renderDatePicker.call(this, h),
      renderTimePicker.call(this, h)
    ]
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'filter-date-time__content'
    },
    [ renderPickers.call(this, h) ]
  )
}

function renderActivator(h) {
  return h(
    sticker,
    {
      props: {
        value: this.activatorContent,
        label: this.getTranslate(`filter.date.labels.${this.label}`),
        icon: icons.date_range,
        color: colors.grey,
        backgroundColor: colors.white,
        disabled: this.disabled,
        width: '100%',
        to: { service: undefined }
      },
      slot: 'activator'
    }
  )
}
function renderMenu(h) {
  return h(
    components.menu,
    {
      props: {
        closeOnContentClick: false,
        offsetDistance: 8,
        placement: popperPlacements['bottom-end'],
        rounded: true
      }
    },
    [
      renderActivator.call(this, h),
      renderContent.call(this, h)
    ]
  )
}

export default function(h) {
  if (this.asIs) {
    return renderContent.call(this, h)
  }

  return renderMenu.call(this, h)
}
