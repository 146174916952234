import Vue from 'vue'

import { get } from 'lodash'

import { colors, components, services } from '@/utils'

import buttonToolbar from '@/components/button/toolbar'
import buttonSupport from '@/components/button/support'
import buttonPayment from '@/components/button/payment'
import buttonNotifications from '@/components/button/notifications'
import buttonAccount from '@/components/button/account'
import clock from '@/components/misc/clock'
import navigationList from '@/components/misc/navigationList'
import UserWidget from '@/components/misc/UserWidget'
import MobilePanel from '@/components/misc/MobilePanel'
import LocaleSwitch from '@/components/misc/LocaleSwitch'
import JivoSite from '@/components/misc/JivoSite'
import AudioPlayer from '@/components/misc/AudioPlayer'
import fileInput from '@/components/file/input'
import Copyright from '@/components/misc/copyright'
import wsConnection from '@/components/misc/ws/connection'
import globalDialog from '@/components/global/dialog'
import globalPaymentDialog from '@/components/global/dialog/payment'

function renderPaymentButton(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(buttonPayment)
  }
}
function renderSupportButton(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(buttonSupport)
  }
}
function renderNotificationsButton(h) {
  return h(buttonNotifications)
}
function renderAccountButton(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(buttonAccount)
  }
}
function renderClock(h) {
  return h(clock)
}
function renderButtons(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic',
      style: this.buttonsStyle
    },
    [
      renderSupportButton.call(this, h),
      renderPaymentButton.call(this, h),
      renderNotificationsButton.call(this, h),
      renderAccountButton.call(this, h),
      renderClock.call(this, h)
    ]
  )
}
function renderBackButton(h, color) {
  if (
    this.viewport.breakpoint.smDown &&
    this.$route.matched.length > 1
  ) {
    return h(
      buttonToolbar,
      {
        props: {
          iconSVG: 'back-outline',
          color
        },
        on: {
          click: () => {
            this.$router.go(-1)
          }
        }
      }
    )
  }
}
function renderHomeButton(h, color) {
  return h(
    buttonToolbar,
    {
      props: {
        iconSVG: 'home',
        color
      },
      on: {
        click: () => {
          if (this.viewport.breakpoint.smDown) {
            this.showSidebar = false
          }
          Vue.router.push({ name: 'home' })
        }
      }
    }
  )
}
function renderSideButton(h, color) {
  return h(
    buttonToolbar,
    {
      props: {
        iconSVG: this.showSidebar ? 'left-side' : 'right-side',
        color
      },
      on: {
        click: () => {
          this.showSidebar = !this.showSidebar
          if (window.jivo_api) {
            window.jivo_api.close()
          }
        }
      }
    }
  )
}
function renderToolbarTitle(h) {
  if (this.viewport.breakpoint.smUp) {
    return h(
      'router-link',
      {
        class: 'toolbar-title',
        props: { to: { name: this.$route.name } }
      },
      [ this.getTranslate(`${this.pathToTitle}.title`) ]
    )
  }
}
function renderSwaggerButton(h, color) {
  if (process.env.NODE_ENV === 'development') {
    const includes = [ services.invoices, services['otp-settings'] ]
    const name = this.$route.path.split('/')[1]

    if (includes.includes(name)) {
      return h(
        buttonToolbar,
        {
          props: {
            iconSVG: 'cloud-share',
            color
          },
          on: {
            click: () => {
              window.open(`/api/n/${name}/api`, '_blank')
            }
          }
        }
      )
    }
  }
}
function renderToolbarLeftSide(h, trigger, paddings, color) {
  if (trigger) {
    return h(
      'div',
      {
        class: {
          'grid grid-gap--8 faic fjcfs': true,
          'pa-2': paddings
        },
        style: {
          'grid-template-columns': 'repeat(5, auto)',
          'min-height': '64px'
        }
      },
      [
        renderBackButton.call(this, h, color),
        renderHomeButton.call(this, h, color),
        renderSideButton.call(this, h, color),
        renderToolbarTitle.call(this, h),
        renderSwaggerButton.call(this, h, color)
      ]
    )
  }
}
function renderToolbar(h) {
  return h(
    components.toolbar,
    {
      class: this.viewport.breakpoint.smUp ? 'px-3' : 'px-2',
      props: { fixed: true }
    },
    [
      renderToolbarLeftSide.call(this, h, true),
      h('div', { class: 'ff' }),
      renderButtons.call(this, h)
    ]
  )
}

function renderUserWidget(h) {
  if (this.viewport.breakpoint.smDown) {
    return [
      h(
        UserWidget,
        {
          props: {
            flat: true,
            overdraft: get(this.globalSettings, 'billings.overdraft', 0)
          }
        }
      ),
      h('g-divider')
    ]
  }
}
function renderSupportLink(h) {
  const contacts = get(this.globalSettings, 'contacts')
  if (contacts) {
    return h(
      'router-link',
      {
        class: 'link',
        props: { to: { name: 'support' } },
        nativeOn: {
          click: () => {
            if (this.viewport.breakpoint.smDown) {
              this.showSidebar = false
            }
          }
        }
      },
      this.getTranslate('misc.links.support')
    )
  }
}
function renderDebugLink(h) {
  return h(
    'router-link',
    {
      class: 'link',
      props: { to: { name: 'debug' } },
      nativeOn: {
        click: () => {
          if (this.viewport.breakpoint.smDown) {
            this.showSidebar = false
          }
        }
      }
    },
    this.getTranslate('debug.title')
  )
}
function renderPlaygroundLink(h) {
  if (process.env.NODE_ENV === 'development') {
    return h(
      'router-link',
      {
        class: 'link',
        props: { to: { name: 'playground' } }
      },
      this.getTranslate('playground.title')
    )
  }
}
function renderSidebar(h) {
  return h(
    components.sidebar,
    {
      props: {
        value: this.showSidebar,
        fixed: true,
        mobile: this.viewport.breakpoint.smDown
      },
      on: {
        input: event => {
          this.showSidebar = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'fc ff h--100'
        },
        [
          renderToolbarLeftSide.call(this, h, this.viewport.breakpoint.smDown, true, colors.primary),
          h(components.divider),
          renderUserWidget.call(this, h),

          h(
            navigationList,
            {
              class: 'sidebar',
              on: {
                click: event => {
                  this.showSidebar = event
                }
              }
            }
          ),

          h('div', { class: 'ff' }),

          h(
            'div',
            {
              class: 'grid grid-gap--8 pa-2'
            },
            [
              h(
                'div',
                {
                  class: 'flex flex-wrap grid-gap--8 fjcfs caption'
                },
                [
                  h(LocaleSwitch),

                  renderSupportLink.call(this, h),
                  renderDebugLink.call(this, h),
                  renderPlaygroundLink.call(this, h)
                ]
              ),
              h(Copyright)
            ]
          ),

          h('g-divider'),
          h(wsConnection)
        ]
      )
    ]
  )
}

function renderContent(h) {
  return h(
    components.content,
    {},
    [ this.$slots.default ]
  )
}

export default function(h) {
  return h(
    components.app,
    {},
    [
      renderToolbar.call(this, h),
      renderSidebar.call(this, h),
      renderContent.call(this, h),

      h(JivoSite),
      h(MobilePanel),
      h(AudioPlayer),
      h(fileInput),
      h(globalDialog),
      h(globalPaymentDialog)
    ]
  )
}
