export const types = {
  sms: 'SMS',
  smsping: 'SMS ping',
  mms: 'MMS',
  viber: 'Viber',
  voice: 'Voice',
  whatsapp: 'WhatsApp',
  email: 'E-mail',
  vk: 'VK',
  ok: 'OK',
  push: 'Push',
  flashcall: 'Flash call',
  telegram: 'Telegram',
  tgBot: 'Telegram bot',
  facebook: 'Facebook',
  instagram: 'Instagram',
  whatsappchat: 'Chat WhatsApp',
  viberchat: 'Chat Viber',
  vkchat: 'Chat VK',
  telegramchat: 'Chat Telegram',
  facebookchat: 'Chat Facebook',
  subtract: 'Subtract',
  accrual: 'Accrual',
  web: 'Web',
  incoming_wapi: 'Incoming WAPI',
  mobileid_mts_push: 'Mobile ID MTS Push',
  mobileid_mts_push_sms: 'Mobile ID MTS Push SMS',
  incoming_tg_bot: 'Incoming Telegram bot',
  hlr: 'HLR',
  apisender: 'WAPI',
  web: 'Web',
  tgBot: 'Telegram bot',
  accrual: 'Accrual',
  subtract: 'Subtract',

  subscription: {
    apisender: 'WAPI'
  }
}

export const labels = {
  title: 'Title',
  message: 'Message',
  recipient: 'Recipient',
  type: 'Type',
  file: 'File',
  sender: 'Sender name',
  sendername: 'Sender name',
  allowSending: 'Allow sending type',
  text: 'Text',
  image: 'Image URL',
  images: 'Images',
  hash: 'Hash',
  service: 'Service',
  provider: 'Provider',
  defaultRoute: 'Default route',
  ip: 'IP',
  hostname: 'Hostname',
  headers: 'Headers',
  body: 'Body',
  params: 'Parameters',
  request: 'Request',
  response: 'Response',
  server: 'Server',
  method: 'Method',
  uri: 'Uri',
  statusCode: 'Status code',
  preset: 'Preset',
  encoding: 'Encoding',
  asOwner: 'On behalf of the owner of the message',
  button: {
    subheader: 'Button',
    url: 'Button URL',
    text: 'Button text',
    action: 'Button action'
  },
  target: 'no targets | one target | {count} targets',
  targets: {
    include: 'Phone numbers to include (with + prefix)',
    exclude: 'Phone numbers to exclude (with + prefix)',
    info: 'Choose contact lists or type in phone numbers with + prefix (up to 100 numbers)'
  },
  schedule: {
    delay: 'Start at',
    until: 'Stretch until'
  },
  $options: {
    onStatus: 'On status',
    onTimeout: 'On timeout',
    onIncomingPayload: 'On incoming payload'
  },
  options: {
    status: 'Previous message received status',
    timeout: 'Time passed',
    except: 'And previous message status is not equal',
    onIncomingPayload: {
      text: 'If the text of the incoming message is'
    }
  },
  template: 'Template',
  id: 'ID',
  chainId: 'Chain ID',
  groupId: 'Group ID',
  owner: 'Owner',
  Owner: 'Owner',
  OwnerId: 'Owner',
  TariffId: 'Tariff',
  dursec: 'Call (total)',
  billsec: 'Call (billing)',
  pattern: 'Pattern',
  status: 'Status',
  time: 'Time',
  source: 'Source',
  amount: 'Amount',
  payload: {
    sender: 'Sender',
    recipient: 'Recipient',
    text: 'Text',
    image: 'Image',
    file: 'File',
    tts: 'TTS',
    button: {
      url: 'Button URL',
      text: 'Button text'
    }
  },
  state: {
    detailed: {
      ids: 'Detailed IDs'
    },
    status: 'Status'
  },
  meta: {
    _recipientData: 'Operator',
    _routing: {
      provider: 'Provider'
    },
    billing: {
      id: 'Billing ID',
      amount: 'Amount'
    },
    billings: {
      ids: 'Billing ID'
    },
    stats: {
      segments: 'Segments',
      characters: 'Characters'
    },
    replacement: {
      payload: {
        sender: 'Sender replacement',
        text: 'Text replacement'
      }
    }
  },
  data: {
    price: 'Price',
    multiplier: 'Multiplier'
  },

  external: {
    id: 'External ID'
  }
}

export default {
  title: 'Detailed report',

  sending: 'Sending',
  sendings: 'Sendings',
  new: 'New sending',
  noData: 'No sendings found',
  targets: 'Targets',
  messages: 'Messages',
  preview: 'Preview',
  create: 'Create new sending',
  type: 'Sending type',
  uploadImageText: 'Drag an image here<br>or<br>click to choose file',
  getImageKey: 'Get image key',
  count: 'no new sendings | one new sending | {count} new sendings',
  insert: 'Insert',

  labels,

  headers: {
    status: 'Status',
    owner: 'Owner',
    provider: 'Provider',
    type: 'Type',
    recipient: 'Recipient',
    sender: 'Sender',
    text: 'Text',
    amount: 'Amount',
    operator: 'Operator',
    count_sent: 'In&nbsp;progress',
    count_delivered: 'Delivered',
    percent_delivered: 'Percent delivered',
    count_seen: 'Seen',
    count_failed: 'Failed',
    count_total: 'Total',
    opgroup: 'Operator&nbsp;group',
    operatorGroup: 'Operator group',
    mcc: 'mcc',
    mnc: 'mnc',
    sending: 'Message to',
    fallback: 'Fallback to',
    tariff: 'Tariff',
    from: 'From',
    billing_tariff_price: 'Price',
    billing_sum_all: 'Sum&nbsp;of&nbsp;all',
    billing_sum_pending_complete: 'Sum completed',
    billing_sum_refunded_invalid: 'Sum refunded',
    updatedAt: 'Updated at',
    createdAt: 'Created at'
  },

  titles: {
    preview: 'Preview',
    filter: 'Filter',
    sudoData: 'Data',
    resend: 'Resend',
    incoming: {
      details: 'Incoming messages'
    },
    replacement: {
      original: 'Original',
      rule: 'By rule',
      provider: 'By provider'
    }
  },

  filter: {
    attributes: 'Attributes',
    id: 'ID',
    groupId: 'Group ID',
    chainId: 'Chain ID',
    title: 'Filter',
    status: 'Status',
    type: 'Type',
    OwnerId: 'Owner',
    from: 'From',
    pattern: 'Pattern',
    payload: {
      recipient: 'Recipient',
      sender: 'Sender name'
    },
    meta: {
      _routing: {
        provider: 'Provider'
      },
      user: {
        id: 'Owner'
      }
    },
    state: {
      status: 'Status'
    },
    date: {
      from: 'Date from',
      by: 'Date by'
    },
    time: {
      from: 'Time from',
      by: 'Time by'
    }
  },

  hints: {
    date: {
      from: 'YYYY-MM-DD',
      by: 'YYYY-MM-DD'
    },
    time: {
      from: 'HH:MM',
      by: 'HH:MM'
    },
    button: {
      url: 'Max 2048 symbols, example: http://www.example.com',
      text: 'Max 30 symbols'
    },
    text: 'View full text of message',
    empty: 'To get data use the filter.',
    remoteReport: 'Your report has been sent to the email you specified during registration.',
    sale: 'Sale',
    purchase: 'Purchase',
    has: {
      replacement: 'Has replacement'
    },
    resend: 'By default, the message will be sent on behalf of the current user.',
    apisender: 'Found instance bound to sendername:',
    replacement: {
      original: 'Original message payload',
      rule: 'Message rules replacement results',
      provider: 'Message payload sent to provider'
    },
    warning: 'To send a test SMS without moderation, <strong>do not change it</strong>!<br><br>To send messages with any text, contact your manager or technical support.'
  },

  presets: {
    sendings: {
      details: 'Sendings details',
      group001: 'Group by sending type',
      group002: 'Group by operator group and provider',
      group003: 'Dashboard report'
    }
  },

  statistic: {
    title: 'Statistic',
    symbols: 'Symbols: ',
    parts: 'Parts: ',
    segment: 'Segments: '
  },

  statuses: {
    pending: 'Pending',
    paused: 'Paused',
    processing: 'Processing',
    sent: 'Sent',
    delivered: 'Delivered',
    seen: 'Seen',
    failed: 'Failed'
  },

  report: {
    download: 'Download',
    get: 'Get report'
  },

  attributes: {
    id: 'ID',
    chainId: 'Chain ID',
    type: 'Type',
    userId: 'User',
    providerId: 'Provider',
    operatorCode: 'Operator code',
    operatorGroup: 'Operator group',
    text: 'Text',
    sender: 'Sender',
    recipient: 'Recipient',
    error: 'Error',
    status: 'Status'
  },

  status: {
    succeed: 'Succeed',
    failed: 'Failed',
    pending: 'Pending',
    processing: 'Processing',
    queued: 'Queued',
    paused: 'Paused',
    sent: 'Sent',
    processed: 'Processed',
    delivered: 'Delivered',
    seen: 'Seen'
  },

  subheaders: {
    scheduling: 'Scheduling',
    options: 'Resend conditions',
    resend: 'Send this message only if:'
  },

  confirm: {
    send: {
      title: 'You are about to send ',
      count: 'no message | one message | {count} messages',
      text: 'Be sure to check recipient, sender, text and other info before continuing.',
      loading: 'We are preparing to send your messages. This may take a minute.'
    }
  },

  tooltips: {
    preview: 'Message preview',
    count: '{count} messages',
    save: 'Save as template',
    planning: 'Planning',
    insert: 'Insert variable',
    billing_sum_pending_complete: 'Sum of pending and completed',
    billing_sum_refunded_invalid: 'Sum of refunded and invalid',
    refresh: 'Refresh table data using the selected filter.',
    segments: 'Message length | Segments',
    billSec: 'Tariff seconds',
    approve: 'Approve',
    reject: 'Reject',
    moderation: 'Moderation',

    tasks: {
      filter: {
        owner: 'Go to the outgoing tab and add the owner of the current task to the filter'
      }
    }
  },

  filterMenu: {
    any: 'Any data',
    string: 'Russian characters without spaces',
    phone: 'Phone number',
    email: 'E-mail',
    date: 'Date dd.mm.yy'
  },

  catchSymbolsMenu: {
    symbols: 'Symbols',
    numbers: 'Numbers',
    symbolsNumbers: 'Symbols or numbers',
    symbolsNumbersSpaces: 'Symbols, numbers or spaces',
    russianSymbols: 'Russian symbols',
    russianSymbolsNumber: 'Russian symbols or numbers',
    russianSymbolsNumbersSpaces: 'Russian symbols, numbers or spaces'
  },

  tabs: {
    format: 'Format',
    source: 'Source',
    create: 'New message',
    templates: 'Templates',
    sendings: 'Sendings',
    outgoing: 'Outgoing',
    incoming: 'Incoming',
    tasks: 'Tasks',
    logs: 'Logs',
    routing: 'Routes',
    billings: 'Billings',
    fallbacks: 'Fallbacks',
    replacement: 'Replacement',
    sendingsModeration: 'Moderation',
    eventsHistory: 'History of changes'
  },

  types,

  contents: {
    noAllowedSendingTypes: 'Currently you cannot send any types of messages.<br>Please contact administrator to set up your account.',
    noPattern: 'Pattern not found',
    confirm: {
      resend: 'Confirm message resending.'
    }
  },

  errors: {
    payload: {
      button: {
        url: 'The url field fails to match the required pattern!'
      }
    },
    recipients: {
      include: 'Recipients is required'
    },
    button: {
      url: 'Character limit exceeded',
      text: 'Character limit exceeded'
    }
  },

  schedule: {
    until: {
      0: 'Don\'t stretch',
      1: 'Stretch for 1 hour',
      2: 'Stretch for 2 hours',
      3: 'Stretch for 3 hours',
      4: 'Stretch for 4 hours',
      5: 'Stretch for 5 hours',
      6: 'Stretch for 6 hours',
      7: 'Stretch for 7 hours',
      8: 'Stretch for 8 hours',
      9: 'Stretch for 9 hours',
      10: 'Stretch for 10 hours',
      11: 'Stretch for 11 hours',
      12: 'Stretch for 12 hours'
    }
  },

  buttons: {
    refresh: 'Refresh'
  },

  snackbars: {
    created: 'Sending successfully created.',
    updated: 'Sending successfully updated.',
    removed: 'Sending successfully removed.'
  }
}
