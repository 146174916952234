import { EVENTSHISTORY_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { components, inputModes, services } from '@/utils'

import selectorOwner from '@/components/selector/owner'

export default function(h) {
  return h(
    'div',
    {
      class: `${services.eventsHistory}-filter`
    },
    [
      h(
        components['text-field'],
        {
          props: {
            value: this.proxy.entityId,
            label: this.getTranslate(`${services.eventsHistory}.labels.entityId`),
            clearable: true,
            mode: inputModes.outline,
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.entityId = event
            }
          }
        }
      ),
      h(
        selectorOwner,
        {
          props: {
            value: this.proxy.userId,
            mode: inputModes.outline,
            dense: true
          },
          on: {
            input: event => {
              this.proxy.userId = event
            }
          }
        }
      ),
      h(
        components.select,
        {
          props: {
            value: this.proxy.service,
            label: this.getTranslate(`${services.eventsHistory}.labels.service`),
            items: this.selectItems,
            clearable: true,
            mode: inputModes.outline,
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.service = event
            }
          }
        }
      ),
      h(
        components.select,
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate(`${services.eventsHistory}.labels.type`),
            items: EVENTSHISTORY_TYPES.map(type => ({
              title: this.getTranslate(`${services.eventsHistory}.types.${type}`),
              value: type
            })),
            clearable: true,
            mode: inputModes.outline,
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          }
        }
      )
    ]
  )
}
