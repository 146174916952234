import { get } from 'lodash'

import { components, inputModes, permissionPresets, safeNameRegex, services, usernameRegexSoft } from '@/utils'

import PasswordField from '@/components/misc/PasswordField'

function renderIsActiveField(h) {
  return h(
    components.switch,
    {
      props: {
        value: this.proxy.isActive,
        label: this.getTranslate('misc.isActive')
      },
      on: {
        input: event => {
          this.proxy.isActive = event
        }
      }
    }
  )
}
function renderHasContractField(h) {
  if (this.proxy.data && this.proxy.data.partnership) {
    return h(
      components.switch,
      {
        props: {
          value: this.proxy.data.partnership.hasContract,
          label: this.getTranslate(`${services.users}.labels.data.partnership.hasContract`)
        },
        on: {
          input: event => {
            this.proxy.data.partnership.hasContract = event
          }
        }
      }
    )
  }
}
function renderFlags(h) {
  if (this.checkPermissions(`advanced.${services.users}.update`, permissionPresets.resellerUp)) {
    return h(
      'div',
      {
        class: {
          'grid grid-gap--8': true,
          'grid-cols--2': this.viewport.breakpoint.mdUp
        }
      },
      [
        h(
          'div',
          {
            class: {
              'border border--rounded faic grid grid-gap--8 pa-2': true,
              'grid-cols--2': this.viewport.breakpoint.mdUp
            }
          },
          [
            renderIsActiveField.call(this, h),
            renderHasContractField.call(this, h)
          ]
        )
      ]
    )
  }
}

function renderUsernameField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.username,
        label: this.getTranslate(`${services.users}.labels.username`),
        error: this.errors.username,
        mode: inputModes['line-label'],
        disabled: this.checkPermissions(`advanced.${services.users}.update`, permissionPresets.me),
        trim: true,
        dense: true,
        rounded: true,
        required: true,
        clearable: true,
        tabindex: 1,
        mask: usernameRegexSoft
      },
      on: {
        input: event => {
          this.proxy.username = event
        }
      }
    }
  )
}
function renderPasswordField(h) {
  return h(
    'div',
    {
      class: 'grid faifs'
    },
    [
      h(
        PasswordField,
        {
          props: {
            value: this.proxy.password,
            isNew: this.isNew
          },
          on: {
            input: event => {
              this.proxy.password = event
            }
          }
        }
      )
    ]
  )
}
function renderEmailField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.data.email,
        label: this.getTranslate(`${services.users}.labels.data.email`),
        error: get(this.errors, 'data.email'),
        mode: inputModes['line-label'],
        trim: true,
        dense: true,
        rounded: true,
        clearable: true,
        required: true,
        tabindex: 3
      },
      on: {
        input: event => {
          this.proxy.data.email = event
        }
      }
    }
  )
}
function renderPhoneField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.data.phone,
        label: this.getTranslate(`${services.users}.labels.data.phone`),
        error: get(this.errors, 'data.phone'),
        mode: inputModes['line-label'],
        trim: true,
        dense: true,
        rounded: true,
        clearable: true,
        required: true,
        tabindex: 4
      },
      on: {
        input: event => {
          this.proxy.data.phone = event
        }
      }
    }
  )
}
function renderFirstNameField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.data.firstName,
        label: this.getTranslate(`${services.users}.labels.data.firstName`),
        error: get(this.errors, 'data.firstName'),
        mode: inputModes['line-label'],
        trim: true,
        dense: true,
        rounded: true,
        clearable: true,
        tabindex: 5,
        mask: safeNameRegex
      },
      on: {
        input: event => {
          this.proxy.data.firstName = event
        }
      }
    }
  )
}
function renderLastNameField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.data.lastName,
        label: this.getTranslate(`${services.users}.labels.data.lastName`),
        error: get(this.errors, 'data.lastName'),
        mode: inputModes['line-label'],
        trim: true,
        dense: true,
        rounded: true,
        clearable: true,
        tabindex: 6,
        mask: safeNameRegex
      },
      on: {
        input: event => {
          this.proxy.data.lastName = event
        }
      }
    }
  )
}
function renderReferralCodeField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.referralCode,
        label: this.getTranslate(`${services.users}.labels.referralCode`),
        error: this.errors.referralCode,
        defaultValue: '',
        mode: inputModes['line-label'],
        trim: true,
        dense: true,
        rounded: true,
        clearable: true,
        tabindex: 7
      },
      on: {
        input: event => {
          this.proxy.referralCode = event
        }
      }
    }
  )
}
function renderUsedReferralCodeField(h) {
  if (this.checkPermissions(`advanced.${services.users}.update`)) {
    if (this.proxy.data && this.proxy.data.meta) {
      return h(
        components['text-field'],
        {
          props: {
            value: this.proxy.data.meta.usedReferralCode,
            label: this.getTranslate(`${services.users}.labels.data.meta.usedReferralCode`),
            error: get(this.errors, 'data.meta.usedReferralCode'),
            defaultValue: '',
            mode: inputModes['line-label'],
            trim: true,
            dense: true,
            rounded: true,
            clearable: true,
            tabindex: 8
          },
          on: {
            input: event => {
              this.proxy.data.meta.usedReferralCode = event
            }
          }
        }
      )
    }
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid pa-3'
    },
    [
      renderFlags.call(this, h),

      h(
        'div',
        {
          class: {
            'grid grid-gap--8': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [
          renderUsernameField.call(this, h),
          renderPasswordField.call(this, h),
          renderEmailField.call(this, h),
          renderPhoneField.call(this, h),
          renderFirstNameField.call(this, h),
          renderLastNameField.call(this, h),
          renderReferralCodeField.call(this, h),
          renderUsedReferralCodeField.call(this, h)
        ]
      ),

      this.$slots.default
    ]
  )
}
