import { BILLING_CURRENCY } from '@sigma-legacy-libs/essentials/lib/constants'

import { getPrice, permissionPresets, services } from '@/utils'
import { statusColors } from '@/components/services/invoices/utils'

import linkService from '@/components/misc/link/service'
import infoTable from '@/components/info/table'
import fileItemCard from '@/components/file/item/card'

function renderStatus(h) {
  return h(
    'div',
    {
      class: `text--${statusColors[this.value.status]}`
    },
    [ this.getTranslate(`${services.invoices}.labels.statuses.${this.value.status}`) ]
  )
}

export default function(h) {
  const value = [
    {
      title: this.getTranslate(`${services.invoices}.labels.docNumber`),
      value: this.value.docNumber
    },
    {
      title: this.getTranslate(`${services.invoices}.labels.status`),
      value: renderStatus.call(this, h)
    },
    {
      title: this.getTranslate(`${services.invoices}.labels.requisite`),
      value: h(
        linkService,
        {
          props: {
            value: this.value.requisite || this.value.requisiteId,
            service: 'requisites'
          }
        }
      )
    },
    {
      title: this.getTranslate(`${services.invoices}.labels.company`),
      value: h(
        linkService,
        {
          props: {
            value: this.value.company || this.value.companyId,
            service: 'companies'
          }
        }
      )
    },
    // NOTE: Так как счета мы пока не умеем выставлять
    // ни в какой другой валюте кроме RUB
    // то и валюта суммы счета всегда RUB
    {
      title: this.getTranslate(`${services.invoices}.labels.amount`),
      value: getPrice(this.value.amount, { currency: BILLING_CURRENCY.RUB })
    }
  ]

  if (this.checkPermissions('advanced.users.get', permissionPresets.resellerUp)) {
    value.push(
      {
        title: this.getTranslate(`${services.invoices}.labels.customer`),
        value: h(
          linkService,
          {
            props: {
              value: this.value.customer || this.value.customerId,
              pathToTitle: 'username'
            }
          }
        )
      },
      {
        title: this.getTranslate(`${services.invoices}.labels.owner`),
        value: h(
          linkService,
          {
            props: {
              value: this.value.owner || this.value.ownerId,
              pathToTitle: 'username'
            }
          }
        )
      }
    )
  }

  return h(
    'div',
    {
      class: 'grid py-3'
    },
    [
      h(
        infoTable,
        {
          props: {
            value
          }
        }
      ),

      h(
        'div',
        {
          class: 'px-3'
        },
        [
          h(
            fileItemCard,
            {
              props: {
                value: this.value.document || this.value.documentId,
                displayMenu: false,
                small: true
              }
            }
          )
        ]
      )
    ]
  )
}
