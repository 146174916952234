import { alphabets, colors, components, icons } from '@/utils'

import info from '@/components/info'
import sticker from '@/components/misc/sticker'

function renderContent(h) {
  if (this.highlight) {
    return h(
      'div',
      {
        class: 'text-highlight__content',
        domProps: { innerHTML: this.highlight }
      }
    )
  }

  return h(
    info,
    {
      props: {
        value: this.getTranslate('commons.contents.text.highlight'),
        color: colors.grey
      }
    }
  )
}

function renderSchemaItem(h, item) {
  return h(
    sticker,
    {
      props: {
        value: this.getTranslate(`commons.alphabets.${alphabets[item]}`),
        label: this.getTranslate('commons.labels.symbols')
      },
      scopedSlots: {
        icon: () => {
          return h(
            'div',
            {
              class: `text-highlight--${alphabets[item]} text-highlight__icon`
            },
            [
              h(
                components.icon,
                {
                  props: {
                    value: icons.text_format,
                    color: colors.text,
                    size: 20
                  }
                }
              )
            ]
          )
        }
      }
    }
  )
}
function renderSchema(h) {
  return h(
    'div',
    {
      class: 'text-highlight__schema'
    },
    [
      Object.keys(alphabets).map(item => {
        return renderSchemaItem.call(this, h, item)
      })
    ]
  )
}

export default function(h) {
  return h(
    components.card,
    {
      class: 'text-highlight',
      props: {
        title: this.getTranslate('commons.titles.text.highlight'),
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [
      renderContent.call(this, h),
      renderSchema.call(this, h)
    ]
  )
}
