import proxy from '@sigma-legacy-libs/g-proxy'

import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { services } from '@/utils'
import { setTariffPrice } from '@/components/services/tariffs/utils'

import render from './render'

const getHintPathBySource = source => {
  switch (source) {
    case SENDING_TYPES.voice: return [ 'perCall', 'perSecond' ]
    case 'apisender': return [ 'perPeriod' ]
    default: return [ 'default' ]
  }
}

export default {
  name: 'Prices',

  mixins: [ proxy({ type: 'array' }) ],

  props: {
    source: String,
    readonly: Boolean
  },

  watch: {
    source() {
      this.proxy = setTariffPrice(this.proxy, this.source)
    }
  },

  computed: {
    hint() {
      return getHintPathBySource(this.source).map(hint => {
        return this.getTranslate(`${services.tariffs}.hints.${hint}`)
      })
    }
  },

  render
}
