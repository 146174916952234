import { proxy, services } from '@/utils'

import render from './render'

export default {
  name: 'Dialog',

  mixins: [ proxy() ],

  props: {
    data: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },

  data() {
    return {
      currentTab: 'format'
    }
  },

  computed: {
    tabs() {
      const result = [ 'format' ]
      if (this.checkPermissions(`advanced.${services.billings}.find`)) {
        result.push('source')
      }

      return result
    }
  },

  render
}

