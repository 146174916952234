import Vue from 'vue'

import { debounce } from 'lodash'
import { PROVIDER_PRICES_MULTIPLIER } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, globalErrorHandler, globalErrorProcessor, orderDirections, services, states } from '@/utils'

import render from './render'

export default {
  name: services.providersPrices,

  mixins: [
    generateServices({
      name: services.providersPrices,

      find: {
        defaultFilter: {
          ProviderId: undefined,
          createdAt: undefined,
          $search: undefined,
          $scope: [ 'OperatorGroups', 'PreviousPrice' ],
          $limit: 100
        },
        defaultOrder: { mcc: orderDirections.ASC },
        appendMode: true
      },

      get: false,
      create: false,
      update: false,
      remove: false
    })
  ],

  props: {
    ProviderId: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      loading: false,
      version: undefined,
      versions: []
    }
  },

  computed: {
    $loading() {
      return this.restData[services.providersPrices].find.state === states.loading || this.loading
    }
  },

  watch: {
    version() {
      this.getVersion()
    }
  },

  created() {
    this.onScroll = debounce(this.onScroll, 500, {
      leading: false,
      trailing: true
    })
  },

  mounted() {
    this.restData[services.providersPrices].find.filter.ProviderId = this.ProviderId

    this.getVersions()
  },

  methods: {
    async getVersions() {
      if (this.ProviderId) {
        this.loading = true
        const { data } = await Vue.$GRequest.get(`${services.providersPrices}/versions`, this.ProviderId)
        if (Array.isArray(data)) {
          if (data.length) {
            this.versions = data
            this.version = data[0]
          } else {
            this.versions = []
            this.version = undefined
            this.restData[services.providersPrices].find.filter.createdAt = undefined
          }
        }
        this.loading = false
      }
    },

    async getVersion() {
      if (this.version) {
        this.restData[services.providersPrices].find.pagination.offset = 0
        this.restData[services.providersPrices].find.filter.createdAt = this.version
        this.scrollListTop()
      } else {
        this.getVersions()
      }
    },

    async removeVersion() {
      try {
        await Vue.$GRequest.remove(`${services.providersPrices}/versions`, this.ProviderId, { query: { createdAt: this.version } })
        this.getVersions()
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    onScroll(event) {
      const { state, filter, pagination } = this.restData[services.providersPrices].find
      const targetState = state === states.ready
      const targetScrollPosition = event.target.scrollHeight - 100 <= event.target.clientHeight + event.target.scrollTop
      const targetLimit = filter.$limit + pagination.offset < pagination.total
      if (targetState && targetScrollPosition && targetLimit) {
        pagination.offset += filter.$limit
      }
    },

    scrollListTop() {
      const list = this.$refs.list
      if (list) {
        list.scrollTop = 0
      }
    },

    getDiff(a, b) {
      const result = []
      const multiplier = PROVIDER_PRICES_MULTIPLIER

      a = Math.round(a * multiplier)
      b = Math.round(b * multiplier)

      result.push((a - b) / multiplier)
      if (result > 0) {
        result.unshift('+')
      }

      return result.join('')
    },

    getClasses({ price, previousPrice, direction }, previous) {
      const result = [ 'providers-prices__row-price' ]
      if (previousPrice || previous) {
        if (direction === 'up' || price > previousPrice) {
          result.push('providers-prices__row-price--up')
        } else if (direction === 'down' || price < previousPrice) {
          result.push('providers-prices__row-price--down')
        } else {
          result.push('providers-prices__row-price--equal')
        }
      }
      if (previous) {
        result.push('providers-prices__row-price--previous')
      }

      return result
    }
  },

  render
}
