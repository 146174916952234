import { get, isEqual } from 'lodash'
import { SENDING_REPLACEMENT_FIELDS } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

export default {
  name: 'Replacement',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      mode: 'source'
    }
  },

  computed: {
    replacement() {
      const result = SENDING_REPLACEMENT_FIELDS.reduce((result, field) => {
        result[field] = []

        return result
      }, {})

      const history = get(this.value, 'meta.contentHistory')

      for (let index = 0; index < history.length; index++) {
        const item = history[index]

        for (const key in result) {
          const value = get(item, key)
          if (value) {
            switch (key) {
              case 'fallbacks': {
                if (index === 0) {
                  result[key].push(value)
                } else {
                  if (value.length) {
                    if (result[key].every(item => Array.isArray(item) && item.every(i => value.every(v => isEqual(v, i))))) {
                      result[key].push(value)
                    }
                  }
                }
                break
              }

              default: {
                if (index > 0 && ~result[key].indexOf(value)) {
                  continue
                }
                result[key].push(value)
                break
              }
            }
          }
        }
      }

      return result
    }
  },

  render
}
