function renderPoint(h, index, color) {
  return h(
    'div',
    {
      class: {
        preloader__point: true,
        [`preloader__point-size--${this.size}`]: true,
        [`preloader__point-color--${color}`]: !!color,
        'preloader__point--active': index === this.index
      }
    }
  )
}

function renderPoints(h) {
  return Array.from({ length: this.count }).map((_, index) => {
    return renderPoint.call(this, h, index, this.rainbow ? this.rainbowColors[index] : this.color)
  })
}

export default function(h) {
  return h(
    'div',
    {
      class: this.$class,
      style: this.$style
    },
    [ renderPoints.call(this, h) ]
  )
}
