import { EVENTSHISTORY_SERVICES } from '@sigma-legacy-libs/essentials/lib/constants'

import { isUUID, services } from '@/utils'

import render from './render'

export default {
  name: 'EventsHistoryCol',

  props: {
    service: {
      type: String,
      required: true
    },

    id: {
      type: String,
      required: true,
      validator: isUUID
    }
  },

  data() {
    return {
      tab: undefined
    }
  },

  computed: {
    validService() {
      return EVENTSHISTORY_SERVICES.includes(this.service)
    },
    tabs() {
      const result = []
      if (!this.validService) {
        return result
      }
      if (this.checkPermissions(`advanced.${services.eventsHistory}.get`)) {
        result.push(services.eventsHistory)
      }

      return result.map(key => {
        return {
          key,
          title: this.getTranslate(`commons.tabs.${key}`)
        }
      })
    }
  },

  mounted() {
    this.tab = this.tabs[0]?.key
  },

  render
}
