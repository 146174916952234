import { cloneDeep, isEqual } from 'lodash'
import { NOTIFICATIONS_AVAILABLE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { balance, getPrice, services } from '@/utils'

import render from './render'

const defaultValue = {
  balance: 0,
  types: [ NOTIFICATIONS_AVAILABLE_TYPES.email ],
  includeOverdraft: false,
  invoice: false
}

export default {
  name: 'NotificationsEventsBillingsLimit',

  mixins: [ balance() ],

  props: {
    path: {
      type: String,
      default: undefined
    },

    value: {
      type: Array,
      default: () => []
    },

    ResellerId: String
  },

  data() {
    return {
      proxy: [],
      index: 0,

      showInvoiceDialog: false
    }
  },

  mounted() {
    this.inputFilter()
  },

  methods: {
    add() {
      this.proxy.push(cloneDeep(defaultValue))
    },
    remove(index) {
      this.proxy.splice(index, 1)
    },

    inputFilter() {
      if (!isEqual(this.proxy, this.value)) {
        this.proxy = cloneDeep(this.value)
      }
    },

    getLabel(balance, includeOverdraft, path) {
      let amount = balance
      if (path === this.path) {
        if (includeOverdraft) {
          amount = getPrice(balance - this.overdraft, { currency: this.currency })
        } else {
          amount = getPrice(balance, { currency: this.currency })
        }
      }
      const targetAmount = parseFloat(amount)
      let result = this.getTranslate(`${services.settingsUser}.hints.${path}.balance`, { amount })
      if (!isNaN(targetAmount) && targetAmount == 0) {
        result = this.getTranslate(`${services.settingsUser}.hints.${path}.onNullBalance`)
      }

      return result
    }
  },

  render
}
