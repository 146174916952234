import { proxy } from '@/utils'

import render from './render'

export default {
  name: 'FormBasic',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    currency: String,
    required: Boolean
  },

  computed: {
    $currency() {
      return this.currency || this.globalSettings.billings.currency
    }
  },

  render
}
