import services from './services'
import settingsUser from './settingsUser'

const settingsUserLabels = Object.assign(
  settingsUser.labels,
  {
    sendings: {
      ...settingsUser.labels.sendings,
      sendernamesCheck: 'Обработка сообщений: Имена отправителей',
      patternsCheck: 'Обработка сообщений: Паттерны'
    }
  }
)

export default {
  title: 'История событий',

  labels: {
    eventsHistory: 'История изменений',
    type: 'Тип',
    userId: 'Пользователь',
    entityId: 'ID сущности',
    service: 'Сервис',
    oldValues: 'Было',
    newValues: 'Стало',
    author: 'Автор',
    changesType: 'Тип',
    created: 'Создано',
    compareId: 'ID сущности',
    permissionsUsers: 'Право пользователя'
  },

  updatedValues: {
    ...services,
    ...settingsUserLabels,

    value: 'Значение',

    data: 'Data',
    isActive: 'Активация/Деактивация',
    state: 'Статус',
    startsAt: 'Начало',
    updatedAt: 'Обновлено в',
    title: 'Заголовок',
    serverId: 'Сервер',
    credentialsId: 'Учетные данные',
    comment: 'Комментарий',
    properties: 'Свойства',
    replacements: 'Подмена контента',
    createdAt: 'Создано',
    deletedAt: 'Удалено',

    type: 'Тип',
    provider: 'Провайдер',
    name: 'Имя',

    rules: 'Правила',

    description: 'Описание',

    source: 'Источник',
    ownerId: 'Владелец',

    resellerId: 'Реселлер',
    password: 'Пароль',
    referralCode: 'Реферальный код',
    sudo: 'Sudo',
    username: 'Имя пользователя',

    amount: 'Сумма',
    status: 'Статус',
    TariffId: 'Тариф',

    listId: 'ListId',
    date: 'Дата',
    email: 'Email',
    firstName: 'Имя',
    gender: 'Пол',
    id: 'Id',
    lastName: 'Фамилия',
    middleName: 'Отчество',
    phone: 'Телефон',

    fallbacks: 'Переотправления',
    payload: 'Информация',
    recipient: 'Получатель',

    meta: 'Meta',
    moderation: 'Модерация',

    conditions: 'Условия',
    trafficType: 'Тип траффика',

    records: 'Учетные данные'
  },

  types: {
    created: 'Создано',
    updated: 'Обновлено',
    removed: 'Удалено'
  },

  services: {
    ...services,

    billingsUsers: 'Финансовая операция',
    settingsUserUsers: 'Настройки пользователя',
    contactListsContacts: 'Контакт',
    keywordListsKeywords: 'Ключевое слово',
    permissionsUsers: 'Права пользователя'
  },

  tooltips: {
    historyButton: 'Просмотреть историю изменений'
  }
}
