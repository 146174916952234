import { components, inputModes, services } from '@/utils'

import selectorOwner from '@/components/selector/owner'

export default function(h) {
  return h(
    'div',
    {
      class: `${services.yclients}-filter`
    },
    [
      h(
        selectorOwner,
        {
          props: {
            value: this.proxy.OwnerId,
            mode: inputModes.outline,
            dense: true
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      ),

      h(
        components['text-field'],
        {
          props: {
            value: this.proxy.salonId,
            label: this.getTranslate(`${services.yclients}.labels.salon`),
            clearable: true,
            mode: inputModes.outline,
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.salonId = event
            }
          }
        }
      )
    ]
  )
}
