import { permissionPresets, serviceTemplate, services } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services['otp-settings/widgets'],

      backendGeneration: 'nest',

      outputFilter(result) {
        if (!this.checkPermissions(`advanced.${services.users}.find`, permissionPresets.resellerUp)) {
          result.ownerId = result.ownerId || this.account.id
        }

        return result
      },

      find: {
        defaultFilter: {
          scopes: [ 'full' ],
          order: [ [ 'createdAt', 'desc' ] ],
          search: undefined,
          ownerId: undefined,
          isActive: true,
          isBlocked: false,
          captchaIsRequired: undefined
        }
      },
      get: {
        params: {
          query: {
            scopes: [ 'full' ]
          }
        }
      },
      update: {
        params: {
          query: {
            scopes: [ 'full' ]
          }
        }
      },
      create: { redirect: true },

      has: {
        search: false,
        clone: false,
        tabs: false
      },

      titlePath: 'name',
      formClass: ''
    })
  ]
}
