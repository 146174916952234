import { moderationColors, moderationStatusIcons, permissionPresets, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import squircle from '@/components/icon/squircle'
import date from '@/components/templates/service/item/date'
import owner from '@/components/templates/service/item/owner'
import comments from '@/components/services/sendernames/item/comments'
import actions from '@/components/templates/service/item/actions'
import moderationButtons from '@/components/services/sendernames/item/moderation/buttons'

function renderType(h) {
  if (this.viewport.size.width > 430) {
    return h(
      squircle,
      {
        props: {
          iconSVG: this.$iconSVG,
          color: this.$iconSVG,
          tooltip: this.getTranslate(`${services.sendings}.types.${this.value.type}`)
        }
      }
    )
  }
}
function renderTitle(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        label: this.getTranslate(`${services.sendernames}.labels.name`),
        icon: moderationStatusIcons[this.value.moderation],
        color: moderationColors[this.value.moderation],
        iconTooltip: this.getTranslate(`${services.sendernames}.statuses.${this.value.moderation}`),
        service: services.sendernames,
        pathToTitle: 'name',
        copy: true
      }
    }
  )
}
function renderOwner(h) {
  if (this.viewport.size.width > 640) {
    return h(
      owner,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: {
        [`${services.sendernames}-item__info`]: true,
        [`${services.sendernames}-item__info--admin`]: this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)
      }
    },
    [
      renderType.call(this, h),
      renderTitle.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderComments(h) {
  if (this.viewport.size.width > 1280) {
    return h(
      comments,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

function renderModerationButtons(h) {
  return h(
    moderationButtons,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderActions(h) {
  return h(
    actions,
    {
      props: {
        value: this.value,
        service: services.sendernames
      }
    }
  )
}

function renderCreatedAt(h) {
  if (this.viewport.size.width > 768) {
    return h(
      date,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `service-template-item ${services.sendernames}-item hover-parent hover-parent--opacity`
    },
    [
      renderInfo.call(this, h),
      renderComments.call(this, h),
      renderModerationButtons.call(this, h),
      renderActions.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
