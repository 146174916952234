import { DICTIONARIES, DICTIONARIES_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { components, inputModes, permissionPresets, services } from '@/utils'

import selectorOwner from '@/components/selector/owner'

function renderFilterOwner(h) {
  return h(
    selectorOwner,
    {
      props: {
        value: this.proxy.OwnerId,
        mode: inputModes.outline,
        dense: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}
function renderFilterType(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.type,
        label: this.getTranslate(`${services.dictionaries}.labels.type`),
        items: DICTIONARIES.map(value => {
          return {
            value,
            title: this.getTranslate(`${services.dictionaries}.titles.${value}`)
          }
        }),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        multiple: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.type = event
        }
      }
    }
  )
}
function renderFilterStatus(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.status,
        label: this.getTranslate(`${services.dictionaries}.labels.status`),
        items: DICTIONARIES_STATUSES.map(value => {
          return {
            value,
            title: this.getTranslate(`${services.dictionaries}.statuses.${value}`)
          }
        }),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        multiple: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.status = event
        }
      }
    }
  )
}

export default function(h) {
  if (this.checkPermissions(`advanced.${services.dictionaries}.get`, permissionPresets.resellerUp)) {
    return h(
      'div',
      {
        class: `${services.dictionaries}-filter`
      },
      [
        renderFilterOwner.call(this, h),
        renderFilterType.call(this, h),
        renderFilterStatus.call(this, h)
      ]
    )
  }
}
