import { buttonModes } from '@/utils'

import button from '@/components/button'

export default function(h) {
  return h(
    this.$component,
    {
      props: { to: this.$to },
      style: { 'text-decoration': 'none' }
    },
    [
      h(
        button,
        {
          props: {
            icon: this.$icon,
            mode: buttonModes.flat,
            disabled: !this.value.id
          }
        }
      )
    ]
  )
}
