import { defaultsDeep, differenceBy } from 'lodash'

export const attach = (result, path) => {
  const attach = result[path]
  const current = result[`_${path}`]
  const detach = differenceBy(current, attach, 'id')
  const output = {}

  for (const item of attach) {
    output[item.id] = defaultsDeep({ $attach: true }, item)
  }
  for (const item of detach) {
    output[item.id] = defaultsDeep({ $attach: false }, item)
  }

  result[path] = Object.values(output).filter(item => item.id)

  delete result[`_${path}`]
}
