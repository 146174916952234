import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, popperPlacements, services } from '@/utils'

import button from '@/components/button'

function renderApproveButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.done,
        mode: buttonModes.flat,
        color: colors.success,
        loading: this.approveLoading,
        disabled: this.approveLoading || MODERATION_VALUES.approved === this.value.moderation,
        tooltip: this.getTranslate(`${services.sendernames}.tooltips.approve`)
      },
      on: {
        click: () => {
          this.approve()
        }
      }
    }
  )
}
function renderRejectMenuActivator(h) {
  return h(
    button,
    {
      props: {
        icon: icons.clear,
        mode: buttonModes.flat,
        color: colors.warning,
        loading: this.rejectLoading,
        disabled: this.rejectLoading || MODERATION_VALUES.rejected === this.value.moderation,
        tooltip: this.getTranslate(`${services.sendernames}.tooltips.rejectForReason`)
      },
      slot: 'activator'
    }
  )
}
function renderRejectListItem(h, item) {
  return h(
    components['list-item'],
    {
      props: {
        label: this.getTranslate(`${services.sendernames}.variants.${item}`),
        dense: true
      },
      on: {
        click: () => {
          this.reject(item)
        }
      }
    }
  )
}
function renderRejectList(h) {
  return h(
    components.list,
    {
      props: {
        dense: true,
        rounded: true
      }
    },
    [
      Object.keys(this.rejectVariants).map(item => {
        return renderRejectListItem.call(this, h, item)
      })
    ]
  )
}
function renderRejectMenu(h) {
  return h(
    components.menu,
    {
      props: {
        value: this.showRejectMenu,
        rounded: true,
        offsetDistance: 8,
        placement: popperPlacements['bottom-end'],
        disabled: MODERATION_VALUES.rejected === this.value.moderation,
        width: 190
      },
      on: {
        input: event => {
          this.showRejectMenu = event
        }
      }
    },
    [
      renderRejectMenuActivator.call(this, h),
      renderRejectList.call(this, h)
    ]
  )
}
function renderRejectButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.cancel,
        mode: buttonModes.flat,
        color: colors.error,
        disabled: MODERATION_VALUES.rejected === this.value.moderation,
        tooltip: this.getTranslate(`${services.sendernames}.tooltips.reject`)
      },
      on: {
        click: () => {
          this.reject()
        }
      }
    }
  )
}

export default function(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.update`)) {
    return h(
      'div',
      {
        class: {
          [`${services.sendernames}-item__buttons`]: true,
          'hover-child': this.viewport.breakpoint.mdUp && !this.showRejectMenu
        }
      },
      [
        renderApproveButton.call(this, h),
        renderRejectMenu.call(this, h),
        renderRejectButton.call(this, h)
      ]
    )
  }
}
