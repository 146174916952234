import render from './render'

export default {
  name: 'RulesReadonly',

  props: {
    rules: {
      type: Array,
      default: () => []
    },

    defaultRule: {
      type: Object,
      default: undefined
    },

    source: String
  },

  computed: {
    hasRules() {
      return Array.isArray(this.rules) && this.rules.length > 0
    }
  },

  render
}
