import { upgradeArray } from '@sigma-legacy-libs/essentials/lib/utils'
import { TASK_STATES, TASK_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, datePresets, icons } from '@/utils'

export const countsTypes = upgradeArray([ 'completed', 'errored', 'remaining', 'total' ])

export const countsColors = {
  completed: colors.success,
  errored: colors.error,
  remaining: colors.primary,
  total: colors.black
}

export const typeColors = {
  [TASK_TYPES['payments:recurrent']]: colors.info,
  [TASK_TYPES['billings:operations']]: colors.success
}
export const typeIcons = {
  [TASK_TYPES['payments:recurrent']]: 'repeat',
  [TASK_TYPES['billings:operations']]: 'account_balance'
}

export const tasksStates = {
  [TASK_STATES.completed]: {
    icon: icons.check_circle_outline,
    color: colors.success
  },
  [TASK_STATES.paused]: {
    icon: icons.pause_circle_outline,
    color: colors.warning
  },
  [TASK_STATES.running]: {
    icon: icons.keyboard_double_arrow_right,
    color: colors.primary
  },
  [TASK_STATES.scheduled]: {
    icon: icons.access_time,
    color: colors.epic
  },
  [TASK_STATES.failed]: {
    icon: icons.error_outline,
    color: colors.error
  }
}

export const tasksFilter = {
  id: undefined,
  ListId: undefined,
  OwnerId: undefined,
  CreatorId: undefined,
  state: undefined,
  type: undefined,
  isActive: true,
  startsAt: {
    $gt: undefined,
    $lt: undefined
  },
  createdAt: {
    $gt: undefined,
    $lt: undefined
  },
  createdAtPreset: datePresets.today,
  $scope: [ 'full' ]
}
