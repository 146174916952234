import { get } from 'lodash'

import { colors, components, inputModes, services } from '@/utils'

import info from '@/components/info'
import arrow from '@/components/misc/arrow'
import selectorOwner from '@/components/selector/owner'
import billingsFormBasic from '@/components/services/billings/form/basic'

function renderBillingsFormBasic(h) {
  return h(
    billingsFormBasic,
    {
      props: {
        value: this.proxy,
        currency: this.currency,
        required: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
function renderInfo(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${services.billings}.hints.amount`),
        color: colors.primary,
        icon: 'info'
      }
    }
  )
}
function renderContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderBillingsFormBasic.call(this, h),
      renderInfo.call(this, h)
    ]
  )
}

function renderSelectorOwner(h) {
  return h(
    selectorOwner,
    {
      props: {
        value: this.proxy.OwnerId,
        itemsDisabled: !this.checkPermissions(`advanced.${services.billings}.generate`) ? [ this.account.id ] : [],
        dense: true,
        required: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}
function renderMultiplier(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { gridTemplateColumns: 'auto 200px' }
    },
    [
      h(
        components['text-field'],
        {
          props: {
            value: this.proxy.data.multiplier,
            label: this.getTranslate(`${services.billings}.labels.multiplier`),
            mode: inputModes['line-label'],
            dense: true,
            details: false,
            rounded: true,
            type: 'number',
            min: 1,
            step: 'any',
            clearable: true,
            required: true
          },
          on: {
            input: event => {
              this.proxy.data.multiplier = event
            }
          }
        }
      ),

      h(
        components.switch,
        {
          props: {
            value: this.proxy.data.hidden.noRef,
            label: this.proxy.data.hidden.noRef ? this.getTranslate(`${services.billings}.labels.ref.${this.proxy.data.hidden.noRef}`) : this.getTranslate(`${services.billings}.labels.ref.${this.proxy.data.hidden.noRef}`),
            outline: true
          },
          on: {
            input: event => {
              this.proxy.data.hidden.noRef = event
            }
          }
        }
      )
    ]
  )
}
function renderComment(h) {
  return h(
    components.textarea,
    {
      props: {
        value: this.proxy.data.hidden.comment,
        label: this.getTranslate(`${services.billings}.labels.comment`),
        error: get(this.errors, 'data.hidden.comment'),
        mode: inputModes['line-label'],
        dense: true,
        details: false,
        rounded: true,
        grow: true,
        rows: 3,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.data.hidden.comment = event
        }
      }
    }
  )
}
function renderAdditional(h) {
  return h(
    components['expansion-panels'],
    {
      props: {
        rounded: true,
        outline: true
      }
    },
    [
      h(
        components['expansion-panel'],
        {
          scopedSlots: {
            header: ({ expanded }) => {
              return h(
                'div',
                {
                  class: 'default-expansion-panel-header'
                },
                [
                  this.getTranslate('misc.additional'),
                  h(arrow, { props: { expanded } })
                ]
              )
            },
            default: () => {
              return h(
                'div',
                {
                  class: 'grid grid-gap--12 pa-2'
                },
                [
                  renderSelectorOwner.call(this, h),
                  renderMultiplier.call(this, h),
                  renderComment.call(this, h)
                ]
              )
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.form,
    {
      class: 'grid grid-gap--8 pt-2 px-2'
    },
    [
      renderContent.call(this, h),
      renderAdditional.call(this, h)
    ]
  )
}
