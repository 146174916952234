import billingsDetails from '@/components/services/billings/details'
import billingsGroup001 from '@/components/services/billings/group001'

import { cloneDeep } from 'lodash'

import { BILLINGS_FILTER, currentTab, generateServices, globalErrorHandler, globalErrorProcessor, permissionPresets, services, states } from '@/utils'

import render from './render'

export default {
  name: 'Template',

  components: {
    [`${services.billings}-details`]: billingsDetails,
    [`${services.billings}-group001`]: billingsGroup001
  },

  mixins: [
    generateServices({
      name: services.billings,
      outputFilter(result) {
        if (result.source !== 'accrual') {
          result.amount = result.amount * -1
        }

        result.amount = parseFloat(result.amount)

        return result
      },
      find: {
        defaultFilter: {
          id: undefined,
          createdAt: undefined,
          $scope: [ 'full' ]
        }
      },
      get: false,
      remove: false,
      update: false
    }),

    currentTab(services.billings)
  ],

  data() {
    return {
      showDialog: false,

      filter: cloneDeep(BILLINGS_FILTER),

      preset: `${services.billings}.details`
    }
  },

  computed: {
    presetNameKebab() {
      return this.preset.replace('.', '-')
    },

    createDataIsValid() {
      return this.restData[services.billings].create.state !== states.loading && this.restData[services.billings].create.isValid
    },

    tabs() {
      const result = [ services.billings ]
      if (this.checkPermissions(`advanced.${services.tasks}.find`, permissionPresets.resellerUp)) {
        result.push(services.tasks)
      }

      return result
    }
  },

  watch: {
    preset() {
      this.filter = cloneDeep(BILLINGS_FILTER)
    }
  },

  mounted() {
    const { id, createdAt } = this.$route.params
    if (id || createdAt) {
      this.filter.id = id
      this.filter.createdAt = createdAt
    }
  },

  methods: {
    async billingsCreate() {
      if (this.createDataIsValid) {
        try {
          const result = await this.rest[services.billings].create(this.restData[services.billings].create.data)
          if (result) {
            this.showDialog = false
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    }
  },

  render
}
