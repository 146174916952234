import { components, inputModes, services } from '@/utils'

export default function(h) {
  return h(
    components.form,
    {
      class: `${services.operatorGroups}-form`,
      on: {
        validation: event => {
          this.$emit('validation', event)
        }
      }
    },
    [
      h(
        components.select,
        {
          props: {
            value: this.proxy.operator,
            label: this.getTranslate(`${services.dictionaries}.labels.operatorName`),
            service: services.operatorGroups,
            query: {
              DictionaryId: this.dictionary.id,
              $scope: [ 'GroupByOperator' ]
            },
            itemTitle: 'operator',
            itemValue: 'operator',
            mode: inputModes['line-label'],
            dense: true,
            rounded: true,
            combobox: true,
            setOnBlur: true,
            clearable: true,
            autocomplete: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.operator = event
            }
          }
        }
      ),
      h(
        components.select,
        {
          props: {
            value: this.proxy.group,
            label: this.getTranslate(`${services.dictionaries}.labels.group`),
            service: services.operatorGroups,
            query: {
              DictionaryId: this.dictionary.id,
              $scope: [ 'GroupByGroup' ]
            },
            itemTitle: 'group',
            itemValue: 'group',
            mode: inputModes['line-label'],
            dense: true,
            rounded: true,
            combobox: true,
            setOnBlur: true,
            clearable: true,
            autocomplete: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.group = event
            }
          }
        }
      ),
      h(
        components['text-field'],
        {
          props: {
            value: this.proxy.mcc,
            label: this.getTranslate(`${services.dictionaries}.labels.mcc`),
            mode: inputModes['line-label'],
            dense: true,
            rounded: true,
            required: true,
            details: false,
            type: 'number',
            step: 'any'
          },
          on: {
            input: event => {
              this.proxy.mcc = event
            }
          }
        }
      ),
      h(
        components['text-field'],
        {
          props: {
            value: this.proxy.mnc,
            label: this.getTranslate(`${services.dictionaries}.labels.mnc`),
            mode: inputModes['line-label'],
            dense: true,
            rounded: true,
            required: true,
            details: false,
            type: 'number',
            step: 'any'
          },
          on: {
            input: event => {
              this.proxy.mnc = event
            }
          }
        }
      )
    ]
  )
}
