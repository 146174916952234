import render from './render'

import { balance, colors } from '@/utils'

export default {
  name: 'ButtonAccount',

  mixins: [ balance() ],

  data() {
    return {
      show: false
    }
  },

  computed: {
    balanceColor() {
      if (this.balance > 0) {
        return colors.success
      } else if (this.balance <= 0) {
        if (this.balance + this.overdraft >= 0) {
          return colors.warning
        } else {
          return colors.error
        }
      } else {
        return colors.grey
      }
    }
  },

  render
}
