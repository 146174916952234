import { isActiveColors, isActiveIcons, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import owner from '@/components/templates/service/item/owner'
import actions from '@/components/templates/service/item/actions'
import date from '@/components/templates/service/item/date'

const serviceNameViaPoint = services['otp-settings/widgets'].split('/').join('.')

function renderName(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        pathToTitle: 'name',
        label: this.getTranslate(`${serviceNameViaPoint}.labels.name`),
        icon: isActiveIcons[this.value.isBlocked ? false : this.value.isActive],
        color: isActiveColors[this.value.isBlocked ? false : this.value.isActive],
        iconTooltip: this.getTranslate(`${serviceNameViaPoint}.tooltips.${this.pathToTranslate}.${this.value.isActive}`),
        service: serviceNameViaPoint
      }
    }
  )
}
function renderOwner(h) {
  if (this.checkPermissions(`advanced.${services.users}.get`)) {
    if (this.viewport.breakpoint.smUp) {
      return h(
        owner,
        {
          props: {
            value: this.value
          }
        }
      )
    }
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: `${serviceNameViaPoint.replace('.', '-')}-panel__info`
    },
    [
      renderName.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderActions(h) {
  return h(
    actions,
    {
      props: {
        value: this.value,
        service: services['otp-settings/widgets']
      }
    }
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-item hover-parent hover-parent--opacity': true,
        [`${serviceNameViaPoint.replace('.', '-')}-panel`]: true
      }
    },
    [
      renderInfo.call(this, h),
      renderActions.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
