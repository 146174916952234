import { serviceTemplate, services } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.credentials,

      has: {
        clone: false,
        filter: false
      }
    })
  ]
}
