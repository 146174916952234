import { components, inputModes } from '@/utils'

export default function(h, { path, serviceName }) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.${path}`),
        suffix: this.getTranslate('misc.measure.time.sec'),
        mode: inputModes['outline-label'],
        type: 'number',
        step: 'any',
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
