export const methods = {
  yooMoney: 'YooMoney',
  tinkoff: 'Tinkoff',
  mandarin: 'Mandarin',
  stripe: 'Stripe',
  cards: 'Pay by cards',
  recurrent: 'Recurrent payment',
  invoice: 'Invoice'
}

export default {
  title: 'Payments',

  titles: {
    individual: 'Individual',
    legal: 'Legal',
    threshold: 'By threshold',
    frequency: 'By frequency',
    billings: 'Billings',
    paymentLogs: 'Payment logs',

    step: {
      first: 'Payment method',
      individual: 'Payment method',
      legal: 'Making an invoice',
      cards: 'Pay with card',
      yooMoney: 'YooMoney',
      recurrent: 'Recurrent payment'
    }
  },

  labels: {
    amount: 'Amount',
    method: 'Method',
    frequency: '',
    status: 'Status',
    paymentType: 'Payment type',
    sum: 'Sum',
    owner: 'Owner',

    methods: {
      tinkoff: {
        TerminalKey: 'Terminal key',
        Password: 'Password',
        ekamToken: 'EKAM token'
      },

      mandarin: {
        merchantId: 'Merchant ID',
        secret: 'Secret',
        ekamToken: 'EKAM token',
        includeFiscalInformation: 'Include fiscal information'
      },

      stripe: {
        apikey: 'API key',
        usd_price_id: 'USD price ID',
        eur_price_id: 'RUB price ID'
      }
    }
  },

  recurrentTypes: {
    once: 'Once',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly'
  },

  contents: {
    legal: 'To invoice, please write to <a href="mailto:support@sigmasms.ru">support@sigmasms.ru</a>.',

    payment: {
      result: {
        success: 'Payment completed successfully.<br>Your money will be credited to your account soon.',
        fail: 'Could not complete payment.<br>Please contact your manager to resolve this issue.'
      }
    }
  },

  hints: {
    delay: 'Balance credited within 5 minutes',
    commission: 'Bank fee:',
    commissions: 'fee',
    yooMoney: 'You can also pay with YooMoney, with a lower fee.',
    noOneFound: 'No one payment found',
    nextPaymentAt: 'Next payment at',
    addPayment: 'Add payment',
    offer: 'I agree to the offer for automatic payment service.',
    goToPayment: 'Go to payment',
    notFound: 'No payments found',
    individual: 'To replenish the balance of your personal account by credit card, contact us in any convenient way.',
    owner: 'Owner',
    due: 'Fee: {due}%',
    sum: 'Commission: 3%',
    recurrent: 'Recurrent automatic payment'
  },

  types: {
    individual: 'Individual',
    legal: 'Company'
  },

  methods,

  buttons: {
    add: {
      funds: 'Add funds',
      payment: 'Add recurrent payment'
    },
    request: {
      payment: 'Request payment'
    },
    billings: 'Billings history',
    paymentLogs: 'Payment logs',
    refund: 'Refund payment'
  },

  links: {
    attachInvoice: 'Attach invoice',
    attachInvoiceTo: 'Attach an invoice for {title}'
  },

  statuses: {
    init: 'Initialized',
    completed: 'Completed',
    refundRequested: 'Refund requested',
    refunded: 'Refunded',
    partialRefunded: 'Partial refunded',
    error: 'Error'
  },

  tabs: {
    payments: 'Payments',
    recurrentPayments: 'Recurrent payments'
  },

  infos: {
    method: 'There are currently no payment methods configured. Please contact support to resolve this issue.'
  },

  warnings: {
    yooMoney: 'To identify your payment, you need to write to us by email.<a href="mailto:a1@sigmasms.ru" class="my-2">a1@sigmasms.ru</a>In the body of the email, please include your login and payment amount.<br>Attach the payment receipt to the email.<p class="mt-2 mb-0">The indicated 3% commission is approximate.</p><a href="https://yoomoney.ru/page?id=536332" target="_blank">More about YooMoney commissions</a>',
    no: {
      methods: 'Payment methods are not currently configured. Please contact support.'
    }
  }
}
