import { components, inputModes, services } from '@/utils'

import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

const serviceNameViaPoint = services['otp-settings/templates'].split('/').join('.')

function renderNameField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.search,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.name`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.search = event
        }
      }
    }
  )
}

function renderChannelTypeField(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.channelType,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.channelType`),
        items: this.channelTypes,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.channelType = event
        }
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title
              }
            }
          )
        },
        item: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title,
                as: 'text'
              }
            }
          )
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceNameViaPoint.replace('.', '-')}-filter`,
      style: { gridTemplateColumns: this.gridTemplateColumns }
    },
    [
      renderNameField.call(this, h),
      renderChannelTypeField.call(this, h)
    ]
  )
}
