import { buttonModes, colors, components, icons, permissionPresets, services, sizes, states } from '@/utils'

import button from '@/components/button'
import preloader from '@/components/preloader'
import fileManager from '@/components/file/manager'
import textOverflow from '@/components/text/overflow'
import buttonConfirmRemove from '@/components/button/confirm/remove'

function renderTitle(h) {
  return h(
    'div',
    {
      class: 'document-templates__selector-title'
    },
    [ this.getTranslate(`${services.documentTemplates}.titles.${this.type}`) ]
  )
}

function renderFileTitle(h) {
  if (this.fileTitle) {
    return h(
      textOverflow,
      {
        class: 'caption text--grey',
        props: {
          value: this.fileTitle
        }
      }
    )
  }

  return h('div')
}
function renderAddButton(h) {
  if (this.checkPermissions(`advanced.${services.documentTemplates}.create`, permissionPresets.resellerUp)) {
    return h(
      'div',
      {
        class: 'fjcfe facfe'
      },
      [
        h(
          button,
          {
            props: {
              label: this.getTranslate(`${services.documentTemplates}.buttons.add.template`),
              mode: buttonModes.flat,
              size: sizes.small,
              color: colors.secondary
            },
            on: {
              click: () => {
                this.currentType = this.type
                this.showFileManager = true
              }
            }
          }
        )
      ]
    )
  }
}
function renderEditButton(h) {
  if (this.fileId) {
    return h(
      button,
      {
        props: {
          mode: buttonModes.flat,
          size: sizes.small,
          icon: icons.edit,
          tooltip: this.getTranslate('misc.buttons.edit'),
          loading: this.editFileProgress,
          disabled: !this.checkPermissions(`advanced.${services.storage}.update`, permissionPresets.meUp)
        },
        on: {
          click: () => {
            this.editFile()
          }
        }
      }
    )
  }
}
function renderDownloadButton(h) {
  if (this.document.File) {
    return h(
      button,
      {
        props: {
          size: sizes.small,
          mode: buttonModes.flat,
          icon: icons.file_download,
          color: colors.primary,
          tooltip: this.getTranslate('misc.buttons.download'),
          disabled: !this.checkPermissions(`advanced.${services.storage}.download`, permissionPresets.meUp)
        },
        on: {
          click: () => {
            this.downloadFile(this.document.File)
          }
        }
      }
    )
  }
}
function renderRemoveButton(h) {
  return h(
    buttonConfirmRemove,
    {
      props: {
        labeled: false,
        size: sizes.small,
        icon: icons.close,
        tooltip: this.getTranslate('misc.buttons.remove'),
        loading: this.restData[services.documentTemplates].remove.state === states.loading,
        callback: () => {
          this.removeFile()
        }
      }
    }
  )
}
function renderActionsContent(h) {
  if (!this.document) {
    return renderAddButton.call(this, h)
  } else {
    return [
      renderEditButton.call(this, h),
      renderDownloadButton.call(this, h),
      renderRemoveButton.call(this, h)
    ]
  }
}
function renderActions(h) {
  return h(
    'div',
    {
      class: 'document-templates__selector-actions',
      style: { gridTemplateColumns: this.document ? 'repeat(3, auto)' : 'auto' }
    },
    [ renderActionsContent.call(this, h) ]
  )
}

function renderContent(h) {
  if (this.loading) {
    return h(
      preloader,
      {
        props: {
          size: 18
        }
      }
    )
  }

  return h(
    'div',
    {
      class: 'document-templates__selector-content'
    },
    [
      renderTitle.call(this, h),
      renderFileTitle.call(this, h),
      renderActions.call(this, h)
    ]
  )
}

function renderFileManager(h) {
  return h(
    fileManager,
    {
      props: {
        show: this.showFileManager,
        flat: true,
        readonly: true,
        embedded: true,
        types: [ 'doc', 'docx' ]
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.showFileManager = false
          this.createDocumentTemplate(event.id)
        }
      }
    }
  )
}

function renderIFrame(h) {
  if (this.googleFileURL) {
    return h(
      'iframe',
      {
        attrs: {
          src: this.googleFileURL,
          frameborder: 0
        },
        class: 'position-absolute h--100 w--100'
      }
    )
  }
}
function renderEditDialog(h) {
  if (this.fileId) {
    return h(
      components.dialog,
      {
        props: {
          value: this.showEditFileDialog,
          fullscreen: true
        },
        on: {
          input: event => {
            this.showEditFileDialog = event
          }
        }
      },
      [
        renderIFrame.call(this, h),

        h(
          'div',
          {
            class: 'fjcfe facfe pa-2',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.save'),
                  mode: buttonModes.flat,
                  loading: this.uploadFileProgress,
                  disabled: this.uploadFileProgress,
                  color: colors.primary
                },
                on: {
                  click: () => {
                    this.saveFile()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'document-templates__selector': true,
        'document-templates__selector--empty': !this.document
      }
    },
    [
      renderContent.call(this, h),
      renderFileManager.call(this, h),
      renderEditDialog.call(this, h)
    ]
  )
}
