import preloader from '@/components/preloader'

export default function(h) {
  if (this.loading) {
    return h(
      preloader,
      {
        props: {
          size: 8
        }
      }
    )
  }

  if (this.$to && this.$title) {
    return h(
      this.$component,
      {
        class: {
          'text-overflow': true,
          link: this.permissionCheck
        },
        props: { to: this.$to }
      },
      [ this.$title ]
    )
  }
}
