import { colors, icons } from '@/utils'

export const amountColors = {
  accrual: colors.success,
  subtract: colors.error
}

export const amountIcons = {
  accrual: icons.add,
  subtract: icons.remove
}

export const billingsStatuses = {
  pending: {
    color: colors.info,
    icon: icons.query_builder
  },
  refunded: {
    color: colors.info,
    icon: icons.replay
  },
  complete: {
    color: colors.success,
    icon: icons.done
  },
  invalid: {
    color: colors.error,
    icon: icons.clear
  },
  total: {
    color: colors.grey,
    icon: icons.equalizer
  }
}
