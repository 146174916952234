import { components, inputModes, services } from '@/utils'

import selectorOwner from '@/components/selector/owner'

function renderSelectorOwner(h) {
  return h(
    selectorOwner,
    {
      props: {
        value: this.proxy.OwnerId,
        mode: inputModes.outline,
        dense: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderIsActiveField(h) {
  return h(
    components.switch,
    {
      props: {
        value: this.proxy.isActive,
        label: this.getTranslate(`filter.isActive.${this.proxy.isActive}`),
        outline: true
      },
      on: {
        input: event => {
          this.proxy.isActive = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.requisites}-filter`
    },
    [
      renderSelectorOwner.call(this, h),
      renderIsActiveField.call(this, h)
    ]
  )
}
