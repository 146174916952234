import { eventsHistoryColors, eventsHistoryIcons, permissionPresets, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import date from '@/components/templates/service/item/date'
import owner from '@/components/templates/service/item/owner'

function renderType(h) {
  return h(
    sticker,
    {
      props: {
        value: this.getTranslate(`${services.eventsHistory}.services.${this.value.service}`),
        label: this.getTranslate(`${services.eventsHistory}.types.${this.value.type}`),
        icon: eventsHistoryIcons[this.value.type],
        color: eventsHistoryColors[this.value.type]
      }
    }
  )
}
function renderUser(h) {
  return h(
    owner,
    {
      props: {
        value: this.value.userId,
        label: this.getTranslate(`${services.eventsHistory}.labels.author`)
      }
    }
  )
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: {
        [`${services.eventsHistory}-tab__item-info`]: true,
        [`${services.eventsHistory}-tab__item-info--admin`]: this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)
      }
    },
    [
      renderType.call(this, h),
      renderUser.call(this, h)
    ]
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `service-template-item ${services.eventsHistory}-tab__item`,
      on: {
        click: () => {
          this.clickHandler()
        }
      }
    },
    [
      renderInfo.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
