import Vue from 'vue'

import { datePresets, orderDirections, serviceTemplate, services } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.notifications,

      backendGeneration: 'nest',

      find: {
        defaultFilter: {
          scopes: [ 'all', 'targetUser' ],
          channel: undefined,
          type: undefined,
          targetUserId: undefined,
          createdAtPreset: datePresets.today,
          createdAt: {
            gte: undefined,
            lt: undefined
          }
        },
        defaultPagination: { limit: 15 },
        defaultOrder: { [`${services.notifications}.createdAt`]: orderDirections.DESC }
      },

      get: false,
      update: false,
      create: false,
      remove: false,

      has: {
        search: false,
        tabs: false
      }
    })
  ],

  mounted() {
    Vue.$socket.on(`${services.notifications}.sent`, notification => {
      if (Array.isArray(this.restData[services.notifications].find.data)) {
        if (this.restData[services.notifications].find.pagination.offset === 0 && this.restData[services.notifications].find.filterIsEqualToDefault) {
          this.restData[services.notifications].find.data.splice(0, 0, notification)
          if (this.restData[services.notifications].find.data.length >= this.restData[services.notifications].find.pagination.limit) {
            this.restData[services.notifications].find.data.pop()
          }
        }
      }
    })

    Vue.$socket.on(`${services.notifications}.read`, notification => {
      const index = this.restData[services.notifications].find.data.findIndex(item => item.id === notification.id)
      if (index !== -1) {
        this.restData[services.notifications].find.data.splice(index, 1, {
          ...this.restData[services.notifications].find.data[index],
          ...notification
        })
      }
    })
  }
}
