import { escape, get } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

import { balance } from '@/utils'

import render from './render'

export default {
  name: 'UserWidget',

  mixins: [ balance() ],

  props: {
    flat: Boolean,
    rounded: Boolean
  },

  computed: {
    ...mapGetters({ isLoggedAs: 'login/isLoggedAs' }),

    $name() {
      const { firstName, lastName } = get(this.account, 'data', {})

      return [ firstName, lastName ].filter(Boolean).map(escape).join(' ')
    },
    $verified() {
      return this.account.$verified
    }
  },

  methods: {
    ...mapActions({
      logout: 'login/logout',
      loginAs: 'login/loginAs'
    })
  },

  render
}
