export default {
  titles: {
    import: {
      result: 'Import results'
    }
  },

  labels: {
    version: 'Version'
  },

  contents: {
    import: {
      result: 'Successfully imported records: {result}',
      error: 'Failed to import records from this file.<br>Please check the file and try again.<strong class="mt-2">Required columns: mcc, mnc, price.</strong>'
    }
  }
}
