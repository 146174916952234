import Vue from 'vue'

import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'Buttons',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      showRejectMenu: false,
      approveLoading: false,
      rejectLoading: false
    }
  },

  computed: {
    // INFO: https://sigmasms.slack.com/archives/D01SHR01X1R/p1656321489405479
    rejectVariants() {
      return {
        contact: 'По регистрации имени свяжитесь с нами по телефону 8 800 333 16 50',
        comment: 'В поле "Комментарий" добавьте полное наименование юридического лица и ИНН',
        length: 'Имя отправителя должно содержать от 4 до 11 латинских букв',
        common: 'Общее имя отправителя',
        international: 'Международное имя отправителя'
      }
    }
  },

  methods: {
    showSnackbar() {
      this.addSnackbar({
        text: this.getTranslate(`${services.sendernames}.snackbars.updated`),
        type: colors.success
      })
    },

    async approve() {
      try {
        this.approveLoading = true
        const response = await Vue.$GRequest.update(services.sendernames, this.value.id, {
          ...this.value,
          moderation: MODERATION_VALUES.approved,
          meta: {
            // INFO: https://sigmasms.slack.com/archives/D01SHR01X1R/p1656321489405479
            adminComment: 'Активно по Билайн и Теле2, по работе имени на МТС и Мегафон - обратитесь к своему менеджеру'
          }
        })
        if (response) {
          this.showSnackbar()
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.approveLoading = false
      }
    },

    async reject(variant) {
      try {
        this.rejectLoading = true
        const replacement = {
          moderation: MODERATION_VALUES.rejected,
          meta: { adminComment: 'Причина не указана' }
        }
        if (variant) {
          replacement.meta.adminComment = this.rejectVariants[variant]
        }
        const response = await Vue.$GRequest.update(services.sendernames, this.value.id, {
          ...this.value,
          ...replacement
        })
        if (response) {
          this.showSnackbar()
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.rejectLoading = false
      }
    }
  },

  render
}
