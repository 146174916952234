import { buttonModes, components, icons } from '@/utils'

import button from '@/components/button'

function renderArrow(h, direction = 'prev') {
  if (this.hasArrows) {
    return h(
      button,
      {
        props: {
          icon: direction === 'prev' ? icons.keyboard_arrow_left : icons.keyboard_arrow_right,
          mode: buttonModes.flat,
          disabled: this[`${direction}Disabled`]
        },
        on: {
          click: () => {
            this.slide(direction)
          }
        }
      }
    )
  }
}

function renderItem(h, tab) {
  const { key, title, icon } = tab
  const active = this.proxy === key

  return h(
    button,
    {
      class: { [`${components['tabs-header']}__item--active`]: active },
      props: {
        icon,
        label: title,
        mode: active ? buttonModes.flat : buttonModes.clear
      },
      on: {
        click: () => this.onItemClick(tab)
      }
    }
  )
}
function renderLink(h, tab) {
  const { key } = tab

  return h(
    'router-link',
    {
      class: 'link link--passive',
      props: {
        to: {
          name: this.$route.name,
          query: { currentTab: key }
        }
      }
    },
    [ renderItem.call(this, h, tab) ]
  )
}
function renderItems(h) {
  return h(
    'div',
    {
      class: `${components['tabs-header']}__items`,
      ref: 'items'
    },
    [
      this.tabs.map(tab => {
        if (this.isLink) {
          return renderLink.call(this, h, tab)
        }

        return renderItem.call(this, h, tab)
      })
    ]
  )
}
function renderHolder(h) {
  return h(
    'div',
    {
      class: `${components['tabs-header']}__holder`,
      ref: 'holder',
      on: { wheel: this.onWheel }
    },
    [ renderItems.call(this, h) ]
  )
}

export default function(h) {
  if (Array.isArray(this.tabs) && this.tabs.length) {
    return h(
      'div',
      {
        class: {
          [`${components['tabs-header']}`]: true,
          [`${components['tabs-header']}--arrows`]: this.hasArrows
        }
      },
      [
        renderArrow.call(this, h, 'prev'),
        renderHolder.call(this, h),
        renderArrow.call(this, h, 'next')
      ]
    )
  }
}
