import { mergeWith } from 'lodash'

export const mergeReplacingArrays = (...args) => {
  return mergeWith(...args, (a, b) => {
    if (Array.isArray(a) || Array.isArray(b)) {
      return b
    }

    return undefined
  })
}
