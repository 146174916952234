export const types = {
  sms: 'SMS',
  smsping: 'SMS ping',
  mms: 'MMS',
  viber: 'Viber',
  voice: 'Voice',
  whatsapp: 'WhatsApp',
  email: 'E-mail',
  vk: 'ВКонтакте',
  ok: 'Одноклассники',
  push: 'Push',
  flashcall: 'Flash call',
  telegram: 'Телеграм',
  tgBot: 'Telegram бот',
  facebook: 'Facebook',
  instagram: 'Instagram',
  subtract: 'Списание',
  whatsappchat: 'WhatsApp чат',
  viberchat: 'Viber чат',
  vkchat: 'ВКонтакте чат',
  telegramchat: 'Телеграм чат',
  facebookchat: 'Facebook чат',
  accrual: 'Начисление',
  web: 'Web',
  incoming_wapi: 'WAPI',
  mobileid_mts_push: 'Mobile ID MTS Push',
  mobileid_mts_push_sms: 'Mobile ID MTS Push SMS',
  incoming_tg_bot: 'Telegram Bot',
  hlr: 'HLR',
  apisender: 'WAPI',
  web: 'Web',
  tgBot: 'Telegram Bot',
  accrual: 'Начисление',
  subtract: 'Списание',

  subscription: {
    apisender: 'WAPI'
  }
}

export const labels = {
  title: 'Название рассылки',
  message: 'Сообщение',
  recipient: 'Получатель',
  type: 'Тип сообщения',
  file: 'Файл',
  sender: 'Отправитель',
  sendername: 'Имя отправителя',
  allowSending: 'Разрешенные типы рассылок',
  text: 'Текст',
  image: 'Ссылка на изображение',
  images: 'Картинки',
  hash: 'Хэш',
  service: 'Сервис',
  provider: 'Провайдер',
  defaultRoute: 'Маршрут по умолчанию',
  ip: 'IP',
  headers: 'Заголовки',
  body: 'Тело',
  params: 'Параметры',
  hostname: 'Имя хоста',
  request: 'Запрос',
  response: 'Ответ',
  server: 'Сервер',
  method: 'Метод',
  uri: 'Ссылка',
  statusCode: 'Код статуса',
  preset: 'Пресет',
  encoding: 'Кодировка',
  asOwner: 'От имени владельца сообщения',
  button: {
    subheader: 'Кнопка',
    url: 'Ссылка кнопки',
    text: 'Текст кнопки',
    action: 'Действие кнопки'
  },
  target: 'нет адресов | один адрес | {count} адресов(а)',
  targets: {
    include: 'Адреса для отправки',
    exclude: 'Адреса для исключения',
    info: 'Выберите адресные книги или введите отдельные номера (не более 100 номеров)'
  },
  schedule: {
    delay: 'Начало рассылки',
    until: 'Растянуть до даты'
  },
  $options: {
    onStatus: 'По статусу',
    onTimeout: 'По таймауту',
    onIncomingPayload: 'По входящему'
  },
  options: {
    status: 'Предыдущее сообщение получило статус',
    timeout: 'Прошло',
    except: 'И статус предыдущего сообщения не равен',
    onIncomingPayload: {
      text: 'Если текст входящего сообщения равен'
    }
  },
  template: 'Шаблон',
  id: 'ID',
  chainId: 'Chain ID',
  groupId: 'Group ID',
  owner: 'Владелец',
  Owner: 'Владелец',
  OwnerId: 'Владелец',
  TariffId: 'Тариф',
  dursec: 'Звонок (всего)',
  billsec: 'Звонок (тарификация)',
  pattern: 'Шаблон для модерации',
  status: 'Статус',
  time: 'Время',
  source: 'Источник',
  amount: 'Сумма',
  payload: {
    sender: 'Отправитель',
    recipient: 'Получатель',
    text: 'Текст',
    image: 'Изображение',
    file: 'Файл',
    tts: 'TTS',
    button: {
      url: 'URL кнопки',
      text: 'Текст кнопки'
    }
  },
  state: {
    detailed: {
      ids: 'Detailed IDs'
    },
    status: 'Статус'
  },
  meta: {
    _recipientData: 'Оператор',
    _routing: {
      provider: 'Провайдер'
    },
    billing: {
      id: 'Billing ID',
      amount: 'Сумма'
    },
    billings: {
      ids: 'Billing ID'
    },
    stats: {
      segments: 'Сегменты',
      characters: 'Символы'
    },
    replacement: {
      payload: {
        sender: 'Подмена отправителя',
        text: 'Подмена текста'
      }
    }
  },
  data: {
    price: 'Цена',
    multiplier: 'Множитель'
  },

  external: {
    id: 'Внешний ID'
  }
}

export default {
  title: 'Детальный отчёт',

  sending: 'Рассылка',
  sendings: 'Рассылки',
  new: 'Создать рассылку',
  noData: 'Сообщения не найдены',
  targets: 'Адреса',
  messages: 'Сообщения',
  preview: 'Предпросмотр',
  create: 'Создать рассылку',
  type: 'Тип рассылки',
  uploadImageText: 'Перетащите сюда изображение<br>или<br>кликните для выбора файла',
  getImageKey: 'Получить код картинки',
  count: 'нет отправлений | одно новое отправление | {count} новых отправлений(ия)',
  insert: 'Вставка',

  labels,

  headers: {
    status: 'Статус',
    owner: 'Владелец',
    provider: 'Провайдер',
    type: 'Тип',
    recipient: 'Получатель',
    sender: 'Отправитель',
    text: 'Текст',
    amount: 'Стоимость',
    operator: 'Оператор',
    count_sent: 'В&nbsp;процессе',
    count_delivered: 'Доставлено',
    percent_delivered: 'Процент доставленных',
    count_seen: 'Просмотрено',
    count_failed: 'Неудачно',
    count_total: 'Всего',
    opgroup: 'Группа оператора',
    operatorGroup: 'Группа оператора',
    mcc: 'mcc',
    mnc: 'mnc',
    sending: 'Отправка на',
    fallback: 'Переотправка на',
    tariff: 'Тариф',
    from: 'От кого',
    billing_tariff_price: 'Цена',
    billing_sum_all: 'Сумма за всё',
    billing_sum_pending_complete: 'Сумма завершённых',
    billing_sum_refunded_invalid: 'Сумма возмещённых',
    updatedAt: 'Обновлено в',
    createdAt: 'Создано в'
  },

  titles: {
    preview: 'Предпросмотр',
    filter: 'Фильтр',
    sudoData: 'Данные',
    resend: 'Переотправка',
    incoming: {
      details: 'Входящие сообщения'
    },
    replacement: {
      original: 'Оригинал',
      rule: 'По правилу',
      provider: 'По провайдеру'
    }
  },

  filter: {
    attributes: 'Атрибуты',
    id: 'ID',
    groupId: 'ID группы',
    chainId: 'ID цепочки',
    title: 'Фильтр',
    status: 'Статус',
    type: 'Тип',
    OwnerId: 'Владелец',
    from: 'От кого',
    pattern: 'Паттерн',
    payload: {
      recipient: 'Получатель',
      sender: 'Отправитель'
    },
    meta: {
      _routing: {
        provider: 'Провайдер'
      },
      user: {
        id: 'Владелец'
      }
    },
    state: {
      status: 'Статус'
    },
    date: {
      from: 'Дата с',
      by: 'Дата до'
    },
    time: {
      from: 'Время с',
      by: 'Время до'
    },
    clear: 'Очистить фильтр'
  },

  hints: {
    date: {
      from: 'ГГГГ-ММ-ДД',
      by: 'ГГГГ-ММ-ДД'
    },
    time: {
      from: 'ЧЧ:ММ',
      by: 'ЧЧ:MM'
    },
    button: {
      url: 'Максимум 2048 символов, пример: http://www.example.com',
      text: 'Максимум 30 символов'
    },
    text: 'Посмотреть полный текст сообщения',
    empty: 'Чтобы получить данные воспользуйтесь фильтром.',
    remoteReport: 'Ваш отчёт отправлен на e-mail который вы указали при регистрации.',
    sale: 'Продажа',
    purchase: 'Закупка',
    has: {
      replacement: 'Есть подмена'
    },
    resend: 'По умолчанию сообщение будет отправлено от лица текущего пользователя.',
    apisender: 'Найден инстанс привязанный к имени отправителя:',
    replacement: {
      original: 'Оригинальный контент сообщения',
      rule: 'Результат подмены по правилам',
      provider: 'Контент отправленный на провайдера'
    },
    warning: 'Для того что бы тестовое СМС было доставлено без отправки на модерацию, <strong>не меняйте его</strong>!<br><br>Для возможности отправки сообщений с любым текстом свяжитесь с вашим менеджером или технической поддержкой.'
  },

  presets: {
    sendings: {
      details: 'Детальный отчёт по отправлениям',
      group001: 'Группировка по типу отправления',
      group002: 'Группировка по группе оператора и провайдеру',
      group003: 'СПОМ' // Самый подробный отчёт в мире!
    }
  },

  statistic: {
    title: 'Статистика',
    symbols: 'Символов: ',
    parts: 'Частей: ',
    segment: 'Сегментов: '
  },

  statuses: {
    pending: 'В ожидании',
    paused: 'Приостановлен',
    processing: 'В процессе',
    sent: 'Отправлено',
    delivered: 'Доставлено',
    seen: 'Просмотрено',
    failed: 'Неудачно'
  },

  report: {
    download: 'Скачать в файл',
    get: 'Получить отчёт'
  },

  attributes: {
    id: 'ID',
    chainId: 'ID цепочки',
    type: 'Тип',
    userId: 'Пользователь',
    providerId: 'Провайдер',
    operatorCode: 'Код оператора',
    operatorGroup: 'Группа оператора',
    text: 'Текст',
    sender: 'Отправитель',
    recipient: 'Получатель',
    error: 'Ошибка',
    status: 'Статус'
  },

  status: {
    succeed: 'Успешно',
    failed: 'Не доставлено',
    pending: 'Ожидание',
    processing: 'Обработка',
    queued: 'В очереди',
    paused: 'Приостановлено',
    sent: 'Отправлено',
    processed: 'Обработано',
    delivered: 'Доставлено',
    seen: 'Просмотрено'
  },

  subheaders: {
    scheduling: 'Планирование',
    options: 'Параметры переотправления',
    resend: 'Отправлять это сообщение если:'
  },

  confirm: {
    send: {
      title: 'Вы собираетесь отправить ',
      count: 'ноль сообщений | одно сообщение | {count} сообщений(ия)',
      text: 'Проверьте получателей, имя отправителя, текст и другую информацию перед тем, как продолжить.',
      loading: 'Мы подготавливаем вашу рассылку. Это займет немного времени.'
    }
  },

  tooltips: {
    preview: 'Предпросмотр сообщения',
    count: '{count} сообщений(ие)',
    save: 'Сохранить как шаблон',
    planning: 'Планирование',
    insert: 'Вставить переменную',
    billing_sum_pending_complete: 'Сумма завершённых и ожидающих',
    billing_sum_refunded_invalid: 'Сумма возмещённых и ошибочных',
    refresh: 'Обновление данных таблицы с применением выбранного фильтра.',
    segments: 'Длина сообщения | Сегменты',
    billSec: 'Секунды тарификации',
    approve: 'Одобрить',
    reject: 'Отклонить',
    moderation: 'На модерации',

    tasks: {
      filter: {
        owner: 'Перейти на вкладку исходящие и добавить в фильтр владельца текущей задачи'
      }
    }
  },

  filterMenu: {
    any: 'Любые данные',
    string: 'Русские символы без пробелов',
    phone: 'Телефон',
    email: 'Email',
    date: 'Дата дд.мм.гг'
  },

  catchSymbolsMenu: {
    symbols: 'Буквы',
    numbers: 'Цифры',
    symbolsNumbers: 'Буквы или цифры',
    symbolsNumbersSpaces: 'Буквы, цифры или пробелы',
    russianSymbols: 'Русские буквы',
    russianSymbolsNumber: 'Русские буквы или цифры',
    russianSymbolsNumbersSpaces: 'Русские буквы, цифры или пробелы'
  },

  tabs: {
    format: 'Форматированные',
    source: 'Исходные',
    create: 'Новое сообщение',
    templates: 'Шаблоны',
    sendings: 'Отправления',
    outgoing: 'Исходящие',
    incoming: 'Входящие',
    tasks: 'Задачи',
    logs: 'Логи',
    routing: 'Маршрутизация',
    billings: 'Счета',
    fallbacks: 'Переотправления',
    replacement: 'Подмена',
    sendingsModeration: 'Модерация',
    eventsHistory: 'История изменений'
  },

  types,

  contents: {
    noAllowedSendingTypes: 'На данный момент вы не можете отправлять ни один из типов сообщений.<br>Пожалуйста, обратитесь к администратору для настройки вашего аккаунта.',
    noPattern: 'Шаблон не найден',
    confirm: {
      resend: 'Подтвердите переотправку сообщения.'
    }
  },

  errors: {
    payload: {
      button: {
        url: 'Поле url не соответствует требуемому шаблону!'
      }
    },
    recipients: {
      include: 'Адреса для отправки обязателены'
    },
    button: {
      url: 'Превышен лимит символов',
      text: 'Превышен лимит символов'
    }
  },

  schedule: {
    until: {
      0: 'Не растягивать',
      1: 'Растянуть на 1 час',
      2: 'Растянуть на 2 часа',
      3: 'Растянуть на 3 часа',
      4: 'Растянуть на 4 часа',
      5: 'Растянуть на 5 часов',
      6: 'Растянуть на 6 часов',
      7: 'Растянуть на 7 часов',
      8: 'Растянуть на 8 часов',
      9: 'Растянуть на 9 часов',
      10: 'Растянуть на 10 часов',
      11: 'Растянуть на 11 часов',
      12: 'Растянуть на 12 часов'
    }
  },

  buttons: {
    refresh: 'Обновить'
  },

  snackbars: {
    created: 'Рассылка успешно создана.',
    updated: 'Рассылка успешно изменена.',
    removed: 'Рассылка успешно удалена.'
  }
}
