import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { inputModes, services } from '@/utils'

import selectorSendername from '@/components/selector/sendername'

export default function(h) {
  return h(
    selectorSendername,
    {
      props: {
        value: this.proxy,
        type: SENDING_TYPES.sms,
        label: this.getTranslate(`${services.notifications}.labels.${services.settingsSite}.sms.sendername`),
        mode: inputModes['line-label'],
        dense: true,
        clearable: true,
        required: false
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
