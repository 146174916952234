import { buttonModes, colors, components, services, sizes } from '@/utils'

import tag from '@/components/tag'
import button from '@/components/button'
import sticker from '@/components/misc/sticker'
import pagination from '@/components/pagination'
import sourceBox from '@/components/source/box'
import buttonRefresh from '@/components/button/refresh'
import eventsHistoryTabItem from '@/components/services/eventsHistory/tab/item'

function renderTab(h, type, key) {
  return h(
    'div',
    {
      class: `${services.eventsHistory}__dialog-col`
    },
    [
      h(
        tag,
        {
          props: {
            label: this.getLabel(key),
            size: sizes.small,
            color: colors.grey
          }
        }
      ),
      h(
        sourceBox,
        {
          props: {
            value: this.compareData[type][key],
            rounded: true
          }
        }
      )
    ]
  )
}

function renderTabForCreated(h) {
  return h(
    'div',
    {
      class: `${services.eventsHistory}__dialog-cols`,
      style: { 'grid-template-columns': '1fr' }
    },
    [
      h(
        sourceBox,
        {
          props: {
            value: this.compareData[this.currentTab],
            rounded: true
          }
        }
      )
    ]
  )
}

function renderValues(h, type) {
  return Object.keys(this.compareData[type]).map(key => {
    return renderTab.call(this, h, type, key)
  })
}

function renderTabs(h) {
  return Object.keys(this.compareData[this.currentTab]).map(key => {
    return this.types.map(type => renderTab.call(this, h, type, key))
  })
}

function renderHeaders(h) {
  return this.types.map(type => {
    return h(
      tag,
      {
        props: {
          label: this.getTranslate(`${services.eventsHistory}.labels.${type}`),
          size: sizes.small
        }
      }
    )
  })
}

function renderEntityId(h) {
  if (this.compareId) {
    return h(
      sticker,
      {
        props: {
          value: { entityId: this.compareId },
          label: this.getTranslate(`${services.eventsHistory}.labels.compareId`),
          pathToTitle: 'entityId',
          maxWidth: 'fit-content',
          copy: true
        }
      }
    )
  }
}
function renderDialogContent(h) {
  if (this.tabForCreated) {
    return renderTabForCreated.call(this, h)
  } else {
    if (this.viewport.breakpoint.mdUp) {
      return h(
        'div',
        {
          class: `${services.eventsHistory}__dialog-cols`
        },
        [
          renderHeaders.call(this, h),
          renderTabs.call(this, h)
        ]
      )
    } else {
      return [
        h(
          components['tabs-header'],
          {
            props: {
              value: this.currentTab,
              tabs: this.types.map(key => {
                return {
                  key,
                  title: this.getTranslate(`${services.eventsHistory}.labels.${key}`)
                }
              })
            },
            on: {
              input: event => {
                this.currentTab = event
              }
            }
          }
        ),

        h(
          components.tabs,
          {
            props: { value: this.currentTab },
            scopedSlots: {
              body: () => {
                return h(
                  'div',
                  {
                    class: `${services.eventsHistory}__dialog-cols`,
                    key: this.currentTab
                  },
                  [ renderValues.call(this, h, this.currentTab) ]
                )
              }
            }
          }
        )
      ]
    }
  }
}
function renderDialogBody(h) {
  return h(
    'div',
    {
      class: `${services.eventsHistory}__dialog-body`
    },
    [
      renderEntityId.call(this, h),
      renderDialogContent.call(this, h)
    ]
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.dialogTitle,
        value: this.showDialog,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 800 : '100%'
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderDialogBody.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}

function renderDivider(h) {
  if (this.items.length) {
    return h(components.divider)
  }
}
function renderPagination(h) {
  return h(
    pagination,
    {
      props: {
        value: this.pagination,
        service: services.eventsHistory,
        selectRows: false
      },
      on: {
        input: event => {
          this.pagination = event
        }
      }
    }
  )
}
function renderRefreshButton(h) {
  return h(
    buttonRefresh,
    {
      props: {
        state: this.loading,
        shortClickMethod: () => this.getItems()
      }
    }
  )
}
function renderFooter(h) {
  if (this.items.length) {
    return h(
      'div',
      {
        class: 'fjcfe grid-gap--8 pa-2'
      },
      [
        renderPagination.call(this, h),
        renderRefreshButton.call(this, h)
      ]
    )
  }
}

function renderBody(h) {
  if (this.items.length) {
    return this.items.map((item, index) => {
      return h(
        eventsHistoryTabItem,
        {
          props: {
            value: item
          },
          on: {
            click: () => {
              this.compareItems(item, index)
            }
          },
          key: item.id
        }
      )
    })
  } else {
    return h(components.empty)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.eventsHistory}-tab`
    },
    [
      renderBody.call(this, h),
      renderDivider.call(this, h),
      renderFooter.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}
