import { buttonModes, colors, services } from '@/utils'

import tag from '@/components/tag'

export default function(h) {
  if (this.checkPermissions(`advanced.${services.sendingsModeration}.get`)) {
    if (this.count > 0) {
      return h(
        tag,
        {
          props: {
            label: this.count,
            color: colors.primary,
            mode: buttonModes.filled
          }
        }
      )
    }
  }
}
