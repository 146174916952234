import { get } from 'lodash'

import { getLocaleDateString, getLocaleTimeString } from '@/utils'

import SingleLink from '@/components/misc/SingleLink'
import EntityTable from '@/components/entity/table'

function renderDate(h, date) {
  if (date) {
    return h(
      'div',
      {
        class: 'faic'
      },
      [
        getLocaleDateString(date),

        h(
          'span',
          {
            class: 'text--grey pl-1 pr-1'
          },
          this.getTranslate('misc.in')
        ),

        getLocaleTimeString(date)
      ]
    )
  }
}

function renderDataByPath(h, path) {
  switch (path) {
    case 'owner': {
      return h(SingleLink, { props: { value: this.proxy.Owner } })
    }
    case 'reseller': {
      return h(SingleLink, { props: { value: this.proxy.Reseller } })
    }
    case 'source': {
      return get(this.proxy, 'data.meta.source')
    }
    case 'createdAt': {
      return renderDate.call(this, h, this.proxy.createdAt)
    }
    case 'updatedAt': {
      return renderDate.call(this, h, this.proxy.updatedAt)
    }
  }
}

export default function(h) {
  return h(
    EntityTable,
    {
      props: {
        rows: this.paths.map(path => {
          return {
            title: this.getTranslate(`misc.entity.info.${path}`),
            value: renderDataByPath.call(this, h, path)
          }
        })
      }
    }
  )
}
