import Vue from 'vue'

import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, globalErrorHandler, globalErrorProcessor, icons, services } from '@/utils'

import render from './render'

export default {
  name: 'Actions',

  props: {
    sending: {
      type: Object,
      default: () => ({})
    },

    filter: {
      type: Object,
      default: () => ({})
    },

    massive: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      showRejectMenu: false,

      providerId: undefined
    }
  },

  computed: {
    icon() {
      const result = {
        approved: {
          icon: icons.done,
          color: colors.success
        },
        rejected: {
          icon: icons.close,
          color: colors.error
        },
        rejectedRefound: {
          icon: icons.money_off_csred,
          color: colors.error
        }
      }
      if (this.massive) {
        result.approved = {
          icon: icons.done_all,
          color: colors.success
        }
        result.rejected = {
          icon: icons.remove_done,
          color: colors.error
        }
      }

      return result
    },

    disabled() {
      return this.loading || this.massive && !this.filter.OwnerId
    }
  },

  methods: {
    async createSendingsModerationTask(moderation, refund) {
      if (this.checkPermissions(`advanced.${services.sendingsModeration}.create`)) {
        this.loading = true
        if (moderation === MODERATION_VALUES.rejected) {
          this.providerId = undefined
        }
        try {
          await Vue.$GRequest.create(services.sendingsModeration, {
            refund,
            range: this.filter.range,
            sender: this.massive ? this.filter.sender : this.sending.sender,
            pattern: this.massive ? this.filter.pattern : this.sending.text,
            providerId: this.providerId,
            moderation: MODERATION_VALUES[moderation],
            OwnerId: this.massive ? this.filter.OwnerId : this.sending.OwnerId || this.filter.OwnerId
          })
          this.addSnackbar({
            text: this.getTranslate('tasks.snackbars.created'),
            type: 'success'
          })
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
          this.providerId = undefined
        }
      }
    }
  },

  render
}
