import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, components, inputModes, services } from '@/utils'

import info from '@/components/info'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

const serviceNameViaPoint = services['otp-settings/channels'].split('/').join('.')
const serviceNameKebab = services['otp-settings/channels'].split('/').join('-')

function renderNameField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.name,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.name`),
        disabled: this.disabled,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        required: true,
        autofocus: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.name = event
        }
      }
    }
  )
}

function renderChannelTypeField(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.channelType,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.channelType`),
        items: this.channelTypes,
        mode: inputModes['line-label'],
        disabled: this.disabled || !this.isNew,
        dense: true,
        rounded: true,
        required: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.channelType = event
        }
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title
              }
            }
          )
        },
        item: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title,
                as: 'text'
              }
            }
          )
        }
      }
    }
  )
}

function renderInfo(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${serviceNameViaPoint}.hints.additionalSettings`),
        color: colors.info
      }
    }
  )
}
function renderLifetimeField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.additionalSettings.lifetime,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.additionalSettings.lifetime`),
        disabled: this.disabled,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        required: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.additionalSettings.lifetime = event
        }
      }
    }
  )
}
function renderNumberOfTriesField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.additionalSettings.numberOfTries,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.additionalSettings.numberOfTries`),
        disabled: this.disabled,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        required: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.additionalSettings.numberOfTries = event
        }
      }
    }
  )
}
function renderBooleanField(h, prop) {
  return h(
    components.switch,
    {
      props: {
        value: this.proxy.additionalSettings.code[prop],
        label: this.getTranslate(`${serviceNameViaPoint}.labels.additionalSettings.code.${prop}`),
        disabled: this.disabled
      },
      on: {
        input: event => {
          this.proxy.additionalSettings.code[prop] = event
        }
      }
    }
  )
}
function renderStringField(h, prop, required, number) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.additionalSettings.code[prop],
        label: this.getTranslate(`${serviceNameViaPoint}.labels.additionalSettings.code.${prop}`),
        disabled: this.disabled,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        required,
        mask: number ? /^[0-9]*$/ : undefined
      },
      on: {
        input: event => {
          this.proxy.additionalSettings.code[prop] = event
        }
      }
    }
  )
}
function renderCodeFields(h) {
  const codeFields = [
    'length',
    'symbols',
    'exclude'
  ]

  return codeFields.map(prop => renderStringField.call(this, h, prop, false, prop === 'length'))
}
function renderBooleanFields(h) {
  const booleanFields = [
    'excludeSimilarCharacters',
    'useSymbols',
    'useNumbers',
    'useLowerCaseLetters',
    'useUpperCaseLetters',
    'strict',
    'beautify'
  ]

  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [ booleanFields.map(prop => renderBooleanField.call(this, h, prop)) ]
  )
}
function renderAdditionalSettingsCode(h) {
  if (this.proxy.channelType === SENDING_TYPES.sms) {
    return h(
      components.card,
      {
        class: `${serviceNameKebab}-form__code`,
        props: {
          title: this.getTranslate(`${serviceNameViaPoint}.titles.additionalSettings.code`),
          outline: true,
          dashed: true,
          rounded: true
        }
      },
      [
        renderCodeFields.call(this, h),
        renderBooleanFields.call(this, h)
      ]
    )
  }
}
function renderAdditionalSettings(h) {
  return h(
    components.card,
    {
      class: `${serviceNameKebab}-form__additional`,
      props: {
        title: this.getTranslate(`${serviceNameViaPoint}.titles.additionalSettings.title`),
        outline: true,
        dashed: true,
        rounded: true
      }
    },
    [
      renderInfo.call(this, h),
      renderLifetimeField.call(this, h),
      renderNumberOfTriesField.call(this, h),
      renderAdditionalSettingsCode.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    components.form,
    {
      class: {
        [`${serviceNameKebab}-form`]: true,
        [`${serviceNameKebab}-form--new`]: this.isNew
      }
    },
    [
      renderNameField.call(this, h),
      renderChannelTypeField.call(this, h),
      renderAdditionalSettings.call(this, h)
    ]
  )
}
