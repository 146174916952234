import Vue from 'vue'

import { get } from 'lodash'
import { mapActions } from 'vuex'

import { backendServerIP, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

export default {
  name: 'RecoverTemplate',

  data() {
    return {
      login: undefined,
      password: undefined,

      loading: false,
      passwordVisibility: false,

      validation: undefined,
      state: undefined
    }
  },

  computed: {
    $key() {
      return get(this.$route, 'params.key')
    },

    $buttonDisabled() {
      switch (this.state) {
        case undefined: return this.loading || !this.login
        case 'key': return this.loading || !this.password
        default: return this.loading
      }
    }
  },

  watch: {
    $route: {
      handler() {
        this.checkRecoveryKey()
      },
      deep: true
    }
  },

  mounted() {
    this.checkRecoveryKey()
  },

  beforeDestroy() {
    this.login = undefined
    this.password = undefined
    this.loading = false
    this.validation = undefined
    this.state = undefined
  },

  methods: {
    ...mapActions({
      setToken: 'authentication/setToken',
      setAccountFromToken: 'authentication/setAccountFromToken'
    }),

    async requestRecover() {
      if (this.login) {
        try {
          this.loading = true
          const response = await fetch(`${backendServerIP}/api/recover/`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: this.login })
          })
          if (response.ok) {
            const { result } = await response.json()
            if (result === this.login) {
              this.validation = true
              this.state = 'request'
              this.login = undefined
            }
          } else {
            const { type, field, message, data } = await response.json()
            if (Array.isArray(data) && data.length) {
              data.forEach(this.errorProcess)
            } else {
              this.errorProcess({
                type,
                field,
                message
              })
            }
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    },

    async checkRecoveryKey() {
      if (this.$key) {
        try {
          this.loading = true
          const response = await fetch(`${backendServerIP}/api/recover/${this.$key}`, {
            method: 'get',
            headers: { 'Content-Type': 'application/json' }
          })
          if (response.ok) {
            const { result } = await response.json()
            this.validation = result
            this.state = 'key'
            if (this.validation === false) {
              Vue.router.push({ name: 'login' })
            }
          } else {
            const { type, field, message, data } = await response.json()
            if (Array.isArray(data) && data.length) {
              data.forEach(this.errorProcess)
            } else {
              this.errorProcess({
                type,
                field,
                message
              })
            }
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    },

    async changePassword() {
      if (this.password && this.$key) {
        try {
          this.loading = true
          const response = await fetch(`${backendServerIP}/api/recover/${this.$key}`, {
            method: 'post',
            body: JSON.stringify({ password: this.password }),
            headers: { 'Content-Type': 'application/json' }
          })
          if (response.ok) {
            const { result } = await response.json()
            this.password = undefined
            this.setToken(result)
            await this.setAccountFromToken()
            Vue.router.push({ name: 'home' })
          } else {
            const { type, field, message, data } = await response.json()
            if (Array.isArray(data) && data.length) {
              data.forEach(this.errorProcess)
            } else {
              this.errorProcess({
                type,
                field,
                message
              })
            }
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    },

    errorProcess(value) {
      const { type, message, field } = value
      const text = type ? this.getTranslate(`errors.types.${type}`, { field }) : message
      if (text) {
        this.addSnackbar({
          text,
          type: 'error'
        })
      }
    }
  },

  render
}
