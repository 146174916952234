import { services } from '@/utils'

import sticker from '@/components/misc/sticker'
import owner from '@/components/templates/service/item/owner'
import actions from '@/components/services/yclients/item/actions'
import date from '@/components/templates/service/item/date'

function renderSalon(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        service: services.yclients,
        pathToTitle: 'salonId',
        label: this.getTranslate(`${services.yclients}.labels.salon`),
        icon: this.status.icon,
        color: this.status.color,
        iconTooltip: this.status.tooltip
      }
    }
  )
}

function renderOwner(h) {
  if (this.viewport.size.width > 500) {
    return h(
      owner,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

function renderError(h) {
  if (this.viewport.size.width > 700) {
    if (this.error) {
      return h(
        sticker,
        {
          props: {
            value: this.error,
            label: this.getTranslate(`${services.yclients}.labels.error`)
          }
        }
      )
    }
  }
}

function renderInfo(h) {
  return h(
    'div',
    {
      class: `${services.yclients}-item__info`
    },
    [
      renderSalon.call(this, h),
      renderOwner.call(this, h),
      renderError.call(this, h)
    ]
  )
}

function renderActions(h) {
  return h(
    actions,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-item': true,
        [`${services.yclients}-item`]: true,
        'hover-parent hover-parent--opacity': this.viewport.breakpoint.mdUp
      }
    },
    [
      renderInfo.call(this, h),
      renderActions.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
