import { proxy } from '@/utils'

import render from './render'

export default {
  name: 'Filters',

  mixins: [ proxy({ type: 'object' }) ],

  render
}
