import { generateAmount } from '@sigma-legacy-libs/essentials/lib/utils'

import { services } from '@/utils'

import render from './render'

export default {
  name: 'YooMoney',

  data() {
    return {
      sum: 1000
    }
  },

  computed: {
    validation() {
      if (!this.sum) {
        return false
      }

      return true
    },

    payload() {
      return {
        receiver: '410011603821523', // https://sigmasms.slack.com/archives/DQV41N7HB/p1656062571162399
        'quickpay-form': 'button',
        paymentType: 'PC',
        sum: this.sum,
        label: this.account.username,
        successURL: window.location.origin,
        targets: [ window.location.origin, '#', 'admin', 'users', this.account.id ].join('/'),
        formcomment: `Пополнение счёта кабинета "${this.account.username}".`,
        'short-dest': `Пополнение счёта кабинета "${this.account.username}".`,
        'need-email': true,
        'need-phone': true
      }
    },

    commission() {
      return [
        [
          '+',
          this.getCommission(),
          this.getTranslate('currency.RUB.symbol.unicode')
        ].join(''),
        this.getTranslate(`${services.payments}.hints.commissions`)
      ].join(' ')
    }
  },

  methods: {
    getCommission() {
      const amount = this.sum || 0
      const amountWithCommission = generateAmount(amount, 3)

      return (amountWithCommission - amount).toFixed(2)
    }
  },

  render
}
