import { get } from 'lodash'

import { SENDING_STATES, colors, moderationColors, moderationIcons, services } from '@/utils'

import tag from '@/components/tag'
import info from '@/components/info'
import formatTable from '@/components/format/table'

function renderTags(h) {
  const moderation = get(this.value, 'state.moderation', 'pending')
  const status = get(this.value, 'state.status')

  const status_color = get(SENDING_STATES, `${status}.color`, '')
  const status_name = get(SENDING_STATES, `${status}.name`, '')
  const status_icon = get(SENDING_STATES, `${status}.icon`, '')

  return h(
    'div',
    {
      class: 'faic grid-gap--8 px-2 text-upper'
    },
    [
      h(
        tag,
        {
          props: {
            color: colors[this.value.type],
            label: this.value.type
          }
        }
      ),

      h(
        tag,
        {
          class: 'text-upper',
          props: {
            icon: status_icon,
            color: status_color,
            label: this.getTranslate(`${services.sendings}.statuses.${status_name}`)
          }
        }
      ),

      h(
        tag,
        {
          class: 'text-upper',
          props: {
            icon: moderationIcons[moderation],
            color: moderationColors[moderation],
            label: this.getTranslate(`misc.moderation.${moderation}`).toLowerCase()
          }
        }
      )
    ]
  )
}

function renderError(h) {
  const value = get(this.value, 'state.error')
  if (value) {
    return h(
      'div',
      {
        class: 'px-2'
      },
      [
        h(
          info,
          {
            props: {
              value,
              color: colors.error
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  if (this.value) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        renderTags.call(this, h),
        renderError.call(this, h),
        h(formatTable, { props: { value: this.value } })
      ]
    )
  }
}
