import Vue from 'vue'

import { cloneDeep, get } from 'lodash'

import { buttonModes, components, globalErrorHandler, globalErrorProcessor, icons, services } from '@/utils'

import render from './render'

export default {
  name: 'FileEdit',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    component: {
      type: String,
      default: 'btn',
      validator: value => {
        return [ 'btn', components['list-item'] ].includes(value)
      }
    }
  },

  data() {
    return {
      currentEditFile: undefined,
      googleFileURL: undefined,

      loading: false,
      showDialog: false
    }
  },

  computed: {
    $props() {
      const result = {
        icon: icons.description,
        loading: this.loading,
        disabled: this.loading
      }
      switch (this.component) {
        case 'btn': {
          result.mode = buttonModes.flat
          result.tooltip = this.getTranslate(`${services.contracts}.tooltips.edit`)
          break
        }
        case components['list-item']: {
          result.label = this.getTranslate('misc.buttons.edit')
          result.dense = true
          break
        }
      }

      return result
    }
  },

  methods: {
    async edit() {
      try {
        this.loading = true
        const { id, OwnerId } = get(this.value, 'File', {})
        if (id) {
          const { data } = await Vue.$GRequest.find(`${services.storage}/edit/${id}`, { query: { OwnerId } })
          if (data) {
            this.googleFileURL = data.result
            this.currentEditFile = cloneDeep(this.value.File)
            this.showDialog = true
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
