import { cloneDeep } from 'lodash'
import { SENDING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, inputModes, percentageRegex, services, sizes } from '@/utils'

import d360generator from '@/components/services/providers/d360generator'
import rulesConditions from '@/components/misc/rules/conditions'
import button from '@/components/button'
import squircle from '@/components/icon/squircle'
import tag from '@/components/tag'
import textOverflow from '@/components/text/overflow'
import info from '@/components/info'

function renderTitle(h, { key }) {
  const property = this.properties.find(item => item.property === key)
  const item = Object.assign({}, { title: key }, cloneDeep(property))

  return h(
    'div',
    {
      class: 'grid grid-gap--2 ff'
    },
    [
      h(
        textOverflow,
        {
          props: {
            value: item.title
          }
        }
      ),
      h(
        'div',
        {
          class: 'small text--grey'
        },
        [ item.description ]
      )
    ]
  )
}
function renderValue(h, item) {
  let label = item.value
  switch (item.key) {
    case 'd360ApiKeys': {
      label = Object.keys(item.value).length
      break
    }
    case 'forceMessageStatus': {
      label = item.value.status ? this.getTranslate(`${services.sendings}.statuses.${item.value.status}`) : undefined
      break
    }
    case 'megafonTarget_campaignTimeWindowHrs': {
      label = item.value.join(' - ')
      break
    }
  }

  return h(
    'div',
    {
      class: 'fjcfe',
      style: { 'max-width': '150px' }
    },
    [
      h(
        tag,
        {
          props: {
            label,
            size: sizes.tiny,
            color: colors.primary
          }
        }
      )
    ]
  )
}
function renderRemoveButton(h, index) {
  return h(
    button,
    {
      class: { 'hover-child': this.viewport.breakpoint.mdUp },
      props: {
        icon: icons.remove,
        mode: buttonModes.flat,
        color: colors.error
      },
      on: {
        click: () => {
          this.removeField(index)
        }
      }
    }
  )
}
function renderItem(h, item, index) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 hover-parent hover-parent--opacity',
      style: { 'grid-template-columns': '1fr auto' }
    },
    [
      h(
        'div',
        {
          class: 'faic grid grid-gap--8 hover-background cursor-pointer border--rounded py-2 px-3',
          style: { gridTemplateColumns: '1fr auto' },
          on: {
            click: () => {
              this.edit(index)
            }
          }
        },
        [
          renderTitle.call(this, h, item),
          renderValue.call(this, h, item, index)
        ]
      ),

      renderRemoveButton.call(this, h, index)
    ]
  )
}
function renderItems(h) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [ this.proxy.map((item, index) => renderItem.call(this, h, item, index)) ]
    )
  } else {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic',
        style: { 'grid-template-columns': 'auto 1fr' }
      },
      [
        h(
          squircle,
          {
            props: {
              icon: icons.search_off,
              color: colors.grey,
              size: sizes.medium
            }
          }
        ),

        h(
          'div',
          {
            class: 'grid grid-gap--2 ff'
          },
          [
            h(
              'div',
              {
                class: 'body-2'
              },
              [ this.getTranslate(`${services.providers}.titles.properties.notFound`) ]
            ),
            h(
              'div',
              {
                class: 'small text--grey'
              },
              [ this.getTranslate(`${services.providers}.hints.properties.notFound`) ]
            )
          ]
        )
      ]
    )
  }
}

function renderKeyField(h) {
  return h(
    components.select,
    {
      props: {
        value: this.payload.key,
        label: this.getTranslate('misc.key'),
        hint: this.$hint,
        items: this.properties.filter(this.propertiesFilter).map(item => {
          return {
            title: item.property,
            value: item.property,
            group: item.group,
            description: item.description
          }
        }),
        itemsDisabled: this.proxy.map(({ key }) => key),
        searchValidation: this.searchValidator,
        loading: this.propertiesLoading,
        disabled: !this.$processor || this.propertiesLoading,
        mode: inputModes['outline-label'],
        dense: true,
        rounded: true,
        clearable: true,
        combobox: true,
        autocomplete: true
      },
      on: {
        input: event => {
          this.payload.key = event
        }
      },
      scopedSlots: {
        item: ({ item }) => renderTitle.call(this, h, { key: item.title }, true)
      }
    }
  )
}
function renderValueField(h) {
  if (this.payload.key) {
    const { type, property } = this._getProperty()
    let mask = type === 'number' ? /^\d+$/ : undefined
    if (property === 'failurePercent') {
      mask = percentageRegex
    }
    switch (type) {
      case 'boolean': {
        return h(
          components.switch,
          {
            props: {
              label: this.getTranslate(`misc.${this.payload.value}`),
              value: this.payload.value,
              outline: true
            },
            on: {
              input: event => {
                this.payload.value = event
              }
            }
          }
        )
      }
      default: {
        switch (property) {
          case 'd360ApiKeys': {
            return h(
              d360generator,
              {
                props: {
                  value: this.payload.value
                },
                on: {
                  input: event => {
                    this.payload.value = event
                  }
                }
              }
            )
          }

          case 'forceMessageStatus': {
            return h(
              'div',
              {
                class: 'grid'
              },
              [
                h(
                  components.select,
                  {
                    props: {
                      value: this.payload.value.status,
                      label: this.getTranslate(`${services.sendings}.labels.status`),
                      items: SENDING_STATUS.map(status => {
                        return {
                          title: this.getTranslate(`${services.sendings}.statuses.${status}`),
                          value: status
                        }
                      }),
                      mode: inputModes['line-label'],
                      dense: true,
                      rounded: true,
                      clearable: true,
                      details: false
                    },
                    on: {
                      input: event => {
                        this.payload.value.status = event
                      }
                    }
                  }
                ),

                h(
                  rulesConditions,
                  {
                    props: {
                      value: this.payload.value.conditions,
                      whitelist: [ 'mccmnc' ]
                    },
                    on: {
                      input: event => {
                        this.payload.value.conditions = event
                      }
                    }
                  }
                )
              ]
            )
          }

          case 'megafonTarget_campaignTimeWindowHrs': {
            const hourRegExp = /^([01]?[0-9]|2[0-4])$/

            return h(
              'div',
              {
                class: 'grid grid-gap--8 grid-cols--2'
              },
              [
                h(
                  components['text-field'],
                  {
                    props: {
                      value: this.payload.value[0],
                      label: this.getTranslate('misc.measure.time.from'),
                      hint: this.getTranslate('misc.hints.from0To24'),
                      suffix: this.getTranslate('misc.measure.time.short.hours'),
                      mode: inputModes['outline-label'],
                      dense: true,
                      rounded: true,
                      clearable: true,
                      mask: hourRegExp
                    },
                    on: {
                      input: event => {
                        this.payload.value[0] = event
                      }
                    }
                  }
                ),

                h(
                  components['text-field'],
                  {
                    props: {
                      value: this.payload.value[1],
                      label: this.getTranslate('misc.measure.time.to'),
                      hint: this.getTranslate('misc.hints.from0To24'),
                      suffix: this.getTranslate('misc.measure.time.short.hours'),
                      mode: inputModes['outline-label'],
                      dense: true,
                      rounded: true,
                      clearable: true,
                      mask: hourRegExp
                    },
                    on: {
                      input: event => {
                        this.payload.value[1] = event
                      }
                    }
                  }
                )
              ]
            )
          }

          case '_testPayloadText': {
            return h(
              components.textarea,
              {
                props: {
                  value: this.payload.value,
                  label: this.getTranslate('misc.value'),
                  mode: inputModes['outline-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: false
                },
                on: {
                  input: event => {
                    this.payload.value = event
                  }
                }
              }
            )
          }

          default: {
            return h(
              components['text-field'],
              {
                props: {
                  value: this.payload.value,
                  label: this.getTranslate('misc.value'),
                  mode: inputModes['outline-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  autofocus: true,
                  details: false,
                  mask
                },
                on: {
                  input: event => {
                    this.payload.value = event
                  }
                }
              }
            )
          }
        }
      }
    }
  }
}
function renderDialogBody(h) {
  if (!this.$processor) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${services.providers}.warnings.server`),
          color: colors.error
        }
      }
    )
  }

  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-3'
    },
    [
      renderKeyField.call(this, h),
      renderValueField.call(this, h)
    ]
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe faic grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            mode: buttonModes.flat,
            label: this.getTranslate('misc.buttons.cancel')
          },
          on: {
            click: () => {
              this.cancel()
            }
          }
        }
      ),

      h(
        button,
        {
          props: {
            mode: buttonModes.flat,
            color: colors.primary,
            label: this.getTranslate(`misc.buttons.${this.editMode ? 'update' : 'add'}`)
          },
          on: {
            click: () => {
              if (this.editMode) {
                this.update()
              } else {
                this.add()
              }
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${services.providers}.titles.properties.${this.editMode ? 'edit' : 'add'}`),
        value: this.showDialog,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 400 : undefined
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderDialogBody.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        button,
        {
          props: {
            icon: icons.add,
            mode: buttonModes.flat,
            color: colors.primary,
            label: this.getTranslate(`${services.providers}.buttons.add.property`)
          },
          on: {
            click: () => {
              this.showDialog = true
            }
          },
          scopedSlots: {
            dialog: () => renderDialog.call(this, h)
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.card,
    {
      class: 'grid grid-gap--8 pt-3 px-2 pb-2',
      props: {
        title: this.getTranslate(`${services.providers}.hints.properties.hint`),
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [
      renderItems.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
