import eventsHistoryCol from '@/components/templates/service/cols/eventsHistory'

function renderEventsHistoryCol(h) {
  return h(
    eventsHistoryCol,
    {
      props: {
        service: this.service,
        id: this.id
      }
    }
  )
}

export default function(h) {
  try {
    return h(
      require(`@/components/services/${this.service}/cols/second`).default,
      {
        props: { value: this.value }
      }
    )
  } catch (error) {
    return renderEventsHistoryCol.call(this, h)
  }
}

