import Vue from 'vue'

import { cloneDeep, defaultsDeep } from 'lodash'
import { EMPTY_REQUISITES, REST_ACTIONS } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, globalErrorHandler, globalErrorProcessor, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'UsersRequisites',

  mixins: [
    generateServices({
      name: services.requisites,

      inputFilter(result) {
        return defaultsDeep(result, cloneDeep(EMPTY_REQUISITES)) // потому что с сервера приходит урезанный payload
      },

      find: {
        defaultFilter: {
          OwnerId: undefined
        },
        defaultPagination: {
          limit: 10
        },

        alwaysCreateFromWebSocket: true,
        alwaysUpdateFromWebSocket: true,
        alwaysRemoveFromWebSocket: true
      }
    })
  ],

  props: {
    OwnerId: String
  },

  data() {
    return {
      requisiteId: undefined,
      dialogType: undefined,

      showDialog: false
    }
  },

  mounted() {
    this.restData[services.requisites].find.filter.OwnerId = this.OwnerId
  },

  methods: {
    async get(id) {
      if (this.checkPermissions(`advanced.${services.requisites}.get`, permissionPresets.meUp)) {
        if (id) {
          try {
            await this.rest[services.requisites].get(id)
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          } finally {
            this.dialogType = REST_ACTIONS.update
            this.showDialog = true
          }
        }
      }
    },

    async create() {
      if (this.checkPermissions(`advanced.${services.requisites}.create`, permissionPresets.meUp)) {
        if (this.restData[services.requisites].create.isValid) {
          try {
            const result = await this.rest[services.requisites].create(Object.assign({ OwnerId: this.OwnerId }, this.restData[services.requisites].create.data))
            if (result) {
              this.showDialog = false
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },

    async update() {
      if (this.checkPermissions(`advanced.${services.requisites}.update`, permissionPresets.meUp)) {
        if (this.restData[services.requisites].update.isValid) {
          try {
            const result = await this.rest[services.requisites].update(this.restData[services.requisites].get.data.id, this.restData[services.requisites].get.data)
            if (result) {
              this.showDialog = false
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },

    async remove(id) {
      if (this.checkPermissions(`advanced.${services.requisites}.remove`, permissionPresets.meUp)) {
        if (id) {
          try {
            await this.rest[services.requisites].remove(id)
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },

    editClickHandler(id) {
      if (this.checkPermissions(`advanced.${services.requisites}.get`, permissionPresets.resellerUp)) {
        Vue.router.push({
          name: `${services.requisites}.single`,
          params: { id }
        })
      } else if (this.checkPermissions(`advanced.${services.requisites}.get`, permissionPresets.me)) {
        this.get(id)
      }
    }
  },

  render
}
