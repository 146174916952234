export const orderDirections = {
  ASC: 'ASC',
  DESC: 'DESC'
}

export const alphabets = {
  latin: 'latin',
  cyrillic: 'cyrillic'
}

