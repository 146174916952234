import { cloneDeep, isEmpty } from 'lodash'

import { BILLINGS_FILTER, downloadable, generateServices, services } from '@/utils'

import render from './render'

export default {
  name: 'Details',

  mixins: [
    generateServices({
      name: services.billings,
      get: false,
      find: {
        bucketEnabled: true,
        defaultFilter: cloneDeep(BILLINGS_FILTER),
        disableWatcherFilter: true
      },
      create: false,
      update: false,
      remove: false
    }),

    downloadable({ permissionAccept: `advanced.${services.billings}.find` })
  ],

  props: {
    filter: {
      type: Object,
      default: () => cloneDeep(BILLINGS_FILTER),
      required: true
    }
  },

  data() {
    return {
      showDialog: false,
      source: undefined
    }
  },

  watch: {
    filter: {
      handler() {
        this.restData[services.billings].find.filter = cloneDeep(this.filter)
        if (this.restData[services.billings].find.pagination.offset !== 0) {
          this.restData[services.billings].find.pagination.offset = 0
        }
      },
      deep: true
    }
  },

  mounted() {
    if (!isEmpty(this.$route.params)) {
      this.restData[services.billings].find.filter.id = this.$route.params.id
      this.restData[services.billings].find.filter.createdAt = this.$route.params.createdAt
      this.rest[services.billings].find()
    }
  },

  render
}
