import { colors, inputModes, services } from '@/utils'

import info from '@/components/info'

function renderTitleField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.title,
        label: this.getTranslate(`${services.credentials}.labels.title`),
        mode: inputModes['line-label'],
        rounded: true,
        dense: true,
        clearable: true,
        required: true,
        details: false,
        autofocus: true,
        mask: /^.{0,255}$/
      },
      on: {
        input: event => {
          this.proxy.title = event
        }
      }
    }
  )
}

function renderInfo(h) {
  if (this.isNew) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${services.credentials}.hints.info`),
          color: colors.warning
        }
      }
    )
  }
}

function renderUsernameField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.records.username,
        label: this.getTranslate(`${services.credentials}.labels.records.username`),
        mode: inputModes['line-label'],
        autocomplete: 'username',
        rounded: true,
        dense: true,
        clearable: true,
        details: false,
        required: !!this.proxy.records.username || !this.recordsValidation
      },
      on: {
        input: event => {
          this.proxy.records.username = event
        }
      }
    }
  )
}

function renderPasswordField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.records.password,
        label: this.getTranslate(`${services.credentials}.labels.records.password`),
        mode: inputModes['line-label'],
        autocomplete: 'new-password',
        type: this.passwordVisibility ? 'password' : 'text',
        afterIcon: this.passwordVisibility ? 'visibility' : 'visibility_off',
        afterIconCallback: () => {
          this.passwordVisibility = !this.passwordVisibility
        },
        clearable: true,
        dense: true,
        rounded: true,
        details: false,
        required: !!this.proxy.records.password || !this.recordsValidation
      },
      on: {
        input: event => {
          this.proxy.records.password = event
        }
      }
    }
  )
}

function renderTokenField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.records.token,
        label: this.getTranslate(`${services.credentials}.labels.records.token`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        clearable: true,
        details: false,
        required: !!this.proxy.records.token || !this.recordsValidation
      },
      on: {
        input: event => {
          this.proxy.records.token = event
        }
      }
    }
  )
}

function renderApikeyField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.records.apikey,
        label: this.getTranslate(`${services.credentials}.labels.records.apikey`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        clearable: true,
        details: false,
        required: !!this.proxy.records.apikey || !this.recordsValidation
      },
      on: {
        input: event => {
          this.proxy.records.apikey = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid pa-3'
    },
    [
      renderTitleField.call(this, h),
      renderInfo.call(this, h),
      renderUsernameField.call(this, h),
      renderPasswordField.call(this, h),
      renderTokenField.call(this, h),
      renderApikeyField.call(this, h)
    ]
  )
}
