import fileEdit from '@/components/services/contracts/item/buttons/file/edit'

export default function(h) {
  return h(
    fileEdit,
    {
      props: {
        value: this.value
      }
    }
  )
}
