import { serviceTemplate, services } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.keywordLists,

      permission: {
        get: { serviceName: services.keywords },
        remove: { serviceName: services.keywords },
        create: { serviceName: services.keywords },
        update: { serviceName: services.keywords }
      },

      has: {
        clone: false,
        filter: false
      }
    })
  ]
}
