import Vue from 'vue'

import { colors, components, globalErrorHandler, globalErrorProcessor, icons, nestjsServices, permissionPresets, sizes } from '@/utils'

import render from './render'

export default {
  name: 'ButtonServiceRemove',

  props: {
    id: {
      type: String,
      required: true
    },

    service: {
      type: String,
      required: true
    },

    labeled: {
      type: Boolean,
      default: true
    },

    component: {
      type: String,
      default: 'btn',
      validator: value => {
        return [ components.button, components['list-item'], 'btn' ].includes(value)
      }
    },

    permissions: {
      type: Object,
      default: () => ({})
    },

    icon: {
      type: String,
      default: icons.delete,
      validator: value => {
        return Object.values(icons).includes(value)
      }
    },
    size: {
      type: String,
      default: sizes.medium,
      validator: value => {
        return Object.values(sizes).includes(value)
      }
    },
    color: {
      type: String,
      default: colors.error,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    callback: {
      type: Function,
      default: undefined
    }
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    $permissions() {
      return {
        path: this.permissions.path ?? `advanced.${this.service}.remove`,
        accept: this.permissions.accept ?? permissionPresets.resellerUp
      }
    }
  },

  methods: {
    async remove() {
      if (this.checkPermissions(this.$permissions.path, this.$permissions.accept)) {
        if (typeof this.callback === 'function') {
          await this.callback()
        } else if (this.id && this.service) {
          try {
            this.loading = true
            const path = []
            if (Object.keys(nestjsServices).includes(this.service)) {
              path.push('n')
            }
            path.push(this.service)
            await Vue.$GRequest.remove(path.join('/'), this.id)
            this.addSnackbar({
              text: this.getTranslate(`${this.service}.snackbars.removed`),
              type: 'warning'
            })
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          } finally {
            this.loading = false
          }
        }
      }
    }
  },

  render
}
