import { components, inputModes } from '@/utils'

export default function(h, { path, serviceName }) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.${path}`),
        hint: this.getTranslate(`${serviceName}.hints.${path}`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
