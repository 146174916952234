export default {
  title: 'Routing groups',

  titles: {
    create: 'Create routing group'
  },

  routingGroup: 'Routing group',
  routingGroups: 'Routing groups',
  one: 'Routing group',
  many: 'Routing groups',

  labels: {
    title: 'Title',
    type: 'Type',
    routingRule: 'Routing rule',
    route: 'Route',
    search: 'Search',
    description: 'Description',
    isActive: 'Is active'
  },

  tabs: {
    eventsHistory: 'Events history'
  },

  dialogs: {
    new: 'New routing group'
  },

  buttons: {
    add: {
      route: 'Add route',
      routingRule: 'Add routing rule'
    }
  },

  hints: {
    notFound: 'Routing groups not found'
  },

  contents: {
    confirm: {
      remove: 'Confirm remove {title}?'
    }
  },

  tooltips: {
    isActive: 'Activate or deactivate a route group.',
    favorites: 'Make the routing group a favorite',
    routingRules: 'List of routing rules',
    remove: 'Remove routing group from selected groups list?',

    filter: {
      isActive: 'Show only active or inactive routing groups'
    }
  },

  subtitles: {
    routingRules: 'Routing rules'
  },

  snackbars: {
    created: 'Routing group successfully created.',
    updated: 'Routing group successfully updated.',
    removed: 'Routing group successfully removed.'
  }
}
