import { buttonModes, colors, components, icons, popperPlacements, services } from '@/utils'

import button from '@/components/button'
import rulePrices from '@/components/services/tariffs/rules/rule/prices'

function renderActionsMenuActivator(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate(`${services.tariffs}.actions.${this.proxy.action}`),
        mode: buttonModes.flat,
        color: this.proxy.action === 'decrease' ? colors.error : colors.success
      },
      slot: 'activator'
    }
  )
}
function renderActionsItem(h, action) {
  return h(
    components['list-item'],
    {
      props: {
        icon: action === 'decrease' ? icons.add : icons.remove,
        color: action === 'decrease' ? colors.error : colors.success,
        label: this.getTranslate(`${services.tariffs}.actions.${action}`),
        dense: true
      },
      on: {
        click: () => {
          this.proxy.action = action
        }
      }
    }
  )
}
function renderActionsList(h) {
  return h(
    components.list,
    {
      props: {
        dense: true,
        rounded: true
      }
    },
    [ 'decrease', 'increase' ].map(action => {
      return renderActionsItem.call(this, h, action)
    })
  )
}
function renderActionsMenu(h) {
  return h(
    components.menu,
    {
      props: {
        rounded: true,
        offsetDistance: 8,
        placement: popperPlacements['bottom-start']
      }
    },
    [
      renderActionsMenuActivator.call(this, h),
      renderActionsList.call(this, h)
    ]
  )
}
function renderRulePrices(h) {
  return h(
    rulePrices,
    {
      props: {
        value: this.proxy.price,
        source: this.source
      }
    }
  )
}
export default function(h) {
  return h(
    'div',
    {
      class: `${services.tariffs}-rule__actions`
    },
    [
      renderActionsMenu.call(this, h),
      renderRulePrices.call(this, h)
    ]
  )
}
