import proxy from '@sigma-legacy-libs/g-proxy'

import { generateServices, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'PublicDocument',

  mixins: [
    proxy(),
    generateServices({
      name: services.storage,

      get: {
        params: {
          query: {
            $scope: [ 'Owner' ],
            return: 'meta'
          }
        }
      },

      find: false,
      create: false,
      update: false,
      remove: false
    })
  ],

  props: {
    title: String,
    hint: String,
    icon: String
  },

  data() {
    return {
      showFileManager: false,

      currentEditFile: undefined,
      googleFileURL: undefined,
      showEditDialog: false,
      editDialogType: undefined
    }
  },

  watch: {
    proxy() {
      this.getFile()
    }
  },

  mounted() {
    this.getFile()
  },

  methods: {
    async getFile() {
      if (this.proxy) {
        try {
          await this.rest[services.storage].get(this.proxy)
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    }
  },

  render
}
