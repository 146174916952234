import Vue from 'vue'

import { globalErrorHandler, globalErrorProcessor, processFilter, services } from '@/utils'

import render from './render'

export default {
  name: 'Group001',

  props: {
    filter: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,

      report: [],
      order: {}
    }
  },

  computed: {
    total() {
      const result = {
        count_multiplier_complete: 0,
        count_multiplier_invalid: 0,
        count_multiplier_pending: 0,
        count_multiplier_refunded: 0,
        count_multiplier_total: 0,
        sum_complete: 0,
        sum_invalid: 0,
        sum_pending: 0,
        sum_refunded: 0,
        sum_total: 0
      }

      this.report.forEach(item => {
        result.count_multiplier_complete += item.count_multiplier_complete
        result.count_multiplier_invalid += item.count_multiplier_invalid
        result.count_multiplier_pending += item.count_multiplier_pending
        result.count_multiplier_refunded += item.count_multiplier_refunded
        result.count_multiplier_total += item.count_multiplier_total
        result.sum_complete += item.sum_complete
        result.sum_invalid += item.sum_invalid
        result.sum_pending += item.sum_pending
        result.sum_refunded += item.sum_refunded
        result.sum_total += item.sum_total
      })

      return result
    }
  },

  watch: {
    filter: {
      handler() {
        this.getReport()
      },
      deep: true
    }
  },

  methods: {
    async getReport() {
      try {
        this.loading = true
        const { data } = await Vue.$GRequest.find(services.reports, { query: processFilter(this.filter, { $preset: 'billings.group001' }) })
        if (data) {
          this.report = data
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
