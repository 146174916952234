import { components } from '@/utils'

function renderHeader(h) {
  if (Array.isArray(this.tabs) && this.tabs.length) {
    return h(
      components['tabs-header'],
      {
        props: {
          value: this.proxy,
          tabs: this.tabs
        },
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}

function renderContent() {
  let slot = this.$slots.default
  if (typeof this.$scopedSlots.default === 'function') {
    slot = this.$scopedSlots.default({ current: this.proxy })
  }
  if (Array.isArray(slot)) {
    for (const content of slot) {
      if (content.data.key === this.proxy) {
        return content
      }
    }
  }
}

function renderScopedSlot(h, name) {
  if (typeof this.$scopedSlots[name] === 'function') {
    const nodes = this.$scopedSlots[name]({ current: this.proxy })
    if (Array.isArray(nodes) && nodes.length) {
      for (const content of nodes) {
        if (content.data.key === this.proxy) {
          return h(
            'div',
            {
              class: `${components.tabs}-${name}`
            },
            [ content ]
          )
        }
      }
    }
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: components.tabs
    },
    [
      renderHeader.call(this, h),
      renderContent.call(this, h),

      [ 'body', 'footer' ].map(slot => renderScopedSlot.call(this, h, slot))
    ]
  )
}
