import { buttonModes, catchSymbolsMenus, colors, components, getEmptyFallback, icons, inputModes, services } from '@/utils'

import button from '@/components/button'
import selectorOwner from '@/components/selector/owner'
import SendingsCreateForm from '@/components/services/sendingsCreate/form'
import SendingsCreateFallbacks from '@/components/services/sendingsCreate/fallbacks'
import SendingsCreateRecipients from '@/components/services/sendingsCreate/recipients'

function renderIsActiveField(h) {
  return h(
    'div',
    {
      class: 'fjcc faic square--38'
    },
    [
      h(
        components.switch,
        {
          props: {
            value: this.proxy.isActive,
            tooltip: this.getTranslate(`${services.templates}.tooltips.isActive`)
          },
          on: {
            input: event => {
              this.proxy.isActive = event
            }
          }
        }
      )
    ]
  )
}

function renderTemplateBasicFields(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { gridTemplateColumns: '1fr 38px' }
    },
    [
      h(
        'div',
        {
          class: {
            'grid grid-gap--8 faic': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp && this.checkPermissions(`advanced.${services.templates}.update`)
          }
        },
        [
          h(
            components['text-field'],
            {
              props: {
                value: this.proxy.title,
                label: this.getTranslate(`${services.templates}.labels.title`),
                error: this.errors.title,
                mode: inputModes['line-label'],
                rounded: true,
                details: false,
                autofocus: true,
                required: true
              },
              on: {
                input: event => {
                  this.proxy.title = event
                },
                keypress: event => {
                  if (event.keyCode === 13) {
                    this.$emit('submit', this.proxy)
                  }
                }
              }
            }
          ),

          h(
            selectorOwner,
            {
              props: {
                value: this.proxy.OwnerId
              },
              on: {
                input: event => {
                  this.proxy.OwnerId = event
                }
              }
            }
          )
        ]
      ),

      renderIsActiveField.call(this, h)
    ]
  )
}

function renderSendingsCreateRecipients(h) {
  return h(
    SendingsCreateRecipients,
    {
      props: {
        value: this.proxy.recipient
      },
      on: {
        input: event => {
          this.proxy.recipient = event
        }
      }
    }
  )
}
function renderSendingsCreateForm(h) {
  if (this.fallbacks) {
    return h(
      SendingsCreateFallbacks,
      {
        props: {
          value: this.proxy,
          defaultValue: getEmptyFallback(),
          rounded: true,
          outline: true
        },
        scopedSlots: {
          default: ({ value }) => {
            return h(
              SendingsCreateForm,
              {
                props: {
                  value,
                  types: this.sendingTypes,
                  fallback: true,
                  tags: catchSymbolsMenus.$,
                  customMenu: catchSymbolsMenus.custom.$
                },
                on: {
                  input: event => {
                    value = event
                  }
                }
              }
            )
          }
        }
      }
    )
  } else {
    return [
      h(
        SendingsCreateForm,
        {
          props: {
            value: this.proxy,
            types: this.sendingTypes,
            isTemplate: true,
            tags: catchSymbolsMenus.$,
            customMenu: catchSymbolsMenus.custom.$
          },
          on: {
            input: event => {
              this.proxy = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe facfe'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.fallback'),
                icon: icons.add,
                mode: buttonModes.outline,
                color: colors.primary
              },
              on: {
                click: () => {
                  this.setFallbacks()
                }
              }
            }
          )
        ]
      )
    ]
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      renderTemplateBasicFields.call(this, h),
      renderSendingsCreateRecipients.call(this, h),
      renderSendingsCreateForm.call(this, h)
    ]
  )
}
