import { generateUniqueKey, inputModes, sizes } from '@/utils'

import squircle from '@/components/icon/squircle'
import selectorSendingType from '@/components/selector/sendingType'

function renderSelectorSendingType(h) {
  return h(
    selectorSendingType,
    {
      props: {
        value: this.proxy.type,
        label: this.getTranslate(`${this.serviceName}.labels.type`),
        permission: `advanced.${this.serviceName}.update`,
        mode: inputModes['line-label'],
        required: true
      },
      on: {
        input: event => {
          this.proxy.type = event
        }
      },
      key: generateUniqueKey()
    }
  )
}
function renderTypeIcon(h, type) {
  if (type) {
    return h(
      squircle,
      {
        props: {
          iconSVG: type,
          color: type,
          size: sizes.medium
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'flex grid-gap--8'
    },
    [
      renderSelectorSendingType.call(this, h),
      renderTypeIcon.call(this, h, this.proxy.type)
    ]
  )
}
