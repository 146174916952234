import { get } from 'lodash'

export const tableHeaderParser = function(headers = []) {
  return headers.reduce((result, header) => {
    if (get(header, 'show', true)) {
      result.push({
        title: this.getTranslate(get(header, 'title')),
        value: get(header, 'value'),
        align: get(header, 'align', 'center'),
        class: get(header, 'class', ''),
        sortable: get(header, 'sortable', false),
        width: get(header, 'width', ''),
        show: get(header, 'show', true)
      })
    }

    return result
  }, [])
}
