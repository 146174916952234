import { components } from '@/utils'

import button from '@/components/button'

export default function(h) {
  if (this.view === components['list-item']) {
    return h(
      components['list-item'],
      {
        props: {
          label: this.label,
          icon: this.icon,
          color: this.color,
          disabled: this.disabled
        }
      }
    )
  }

  return h(
    button,
    {
      props: this.$props,
      on: { click: () => this.clickHandler() }
    }
  )
}
